import { KmsTypeFilterBase } from "./KmsTypeFilterBase";
import { KmsTypeFilterItemDate } from "./item";

/**
 * ts implementation of Kms_Type_Esearch_Filter_DatePicker
 */
export interface KmsTypeFilterDate extends KmsTypeFilterBase<KmsTypeFilterItemDate> {
    dateFilter: true;
}

// noinspection PointlessBooleanExpressionJS
export const isKmsTypeFilterDate = (value: KmsTypeFilterBase<any>): value is KmsTypeFilterDate =>
    (value as KmsTypeFilterDate).dateFilter === true;
