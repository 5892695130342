import styled from "@emotion/styled";
import { Modal, modalClasses } from "@kaltura/ds-react-components";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { Search24Icon } from "@kaltura/ds-react-icons";

export const StyledModal = styled(Modal)(({ theme }) => ({
    [`& .${modalClasses.paper}`]: {
        width: 443,
        maxHeight: "70%",
        minHeight: 319,
        [theme.breakpoints.down("sm")]: {
            width: "100%",
        },
    },
}));

export const StyledListContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
    overflowY: "auto",
}));

export const StyledSearch24Icon = styled(Search24Icon)(({ theme }) => ({
    color: theme.kaltura.palette.tone3,
}));
