import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import EarnedBadge from "./EearnedBadge";
import { Typography } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { BadgeProps } from "../../../common/types";
import { KmsTypeComponent } from "@kaltura/mediaspace-shared-types";
import { ErrorBoundary } from "@kaltura/mediaspace-shared-ui";

type EarnedBadgesProps = {
    badges: BadgeProps[];
    titleAreaButtons: KmsTypeComponent[];
};

const StyledEarnedBadges = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
    color: theme.kaltura.palette.tone1,
}));

const StyledList = styled(Box)(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    marginTop: theme.spacing(3),
}));

const StyledTitleAreaContainer = styled(Box)({
    display: "flex",
    flexDirection: "row",
});

const StyledTitle = styled(Typography)({
    flex: "1 1 0",
});

const StyledTitleButtonsContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
}));

const EarnedBadges = (props: EarnedBadgesProps) => {
    const { badges, titleAreaButtons } = props;
    const badgesCount = badges.length;
    const baseClassName = "kms-ds-personal-badges-earned-badges";

    return (
        <StyledEarnedBadges className={baseClassName}>
            <StyledTitleAreaContainer>
                <StyledTitle variant={"h5"} component={"h2"}>
                    {translate("%1 earned badges", [badgesCount])}
                </StyledTitle>
                <StyledTitleButtonsContainer>
                    {titleAreaButtons && titleAreaButtons.length > 0
                        ? titleAreaButtons.map(({ name, component: Component, props }) => (
                              <ErrorBoundary key={name} fallback={<></>}>
                                  <Component {...props} className={`${baseClassName}-title-button`} />
                              </ErrorBoundary>
                          ))
                        : null}
                </StyledTitleButtonsContainer>
            </StyledTitleAreaContainer>
            <StyledList>
                {badges.map((badge: BadgeProps) => (
                    <EarnedBadge name={badge.name} iconUrl={badge.iconUrl} description={badge.description} />
                ))}
            </StyledList>
        </StyledEarnedBadges>
    );
};

export default EarnedBadges;
