import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const StitchVideosIllustration = () => (
    <StyledIllustration
        fileName={"AddNewStitchVideos.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="95.166" y="88.666" width="76" height="47.3333" rx="8" className="Tone1" fill="#333333" />
        <rect x="83.166" y="69.333" width="76" height="53.3333" rx="8" fill="#006EFA" />
        <rect x="63.166" y="53.333" width="81.3333" height="53.3333" rx="8" fill="#FF9DFF" />
        <path
            d="M110.41 78.0245C111.931 78.9025 111.931 81.0975 110.41 81.9755L102.254 86.6848C100.733 87.5628 98.832 86.4653 98.832 84.7093L98.832 75.2907C98.832 73.5347 100.733 72.4372 102.254 73.3152L110.41 78.0245Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M49.8739 77.1309C50.3433 75.3019 48.5143 73.7272 46.7754 74.4631L36.1277 78.9693C34.1058 79.825 34.3415 82.7631 36.4739 83.2856L39.5255 84.0333C40.1031 84.1748 40.6026 84.5363 40.9175 85.0407L43.0058 88.3851C44.0682 90.0866 46.6515 89.687 47.1502 87.744L49.8739 77.1309Z"
            fill="#006EFA"
        />
        <path
            d="M23.9683 107.402C21.8491 104.339 21.2446 99.8406 23.9975 96.7408C25.8831 94.6177 29.3145 94.1223 31.9865 94.3122C35.4795 94.5603 40.2622 96.4036 41.6135 99.993C42.1584 101.44 41.5333 103.253 39.9832 103.724C38.3115 104.231 36.4337 103.082 35.5252 101.734C34.3346 99.9679 34.3028 97.6221 34.6022 95.5926C35.4189 90.055 38.8834 86.4336 42.8284 82.7437"
            className="sTone1"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <rect
            x="30.7227"
            y="123.754"
            width="32"
            height="16.5926"
            rx="8.2963"
            transform="rotate(-10.3169 30.7227 123.754)"
            fill="#FFD357"
        />
        <circle cx="40.3702" cy="130.43" r="1.77778" transform="rotate(-10.3169 40.3702 130.43)" fill="#333333" />
        <ellipse
            cx="47.9483"
            cy="129.051"
            rx="1.77778"
            ry="1.77778"
            transform="rotate(-10.3169 47.9483 129.051)"
            fill="#333333"
        />
        <ellipse
            cx="55.5284"
            cy="127.671"
            rx="1.77778"
            ry="1.77778"
            transform="rotate(-10.3169 55.5284 127.671)"
            fill="#333333"
        />
        <rect
            width="22.3139"
            height="22.3138"
            rx="11.1569"
            transform="matrix(0.975623 0.219453 0.219455 -0.975623 66.3242 165.77)"
            fill="#FF3D23"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M79.7382 155.005C79.7382 155.005 78.7061 152.333 76.6801 152.317C74.654 152.301 73.0947 154.566 73.9606 156.877C74.7445 158.969 77.5517 161.862 78.3585 162.376C78.443 162.43 78.5605 162.455 78.6936 162.442C81.4437 162.183 83.4579 160.644 84.7877 159.22C84.7877 159.22 86.9912 156.491 84.785 154.441C82.5788 152.392 79.7382 155.005 79.7382 155.005Z"
            fill="white"
        />
    </StyledIllustration>
);
