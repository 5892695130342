import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { Skeleton } from "@kaltura/mediaspace-shared-styled";
import { StyledModalActions, StyledModalContent } from "../../StyledModal";
import { Button } from "@kaltura/ds-react-components";
import { noop, translate } from "@kaltura/mediaspace-shared-utils";

const StyledPublishModalSkeleton = styled("div")(({ theme }) => ({
    paddingTop: theme.spacing(3),
}));

const StyledSkeletonRow = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    display: "inline-block",
    borderRadius: theme.kaltura.shape.roundness1,
    marginRight: theme.spacing(2),
}));

const StyledHr = styled("hr")(({ theme }) => ({
    marginTop: theme.spacing(2),
    borderColor: theme.kaltura.palette.tone4,
    border: 0,
    borderTop: `1px solid ${theme.kaltura.palette.tone4}`,
}));

/**
 * publish modal skeleton
 */
export interface PublishedModalSkeletonProps {
    onClose?: () => void;
}
export function PublishedModalSkeleton({ onClose }: PublishedModalSkeletonProps) {
    const theme = useTheme();

    return (
        <>
            <StyledModalContent>
                <StyledPublishModalSkeleton>
                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(3)} width={theme.spacing(9)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(3)} width={theme.spacing(9)} />
                    </StyledSkeletonRow>

                    <StyledHr />

                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(4)} width={"100%"} />
                    </StyledSkeletonRow>
                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"50%"} />
                    </StyledSkeletonRow>
                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"50%"} />
                    </StyledSkeletonRow>

                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"25%"} />
                    </StyledSkeletonRow>

                    <StyledSkeletonRow>
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                        <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"35%"} />
                    </StyledSkeletonRow>
                </StyledPublishModalSkeleton>
            </StyledModalContent>

            <StyledModalActions>
                <Button variant={"borderless"} color={"secondary"} onClick={onClose}>
                    {translate("Cancel")}
                </Button>
                <Button onClick={noop} disabled={true} size={"medium"}>
                    {translate("Save changes")}
                </Button>
            </StyledModalActions>
        </>
    );
}
