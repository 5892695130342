import { forwardRef, useCallback, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Container, StyledFormLabel } from "./CommonStyles";
import DateFieldWithTime, { DEFAULT_TIME_FORMAT } from "../form/date-field-with-time/DateFieldWithTime";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import objectSupport from "dayjs/plugin/objectSupport";

dayjs.extend(customParseFormat);
dayjs.extend(objectSupport);

export interface DateWithTimeFieldProps {
    id: string;
    label: string;
    description?: string;
    /**
     * short-date representation of selected date, according to given dateFormat,
     * followed by a space and time formatted according to DEFAULT_TIME_FORMAT
     */
    value?: string;
    dateFormat?: string;
}

/**
 * date field with time control for edit entry form
 * customdata[YesTime_date]	"02/16/2023"
 * customdata[YesTime_time]	"12:15 AM"
 */
export const DateWithTimeField = forwardRef<any, DateWithTimeFieldProps>(
    ({ id, label, description, value, dateFormat = "DD/MM/YYYY" }, ref) => {
        const { control, setValue, watch } = useFormContext();

        const convertValueToDate = useCallback(
            (value: string) => {
                // value is formatted like <date> <time>
                // DateFieldWithTime component takes Date object or null for empty date
                const m = dayjs(value, `${dateFormat} ${DEFAULT_TIME_FORMAT}`);
                return m.isValid() ? m : null;
            },
            [dateFormat]
        );

        const v = watch(id as "dummy");

        useEffect(() => {
            // set the value to the model as "_date", "_time"
            const value = convertValueToDate(v);
            const dateVal = value ? value.format(dateFormat) : undefined;
            const timeVal = value ? value.format(DEFAULT_TIME_FORMAT) : undefined;
            // "manually" set both values to form model
            setValue(`${id}_date` as "dummy", dateVal, {});
            setValue(`${id}_time` as "dummy", timeVal, {});
        }, [v]);

        return (
            <Container>
                <StyledFormLabel id={`label_${id}`} htmlFor={id} text={label} description={description} />
                <Controller
                    control={control}
                    name={id as "dummy"}
                    defaultValue={value}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <DateFieldWithTime
                            id={id}
                            dateFormat={dateFormat}
                            value={convertValueToDate(value)}
                            ref={ref}
                            onChange={(value) => {
                                // put updated value on form model
                                const dateVal = value ? value.format(dateFormat) : null;
                                const timeVal = value ? value.format(DEFAULT_TIME_FORMAT) : null;
                                onChange(dateVal && timeVal ? `${dateVal} ${timeVal}` : undefined);
                            }}
                            onBlur={onBlur}
                            ariaLabelledby={`label_${id}`}
                        />
                    )}
                />
            </Container>
        );
    }
);
