import { translate } from "@kaltura/ds-react-utils";
import styled from "@emotion/styled";
import { CSSProperties } from "react";
import { TextSliderProps } from "../text-slider/TextSlider";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";

export interface CaptchaProps {
    className?: string;
}

// This is used when hidden recaptcha is in use since we hide it completely to avoid keyboard focus order problems and
// have to display the google disclaimer.
export const CaptchaDisclaimer: React.FC<CaptchaProps> = ({ className }) => {
    const translatedDisclaimer = translate(
        "This site is protected by reCAPTCHA and the Google %1 Privacy Policy %2 and %3 Terms of Service %4 apply",
        [
            "<a href='https://policies.google.com/privacy'>",
            "</a>",
            "<a href='https://policies.google.com/terms'>",
            "</a>",
        ]
    );
    const StyledCaptchaTitle = styled("div")(({ theme }) => ({
        fontSize: "14px",
        textAlign: "center",
        color: theme.kaltura.palette.tone1,
        margin: theme.spacing(12, 2, 5, 2),
        [theme.breakpoints.down("sm")]: {
            marginTop: theme.spacing(0),
        },
        "& a": {
            color: theme.kaltura.palette.tone1,
            "&:hover": {
                color: theme.kaltura.palette.tone2,
                textDecoration: "none",
            },
        },
    }));

    return <StyledCaptchaTitle className={className}>{HtmlReactParser(translatedDisclaimer)}</StyledCaptchaTitle>;
};
