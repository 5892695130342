import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const UploadIllustration = () => (
    <StyledIllustration
        fileName={"AddNewUpload.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect x="23.8333" y="127.333" width="126" height="35.3333" rx="5.33333" fill="#FF9DFF" />
        <path
            d="M62.3718 117.382C59.0379 118.008 55.741 118.799 52.506 119.839C48.5736 121.104 44.685 122.777 41.578 125.507C38.4708 128.237 36.2145 132.162 36.2468 136.305C36.2828 140.971 39.2182 145.257 43.0279 147.933C46.8377 150.609 51.4349 151.899 55.9961 152.809C66.9706 154.998 78.2215 155.241 89.3858 155.993C100.55 156.745 111.841 158.051 122.233 162.214C132.624 166.378 142.142 173.725 146.866 183.902"
            className="sTone1"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M60.724 50.8783C60.724 47.9327 63.1118 45.5449 66.0573 45.5449H160.5C163.446 45.5449 165.833 47.9327 165.833 50.8783V108.878L177.264 138.054C178.635 141.552 176.055 145.333 172.298 145.333H68.7311C65.9613 145.333 63.6525 143.212 63.4171 140.453L60.724 108.878L60.724 50.8783Z"
            fill="#006EFA"
        />
        <rect x="66.5" y="51" width="93.3333" height="56" rx="4" className="Tone8" fill="white" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M85.6915 117.941C85.5642 116.758 86.4907 115.727 87.68 115.727H145.626C146.556 115.727 147.364 116.369 147.574 117.275L150.352 129.275C150.643 130.529 149.691 131.727 148.404 131.727H88.972C87.9503 131.727 87.0929 130.956 86.9835 129.941L85.6915 117.941Z"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M110.5 66.666C110.5 64.4569 112.291 62.666 114.5 62.666H133.167C135.376 62.666 137.167 64.4569 137.167 66.666V85.3327C137.167 87.5418 135.376 89.3327 133.167 89.3327H114.5C112.291 89.3327 110.5 87.5418 110.5 85.3327V66.666Z"
            className="Tone1"
            fill="#333333"
        />
        <rect x="95.1667" y="82" width="22" height="18.6667" rx="4" fill="#FFD357" />
        <rect x="89.1667" y="31.333" width="36.6667" height="41.3333" rx="4" fill="#FF3D23" />
        <path d="M107.371 64.6663V39.333" stroke="white" stroke-width="2" stroke-linecap="round" />
        <path
            d="M97.8333 49.333L107.436 39.333L117.167 49.333"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M28.4413 90.0003C28.4413 90.0003 27.9148 89.2714 27.1739 87.951L27.1873 88.0003C27.1873 88.0003 21.9161 86.9652 19.2013 82.5459C17.5275 79.8213 16.6277 76.1885 16.5018 71.6474C16.4916 71.278 16.4916 70.5727 17.4958 71.2026C19.9148 72.7201 21.9113 75.5723 23.4506 78.5014C22.3847 74.1598 21.934 68.8802 23.2826 63.1845C23.4469 62.667 23.6594 62.4142 24.1521 63.0203C28.4139 68.2626 30.4413 74.346 30.4413 80.9193C30.4413 80.9193 32.2322 69.9144 40.3196 64.9931C40.8063 64.697 41.8966 64.1103 41.8966 65.6687C41.8966 72.1121 40.4058 77.2216 37.4682 81.2481C39.7436 79.8798 42.8344 78.7646 47.3323 78.6119C47.6793 78.6001 48.4741 78.6001 47.6976 79.4007C43.0512 84.1912 38.2853 87.8826 32.5692 90.1064C32.5692 90.1064 31.2508 88.9031 31.4508 87.3246C27.5939 90.8469 28.4413 90.0003 28.4413 90.0003Z"
            className="Tone1"
            fill="#333333"
        />
        <rect x="16.7741" y="86.667" width="27.3333" height="23.3333" rx="6" fill="#006EFA" />
        <mask
            id="mask0_55669_12774"
            style={{ maskType: "luminance" }}
            maskUnits="userSpaceOnUse"
            x="16"
            y="86"
            width="29"
            height="24"
        >
            <rect x="16.7741" y="86.667" width="27.3333" height="23.3333" rx="6" fill="white" />
        </mask>
        <g mask="url(#mask0_55669_12774)">
            <path d="M16.7741 92.3333H44.1074" className="sTone8" stroke="white" stroke-width="2" />
            <path d="M16.7741 98.3333H44.1074" className="sTone8" stroke="white" stroke-width="2" />
            <path d="M16.7741 104.333H44.1074" className="sTone8" stroke="white" stroke-width="2" />
        </g>
    </StyledIllustration>
);
