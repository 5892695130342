import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const Quizllustration = () => (
    <StyledIllustration
        fileName={"AddNewQuiz.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="63.8335"
            y="54.7832"
            width="31.3333"
            height="15.3333"
            rx="7.66667"
            transform="rotate(-10.0165 63.8335 54.7832)"
            fill="#FFD357"
        />
        <ellipse
            cx="73.373"
            cy="60.8836"
            rx="1.66667"
            ry="1.66667"
            transform="rotate(-10.0165 73.373 60.8836)"
            fill="#333333"
        />
        <ellipse
            cx="80.5947"
            cy="59.6082"
            rx="1.66667"
            ry="1.66667"
            transform="rotate(-10.0165 80.5947 59.6082)"
            fill="#333333"
        />
        <ellipse
            cx="87.8164"
            cy="58.3328"
            rx="1.66667"
            ry="1.66667"
            transform="rotate(-10.0165 87.8164 58.3328)"
            fill="#333333"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M39.5667 87.4006C38.9776 82.6505 42.3507 78.3222 47.1008 77.7331V77.7331C51.8508 77.1439 56.1791 80.517 56.7683 85.2671V85.2671C57.3574 90.0172 53.9843 94.3455 49.2342 94.9346V94.9346C44.4841 95.5238 40.1559 92.1506 39.5667 87.4006V87.4006Z"
            fill="#FF3D23"
        />
        <path d="M45.2698 90.0525L51.0652 82.616" stroke="white" stroke-width="2" stroke-linecap="round" />
        <path d="M51.886 89.2322L44.4495 83.4368" stroke="white" stroke-width="2" stroke-linecap="round" />
        <rect
            x="65.1011"
            y="80.126"
            width="72.6667"
            height="82.6667"
            rx="6"
            transform="rotate(-8 65.1011 80.126)"
            fill="#006EFA"
        />
        <rect
            x="89.8774"
            y="88.7617"
            width="40.6667"
            height="6.66666"
            rx="3.33333"
            transform="rotate(-8 89.8774 88.7617)"
            fill="white"
        />
        <rect
            x="92.2896"
            y="105.927"
            width="40.6667"
            height="6.66666"
            rx="3.33333"
            transform="rotate(-8 92.2896 105.927)"
            fill="#333333"
        />
        <rect
            x="94.7021"
            y="123.091"
            width="40.6667"
            height="6.66666"
            rx="3.33333"
            transform="rotate(-8 94.7021 123.091)"
            fill="white"
        />
        <rect
            x="97.1143"
            y="140.256"
            width="40.6667"
            height="6.66666"
            rx="3.33333"
            transform="rotate(-8 97.1143 140.256)"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M73.6085 111.918C72.9936 107.543 76.042 103.498 80.4173 102.883V102.883C84.7925 102.268 88.8379 105.316 89.4528 109.691V109.691C90.0677 114.067 87.0193 118.112 82.644 118.727V118.727C78.2688 119.342 74.2234 116.294 73.6085 111.918V111.918Z"
            fill="#333333"
        />
        <path
            d="M77.6398 111.863L80.796 114.274L85.1204 107.607"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <rect
            x="75.3535"
            y="90.8027"
            width="6.66667"
            height="6.66666"
            rx="3.33333"
            transform="rotate(-7.99999 75.3535 90.8027)"
            stroke="white"
            stroke-width="2"
        />
        <rect
            x="80.1782"
            y="125.133"
            width="6.66667"
            height="6.66667"
            rx="3.33333"
            transform="rotate(-8 80.1782 125.133)"
            stroke="white"
            stroke-width="2"
        />
        <rect
            x="82.5903"
            y="142.297"
            width="6.66666"
            height="6.66666"
            rx="3.33333"
            transform="rotate(-8 82.5903 142.297)"
            stroke="white"
            stroke-width="2"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M103.485 39.9681C102.265 37.8007 104.06 35.1748 106.523 35.5261L123.459 37.9419C126.326 38.3509 127.007 42.1834 124.456 43.5544L120.083 45.9041C119.393 46.275 118.875 46.9012 118.641 47.6488L116.841 53.3857C116.055 55.8927 112.654 56.2493 111.365 53.9599L103.485 39.9681Z"
            fill="#006EFA"
        />
        <path
            d="M151.2 116.264C152.504 116.021 166.674 113.15 172.208 101.273C178.877 86.9628 168.996 71.8982 164.302 71.8982C161.882 71.8982 161.067 74.0068 162.332 75.8623C165.78 80.9223 175.971 75.661 180.567 70.075C188.151 60.8559 185.201 39.3392 167.916 37.6614C150.631 35.9836 149.087 50.5956 132.167 51.3333C122.394 51.7593 111.443 42.1183 111.443 42.1183"
            className="sTone1"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <rect
            x="17.27"
            y="52.9434"
            width="32"
            height="23.3333"
            rx="4"
            transform="rotate(-22.298 17.27 52.9434)"
            fill="#FF9DFF"
        />
        <path
            d="M22.9946 56.3604L35.2046 59.6766C36.1182 59.9247 37.0878 59.5583 37.6089 58.7679L45.2 47.2542"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </StyledIllustration>
);
