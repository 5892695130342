import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const movement = keyframes`
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    33% {
        transform: translate(120px, 70px) rotate(-90deg);
    }
    66% {
        transform: translate(0px, 140px) rotate(90deg);
    }
    100% {
        transform: translate(0px, 0px) rotate(0deg);
    }
`;

const AnimatedCircle = styled.circle`
    animation: ${movement} 5s infinite;
`;

/* Animated AI triangle */
export const AnimatedAiIllustration = () => {
    return (
        <svg width="121" height="140" viewBox="0 0 121 140" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask
                id="mask0_243_120"
                style={{ maskType: "alpha" }}
                maskUnits="userSpaceOnUse"
                x="0"
                y="1"
                width="119"
                height="138"
            >
                <path
                    d="M-7.78135e-06 3.47623L-1.3597e-05 136.524C-1.36645e-05 138.066 1.67191 139.028 3.00523 138.253L117.426 71.729C118.752 70.9579 118.752 69.0421 117.426 68.271L3.00523 1.74723C1.67191 0.972039 -7.71393e-06 1.93394 -7.78135e-06 3.47623Z"
                    fill="white"
                />
            </mask>
            <g mask="url(#mask0_243_120)">
                <path
                    d="M-7.78135e-06 3.47623L-1.3597e-05 136.524C-1.36645e-05 138.066 1.67191 139.028 3.00523 138.253L117.426 71.729C118.752 70.9579 118.752 69.0421 117.426 68.271L3.00523 1.74723C1.67191 0.972039 -7.71393e-06 1.93394 -7.78135e-06 3.47623Z"
                    fill="#006EFA"
                />
                <g filter="url(#filter0_f_243_120)">
                    <AnimatedCircle r="120" fill="url(#paint0_linear_243_120)" />
                </g>
                <g filter="url(#filter1_bf_243_120)">
                    <path
                        d="M4.94788e-06 61.346L7.57557e-06 1.23128C7.59931e-06 0.688275 0.591446 0.352042 1.05805 0.629782L117.6 70L0.652494 62.0444C0.285184 62.0194 4.93178e-06 61.7142 4.94788e-06 61.346Z"
                        fill="url(#paint1_linear_243_120)"
                        style={{ mixBlendMode: "overlay" }}
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_f_243_120"
                    x="-140"
                    y="-140"
                    width="280"
                    height="280"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
                    <feGaussianBlur stdDeviation="12" result="effect1_foregroundBlur_243_120" />
                </filter>
                <filter
                    id="filter1_bf_243_120"
                    x="-21"
                    y="-20.4697"
                    width="159.6"
                    height="111.47"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="10.5" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_243_120" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_243_120" result="shape" />
                    <feGaussianBlur stdDeviation="1" result="effect2_foregroundBlur_243_120" />
                </filter>
                <linearGradient
                    id="paint0_linear_243_120"
                    x1="-75.6336"
                    y1="99.7725"
                    x2="84.3161"
                    y2="-52.8725"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#5BC686" />
                    <stop offset="0.463542" stopColor="#FF9DFF" />
                    <stop offset="1" stopColor="#FF3D23" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_243_120"
                    x1="118.7"
                    y1="70"
                    x2="-0.519174"
                    y2="67.3496"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="white" stopOpacity="0" />
                    <stop offset="1" stopColor="white" stopOpacity="0.8" />
                </linearGradient>
            </defs>
        </svg>
    );
};
