import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";

export const StyledNameAndStatus = styled.div`
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    min-width: 0;
`;

export const StyledTypography = styled(Typography)(({ theme, variant }) => ({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    color: theme.kaltura.palette.tone2,
    ...(
        variant === "body1Highlight" && {
            color: theme.kaltura.palette.tone1,
        }
    ),
}));
