import { Config, useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { useGuessedTimeZone, useTranslateTime } from "@kaltura/mediaspace-shared-utils";
import { TimeDisplay } from "@kaltura/mediaspace-shared-types";

/**
 * custom hook to format date according to start/end time
 */

export const useDateFormat = (startTime: number, endTime: number, schedulingDataTz?: string, shortDate?: boolean) => {
    const context: Config = useConfig();
    const guessedTz = useGuessedTimeZone();
    const tz = schedulingDataTz ?? guessedTz;
    const { timeDisplay, dateFormats } = context.application;
    // calculate long date
    const formattedScheduledDayTsStart = startTime;
    const dateFormat = shortDate ? dateFormats.shortDate : dateFormats.longDate;
    const formattedScheduledDayStartLong = useTranslateTime((formattedScheduledDayTsStart / 1000) | 0, dateFormat, tz);
    const formattedScheduledDayTsEnd = endTime;
    const formattedScheduledDayEndLong = useTranslateTime((formattedScheduledDayTsEnd / 1000) | 0, dateFormat, tz);
    // calculate short date
    const formattedScheduledDayStartShort = useTranslateTime((formattedScheduledDayTsStart / 1000) | 0, "MMMM DD", tz);
    const formattedScheduledDayEndShort = useTranslateTime((formattedScheduledDayTsEnd / 1000) | 0, "MMMM DD", tz);
    // calculate day time
    const scheduledTimeFormat = timeDisplay === TimeDisplay.TwentyFourHours ? "H:mm" : "h:mm A"; // 15:00 || 3:00 PM
    const formattedScheduledStartTimeTs = startTime;
    const formattedScheduledStartTime = useTranslateTime(formattedScheduledStartTimeTs / 1000, scheduledTimeFormat, tz);
    const formattedScheduledEndTimeTs = endTime;
    const formattedScheduledEndTime = useTranslateTime(
        formattedScheduledEndTimeTs / 1000,
        scheduledTimeFormat + " z",
        tz
    );

    let startDay;
    let endDay;
    if (formattedScheduledDayStartLong === formattedScheduledDayEndLong) {
        endDay = startDay = formattedScheduledDayStartLong;
    }
    else {
        startDay = formattedScheduledDayStartShort;
        endDay = formattedScheduledDayEndShort;
    }

    return { startDay, endDay, formattedScheduledStartTime, formattedScheduledEndTime };
};
