import LabeledProgressBar from "./LabeledProgressBar";
import { useCallback, useRef, useState } from "react";
import { useInterval } from "@kaltura/mediaspace-shared-hooks";

export interface LabeledFakeProgressBarProps {
    /**
     * height in px for the progress bar
     */
    height: number;
    /**
     * label to display next to the progress bar
     */
    label?: string;
}

/**
 * FakeProgressBar component
 * renders an infinite progress bar
 */
export const LabeledFakeProgressBar = ({ height, label }: LabeledFakeProgressBarProps) => {
    const startTime = useRef(Date.now());

    const [value, setValue] = useState(0);

    //calculate values for the fake progress bar
    const fakeProgressBar = useCallback(() => {
        const elapsedTime = Date.now() - startTime.current;
        setValue((Math.atan(elapsedTime / 3000) / (Math.PI / 2)) * 100);
    }, [setValue]);

    const getFakeProgress = () => {
        fakeProgressBar();
    };
    useInterval(getFakeProgress, 10);

    return <LabeledProgressBar height={height} value={value} label={label} />;
};

export default LabeledFakeProgressBar;
