/**
 *  get the fill collaboration string - "Owner, Co-Editor....."
 */
import { MediaCollaborationType } from "@kaltura/mediaspace-shared-types";
import { translate } from "./kms";

export function decodeMediaCollaborationType(permissions: string) {
    const perms = permissions.split(",");
    const decoded = perms.reduce(
        (prev, currValue) => {
            switch (parseInt(currValue)) {
                case MediaCollaborationType.OWN:
                    return [...prev, translate("Owner")];
                case MediaCollaborationType.GROUP_OWN:
                    return [...prev, translate("Group Owner")];
                case MediaCollaborationType.CO_EDIT:
                    return [...prev, translate("Co-Editor")];
                case MediaCollaborationType.GROUP_CO_EDIT:
                    return [...prev, translate("Group Co-Editor")];
                case MediaCollaborationType.CO_PUBLISH:
                    return [...prev, translate("Co-Publisher")];
                case MediaCollaborationType.GROUP_CO_PUBLISH:
                    return [...prev, translate("Group Co-Publisher")];
                case MediaCollaborationType.CO_VIEW:
                    return [...prev, translate("Co-Viewer")];
                case MediaCollaborationType.GROUP_CO_VIEW:
                    return [...prev, translate("Group Co-Viewer")];
                default:
                    return prev;
            }
        },
        [] as string[]
    );
    return decoded.join(", ");
}
