import styled from "@emotion/styled";
import { Box, useTheme } from "@kaltura/mediaspace-shared-styled";
import { Typography } from "@kaltura/ds-react-components";

type MoreBadgesProps = {
    count: number;
};

const StyledBadge = styled(Box)(({ theme }) => ({
    width: 60,
    height: 60,
    borderRadius: "50%",
    border: `2px solid ${theme.kaltura.palette.surfaces.background}`,
    backgroundColor: theme.kaltura.palette.tone6,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginLeft: theme.spacing(-1),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
    fontSize: theme.typography.pxToRem(20),
}));

const MoreBadges = (props: MoreBadgesProps) => {
    const { count } = props;

    const theme = useTheme();

    return (
        <StyledBadge theme={theme}>
            <StyledTypography variant={"body1Highlight"}>{`+${count}`}</StyledTypography>
        </StyledBadge>
    );
};

export default MoreBadges;
