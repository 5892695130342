import { useState, useEffect, useRef, KeyboardEvent } from "react";

export type MenuItemRef = { focus: () => void };

/**
 * custom hook to utilize arrow navigation inside the horizontal menubar.
 * this fits both HorizontalMenu and AutoHorizontalMenu.
 */
export const useArrowNavigation = () => {
    const [infocus, setInfocus] = useState(false);
    const [itemIndex, setItemIndex] = useState(-1);

    // refs array for menu-items
    const elementsRef = useRef<Array<MenuItemRef | undefined>>([]);

    // add menu-item refs to the ref array
    // used as a ref method <MemuItem ref={el => addElementRef(el, index)}/>
    const addElementRef = (element: MenuItemRef | null, index: number) => {
        if (!element) {
            return;
        }

        // new render cicle (there are meny in case of AutoHorizontalMenu) - reset the array.
        if (index === 0) {
            elementsRef.current = [];
        }

        // using useImperativeHandle inside HorizontalMenuItem causes the method to be called twice
        // we want the second one, which contains the handle.
        if (elementsRef.current[index]) {
            elementsRef.current[index] = element;
        }
        else {
            elementsRef.current.push(element);
        }
    };

    // move focus index inside menubar menu-items
    const handleKeyDown = (index: number, event: KeyboardEvent<HTMLElement>) => {
        if (event.key === "ArrowLeft") {
            if (index === 0) {
                setItemIndex(elementsRef.current.length - 1);
            }
            else {
                setItemIndex(index - 1);
            }
        }

        if (event.key === "ArrowRight") {
            if (index === elementsRef.current.length - 1) {
                setItemIndex(0);
            }
            else {
                setItemIndex(index + 1);
            }
        }
    };

    const handleItemClick = (index: number) => {
        setItemIndex(index);
    };

    // move focus inside menubar menu-items when the index changes
    useEffect(() => {
        elementsRef.current[itemIndex]?.focus();
    }, [itemIndex]);

    // set focus on the first/last-focused menu-item when the menubar receives focus.
    const handleFocus = (e: React.FocusEvent<HTMLElement>) => {
        // first focus - set index on the first element
        if (itemIndex < 0) {
            setItemIndex(0);
        }
        !infocus && elementsRef.current[itemIndex]?.focus();
        setInfocus(true);
    };

    const handleBlur = (e: React.FocusEvent<HTMLElement>) => {
        setInfocus(false);
    };

    return { addElementRef, handleKeyDown, handleItemClick, handleFocus, handleBlur, infocus };
};
