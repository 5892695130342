import { WebcastEntry } from "@kaltura/mediaspace-shared-types";

export enum NotificationCloseReason {
    timeout = "Timeout", // reason initiated by C&C
    xButton = "X button clicked", // reason initiated by C&C
    browseSessions = "browsing sessions", // reason initiated by KMS, user clicked on browse sessions
    stay = "stay in current session", // reason initiated by KMS, user clicked stay here
    navigateToSession = "user navigated to session", // reason initiated by KMS, user clicked go to session
}

export enum NotificationType {
    single = "single",
    many = "many",
    gap = "gap",
}

export type JoinNextSessionNotification = {
    type: NotificationType.single;
    session: WebcastEntry;
    timeTillStart: number;
    onWatch: (session: WebcastEntry, notificationId: number) => void;
    onStay: (notificationId: number) => void;
    analyticsEntryId?: string;
};

export type GapNotification = {
    type: NotificationType.gap;
    gapInMinutes: number;
    session: WebcastEntry;
    onGoToSession: (session: WebcastEntry, notificationId: number) => void;
    onBrowseSession: (notificationId: number) => void;
    analyticsEntryId?: string;
};

export type MultipleSessionNotification = {
    type: NotificationType.many;
    upcomingSessions: WebcastEntry[];
    onBrowseSessions: (notificationId: number) => void;
    analyticsEntryId?: string;
};

export type Notification = JoinNextSessionNotification | GapNotification | MultipleSessionNotification;
