import styled from "@emotion/styled";
import { ReactNode, useEffect, useState, useMemo } from "react";
import throttle from "lodash/throttle";
import { HeaderMenuScrollContext, HeaderMenuScrollContextType } from "./HeaderMenuScrollContext";
import { HeaderColor } from "@kaltura/mediaspace-shared-types";
import { alpha } from "@kaltura/mediaspace-shared-styled";
import { headerHeight } from "@kaltura/mediaspace-shared-constants";

export interface HeaderMenuContainerProps {
    sticky?: boolean;
    headerColor: HeaderColor; // Kms_Resource_Config::getConfiguration('header', 'headerStyle'); or transparent for hero pages
    scrollThreshold?: number;
    children?: ReactNode;
}

interface StyledHeaderProps {
    sticky?: boolean;
    scrollRatio?: number;
    headerColor?: string;
}

const StyledHeaderMenu = styled.div<StyledHeaderProps>(({ theme, sticky, headerColor }) => ({
    ...(
        headerColor === "dark" && {
            backgroundColor: theme.kaltura.palette.surfaces.protection,
        }
    ),
    ...(
        headerColor === "transparent" && {
            // The page may have side padding, so make sure that we have a "position: relative" parent for the "position: absolute" header (see "-navbar")
            position: "relative",
            width: "100%",
            zIndex: 1000,
        }
    ),
    ...(
        sticky && {
            top: 0,
            position: "sticky",
            zIndex: 1000,
        }
    ),
}));

const StyledNavbar = styled.div<StyledHeaderProps>(({ theme, sticky, scrollRatio, headerColor }) => ({
    padding: theme.spacing(1),
    display: "flex",
    alignItems: "center",
    height: headerHeight,
    ...(
        headerColor === "transparent" && {
            position: "absolute",
            width: "100%",
            backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0))`,
            ...(
                sticky
                && scrollRatio
                && scrollRatio > 0 && {
                    backgroundImage: `linear-gradient(
                to bottom,
                ${alpha(theme.kaltura.palette.surfaces.protection, Math.min(0.7 + scrollRatio, 1))},
                ${alpha(theme.kaltura.palette.surfaces.protection, scrollRatio)}
            )`,
                }
            ),
        }
    ),
}));

/**
 * Site Header Menu component (with navigation, user menu, locale menu..)
 */
export const HeaderMenuContainer = ({
    sticky,
    headerColor,
    scrollThreshold = 60,
    children,
}: HeaderMenuContainerProps) => {
    const [isPageScrolled, setIsPageScrolled] = useState(false);
    const [scrollRatio, setScrollRatio] = useState(0);

    const throttledHandler = throttle(
        () => {
            // for theming class name
            setIsPageScrolled(window.scrollY > scrollThreshold);
            // for sticky transparency
            setScrollRatio(Math.min((window.scrollY / scrollThreshold) * 0.7, 1));
        },
        300
    );

    // Added class is-scrolled, could be styled header in mediaspace theme
    useEffect(() => {
        window.addEventListener("scroll", throttledHandler);
        return () => {
            window.removeEventListener("scroll", throttledHandler);
            throttledHandler.cancel();
        };
    }, [throttledHandler]);

    // header menu scroll context value
    const contextValue = useMemo<HeaderMenuScrollContextType>(
        () => ({
            headerColor,
            scrollRatio,
        }),
        [headerColor, scrollRatio]
    );

    return (
        <HeaderMenuScrollContext.Provider value={contextValue}>
            <StyledHeaderMenu
                sticky={sticky}
                headerColor={headerColor}
                className={"kms-ds-header-menu"}
                role={"banner"}
            >
                <StyledNavbar
                    sticky={sticky}
                    scrollRatio={scrollRatio}
                    headerColor={headerColor}
                    className={"kms-ds-header-menu-navbar" + (isPageScrolled ? " is-scrolled" : "")}
                >
                    {children}
                </StyledNavbar>
            </StyledHeaderMenu>
        </HeaderMenuScrollContext.Provider>
    );
};
