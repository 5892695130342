import { forwardRef, MouseEvent } from "react";
import styled from "@emotion/styled";
import { alpha } from "@kaltura/mediaspace-shared-styled";

type Props = {
    className?: string;
    data: number /** data to show */;
    onClick?: (ev: MouseEvent<HTMLDivElement>) => void /** click handler */;
    variant?: "translucent" | "normal";
    roundness?: number;
};

const StyledCounter = styled(
    "div",
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "variant" && propName !== "roundness";
        },
    }
)<{ variant: "translucent" | "normal"; roundness?: number }>(({ theme, variant, roundness }) => ({
    borderRadius: roundness ?? theme.kaltura.shape.roundness2,
    backgroundColor: theme.kaltura.palette.tone6,
    color: theme.kaltura.palette.tone1,
    ...(
        variant === "translucent" && {
            backgroundColor: alpha(theme.kaltura.palette.primary.main, 0.2),
            color: theme.kaltura.palette.tone8,
        }
    ),
    fontWeight: "bold",

    display: "flex",
    alignItems: "center",
    alignContent: "center",
    justifyContent: "center",
    width: 36,
    height: 36,
    boxSizing: "border-box",
    cursor: "pointer",
}));

export const Counter = forwardRef<HTMLDivElement, Props>(
    ({ className, onClick, data, variant = "translucent", roundness }, ref) => (
        <StyledCounter ref={ref} onClick={onClick} className={className} variant={variant} roundness={roundness}>
            <div>+{data}</div>
        </StyledCounter>
    )
);

export default Counter;
