import { KmsTypeFilterItemBase } from "./KmsTypeFilterItemBase";
import { SupportedKmsTypeFilterItem } from "./SupportedKmsTypeFilterItem";

/**
 * ts implementation of Kms_Type_Esearch_Filter_Items_Group
 */
export interface KmsTypeFilterItemGroup extends KmsTypeFilterItemBase {
    /**
     * boolean - is this a group item
     */
    groupItem: true;

    /**
     * boolean - is this filter item a checkbox
     */
    checkbox?: boolean;

    /**
     * boolean - is this filter item a radio button
     */
    radioButton?: boolean;

    /**
     * boolean - is this filter item checked
     */
    checked?: boolean;

    /**
     * should the group items be hidden when its unselected
     */
    hideUnselected?: boolean;

    /**
     * KmsTypeFilterItemBase[] - the filter items
     */
    items: SupportedKmsTypeFilterItem[];
}

// noinspection PointlessBooleanExpressionJS
export const isKmsTypeFilterItemGroup = (value: KmsTypeFilterItemBase<any>): value is KmsTypeFilterItemGroup =>
    (value as KmsTypeFilterItemGroup).groupItem === true;
