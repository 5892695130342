import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { Typography } from "@kaltura/ds-react-components";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { sendButtonClickAnalytics } from "@kaltura/mediaspace-shared-utils";
import { BadgeProps } from "../../../common/types";
import { useBadgesModal } from "../../../modal/useBadgesModal";
import BadgeIcon from "../../../common/BadgeIcon";

import { TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";

import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";

const StyledEarnedBadge = styled(Box)(({ theme }) => ({
    width: 120,
    textAlign: "center",
    color: theme.kaltura.palette.tone1,
}));

const Spacer = styled(Box)(({ theme }) => ({
    height: theme.spacing(1),
}));

const staticClassBase = "kms-ds-personal-badges-earned-badge";

const EarnedBadge = (props: BadgeProps) => {
    const { name, iconUrl, description } = props;

    const [toggleModal, modal] = useBadgesModal({ title: name, badgeIconUrl: iconUrl, description: description });

    const handleIconClick = () => {
        sendButtonClickAnalytics("Show Badge Info - Earned badges", "My Profile", ButtonClickAnalyticsType.LAUNCH);
        toggleModal();
    };

    return (
        <StyledEarnedBadge className={`${staticClassBase}`}>
            <BadgeIcon iconUrl={iconUrl} onAction={handleIconClick} showPointer={true} />
            <Spacer />
            <TruncateWithTooltip text={HtmlReactParser(name)} lines={2}>
                <Typography variant={"body2Highlight"} className={`${staticClassBase}-name`}>
                    {HtmlReactParser(name)}
                </Typography>
            </TruncateWithTooltip>

            {modal}
        </StyledEarnedBadge>
    );
};

export default EarnedBadge;
