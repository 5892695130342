import { FileRowProps } from "./../FileRow";
import {
    Clear24Icon,
    Edit24Icon,
    ExternalLink24Icon,
    Lock24Icon,
    Link24Icon,
    Globe24Icon,
} from "@kaltura/ds-react-icons";
import { getEntryUrl, translate, useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import { FileMetaData, UploadStatus } from "@kaltura/mediaspace-shared-upload";
import { PublishStatus } from "@kaltura/mediaspace-shared-types";
import { StyledRowActions, TooltipPopperProps } from "./StyleComponents";
import { useTheme } from "@kaltura/mediaspace-shared-styled";
import { Tooltip } from "@kaltura/ds-react-components";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { ChangingButton } from "@kaltura/mediaspace-shared-ui";

export interface RowActionsProps
    extends Pick<FileRowProps, "isOpen" | "onCancel" | "onPublish" | "onEdit" | "onRemove">,
        Omit<FileMetaData, "progress"> {
    entryId?: string;
    showEditButton?: boolean;
    showRemoveButton?: boolean;
    entryName?: string;
}

const RowActions = ({
    tokenId,
    entryId,
    publishStatus,
    status,
    isOpen,
    onCancel,
    onPublish,
    onEdit,
    onRemove,
    showEditButton,
    showRemoveButton,
    entryName,
}: RowActionsProps) => {
    const theme = useTheme();
    const context = useConfig();

    const isXsScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));
    const calcEntryName = entryName ?? entryId ?? translate("untitled");
    const getPublishStatus = (publishStatus: PublishStatus) => {
        switch (publishStatus) {
            case PublishStatus.UNLISTED:
                return translate("Unlisted");
            case PublishStatus.PUBLISHED:
                return translate("Published");
        }
        return translate("Private");
    };

    const getPublishIcon = (publishStatus: PublishStatus) => {
        switch (publishStatus) {
            case PublishStatus.UNLISTED:
                return <Link24Icon />;
            case PublishStatus.PUBLISHED:
                return <Globe24Icon />;
        }
        return <Lock24Icon />;
    };

    const isKaf = !!context.application.isKAF;

    return (
        <StyledRowActions className={"kms-ds-upload-media-file-row-actions"}>
            {(status === UploadStatus.IN_QUEUE || status === UploadStatus.UPLOADING) && (
                <ChangingButton
                    icon={<Clear24Icon />}
                    onClick={() => onCancel(tokenId)}
                    label={translate("Cancel Upload")}
                    ariaLabel={translate("Cancel upload for entry %1", [calcEntryName])}
                />
            )}
            {entryId && (status === UploadStatus.READY || status === UploadStatus.PROCESSING) && !isKaf && (
                <ChangingButton
                    icon={<ExternalLink24Icon />}
                    href={getEntryUrl(entryId)}
                    target={"_blank"}
                    label={translate("Media Page")}
                    ariaLabel={translate("Open Media page for entry %1", [calcEntryName])}
                />
            )}
            {!isXsScreen
                && status !== UploadStatus.UPLOAD_FAILED
                && status !== UploadStatus.ENTRY_FAILED
                && status !== UploadStatus.CANCELED
                && status !== UploadStatus.PRE_QUEUE && (
                    <Tooltip
                        title={translate("Coming Soon")}
                        placement={"top"}
                        PopperProps={TooltipPopperProps}
                        describeChild={true}
                    >
                        <span>
                            <ChangingButton
                                icon={getPublishIcon(publishStatus)}
                                onClick={() => onPublish(tokenId)}
                                label={getPublishStatus(publishStatus)}
                                disabled={true}
                            />
                        </span>
                    </Tooltip>
                )}
            {showEditButton
                && status !== UploadStatus.UPLOAD_FAILED
                && status !== UploadStatus.ENTRY_FAILED
                && status !== UploadStatus.CANCELED
                && status !== UploadStatus.PRE_QUEUE && (
                    <ChangingButton
                        icon={<Edit24Icon />}
                        onClick={() => onEdit(tokenId)}
                        selected={isOpen}
                        label={translate("Edit")}
                        ariaLabel={translate("Edit details for entry %1", [calcEntryName])}
                    />
                )}
            {showRemoveButton
                && (
                    status === UploadStatus.UPLOAD_FAILED
                    || status === UploadStatus.ENTRY_FAILED
                    || status === UploadStatus.CANCELED
                ) && (
                    <ChangingButton
                        icon={<Clear24Icon />}
                        onClick={() => onRemove(tokenId)}
                        label={translate("Remove")}
                        ariaLabel={translate("Remove entry %1 upload", [calcEntryName])}
                    />
                )}
        </StyledRowActions>
    );
};
export default RowActions;
