import styled from "@emotion/styled";
import { cloneElement, FormEvent, KeyboardEvent, ReactElement } from "react";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { Button } from "@kaltura/ds-react-components";
import clsx from "clsx";

export interface CustomArrowProps {
    className?: string;
    disabled?: boolean;
    iconClassName?: string;
    icon: ReactElement;
    onClick?: () => void;
    ariaLabel?: string;
    position?: string;
    container?: string;
    fullScreenWidth?: boolean;
    handleKeyDown?: (e: KeyboardEvent) => void;
}

const arrowClassName = "kms-ds-carousel-arrow";

const StyledButton = styled(
    Button,
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "fullScreenWidth";
        },
    }
)<{ disabled: boolean; fullScreenWidth?: boolean }>(({ theme, disabled, fullScreenWidth }) => ({
    display: "block",
    border: "none",
    marginTop: theme.typography.pxToRem(-16),
    padding: "6px 0",
    [theme.breakpoints.up("lg")]: {
        padding: 0,
    },
    [`&.${arrowClassName}`]: {
        color: fullScreenWidth
            ? theme.kaltura.palette.tone1
            : disabled
              ? theme.kaltura.palette.tone4
              : theme.kaltura.palette.tone3,
        "&:hover": {
            color: fullScreenWidth
                ? theme.kaltura.palette.tone1
                : disabled
                  ? theme.kaltura.palette.tone4
                  : theme.kaltura.palette.tone6,
            backgroundColor: "unset",
        },
        svg: {
            width: theme.typography.pxToRem(36),
            height: theme.typography.pxToRem(36),
            [theme.breakpoints.up(theme.breakpoints.values.lg)]: {
                width: theme.typography.pxToRem(48),
                height: theme.typography.pxToRem(48),
            },
            ...(
                fullScreenWidth && {
                    "&:focus": {
                        outline: 0,
                    },
                }
            ),
        },
    },
}));

export function CustomArrow({
    className,
    icon,
    iconClassName,
    onClick,
    disabled = false,
    ariaLabel,
    position,
    container,
    fullScreenWidth,
    handleKeyDown,
}: CustomArrowProps) {
    icon = cloneElement(icon, { className: iconClassName });

    const sendButtonAnalytics = useButtonAnalytics();

    const handleArrowClick = (e: FormEvent) => {
        e.preventDefault();
        if (position && container) {
            sendButtonAnalytics(`${container} - ${position} Arrow`, ButtonClickAnalyticsType.BROWSE);
        }
        onClick?.();
    };

    return (
        <div className={clsx(`${arrowClassName}-wrap`, className)}>
            <StyledButton
                classes={{ root: arrowClassName }}
                onKeyDown={handleKeyDown}
                onClick={handleArrowClick}
                disabled={disabled}
                variant={"borderless"}
                aria-label={ariaLabel || undefined}
                fullScreenWidth={fullScreenWidth}
            >
                {icon}
            </StyledButton>
        </div>
    );
}
