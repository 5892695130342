import React, { Suspense, useMemo } from "react";
import { baseUrl, fetchKmsData, translate } from "@kaltura/mediaspace-shared-utils";
import { ErrorBoundary } from "@kaltura/mediaspace-shared-ui";
import {
    KmsTypePublishEntryCategories,
    KmsTypePublishUserCategories,
    PublishStatus,
} from "@kaltura/mediaspace-shared-types";
import { PublishedModalSkeleton } from "./published-modal-skeleton/PublishedModalSkeleton";
import { PublishedModal } from "./PublishedModal";
import { ModalProps, StyledFixedHeightModal } from "../StyledModal";
import { ModalTitle } from "@kaltura/ds-react-components";

interface PublishLocationsResponse {
    entryCategories: KmsTypePublishEntryCategories;
    userCategories: KmsTypePublishUserCategories;
}

export interface PublishModalContainerInnerProps extends ModalProps {
    crossApplicationUnpublishing: boolean;
    onUnpublishExternal: () => void;
    fetchPublishLocations: () => PublishLocationsResponse;
}

/**
 * Publish modal
 */
function PublishModalContainerInner(props: PublishModalContainerInnerProps) {
    const { fetchPublishLocations } = props;
    const locations = fetchPublishLocations();

    const { entryCategories, userCategories } = locations;

    return <PublishedModal entryCategories={entryCategories} userCategories={userCategories} {...props} />;
}

export interface PublishedModalContainerProps extends ModalProps {
    crossApplicationUnpublishing: boolean;
    onUnpublishExternal: () => void;
}

/**
 * Publish modal suspense container - fetch the media publish locations and the user potential publish locations,
 */
export const PublishedModalContainer = (props: PublishedModalContainerProps) => {
    const { entryIds, onClose } = props;

    const entryId = entryIds.length === 1 ? entryIds[0] : null;

    // fetch data from kms
    const fetchPublishLocations = useMemo(
        () =>
            fetchKmsData<PublishLocationsResponse>(
                baseUrl + "/publishmedia/media/get-categories/",
                {
                    entryId: entryId,
                    state: PublishStatus.PUBLISHED,
                    showExternal: true,
                }
            ),
        [entryId]
    );

    // render with Suspense
    return (
        <StyledFixedHeightModal
            open={true}
            className="kms-ds-publish-modal-publish"
            onMouseLeave={(event) => event.stopPropagation()}
        >
            <ModalTitle>{translate("Publish")}</ModalTitle>

            <ErrorBoundary fallback={<PublishedModalSkeleton onClose={onClose} />}>
                <Suspense fallback={<PublishedModalSkeleton onClose={onClose} />}>
                    <PublishModalContainerInner
                        fetchPublishLocations={fetchPublishLocations}
                        {...props}
                    ></PublishModalContainerInner>
                </Suspense>
            </ErrorBoundary>
        </StyledFixedHeightModal>
    );
};
