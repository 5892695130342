function InstagramSocialLink() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.6361 16.2943C17.6361 17.0343 17.0343 17.6361 16.2943 17.6361H7.70567C6.96566 17.6361 6.36395 17.0343 6.36395 16.2943V10.5628H8.4539C8.27364 11.0073 8.17239 11.4921 8.17239 12C8.17239 14.1105 9.88946 15.8284 12 15.8284C14.1105 15.8284 15.8276 14.1105 15.8276 12C15.8276 11.4921 15.7255 11.0073 15.5461 10.5628H17.6361V16.2943ZM10.0022 10.5628C10.45 9.94215 11.1777 9.53551 12 9.53551C12.8223 9.53551 13.55 9.94215 13.9978 10.5628C14.29 10.9678 14.4645 11.4633 14.4645 12C14.4645 13.3582 13.3582 14.4637 12 14.4637C10.6418 14.4637 9.53634 13.3582 9.53634 12C9.53634 11.4633 9.71084 10.9678 10.0022 10.5628ZM17.0706 6.61336L17.3801 6.61254V8.98565L15.0143 8.99306L15.0061 6.61994L17.0706 6.61336ZM16.2943 5H7.70567C6.21413 5 5 6.21331 5 7.70567V16.2943C5 17.7867 6.21413 19 7.70567 19H16.2943C17.7859 19 19 17.7867 19 16.2943V7.70567C19 6.21331 17.7859 5 16.2943 5Z"
                fill="white"
            />
        </svg>
    );
}

export default InstagramSocialLink;
