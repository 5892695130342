import { createRoot } from "react-dom/client";
import React, { ComponentType, Suspense } from "react";
import { ThemeProvider, ToastsProvider } from "@kaltura/mediaspace-shared-contexts";
import { Config, ConfigProvider } from "@kaltura/mediaspace-shared-data-kms-config";
import type { RegistrationEmbedFormProps } from "@mediaspace/features/registration-embed-form";
import { getStandaloneComponentRenderer } from "@kaltura/mediaspace-shared-layout";
import { StatusScreen } from "@kaltura/mediaspace-shared-ui";

const RegistrationSiteHeader = React.lazy(() => import("@kaltura/mediaspace-shared-registration-site-header"));
const Verification = React.lazy(() => import("@mediaspace/features/registration/verification/Verification"));

const Unregister = React.lazy(() => import("@mediaspace/features/registration/unregister/Unregister"));

const Registration = React.lazy(() => import("@mediaspace/features/registration/registration/SiteRegistration"));

const Confirmation = React.lazy(() => import("@mediaspace/features/registration/confirmation/Confirmation"));
const ConfirmationError = React.lazy(
    () => import("@mediaspace/features/registration/confirmation-error/ConfirmationError")
);

const RegistrationEmbedForm = React.lazy(() => import("@mediaspace/features/registration-embed-form"));

/**
 * header is rendered independently in multiple pages, so it deserves its own "render" method
 */
const renderHeader = getStandaloneComponentRenderer(RegistrationSiteHeader);

/**
 * this will be used to render other components/pages
 */
const render = (
    containerId: string,
    props: React.ComponentProps<any> & { context: Config; component: ComponentType<any> }
) => {
    const { context, component: Component, ...rest } = props;
    const { theming } = context;

    const root = createRoot(document.getElementById(containerId)!);
    root.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode}>
                    <Component {...rest} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>
    );
};

export const renderEmbedForm = (
    containerId: string,
    { context, ...props }: RegistrationEmbedFormProps & { context: Config }
) => {
    const { theming } = context;
    const root = createRoot(document.getElementById(containerId)!);
    root.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode}>
                    <ToastsProvider toastDuration={4000}>
                        <RegistrationEmbedForm {...props} />
                    </ToastsProvider>
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>
    );
};

export { renderHeader, render, Verification, Registration, StatusScreen, Confirmation, ConfirmationError, Unregister };
