import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";

export const StyledCreatePlaylistContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

export const StyledForm = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
}));
