import { TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";
import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { Link, Typography } from "@kaltura/ds-react-components";
import { MouseEvent } from "react";
import { sendButtonClickAnalytics, translate } from "@kaltura/mediaspace-shared-utils";
import { BadgeProps } from "../../../../common/types";
import BadgeIcon from "../../../../common/BadgeIcon";
import { useBadgesModal } from "../../../../modal/useBadgesModal";
import { HtmlReactParser } from "@kaltura/mediaspace-shared-utils";

import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";

const StyledBadgeToEarn = styled(Box)(({ theme }) => ({
    display: "flex",
    width: `calc(50% - ${theme.spacing(3)})`,
    gap: theme.spacing(3),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        flex: "100%",
    },
}));

const StyledContent = styled(Box)({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
});

const StyledInstructions = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
    marginTop: theme.spacing(0.5),
}));

const StyledButton = styled(Link)(({ theme }) => ({
    marginTop: theme.spacing(1),
    padding: 0,
    width: "fit-content",
}));

const staticClassBase = "kms-ds-personal-badges-to-earn-item";

const BadgeToEarn = (props: BadgeProps) => {
    const { name, iconUrl, description } = props;

    const [toggleModal, modal] = useBadgesModal({ title: name, badgeIconUrl: iconUrl, description: description });

    const openBadgeModal = (evt: MouseEvent<HTMLAnchorElement>) => {
        evt.preventDefault();
        sendButtonClickAnalytics("Show Badge Info - Badges i can earn", "My Profile", ButtonClickAnalyticsType.LAUNCH);
        toggleModal();
    };

    return (
        <StyledBadgeToEarn className={`${staticClassBase}`}>
            <div>
                <BadgeIcon iconUrl={iconUrl} showOpacity={true} />
            </div>
            <StyledContent className={`${staticClassBase}-content`}>
                <Typography variant={"body1Highlight"} className={`${staticClassBase}-content-title`}>
                    {name}
                </Typography>
                <TruncateWithTooltip text={HtmlReactParser(description)}>
                    <StyledInstructions variant={"body2"} className={`${staticClassBase}-content-description`}>
                        {HtmlReactParser(description)}
                    </StyledInstructions>
                </TruncateWithTooltip>
                <StyledButton
                    onClick={openBadgeModal}
                    component={"button"}
                    className={`${staticClassBase}-content-button`}
                    size={"small"}
                >
                    {translate("Get this badge")}
                </StyledButton>
            </StyledContent>

            {modal}
        </StyledBadgeToEarn>
    );
};

export default BadgeToEarn;
