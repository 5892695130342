import { createRoot } from "react-dom/client";
import React, { Suspense } from "react";
import { ThemeProvider } from "@kaltura/mediaspace-shared-contexts";
import { Config, ConfigProvider } from "@kaltura/mediaspace-shared-data-kms-config";
import type { PreviewBannerProps } from "@mediaspace/features/preview-banner";

const PreviewBanner = React.lazy(() => import("@mediaspace/features/preview-banner"));

export const render = (id: string, props: PreviewBannerProps & { context: Config }) => {
    const root = createRoot(document.getElementById(id)!);
    root.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={{}} mode={"light"}>
                    <PreviewBanner {...props} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>
    );
};

export { PreviewBanner as Component };
