import React, { useContext, useEffect, useId, useRef } from "react";
import { UnisphereContext } from "@kaltura/mediaspace-shared-contexts";

/**
 * Component to load and manage a Unisphere widget.
 *
 * @param widgetName - The name of the widget to load.
 * @param context - The context in which the widget is used.
 * @param settings - The settings to configure the widget.
 */
const UnisphereWidget: React.FC<{ widgetName: string; context: string; settings: any }> = ({
    widgetName,
    context,
    settings,
}) => {
    const { unisphereWorkspace } = useContext(UnisphereContext);

    const isAreaAssignedRef = useRef(false);
    const containerId = useId();

    useEffect(() => {
        if (!unisphereWorkspace) return;

        unisphereWorkspace.loadElement(widgetName, context, settings).then(({ element }) => {
            if (!isAreaAssignedRef.current) {
                element.assignArea(containerId);
                isAreaAssignedRef.current = true;
            }
        });

        return () => {
            if (isAreaAssignedRef.current) {
                unisphereWorkspace?.getElement(widgetName, context)?.unassignArea();
                isAreaAssignedRef.current = false;
            }
        };
    }, [unisphereWorkspace, widgetName, context, settings, containerId]);

    return <div id={containerId}></div>;
};

export default UnisphereWidget;
