import { KalturaBaseEntry } from "kaltura-typescript-client/api/types/KalturaBaseEntry";
import { KalturaScheduleEvent } from "kaltura-typescript-client/api/types/KalturaScheduleEvent";
import { WebcastEntry } from "@kaltura/mediaspace-shared-types";
import ServerTimeHelper from "./ServerTimeHelper";

/**
 * get entry duration prop value
 * @param entry
 */
export const getDuration = (entry: KalturaBaseEntry | KalturaScheduleEvent): number | undefined => {
    if ("duration" in entry) {
        return entry.duration;
    }
    return undefined;
};

/**
 * check if entry is live
 * @param entry
 */
export const getIsLive = (entry: KalturaBaseEntry | WebcastEntry) => {
    if ("schedulingData" in entry) {
        const serverTime = ServerTimeHelper.getUpdatedTime();
        const startTime = entry.schedulingData.start.timestamp * 1000;
        const endTime = entry.schedulingData.end.timestamp * 1000;
        return !!startTime && !!endTime && serverTime < endTime && serverTime > startTime;
    }
    return false;
};
