import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const MeetingRoomIllustration = () => (
    <StyledIllustration
        fileName={"AddNewMeetingRoom.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clip-path="url(#clip0_55669_15776)">
            <rect x="5.1665" y="78.667" width="108.667" height="81.3333" rx="8" fill="#FFD357" />
            <path
                d="M33.3595 143.259C51.3744 160.652 79.2138 149.29 75.4107 117.961C64.0377 118.538 57.6701 117.693 57.6701 117.693C58.0343 122.708 57.6078 146.476 35.0166 119.19C24.2154 106.144 10.4342 108.868 1.37908 113.747L5.16667 134.667C17.1667 124.001 26.2349 136.38 33.3595 143.259Z"
                fill="#006EFA"
            />
            <path
                d="M66.2117 91.3681C65.8566 92.6747 64.1043 100.384 63.831 101.971C62.0615 100.62 58.5111 98.3883 53.985 100.957C49.0352 103.766 50.1487 106.443 50.4436 106.998C50.8312 107.727 52.7473 109.878 54.8553 107.364C56.9632 104.851 62.0567 105.917 61.357 110.238C60.6573 114.559 55.5333 112.3 53.9641 110.212C52.3948 108.123 49.0998 108.889 49.263 111.783C49.5423 116.734 56.1442 122.723 63.3398 124.204C70.3569 125.648 75.5596 119.526 76.9777 116.825C78.3662 114.179 80.7614 108.355 82.7612 104.388C84.3862 101.163 85.4591 97.956 83.5522 97.0045C82.5068 96.4829 80.7861 96.3644 79.4544 98.9775C78.4979 100.854 76.4363 104.402 74.7247 107.973C75.506 105.098 77.0245 100.929 78.2265 96.5067C79.2482 92.7479 78.8829 90.9865 77.2284 90.5368C75.9017 90.1762 74.5217 90.6895 72.3689 96.323C71.0603 99.7475 69.7036 103.063 69.0208 104.809C69.5137 101.54 71.5791 92.3048 71.6907 91.1954C71.8302 89.8087 72.2849 87.083 69.5716 86.5829C67.2134 86.1483 66.5811 90.0092 66.2117 91.3681Z"
                fill="#FF9DFF"
            />
            <path
                d="M73.8612 114.315C71.8132 111.548 68.6827 110.344 66.5 109.926"
                stroke="#333333"
                stroke-width="2"
                stroke-linecap="round"
            />
            <path
                d="M66.7359 118.482C66.7173 117.351 66.4456 116.284 66.0517 115.335C65.9063 114.984 65.7443 114.65 65.5723 114.334"
                stroke="#333333"
                stroke-width="2"
                stroke-linecap="round"
            />
        </g>
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M42.3346 84.2089C42.1404 84.3085 42.0434 84.3582 41.9774 84.4071C41.5242 84.7429 41.4382 85.3868 41.7874 85.8297C41.8383 85.8942 41.9189 85.9677 42.0801 86.1147L47.7157 91.2527C48.0213 91.5314 48.1742 91.6707 48.292 91.7438C49.1129 92.2527 50.1937 91.8645 50.5032 90.9496C50.5477 90.8183 50.5769 90.6135 50.6354 90.204L51.8961 81.3812C51.9359 81.1029 51.9558 80.9637 51.9517 80.8706C51.9231 80.2211 51.2921 79.7711 50.6687 79.9557C50.5794 79.9822 50.4543 80.0463 50.2041 80.1745L42.3346 84.2089Z"
            className="Tone1"
            fill="#333333"
        />
        <rect x="16.5" y="59.333" width="48" height="26.6667" rx="13.3333" className="Tone1" fill="#333333" />
        <path
            d="M25.8335 75.5776C25.8335 75.5776 28.4347 68.2561 31.6261 69.4685C33.9313 70.3443 34.1701 73.965 34.2364 74.7958C34.2475 74.9357 34.2674 75.0885 34.351 75.1802C34.4841 75.3258 34.6881 75.2344 34.8434 75.1305C35.9971 74.3584 36.9862 73.2179 38.1696 72.5226C39.3531 71.8274 40.8899 71.6805 41.8478 72.806C42.4562 73.5208 42.7251 74.6238 43.3616 75.2979C43.9244 75.8942 44.7045 76.0666 45.4334 75.9777C46.1622 75.8889 46.8588 75.5661 47.5523 75.2671C50.4393 74.0224 53.625 73.1567 56.5002 74.445"
            className="sTone8"
            stroke="white"
            stroke-width="2"
            stroke-linecap="round"
        />
        <rect
            x="89.1665"
            y="134.662"
            width="48.977"
            height="37.5131"
            rx="5.48972"
            transform="rotate(-5.3899 89.1665 134.662)"
            fill="#FF3D23"
        />
        <ellipse
            cx="115.269"
            cy="150.712"
            rx="9.13761"
            ry="9.13762"
            transform="rotate(-5.3899 115.269 150.712)"
            fill="white"
        />
        <path
            d="M118.748 149.513C119.448 149.834 119.538 150.792 118.911 151.238L114.567 154.324C113.939 154.77 113.064 154.369 112.992 153.602L112.491 148.297C112.419 147.531 113.203 146.974 113.903 147.294L118.748 149.513Z"
            fill="#006EFA"
        />
        <rect
            x="99.9121"
            y="30.7822"
            width="63.0688"
            height="72.4387"
            rx="9.46032"
            transform="rotate(6.98288 99.9121 30.7822)"
            fill="#FF9DFF"
        />
        <path
            d="M145.092 62.944C144.035 73.5786 128.605 85.3083 117.97 84.2508C107.336 83.1933 104.299 69.7492 105.357 59.1145C106.414 48.4799 117.117 40.7161 127.751 41.7736C138.386 42.831 146.15 52.3094 145.092 62.944Z"
            fill="white"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M107.14 52.9054C108.621 54.9993 117.689 56.4593 121.912 52.3227C121.389 61.4217 127.431 67.3015 136.145 64.7237C136.802 64.5296 136.697 64.9389 136.466 65.8389C136.03 67.5367 135.146 70.9804 138.08 75.4129C141.913 71.6828 144.667 67.2249 145.093 62.9444C146.15 52.3098 138.386 42.8315 127.752 41.774C119.298 40.9334 110.8 45.6097 107.14 52.9054Z"
            fill="#006EFA"
        />
        <path
            d="M112.896 61.9905C112.834 62.619 112.273 63.0778 111.645 63.0153C111.017 62.9528 110.558 62.3927 110.62 61.7642C110.683 61.1357 111.243 60.6769 111.871 60.7394C112.5 60.8019 112.959 61.362 112.896 61.9905Z"
            fill="#333333"
            stroke="#333333"
            stroke-width="0.768122"
        />
        <circle
            cx="120.43"
            cy="65.0307"
            r="1.14356"
            transform="rotate(5.67865 120.43 65.0307)"
            fill="#333333"
            stroke="#333333"
            stroke-width="0.768122"
        />
        <circle cx="135.271" cy="66.4525" r="3.54762" transform="rotate(5.67865 135.271 66.4525)" fill="white" />
        <path
            d="M115.606 72.0314C114.02 72.6363 110.875 73.1671 110.875 70.8488C110.875 69.5415 111.946 69.4543 113.241 68.143"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            d="M130.104 45.8725L132.557 45.9092C140.496 32.4596 149.321 42.1063 149.321 50.3861C149.321 113.869 194.441 101.453 199.071 85.8695C202.159 75.4784 198.181 68.5727 185.432 65.6802C168.604 61.8622 172.847 54.0014 172.987 43.7305C173.158 31.2796 163.05 23.9219 153.415 23.2592C139.474 22.3005 129.881 33.2214 130.104 45.8725Z"
            fill="#006EFA"
        />
        <path
            d="M119.928 77.6376C123.07 78.6565 125.982 76.4255 126.244 74.3966"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M153.23 105.224C151.289 107.999 147.918 109.638 144.323 109.198L103.676 104.228C109.476 93.1945 120.613 86.7041 128.601 86.7041C137.77 86.7041 147.64 94.4215 153.23 105.224Z"
            fill="#333333"
        />
        <path
            d="M121.349 95.143C122.52 90.3365 123.79 81.7928 123.109 79.4294L131.18 75.9209C133.123 77.8972 134.804 84.1138 136.152 89.13C136.658 91.0142 135.573 92.9748 133.701 93.5257L122.924 96.6977C122.003 96.9687 121.122 96.0756 121.349 95.143Z"
            fill="white"
        />
        <rect
            x="55.1665"
            y="20.1309"
            width="35.3333"
            height="26.6667"
            rx="13.3333"
            transform="rotate(-12.1948 55.1665 20.1309)"
            fill="#FF3D23"
        />
        <path
            d="M83.3424 45.8192L72.8945 38.5723L84.8079 37.1333L85.6865 44.4068C85.8417 45.6917 84.4059 46.5569 83.3424 45.8192Z"
            fill="#FF3D23"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M75.0965 27.1665C75.0965 27.1665 76.2747 24.1187 78.586 24.1011C80.8972 24.0836 82.6752 26.6674 81.6867 29.3034C80.7918 31.6897 77.5885 34.9889 76.668 35.5751C76.5717 35.6364 76.4375 35.6654 76.2857 35.651C73.1487 35.3541 70.8515 33.5975 69.335 31.9728C69.335 31.9728 66.8222 28.8589 69.3395 26.5218C71.8569 24.1846 75.0965 27.1665 75.0965 27.1665Z"
            fill="white"
        />
        <defs>
            <clipPath id="clip0_55669_15776">
                <rect width="108.667" height="85.3333" fill="white" transform="translate(5.1665 74.667)" />
            </clipPath>
        </defs>
    </StyledIllustration>
);
