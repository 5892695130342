import { Notification, NotificationType } from "./types";
import { ThemeProvider } from "@kaltura/ds-react-theme";
import React from "react";
import { SingleSessionNotification } from "./SingleSessionNotification";
import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { Theme } from "@mui/material";
import { MultipleSessionsNotification } from "./MultipleSessionsNotification";
import { GapBetweenSessionsNotification } from "./GapBetweenSessionsNotification";
import { Config, ConfigProvider } from "@kaltura/mediaspace-shared-data-kms-config";

export interface NotificationContentProps {
    uniqueId: number;
    notification: Notification;
    theme: Theme;
    context: Config;
}

/**
 * Channel Tv - main notification content component which renders notification content by notification type
 */
export const NotificationContent = ({ notification, uniqueId, theme, context }: NotificationContentProps) => {
    let notificationContent: React.ReactNode;

    switch (notification.type) {
        case NotificationType.single:
            notificationContent = <SingleSessionNotification id={uniqueId} notification={notification} />;
            break;
        case NotificationType.many:
            notificationContent = <MultipleSessionsNotification id={uniqueId} notification={notification} />;
            break;
        case NotificationType.gap:
            notificationContent = <GapBetweenSessionsNotification id={uniqueId} notification={notification} />;
            break;
        default:
            notificationContent = null;
    }

    return (
        <ConfigProvider context={context}>
            <ThemeProvider overrides={theme.kaltura} mode={theme.kaltura.mode} scoped={true} injectFirst={false}>
                <StyledPaddedContent>{notificationContent}</StyledPaddedContent>
            </ThemeProvider>
        </ConfigProvider>
    );
};

const StyledPaddedContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(3),
}));

export const StyledNotificationTitle = styled(Box)(({ theme }) => ({
    fontWeight: theme.typography.fontWeightBold,
}));

export const StyledNotificationTitlePadded = styled(StyledNotificationTitle)(({ theme }) => ({
    paddingBottom: theme.spacing(2),
}));

export const StyledNotificationButtons = styled(Box)(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: theme.spacing(2),
    gap: theme.spacing(1),
}));

export const StyledNotificationWithThumbnailContainer = styled(Box)({
    display: "flex",
    alignItems: "flex-start",
});

export const Thumbnail = styled("img")(({ theme }) => ({
    width: 100,
    height: 56,
    borderRadius: theme.kaltura.shape.roundness1,
    marginRight: theme.spacing(1),
}));
