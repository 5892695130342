import { KmsTypeFilterBase } from "./KmsTypeFilterBase";
import { KmsTypeFilterItemFreeText } from "./item";

/**
 * ts implementation of Kms_Type_Esearch_Filter_FreeText
 */
export interface KmsTypeFilterFreeText extends KmsTypeFilterBase<KmsTypeFilterItemFreeText> {
    /**
     * boolean - is this filter a free text filter
     */
    freeText: true;
}

// noinspection PointlessBooleanExpressionJS
export const isKmsTypeFilterFreeText = (value: KmsTypeFilterBase<any>): value is KmsTypeFilterFreeText =>
    (value as KmsTypeFilterFreeText).freeText === true;
