import StyledIllustration from "./StyledIllustration";

export const BulkInviteIllustration = (
    <StyledIllustration
        fileName={"BulkCard.svg"}
        width="240"
        height="180"
        viewBox="0 0 240 180"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g clipPath="url(#clip0_21574_199638)">
            <rect x="90.4" y="49.2002" width="125.6" height="66.4" rx="8" fill="#FA374B" />
            <path
                d="M122.449 109.241C122.449 126.575 136.502 140.628 153.836 140.628C171.171 140.628 185.223 126.575 185.223 109.241C185.223 91.906 171.171 77.8535 153.836 77.8535C136.502 77.8535 122.449 91.906 122.449 109.241Z"
                fill="#006EFA"
            />
            <ellipse cx="155.217" cy="58.9516" rx="16.3396" ry="16.3437" fill="white" />
            <path
                d="M162.1 39.4186C177.66 40.457 178.159 49.6427 173.94 59.7537L173.913 59.8179C171.928 64.5772 170.98 66.8471 169.936 65.7425C165.363 60.9038 163.729 53.2909 164.776 49.3C160.111 54.0818 159.957 47.6437 155.059 44.1507C147.325 38.6345 142.65 38.1206 162.1 39.4186Z"
                fill="#FFCD00"
            />
            <path
                d="M138.717 44.881C142.567 58.0602 160.36 55.8756 166.574 52.8688L159.574 46.4471C157.824 44.9674 174.276 47.8675 170.475 43.7425C166.243 39.1494 154.59 40.1893 150.548 41.2162C144.09 42.8566 151.968 44.8817 151.968 44.8817C151.561 46.9814 137.981 42.3612 138.717 44.881Z"
                fill="#FFCD00"
            />
            <path
                d="M145.436 81.9588C147.408 79.1401 148.426 74.7942 148.426 72.2194L156.148 71.2119C157.603 73.8986 158.498 76.5854 158.498 81.2871C158.498 85.588 143.085 85.3172 145.436 81.9588Z"
                fill="white"
            />
            <path
                d="M156.827 65.1694C156.402 66.8061 154.451 69.4937 150.052 67.1509"
                stroke="#333333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M149.566 57.8521L146.939 61.7518C146.764 62.2772 147.044 63.2231 149.567 62.8027"
                stroke="#333333"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle r="1.05109" transform="matrix(-1 0 0 1 144.838 56.4959)" fill="#333333" />
            <circle r="1.05109" transform="matrix(-1 0 0 1 154.295 57.5482)" fill="#333333" />
            <path d="M174.254 56.7754L129.887 39.5972" stroke="#333333" strokeWidth="1.9021" strokeLinecap="round" />
            <path
                d="M164.925 36.1519C175.56 39.5609 177.763 52.2261 174.645 57.0249L143.495 44.9657C147.023 39.6092 154.254 32.7309 164.925 36.1519Z"
                fill="#333333"
                stroke="#333333"
                strokeWidth="0.951049"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <rect
            width="32.9901"
            height="24.1927"
            rx="4"
            transform="matrix(0.888018 0.459808 0.459808 -0.888018 115.109 118.035)"
            fill="#FFCD00"
        />
        <rect
            width="32.9901"
            height="24.1927"
            rx="4"
            transform="matrix(0.976649 0.214842 0.214842 -0.976649 114.784 120.055)"
            fill="#FA374B"
        />
        <rect width="32.9901" height="24.1927" rx="4" transform="matrix(-1 0 0 1 147 96.2021)" fill="#333333" />
        <path
            d="M118.408 100.601L128.839 109.665C129.396 110.149 130.217 110.173 130.801 109.722L142.601 100.601"
            stroke="white"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M114.59 114.614V112.287C117.339 113.713 117.815 112.287 117.31 111.13C119.715 110.417 118.114 108.627 117.012 107.82C119.116 107.517 118.264 106.105 117.815 105.629C116.919 104.683 114.149 103.554 112.108 103.727C108.936 103.997 109.274 106.063 110.404 107.286C109.07 108.616 110.929 109.959 111.889 110.227C110.037 111.829 112.839 113.857 114.59 114.614Z"
            fill="white"
            stroke="#333333"
            strokeLinejoin="round"
        />
        <path
            d="M111.888 110.227C113.499 109.019 115.912 108.958 117.814 111.811"
            stroke="#333333"
            strokeLinecap="round"
        />
        <path
            d="M109.924 107.054C109.728 107.25 109.728 107.566 109.924 107.761C110.119 107.957 110.435 107.957 110.631 107.761L109.924 107.054ZM117.23 108.617C117.453 108.78 117.766 108.732 117.929 108.509C118.092 108.286 118.044 107.973 117.821 107.81L117.23 108.617ZM110.631 107.761C111.287 107.105 112.303 106.86 113.514 107.024C114.721 107.188 116.053 107.754 117.23 108.617L117.821 107.81C116.533 106.866 115.047 106.223 113.648 106.033C112.254 105.844 110.878 106.1 109.924 107.054L110.631 107.761Z"
            fill="#333333"
        />
        <path
            d="M162.164 154.819C163.996 156.01 172.663 151.757 173.808 150.612C177.871 154.857 178.013 166.16 169.761 168.383C161.508 170.605 158 168.383 156.4 166L140.546 143.551C140.187 143.041 139.62 142.718 138.998 142.668L131.369 142.052C130.844 142.009 130.331 141.871 129.862 141.63C126.911 140.11 122.298 137.353 121.91 136.38C121.363 135.012 122.193 134.851 123.042 134.851C123.654 134.851 127.767 136.533 130.345 137.627C130.871 137.85 131.434 137.966 132.005 137.966H142.817C143.322 137.966 143.81 138.148 144.192 138.478L145.28 139.419C150.145 144.056 160.333 153.628 162.164 154.819Z"
            fill="white"
            stroke="#333333"
            strokeLinejoin="round"
        />
        <g clipPath="url(#clip1_21574_199638)">
            <rect width="70" height="68" rx="8" transform="matrix(-1 0 0 1 240 102)" fill="#FFCD00" />
            <path
                d="M165.217 164.13C165.217 181.465 179.27 195.517 196.604 195.517C213.939 195.517 227.991 181.465 227.991 164.13C227.991 146.796 213.939 132.743 196.604 132.743C179.27 132.743 165.217 146.796 165.217 164.13Z"
                fill="#333333"
            />
            <ellipse cx="197.398" cy="112.646" rx="15.7791" ry="15.7831" fill="white" />
            <path
                d="M188.424 136.847C190.397 134.029 191.415 129.683 191.415 127.108L199.139 126.101C200.595 128.787 201.49 131.474 201.49 136.176C201.49 140.477 186.073 140.206 188.424 136.847Z"
                fill="white"
            />
            <path
                d="M196.026 119.714C195.718 120.9 194.282 122.844 191.007 121.123"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M192.555 112.741L189.928 116.641C189.753 117.166 190.033 118.112 192.555 117.692"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                r="4.73026"
                transform="matrix(-1 0 0 1 198.862 112.963)"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                r="4.73026"
                transform="matrix(-1 0 0 1 186.249 110.861)"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M204.117 114.014L210.95 115.59"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M194.637 110.868C194.112 109.817 192.556 108.759 190.979 110.336"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle r="1.05109" transform="matrix(-1 0 0 1 187.825 111.387)" fill="#333333" />
            <circle r="1.05109" transform="matrix(-1 0 0 1 197.286 112.438)" fill="#333333" />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-1 0 0 1 219.107 159.737)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.649983 0.759949 0.759949 0.649983 215.448 161.163)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.909568 0.415554 0.415554 0.909568 219.966 166.538)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(0.583511 0.812105 0.812105 -0.583511 215.868 165.353)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(0.283588 0.958946 0.958946 -0.283588 221.991 162.808)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                r="1.9021"
                transform="matrix(-1 0 0 1 218.632 163.065)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.997028 0.0770444 0.0770444 0.997028 197.532 151.142)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.589501 0.807768 0.807768 0.589501 193.994 152.845)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.874849 0.484396 0.484396 0.874849 198.913 157.857)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(0.644344 0.764735 0.764735 -0.644344 194.736 156.992)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(0.356627 0.934247 0.934247 -0.356627 200.645 153.982)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                r="1.9021"
                transform="matrix(-0.997028 0.0770444 0.0770444 0.997028 197.315 154.498)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.90792 -0.419143 -0.419143 0.90792 217.984 138.807)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.90866 0.417537 0.417537 0.90866 214.065 138.568)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.999992 -0.0039497 -0.0039497 0.999992 215.914 145.342)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(0.189392 0.981901 0.981901 -0.189392 212.69 142.55)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.144461 0.989511 0.989511 0.144461 219.316 142.805)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                r="1.9021"
                transform="matrix(-0.90792 -0.419143 -0.419143 0.90792 216.158 141.63)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.921768 0.387743 0.387743 0.921768 186.747 166.74)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.304468 0.952522 0.952522 0.304468 183.927 169.473)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.677283 0.735723 0.735723 0.677283 190.176 172.676)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(0.852749 0.522321 0.522321 -0.852749 185.939 173.173)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(0.633227 0.773966 0.773966 -0.633227 190.597 168.453)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                r="1.9021"
                transform="matrix(-0.921768 0.387743 0.387743 0.921768 187.6 169.993)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.796619 0.604482 0.604482 0.796619 179.676 135.635)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.0584132 0.998293 0.998293 0.0584132 177.623 138.982)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(-0.473384 0.880856 0.880856 0.473384 184.471 140.534)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(0.955739 0.294217 0.294217 -0.955739 180.491 142.067)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <ellipse
                rx="1.42657"
                ry="2.37762"
                transform="matrix(0.805577 0.592491 0.592491 -0.805577 183.831 136.338)"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                r="1.9021"
                transform="matrix(-0.796619 0.604482 0.604482 0.796619 181.309 138.574)"
                fill="#333333"
                stroke="#FFCD00"
                strokeWidth="0.8"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
        </g>
        <path
            d="M167.189 146.429L175.945 140.557L172.523 163.925C172.382 164.893 171.048 165.043 170.695 164.131L165.687 151.196C165.012 149.451 165.636 147.471 167.189 146.429Z"
            fill="#333333"
        />
        <path
            d="M213.042 124.103L211.287 125.322L202.654 126.929C193.192 127.548 180.164 122.244 177.061 118.53C173.958 114.816 175.768 113.31 177.061 113.022C174.522 110.881 174.122 107.99 174.24 106.812C188.415 107.257 201.594 101.117 206.411 97.9918C214.264 95.3922 216.921 102.789 217.267 106.812C226.563 107.508 221.428 115.051 221.623 116.096C221.779 116.931 223.681 117.489 224.613 117.663C224.769 119.566 221.038 120.893 219.153 121.318C219.361 125.728 215.166 125.013 213.042 124.103Z"
            fill="#333333"
        />
        <ellipse cx="198.11" cy="114.074" rx="15.7791" ry="15.7831" fill="white" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M191.379 120.529C190.853 120.529 190.427 120.956 190.427 121.481V122.565H190.435C190.52 123.555 191.55 124.337 192.808 124.337C194.066 124.337 195.096 123.555 195.181 122.565H195.186V122.478C195.186 122.463 195.187 122.448 195.187 122.433C195.187 122.418 195.186 122.403 195.186 122.388V121.481C195.186 120.956 194.76 120.529 194.234 120.529H191.379Z"
            fill="#333333"
        />
        <path
            d="M188.201 136.848C190.174 134.03 191.191 129.684 191.191 127.109L198.913 126.102C200.368 128.788 201.264 131.475 201.264 136.177C201.263 140.478 185.851 140.207 188.201 136.848Z"
            fill="white"
        />
        <path
            d="M192.331 112.742L189.704 116.641C189.529 117.167 189.809 118.113 192.331 117.692"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle r="1.05109" transform="matrix(-1 0 0 1 187.603 111.387)" fill="#333333" />
        <circle r="1.05109" transform="matrix(-1 0 0 1 197.061 112.438)" fill="#333333" />
        <path
            d="M181.469 98.0156C192.701 83.9874 202.749 89.7911 206.368 94.4465C206.949 94.8997 208.78 96.6018 210.114 98.4052L210.524 98.0156C213.85 102.378 219.765 111.345 217.175 112.918C202.341 121.93 208.797 106.412 205.194 103.074C196.627 101.499 198.261 102.128 189.263 108.258C180.265 114.387 171.462 95.5327 176.038 97.6276C179.7 99.3035 181.184 98.5846 181.469 98.0156Z"
            fill="#333333"
        />
        <ellipse rx="3.60402" ry="3.60492" transform="matrix(-1 0 0 1 215.978 116.901)" fill="white" />
        <path
            d="M46.8524 112.673L40.9937 69.9345C42.6098 68.4052 47.022 65.7584 51.743 67.4057C56.4639 69.053 57.1867 74.2235 56.958 76.6029C61.0408 79.0738 61.7613 83.1576 60.5605 85.4226C59.5998 87.2346 60.1602 88.2138 60.5605 88.4769C64.7325 92.1832 62.2988 96.4043 60.5605 98.0516C65.8304 105.245 61.766 109.954 59.0751 111.41L46.8524 112.673Z"
            fill="#333333"
        />
        <path
            d="M75.5744 120.712C72.7552 123.203 64.74 119.221 63.4398 118.257C60.0427 123.05 62.2672 131.705 70.0471 135.242C77.035 138.42 79.4126 136.042 81.7902 131.762L95.7524 105.52C101.154 103.556 100.052 99.3185 100.414 97.9709C100.775 96.6232 100.776 96.925 103.918 95.4244C106.431 94.2239 103.758 94.0042 102.399 94.2394C104.367 93.3864 105.097 92.6194 105.236 92.1047C105.332 91.7513 104.893 91.6296 104.544 91.7392L100.711 92.9427C106.748 90.0076 105.338 88.9675 105.319 88.8397C105.293 88.6669 103.061 89.4823 102.176 89.7449C104.582 88.5031 105.012 87.521 104.811 86.9121C104.66 86.4533 104.074 86.3943 103.602 86.4995C100.005 87.3019 97.0209 89.5077 95.7524 90.5C92.8895 92.7394 91.8217 99.0141 90.021 103.004C85.8876 108.304 77.2115 119.266 75.5744 120.712Z"
            fill="white"
            stroke="#333333"
            strokeLinejoin="round"
        />
        <path
            d="M94.6332 101.883C95.1465 101.566 96.2012 100.982 96.1207 100.003C96.0597 99.261 96.2657 98.8745 96.3644 98.7748C96.5092 98.5547 97.0655 98.0496 98.1333 97.7893C99.2011 97.529 98.7457 96.485 98.266 96.3864C97.6443 96.2585 96.1341 96.3573 95.0675 97.7751"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <g clipPath="url(#clip2_21574_199638)">
            <rect y="30" width="69.4266" height="109.371" rx="8" fill="#006EFA" />
            <path
                d="M46.6328 112.674L40.7726 69.9354C42.3891 68.4062 46.8024 65.7594 51.5246 67.4067C56.2467 69.0539 56.9697 74.2245 56.7409 76.6039C60.8247 79.0748 61.5454 83.1586 60.3443 85.4236C59.3834 87.2355 59.9439 88.2148 60.3443 88.4779C64.5173 92.1842 62.083 96.4053 60.3443 98.0525C65.6155 105.246 61.5501 109.955 58.8585 111.411L46.6328 112.674Z"
                fill="#333333"
            />
            <g clipPath="url(#clip3_21574_199638)">
                <path
                    d="M71.3284 138.895C71.3284 159.642 57.277 176.461 39.9438 176.461C22.6105 176.461 8.55917 159.642 8.55917 138.895C8.55917 118.148 22.6105 101.329 39.9438 101.329C57.277 101.329 71.3284 118.148 71.3284 138.895Z"
                    fill="#FA374B"
                />
            </g>
            <circle r="17.1708" transform="matrix(-1 0 0 1 37.9661 82.8891)" fill="white" />
            <path
                d="M48.5766 105.896C46.6034 103.077 45.5859 98.7312 45.5859 96.1564L37.8616 95.1489C36.4063 97.8356 35.5107 100.522 35.5107 105.224C35.5108 109.525 50.9275 109.254 48.5766 105.896Z"
                fill="white"
            />
            <path
                d="M40.9756 88.7593C41.2843 89.9459 42.7204 91.889 45.9955 90.1682"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M44.4464 81.7896L47.0737 85.6893C47.2488 86.2147 46.9686 87.1606 44.4463 86.7402"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="38.1402"
                cy="82.0105"
                r="4.73026"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle
                cx="50.7527"
                cy="79.9075"
                r="4.73026"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M32.8841 83.061L26.0514 84.6378"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <path
                d="M42.3651 79.9147C42.8905 78.8637 44.4469 77.8059 46.0233 79.3824"
                stroke="#333333"
                strokeWidth="1.0511"
                strokeLinecap="round"
                strokeLinejoin="round"
            />
            <circle cx="49.1763" cy="80.4334" r="1.05109" fill="#333333" />
            <circle cx="39.7157" cy="81.4847" r="1.05109" fill="#333333" />
            <path
                d="M49.9131 70.1252C46.8696 74.4604 40.6794 72.3631 38.1076 70.7243C37.9 73.8616 34.2724 75.1562 32.4845 75.4113C32.7715 76.7505 32.1497 78.6157 30.6193 80.8157C29.395 82.5757 30.1092 83.494 30.6193 83.7331C34.1011 86.2201 34.015 88.3404 33.5367 89.0896C32.6121 90.4925 31.4993 93.7766 34.4454 95.6896C38.128 98.0809 37.1715 100.855 36.3106 102.003C35.4498 103.151 36.3201 105.739 38.1076 106.355C40.6275 107.223 42.313 108.132 43.4176 112.418C44.3263 115.24 42.3559 119.287 37.6498 120.32C31.7671 121.611 31.6237 119.459 28.3236 115.298C25.0236 111.138 22.0584 111.137 16.5581 115.777C11.0577 120.416 8.41128 115.036 8.17215 114.654C7.93301 114.271 5.96116 107.635 11.4408 107.072C13.4159 106.87 13.0827 105.032 12.7798 104.537C9.00154 98.9896 14.8842 93.9201 16.5581 92.5809C18.232 91.2418 16.0797 86.3157 15.7927 84.5461C15.5058 82.7766 16.3188 79.907 18.6145 78.6157C20.451 77.5826 20.6868 76.2722 20.5751 75.7461C18.7163 61.4498 28.546 61.2069 33.3445 62.3547C39.0071 55.1999 45.2463 57.1467 48.6011 61.3025C51.5796 61.0917 53.8495 62.1455 54.5939 64.0038C56.4487 68.6336 51.867 70.1973 49.9131 70.1252Z"
                fill="#333333"
            />
            <path d="M72.442 118.619L60.0898 110.174L68.8499 127.063L72.442 118.619Z" fill="#FA374B" />
        </g>
        <ellipse rx="17.1665" ry="17.1708" transform="matrix(-1 0 0 1 38.1879 82.8881)" fill="white" />
        <path
            d="M48.7957 105.895C46.823 103.076 45.8058 98.7302 45.8058 96.1555L38.0834 95.1479C36.6284 97.8347 35.7331 100.521 35.7331 105.223C35.7331 109.524 51.146 109.253 48.7957 105.895Z"
            fill="white"
        />
        <path
            d="M38.7325 89.5527C39.0412 90.7394 40.8022 92.5978 45.3775 90.5385"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path
            d="M44.6666 82.5025L47.2939 86.4021C47.4691 86.9276 47.1888 87.8735 44.6665 87.4531"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <ellipse
            cx="38.3608"
            cy="82.0096"
            rx="4.72907"
            ry="4.73026"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <ellipse
            cx="50.9706"
            cy="79.9075"
            rx="4.72907"
            ry="4.73026"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <path d="M33.1073 83.0601L26.2763 84.6369" stroke="#333333" strokeLinecap="round" strokeLinejoin="round" />
        <path
            d="M42.5841 79.9142C43.1096 78.8633 44.666 77.8054 46.2424 79.3819"
            stroke="#333333"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
        <circle cx="51.2965" cy="79.4813" r="1.05109" fill="#333333" />
        <circle cx="39.9371" cy="81.4827" r="1.05109" fill="#333333" />
        <path
            d="M49.8997 70.1252C46.8571 74.4604 40.6684 72.3631 38.0973 70.7243C37.8897 73.8616 34.263 75.1562 32.4756 75.4113C32.7625 76.7505 32.1409 78.6157 30.6108 80.8157C29.3868 82.5757 30.1008 83.494 30.6108 83.7331C34.0917 86.2201 34.0056 88.3404 33.5275 89.0896C32.6031 90.4925 31.4906 93.7766 34.436 95.6896C38.1177 98.0809 37.1614 100.855 36.3007 102.003C35.4401 103.151 36.3102 105.739 38.0973 106.355C40.6165 107.223 42.3016 108.132 43.4059 112.418C44.3144 115.24 42.3444 119.287 37.6395 120.32C31.7584 121.611 31.6149 119.459 28.3158 115.298C25.0166 111.138 22.0521 111.137 16.5531 115.777C11.0542 120.416 8.40837 115.036 8.1693 114.654C7.93023 114.271 5.95887 107.635 11.4371 107.072C13.4118 106.87 13.0787 105.032 12.7758 104.537C8.99849 98.9896 14.8796 93.9201 16.5531 92.5809C18.2266 91.2418 16.0749 86.3157 15.788 84.5461C15.5011 82.7766 16.3139 79.907 18.609 78.6157C20.4451 77.5826 20.6808 76.2722 20.5691 75.7461C18.7108 61.4498 28.5381 61.2069 33.3354 62.3547C38.9966 55.1999 45.2342 57.1467 48.5881 61.3025C51.5659 61.0917 53.8352 62.1455 54.5794 64.0038C56.4338 68.6336 51.8532 70.1973 49.8997 70.1252Z"
            fill="#333333"
        />
        <path
            d="M72.3534 117.671L59.2135 109.241C58.4156 108.729 57.4419 109.572 57.8342 110.435L69.0002 135L73.9159 122.502C74.6147 120.725 73.9604 118.702 72.3534 117.671Z"
            fill="#FA374B"
        />
        <defs>
            <clipPath id="clip0_21574_199638">
                <rect width="143.2" height="100" rx="7.60839" transform="matrix(-1 0 0 1 224 15.6001)" fill="white" />
            </clipPath>
            <clipPath id="clip1_21574_199638">
                <rect width="70" height="68" rx="8" transform="matrix(-1 0 0 1 240 102)" fill="white" />
            </clipPath>
            <clipPath id="clip2_21574_199638">
                <rect y="30" width="69.4266" height="109.371" rx="8" fill="white" />
            </clipPath>
            <clipPath id="clip3_21574_199638">
                <rect width="66.5734" height="62.7692" fill="white" transform="translate(4.75507 101.329)" />
            </clipPath>
        </defs>
    </StyledIllustration>
);
