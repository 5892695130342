import React, { Suspense, useMemo } from "react";
import { ErrorBoundary } from "@kaltura/mediaspace-shared-ui";
import { baseUrl, fetchKmsData } from "@kaltura/mediaspace-shared-utils";
import PlaylistsForm, { GetPlaylistsProps, PlaylistsFormProps } from "./PlaylistsForm";
import PlaylistsFormSkeleton from "./playlists-form-skeleton/PlaylistsFormSkeleton";

const PlaylistsFormContainer = (props: Omit<PlaylistsFormProps, "fetchData">) => {
    const { entryIds } = props;

    const ids = entryIds.join(",");
    const fetchData = useMemo(
        () => fetchKmsData<GetPlaylistsProps>(`${baseUrl}/addtoplaylists/my-media/get-playlists/ids/${ids}`),
        [ids]
    );

    return (
        <ErrorBoundary fallback={<PlaylistsFormSkeleton />}>
            <Suspense fallback={<PlaylistsFormSkeleton />}>
                <PlaylistsForm {...props} fetchData={fetchData} />
            </Suspense>
        </ErrorBoundary>
    );
};

export default PlaylistsFormContainer;
