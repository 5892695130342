import { Verified24Icon } from "@kaltura/ds-react-icons";
import { IconButton, Menu, menuClasses, Tooltip } from "@kaltura/ds-react-components";
import styled from "@emotion/styled";
import { useState, MouseEvent } from "react";
import { BadgesTabProps } from "../common/types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import MenuContent from "./menu-content/MenuContent";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { translate } from "@kaltura/mediaspace-shared-utils";

const BadgesMenuButton = styled(IconButton)(({ theme }) => ({
    color: theme.palette.common.white,
    position: "relative",
    minWidth: 24,
    maxWidth: 24,
    "&:hover": {
        backgroundColor: "initial",
    },
    "&:hover .active-indicator": {
        display: "block",
    },
}));

const StyledActiveIndicator = styled(Box)(({ theme }) => ({
    position: "absolute",
    bottom: -13,
    minHeight: 3,
    width: "100%",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    backgroundColor: theme.palette.common.white,
    display: "none",
}));

const StyledMenu = styled(Menu)(({ theme }) => ({
    [`& .${menuClasses.paper}`]: {
        width: 355,
        maxHeight: 560,
        borderRadius: theme.kaltura.shape.roundness2,
        backgroundColor: theme.kaltura.palette.surfaces.paper,
        backgroundImage: "none",
        [theme.breakpoints.down(theme.breakpoints.values.md)]: {
            maxHeight: 482,
        },
    },
}));

export type BadgesMenuProps = {
    badges: BadgesTabProps;
};

const staticClassBasic = "kms-ds-header-menu-badges-menu";

/**
 * site Header user-badges menu component
 */
export const BadgesMenu = (props: BadgesMenuProps) => {
    const { earnedBadges, badgesToEarn } = props.badges;

    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        sendButtonAnalytics("Show Badge Info - Header menu", ButtonClickAnalyticsType.OPEN);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={"kms-ds-user-badges-menu"}>
            <Tooltip title={translate("badges")} placement={"bottom-start"}>
                <BadgesMenuButton
                    id={"userBadgesMenuToggleBtn"}
                    aria-controls={open ? "userBadgesMenuToggleBtn" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    onClick={handleClick}
                    className={`${staticClassBasic}-btn`}
                    aria-label={translate("badges")}
                >
                    {<Verified24Icon />}
                    <StyledActiveIndicator className={"active-indicator"} />
                </BadgesMenuButton>
            </Tooltip>
            <StyledMenu
                id={"userBadgesMenuToggleBtn"}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    "aria-labelledby": "userBadgesMenuToggleBtn",
                }}
                className={`${staticClassBasic}-popup`}
            >
                <MenuContent earnedBadges={earnedBadges} badgesToEarn={badgesToEarn} />
            </StyledMenu>
        </div>
    );
};

export default BadgesMenu;
