import React from "react";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { ColoredErrorIllustration, StatusScreen } from "@kaltura/mediaspace-shared-ui";
import { alpha, useTheme } from "@kaltura/mediaspace-shared-styled";

/**
 * Generate AI quiz error state
 */
export const AiQuizGenerateError = () => {
    const theme = useTheme();
    return (
        <StatusScreen
            title={translate("Something went wrong")}
            titleColor={theme.palette.common.white}
            text={translate(
                "Something went wrong during AI quiz creation process. Please try again later or contact support."
            )}
            textColor={alpha(theme.palette.common.white, 0.9)}
            image={<ColoredErrorIllustration />}
            contentWidth={640}
        />
    );
};
