/**
 * Safely execute callback after timeout milliseconds.
 *
 * The native setTimeout() API doesn't handle big timeouts correctly, but the function below patches the limitation.
 */
export const safeSetTimeout = (callback: () => void, timeout: number): NodeJS.Timeout => {
    if (timeout > 1e9) {
        return 0 as unknown as NodeJS.Timeout;
    }

    return setTimeout(callback, timeout);
};
