import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const ChannelIllustration = () => (
    <StyledIllustration
        fileName={"AddNewChannelIllustration.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="97.6934"
            y="74"
            width="71.387"
            height="78.3865"
            rx="8"
            transform="rotate(10 97.6934 74)"
            fill="#FF9DFF"
        />
        <rect x="44.5" y="87.334" width="110.667" height="76" rx="8" fill="#FFD357" />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M94.285 40.3515C95.6388 34.2667 101.669 30.4315 107.754 31.7853V31.7853C113.839 33.1391 117.674 39.1693 116.32 45.2541V45.2541C114.966 51.339 108.936 55.1742 102.851 53.8204V53.8204C96.7665 52.4666 92.9312 46.4364 94.285 40.3515V40.3515Z"
            fill="#006EFA"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M106.13 40.5009C106.13 40.5009 108.336 38.3611 110.317 39.2558C112.299 40.1505 112.8 43.0576 110.918 44.9204C108.87 47.5334 105.18 48.1642 104.163 48.3026C104.056 48.3171 103.93 48.289 103.806 48.217C101.243 46.7286 99.9721 44.3238 99.3161 42.339C99.3161 42.339 98.3952 38.6899 101.466 37.6842C104.536 36.6785 106.13 40.5009 106.13 40.5009Z"
            fill="white"
        />
        <path
            d="M134.187 84.2738L125.881 98.3439C124.926 99.9628 122.838 100.5 121.22 99.5447V99.5447C119.601 98.589 119.063 96.5019 120.019 94.883L129.882 78.1749C131.698 75.099 135.663 74.0775 138.739 75.8933V75.8933C141.815 77.7091 142.837 81.6746 141.021 84.7505L140.331 85.9199"
            className="sTone1"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            d="M32.5 122C32.5 117.582 36.0817 114 40.5 114H105.167C109.585 114 113.167 117.582 113.167 122V143V164C113.167 168.418 109.585 172 105.167 172H40.5C36.0817 172 32.5 168.418 32.5 164V122Z"
            fill="#006EFA"
        />
        <path d="M44.5 162H101.167" stroke="white" stroke-width="2" stroke-linecap="round" />
        <circle cx="72.8534" cy="140.014" r="12" transform="rotate(-5.3899 72.8534 140.014)" fill="white" />
        <path
            d="M78.0195 139.148C78.6862 139.533 78.6862 140.495 78.0195 140.88L71.0195 144.922C70.3529 145.307 69.5195 144.826 69.5195 144.056L69.5195 135.973C69.5195 135.203 70.3529 134.722 71.0195 135.107L78.0195 139.148Z"
            fill="#FF3D23"
        />
        <rect
            width="28"
            height="14.6667"
            rx="7.33333"
            transform="matrix(0.960672 -0.277687 -0.277687 -0.960672 65.2393 75.1982)"
            fill="#FF3D23"
        />
        <ellipse
            cx="2"
            cy="2"
            rx="2"
            ry="2"
            transform="matrix(0.960672 -0.277687 -0.277687 -0.960672 68.8818 68.5938)"
            fill="white"
        />
        <circle
            cx="2"
            cy="2"
            r="2"
            transform="matrix(0.960672 -0.277687 -0.277687 -0.960672 75.2871 66.7422)"
            fill="white"
        />
        <ellipse
            cx="2"
            cy="2"
            rx="2"
            ry="2"
            transform="matrix(0.960672 -0.277687 -0.277687 -0.960672 81.6914 64.8916)"
            fill="white"
        />
    </StyledIllustration>
);
