/**
 *
 * helper to extract values from Filters
 *
 */

import { Filter, FilterValues } from "@kaltura/mediaspace-shared-types";

/**
 * get the default value of a filter
 */
const getDefaultFilterValue = (filter: Filter) => {
    if (filter.singleSelection) {
        return filter.defaultValue;
    }
    else {
        return [filter.defaultValue];
    }
};

/**
 *  extract default values from filters
 */
export const extractDefaultFilterValues = (filters: Filter[]) => {
    const values = filters.reduce(
        (obj, filter) => {
            if (filter.freeText) {
                // free text items are considered their own filters
                const freeTextValues = filter.items.reduce(
                    (obj, item) => {
                        if (item.param) {
                            obj[item.param] = item.defaultValue;
                        }
                        return obj;
                    },
                    {} as Record<string, any>
                );

                obj = { ...obj, ...freeTextValues };
                return obj;
            }
            if (filter.param) {
                obj[filter.param] = getDefaultFilterValue(filter);
            }
            return obj;
        },
        {} as Record<string, any>
    );

    return values;
};

/**
 * remove default values from the filter values
 */
export const removeDefaultFilterValues = (currentValues: any, defaultValues: any, paramsToIgnore: string[] = []) => {
    const filteredValues: any = {};
    for (const fieldName in currentValues) {
        if (Object.prototype.hasOwnProperty.call(currentValues, fieldName)) {
            if (paramsToIgnore.includes(fieldName)) {
                continue;
            }
            const currentValue = currentValues[fieldName];
            const defaultValue = defaultValues[fieldName];
            if (JSON.stringify(currentValue) !== JSON.stringify(defaultValue)) {
                filteredValues[fieldName] = currentValue;
            }
        }
    }
    return filteredValues;
};

/**
 * remove empty values from the filter values
 */
export const removeEmptyFilterValues = (filterValues: FilterValues) => {
    // remove empty values
    const realValues: FilterValues = {};
    for (const key in filterValues) {
        // if there is a value (not undefined, empty string, etc)
        if (filterValues[key]) {
            // if value is array
            if (Array.isArray(filterValues[key])) {
                // only add if not empty
                if (filterValues[key].length) {
                    realValues[key] = filterValues[key];
                }
            }
            else {
                // has string value
                realValues[key] = filterValues[key];
            }
        }
    }

    return realValues;
};

/**
 * remove empty or default values fro mthe filter values
 */
export const removeEmptyDefaultFilterValues = (filters: Filter[], filterValues: FilterValues) => {
    // remove empty values
    const currentValues = removeEmptyFilterValues(filterValues);
    const defaultValues = extractDefaultFilterValues(filters);

    // remove values which are the same as the default ones
    const minimizedQuery = removeDefaultFilterValues(currentValues, defaultValues, ["format", "page"]);

    return minimizedQuery;
};
