import { KmsTypePublishEntryCategories, KmsTypePublishUserCategories } from "@kaltura/mediaspace-shared-types";
import { PublishedCategories } from "./published-categories/PublishedCategories";
import { PublishTabs } from "./publish-tabs/PublishTabs";

export interface PublishLocationsProps {
    entryCategories: KmsTypePublishEntryCategories;
    userCategories: KmsTypePublishUserCategories;
    entryId: string;
    crossApplicationUnpublishing: boolean;
    onLocationsCount: (count: number) => void;
    onCategoryChange: (categoryId: number, checked: boolean) => void;
    onUnpublishExternal: () => void;
}

/**
 * Publish locations - current entry categories and potential categories the user can publish to
 */
export function PublishLocations(props: PublishLocationsProps) {
    const {
        entryCategories,
        userCategories,
        entryId,
        crossApplicationUnpublishing,
        onLocationsCount,
        onCategoryChange,
        onUnpublishExternal,
    } = props;

    return (
        <div className="kms-ds-publish-locations">
            <PublishedCategories
                entryCategories={entryCategories}
                entryId={entryId}
                onLocationsCount={onLocationsCount}
                onDelete={(categoryId) => onCategoryChange(categoryId, false)}
            />

            <PublishTabs
                entryCategories={entryCategories}
                userCategories={userCategories}
                entryId={entryId}
                crossApplicationUnpublishing={crossApplicationUnpublishing}
                onCategoryChange={onCategoryChange}
                onUnpublishExternal={onUnpublishExternal}
            />
        </div>
    );
}
