import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { BadgeProps } from "../../common/types";
import Carousel from "./carousel/Carousel";
import BadgeIconWrapper from "./BadgeIconWrapper";

type EarnedBadgesProps = {
    badges: BadgeProps[];
};

const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
    fontSize: theme.typography.pxToRem(12),
    textTransform: "uppercase",
    fontWeight: theme.kaltura.typography.fontWeightBold,
    letterSpacing: 1,
}));

/**
 * header user badges menu earned-badges section component
 */
const EarnedBadges = (props: EarnedBadgesProps) => {
    const { badges } = props;
    const badgesCount = badges.length;

    return (
        <div className="kms-ds-header-user-earned-badges">
            <StyledTypography className={"kms-ds-header-menu-badges-menu-earned-badges-title"}>
                {translate("%1 earned badges", [badgesCount])}
            </StyledTypography>
            <Carousel showArrows={badgesCount > 4}>
                {badges.map((badge: BadgeProps) => (
                    <BadgeIconWrapper badge={badge} />
                ))}
            </Carousel>
        </div>
    );
};

export default EarnedBadges;
