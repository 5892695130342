import { Fragment, useMemo } from "react";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { WebcastEntry } from "@kaltura/mediaspace-shared-types";
import styled from "@emotion/styled";
import { convertToScheduledSessions } from "@kaltura/mediaspace-shared-event-platform-event-list";
import { useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { Typography } from "@kaltura/ds-react-components";
import { SidebarSessionItem } from "./SidebarSessionItem";
import { SidebarSessionBreak } from "./SidebarSessionBreak";
import { SidebarContentsTitle } from "@kaltura/ds-react-layouts";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { Bulletlist24Icon } from "@kaltura/ds-react-icons";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";

/*
 * Contents of the channel TV agenda sidebar
 */

export interface SidebarContentsProps {
    channelId: number;
    currentEntryId?: string;
    minBreak: number;
    maxBreak: number;
    entries: WebcastEntry[];
}

export const SidebarContents = ({ entries, channelId, currentEntryId, minBreak, maxBreak }: SidebarContentsProps) => {
    const {
        application: {
            dateFormats: { longDateNoYear },
        },
    } = useConfig();

    const sessions = useMemo(() => convertToScheduledSessions(entries, longDateNoYear), [entries, longDateNoYear]);
    const sendButtonAnalytics = useButtonAnalytics();

    return (
        <StyledOuterContainer>
            <SidebarContentsTitle
                title={translate("Agenda")}
                icon={<Bulletlist24Icon />}
                onClose={() => sendButtonAnalytics("CnC - Close TV guide sidebar", ButtonClickAnalyticsType.CLOSE)}
            />

            <StyledInnerContainer>
                <StyledContainer>
                    {sessions.map((session, index) => {
                        const prevSession = sessions[index - 1];

                        return (
                            <Fragment key={session.id}>
                                {prevSession && (
                                    <SidebarSessionBreak
                                        start={prevSession.schedulingData.end.timestamp}
                                        end={session.schedulingData.start.timestamp}
                                        min={minBreak}
                                        max={maxBreak}
                                    />
                                )}

                                {prevSession?.schedulingData.label !== session.schedulingData.label && (
                                    <Typography variant={"body2Highlight"}>{session.schedulingData.label}</Typography>
                                )}

                                <SidebarSessionItem
                                    channelId={channelId}
                                    session={session}
                                    isActive={session.id === currentEntryId}
                                />
                            </Fragment>
                        );
                    })}
                </StyledContainer>
            </StyledInnerContainer>
        </StyledOuterContainer>
    );
};

const StyledOuterContainer = styled(Box)({
    position: "absolute",
    inset: 0,
    display: "flex",
    flexDirection: "column",
});

const StyledInnerContainer = styled(Box)(({ theme }) => ({
    flex: 1,
    minHeight: 0,
    padding: theme.spacing(2),
    overflow: "auto",
}));

const StyledContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(2),
}));
