import { DropdownOption } from "./DropdownOption";

export type FieldValue = string | number | boolean;

export enum FieldType {
    text = "text",
    readOnlyText = "readOnlyText",
    select = "select",
    checkbox = "checkbox",
    radio = "radio",
    multitag = "multitag",
    usertag = "usertag",
    password = "password",
    registrationCode = "registrationCode",
    email = "email",
    hidden = "hidden",
    autocomplete = "off",
    registrationType = "registrationType",
}

/**
 * definition for registration form field
 */
export interface RegistrationFieldInfo {
    fieldType: FieldType;
    /** unique id for internal form handling */
    id: string;
    /** how to save the field in the registration info */
    name: string;
    /** what will show to the users in the registration form */
    displayName: string;
    /** (marketing id, for the marketo form (and maybe in the future, other forms) */
    marketoRefId?: string;
    mandatory?: boolean;
    /** asked Ido if this is per-item or a generic text */
    mandatoryText: string;
    /** field placeholder for applicable field types */
    placeHolder?: string;
    /** is the field support creating new options */
    createable?: boolean;
    /** should be read-only on edit page */
    readOnlyOnEdit?: boolean;
    /** should the event platform manager be able to edit this field */
    isEditableOnEPM?: boolean;
    /** should field show on edit page */
    hideOnEdit?: boolean;
    /** should field show on registration page */
    hideOnReg?: boolean;
    /** indicates this field is a user-object field */
    userField: boolean;
    /** selectbox options, multitag options */
    options?: DropdownOption[];
    /** select up to X items */
    tagsLimit?: number;
    /** text to show in multitag selection modal */
    multitagInstruction?: string;
    /** show the field if 'field' has value 'value' */
    depends?: {
        /** another field's id */
        field: string;
        /** expect this to match one of the optional values of the relevant field */
        value: FieldValue;
    };
    /** default value, potentially per condition */
    defaults?: {
        /** use given 'defaultValue' if 'field' has value 'value' */
        depends?: {
            /** another field's id */
            field: string;
            /** expect this to match one of the optional values of the relevant field */
            value: FieldValue;
        };
        /** default value for the current field */
        defaultValue: FieldValue;
    }[];
    /** group mapping*/
    groupMap?: {
        value: FieldValue;
        groupId: string;
    }[];
    /** can this field be filled in from url param? */
    allowOnUrl?: boolean;
    /** allow only these values as url param values */
    acceptableUrlValues?: string[];
    /** max length for a free-text field (not relevant for drop-downs) */
    maxLength: number;
    /** should field show on invite force registration page */
    hideOnInvite?: boolean;
    /** should be read-only on invite force registration page */
    readOnlyOnInvite?: boolean;
    /** should field show - used when rendering the registration form */
    hidden?: boolean;
    /**type of personal data */
    autocomplete?: string;
    /** should field be read-only - used when rendering the registration form */
    readOnly?: boolean;
    /** should field show for SSO users on the registration page */
    hideOnSso?: boolean;
    /** should field be read-only for SSO users on the registration page */
    readOnlyOnSso?: boolean;
    /** should field show in checkin flow */
    showFieldOnCheckinFlow?: boolean;
    /** should field show in checkin flow */
    isAllowedToEditInCheckinFlow?: boolean;
    /** should the field show in check-in app */
    showInCheckin?: boolean;
    /** should the field show in check-in app edit page */
    editInCheckIn?: boolean;
}

/**
 * definition for registration form section (a group of fields)
 */
export interface RegistrationSectionInfo {
    /** section title */
    title: string;
    /** should section show on edit page */
    hideOnEdit?: boolean;
    /** section fields */
    fields: RegistrationFieldInfo[];
    /** should section show on invite force registration page */
    hideOnInvite?: boolean;
    /** should section show - used when rendering the registration form */
    hidden?: boolean;
    /** should section show for SSO users on the registration page */
    hideOnSso?: boolean;
}

/**
 * definition for registration form
 */
export interface RegistrationFormInfo {
    /** form title */
    title: string;
    /** text to show under the form title */
    titleText: string;
    /** edit form title */
    editTitle: string;
    /** text to show under the form title on edit screen */
    editTitleText: string;
    /** form sections */
    sections: RegistrationSectionInfo[];
    /** only applicable on registration, not on edit-registration */
    useCaptcha: boolean;
}
