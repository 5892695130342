import { EntryListItemAction, EntryListItemActionProps } from "./EntryListItemAction";
import React from "react";

export interface EntryListItemActionsProps {
    actions?: EntryListItemActionProps[];
}

/**
 * actions for the entry list item
 */
export function EntryListItemActions({ actions }: EntryListItemActionsProps) {
    if (!actions?.length) {
        return null;
    }
    if (actions.length === 1) {
        return <EntryListItemAction {...actions[0]} />;
    }
    return <div style={{ color: "red" }}>multiple actions not implemented yet!</div>;
}

export default EntryListItemActions;
