import { useCallback, useEffect, useRef } from "react";
import { KMS_GLOBAL } from "@kaltura/mediaspace-shared-utils";
import { useEmbeddedCncWidgetContext } from "@kaltura/mediaspace-shared-contexts";

/**
 * React hook to start and end C&C reactions
 * (supports both floated and embedded C&C widgets)
 */
export const useCncReactions = () => {
    const { setShowReactions } = useEmbeddedCncWidgetContext();

    const reactionsInitInterval = useRef(0);

    const startReactions = useCallback(() => {
        // Handle embedded C&C widget
        setShowReactions(true);

        // Handle floated C&C widget
        let count = 0;
        reactionsInitInterval.current = window.setInterval(
            () => {
                if (KMS_GLOBAL.floater) {
                    try {
                        KMS_GLOBAL.floater.startReactions();
                    }
                    catch (err) {
                        console.error("Failed to load chat reactions widget: " + err);
                    }
                    clearInterval(reactionsInitInterval.current);
                }
                if (++count === 20) {
                    clearInterval(reactionsInitInterval.current);
                }
            },
            500
        ); // need interval in case floater is loaded after streaming trigger
    }, [setShowReactions, reactionsInitInterval]);

    const endReactions = useCallback(() => {
        // Handle embedded C&C widget
        setShowReactions(false);

        // Handle floated C&C widget
        try {
            KMS_GLOBAL.floater?.endReactions();
        }
        catch (err) {
            console.error("Failed to end chat reactions widget: " + err);
        }
    }, [setShowReactions]);

    useEffect(() => {
        return () => {
            window.clearInterval(reactionsInitInterval.current);
        };
    }, []);

    return { startReactions, endReactions };
};
