import { KmsTypeFilterItemBase } from "./KmsTypeFilterItemBase";
import { ESearchFilterItemRangePickerType } from "../../ESearchFilterItemRangePickerType";

/**
 * ts implementation of Kms_Type_Esearch_Filter_Items_RangePicker
 */
export interface KmsTypeFilterItemRange extends KmsTypeFilterItemBase {
    /**
     * range picker type
     */
    rangePickerType: ESearchFilterItemRangePickerType;

    /**
     * slider interval
     */
    step?: number;

    /**
     * is this a range picker
     */
    rangepicker: true;

    /**
     * initial status props
     */
    checked: boolean;

    /**
     * slider minimal value, range bottom
     */
    minValue: number;

    /**
     * slider maximal value, range top
     */
    maxValue: number;

    /**
     * label to show for a single item
     */
    labelSingular?: string;

    /**
     * label to show for multiple items
     */
    labelPlural?: string;

    /**
     * how many units equals 1 value? (this is needed for formatting a label for single value vs plural)
     */
    unit?: number;

    /**
     * if false, render a radio button
     * (not implemented)
     */
    isCheckbox?: boolean;
}

// noinspection PointlessBooleanExpressionJS
export const isKmsTypeFilterItemRange = (value: KmsTypeFilterItemBase<any>): value is KmsTypeFilterItemRange =>
    (value as KmsTypeFilterItemRange).rangepicker === true;
