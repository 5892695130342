import { PlaylistType } from "./modal/AddToPlaylistModal";
import { KeyboardEvent } from "react";

/**
 * filter playlists lists according to search term, if not empty
 */
export const filterPlaylists = (
    original: PlaylistType[],
    extra: PlaylistType[],
    newPls: PlaylistType[],
    searchTerm: string
) => {
    const filteredOriginal =
        searchTerm !== ""
            ? original.filter((playlist: PlaylistType) =>
                  playlist.name.toLowerCase().includes(searchTerm.toLowerCase())
              )
            : original;
    const filteredExtra =
        searchTerm !== ""
            ? extra.filter((playlist: PlaylistType) => playlist.name.toLowerCase().includes(searchTerm.toLowerCase()))
            : extra;
    const filteredNew =
        searchTerm !== ""
            ? newPls.filter((playlist: PlaylistType) => playlist.name.toLowerCase().includes(searchTerm.toLowerCase()))
            : newPls;

    return [...filteredNew, ...filteredOriginal, ...filteredExtra];
};

/**
 * allow all characters for input field inside a modal inside a menu,
 * also characters which are the first letter of the menu items
 * issue: https://github.com/mui/material-ui/issues/19096
 */
export const onInputKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    e.stopPropagation();
};
