import { WebcastEntry } from "@kaltura/mediaspace-shared-types";
import { KeyboardEvent } from "react";
import { translate, WebcastStatus } from "@kaltura/mediaspace-shared-utils";
import { Play24Icon } from "@kaltura/ds-react-icons";
import MailMenu from "./mail-menu/MailMenu";
import styled from "@emotion/styled";
import { Button } from "@kaltura/ds-react-components";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";

const JoinButton = styled(Button)({
    float: "right",
});

/**
 * get call-to-action button per given live-status
 * @param status
 * @param entryId
 * @param entry
 * @param vodCallback
 * @param liveCallback
 * @param scheduledCallback
 * @param sendButtonAnalytics
 * @param disabledMode
 * @param categoryId
 */
export const getCallToAction = (
    status: WebcastStatus,
    entryId: string,
    entry: WebcastEntry,
    vodCallback: (entryId: string, entryName: string, categoryId?: number) => void,
    liveCallback: (entryId: string, entryName: string, categoryId?: number) => void,
    scheduledCallback: (entryId: string, calendarType: "google" | "outlook", categoryId?: number) => void,
    sendButtonAnalytics: (actionName: string, buttonType: number) => void,
    disabledMode?: boolean,
    categoryId?: number
) => {
    let callback: () => void;
    let callbackWithParam: (s: string, evt: KeyboardEvent<HTMLAnchorElement> | null) => boolean;

    switch (status) {
        case WebcastStatus.past: {
            if (!entry.recordedEntryId) {
                return null;
            }

            callback = () => {
                sendButtonAnalytics("Session card - Watch VOD", ButtonClickAnalyticsType.LINK);
                vodCallback(entryId, entry.name, categoryId);
                return true;
            };
            return (
                <JoinButton
                    color={"primary"}
                    disabled={disabledMode}
                    onClick={callback}
                    startIcon={<Play24Icon />}
                    className={"kms-ds-event-list-item-button-watch"}
                    size={"large"}
                    aria-label={translate("Watch Button - %1", [entry.name])}
                >
                    {translate("Watch")}
                </JoinButton>
            );
        }
        case WebcastStatus.live: {
            callback = () => {
                sendButtonAnalytics("Session card - Join Now", ButtonClickAnalyticsType.LINK);
                liveCallback(entryId, entry.name, categoryId);
                return true;
            };
            return (
                <JoinButton
                    color={"danger"}
                    disabled={disabledMode}
                    onClick={callback}
                    startIcon={<Play24Icon />}
                    className={"kms-ds-event-list-item-button-join"}
                    size={"large"}
                    aria-label={translate("Join Button - %1", [entry.name])}
                >
                    {translate("Join Now")}
                </JoinButton>
            );
        }
        case WebcastStatus.future: {
            callbackWithParam = (calendarType, evt) => {
                if (evt && evt.key !== "Enter") {
                    return false;
                }
                sendButtonAnalytics("Session card - Add to Calendar", ButtonClickAnalyticsType.DOWNLOAD);
                scheduledCallback(entry.id, calendarType as "google" | "outlook", categoryId);
                return true;
            };
            const dropdownOptions = [
                { label: translate("Outlook Calendar"), value: "outlook" },
                { label: translate("Google Calendar"), value: "google" },
            ];
            return (
                <div>
                    <MailMenu entryId={entryId} callback={callbackWithParam} items={dropdownOptions} />
                </div>
            );
        }
        default:
            return null;
    }
};
