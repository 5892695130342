export enum ChannelViewMode {
    Detailed = "Detailed",
    Grid = "Grid",
}

export enum ChannelLayoutMode {
    Fixed_Tabs = "Fixed_Tabs",
    Fixed_Vertical = "Fixed_Vertical",
    Tabs = "Tabs",
    Vertical = "Vertical",
}

export enum ChannelHeaderType {
    Show_Banner = "ShowBanner",
    Hide_Banner = "HideBanner",
    Fixed_Show_Banner = "Fixed_ShowBanner",
    Fixed_Hide_Banner = "Fixed_HideBanner",
}
