import { BadgeProps } from "../../../common/types";
import { Typography } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import BadgeToEarn from "./badge-to-earn/BadgeToEarn";

type BadgesToEarnProps = {
    badges: BadgeProps[];
};

const StyledBadgesToEarn = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(5),
    color: theme.kaltura.palette.tone1,
}));

const StyledList = styled(Box)(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(3),
    marginTop: theme.spacing(3),
    flexWrap: "wrap",
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        gap: theme.spacing(4),
    },
}));

const BadgesToEarn = (props: BadgesToEarnProps) => {
    const { badges = [] } = props;

    return (
        <StyledBadgesToEarn className="kms-ds-personal-badges-to-earn">
            <Typography variant={"h5"} component={"h2"}>
                {translate("Badges you can earn")}
            </Typography>
            <StyledList>
                {badges.map((badge: BadgeProps) => (
                    <BadgeToEarn name={badge.name} iconUrl={badge.iconUrl} description={badge.description} />
                ))}
            </StyledList>
        </StyledBadgesToEarn>
    );
};

export default BadgesToEarn;
