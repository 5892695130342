declare global {
    interface Window {
        LOCALE?: Record<string, string>;
    }
}

type TranslationArgs = (string | number)[];

/**
 *
 * translation function
 * expects translation string key (the string to translate)
 * and an array of args for placeholders replacements
 * e.g - translate('there are %1 cats and %2 dogs...', array(3, 5))
 * @param value string to be translated
 * @param args for placeholders replacement
 */

export const translate = (value: string, args?: TranslationArgs) => {
    let str = value;
    if (typeof window.LOCALE !== "undefined") {
        str = str in window.LOCALE ? window.LOCALE[str] : str;
    }
    return replacePlaceholders(str, args);
};

/**
 * translate with the option for singular/plural.
 * usage example:
 * translate('%1 Entry Found', '%1 Entries Found', [ 22 ]) will output "22 Entries Found"
 * (Don't forget to add the translations to the LOCALE using the jsTranslations partial!)
 * @param singularString
 * @param pluralString
 * @param args
 * @returns string
 */
export function translatePlural(singularString: string, pluralString: string, args: TranslationArgs) {
    const count = Number(args[0]);
    const plural = !Number.isNaN(count) && (count > 1 || count === 0);

    return plural ? translate(pluralString, args) : translate(singularString, args);
}

/**
 * replacement of placeholders in a string (%1 %2 .... %n)
 * @param string
 * @param args for placeholders replacement
 */
function replacePlaceholders(string: string, args?: TranslationArgs) {
    if (args?.length) {
        //translation placeholders (%1 %2 ... %n)
        const regEx = /(%[1-9]?[0-9])/g;
        const placeholders = string.match(regEx);
        if (placeholders) {
            while (placeholders.length > 0) {
                string = string.replace(placeholders.shift()!, args.shift()?.toString() ?? "");
            }
        }
    }
    return string;
}
