import React from "react";
import { getDsLayoutComponentRenderer } from "@kaltura/mediaspace-shared-layout";

const PersonalProfile = React.lazy(() => import("@mediaspace/features/personal-profile/PersonalProfile"));
const WatchlistTab = React.lazy(() => import("@mediaspace/features/personal-profile/watchlist-tab/WatchlistTab"));
const ScheduledWatchlistTab = React.lazy(
    () => import("@mediaspace/features/personal-profile/scheduled-watchlist-tab/ScheduledWatchlistTab")
);
const CalendarTab = React.lazy(() => import("@mediaspace/features/personal-profile/calendar-tab/CalendarTab"));

export const render = getDsLayoutComponentRenderer(PersonalProfile);

export { WatchlistTab, ScheduledWatchlistTab, CalendarTab };

export { BadgesTab } from "@kaltura/mediaspace-shared-user-badges";
