import styled from "@emotion/styled";
import { Modal } from "@kaltura/ds-react-components";
import { baseFormLabelClasses } from "@kaltura/mediaspace-shared-ui";
import { FormLabel } from "@kaltura/mediaspace-shared-ui/form";

export const StyledContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
}));

export const StyledCheckboxWrap = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
}));
export const StyledCheckboxLabel = styled(FormLabel)({
    [`& .${baseFormLabelClasses.label}.${baseFormLabelClasses.label}`]: {
        paddingBottom: 0,
    },
});

export const StyledModal = styled(Modal)(({ theme }) => ({
    "& .kms-ds-set-ownership-modal": {
        [theme.breakpoints.up("sm")]: {
            width: 584,
            height: 335,
        },
    },
}));
