import { createRoot } from "react-dom/client";
import React, { Suspense, useState, useMemo } from "react";
import { Box } from "@mui/material";
import { SidebarLayoutContext, SidebarLayoutContextData } from "@kaltura/ds-react-layouts";
import { ThemeProvider } from "@kaltura/mediaspace-shared-contexts";
import { Config, ConfigProvider } from "@kaltura/mediaspace-shared-data-kms-config";
import {
    HeaderMenu,
    HeaderMenuProps,
    HighContrastMenuItem,
    NavigationPanelProps,
} from "@kaltura/mediaspace-shared-layout";

export { HeaderMenu as Component, HighContrastMenuItem };

/**
 * HeaderMenu and the portal ref for the NavigationPanel, to be used when there is no DsLayout.
 */
const HeaderMenuApp = (props: { headerProps: HeaderMenuProps; sidebarProps: NavigationPanelProps }) => {
    const [floatedContainerRef, setFloatedContainerRef] = useState<HTMLDivElement | null>(null);

    // mimic context of SidebarLayout used in DsLayout
    const contextData: SidebarLayoutContextData = useMemo(
        () => ({
            isEnabled: false,
            isOpened: false,
            setIsOpened: () => {},
            setActiveButtonId: () => {},
            originalTheme: undefined,
            floatedContainerRef,
        }),
        [floatedContainerRef]
    );

    return (
        <SidebarLayoutContext.Provider value={contextData}>
            <HeaderMenu {...props.headerProps} />
            <Box ref={setFloatedContainerRef}></Box>
        </SidebarLayoutContext.Provider>
    );
};

export const render = (
    id: string,
    props: { headerProps: HeaderMenuProps; sidebarProps: NavigationPanelProps } & { context: Config }
) => {
    const { theming } = props.context;
    const root = createRoot(document.getElementById(id)!);
    root.render(
        <Suspense fallback={<div />}>
            <ConfigProvider context={props.context}>
                <ThemeProvider overrides={theming?.theme} mode={theming?.mode} scoped={true}>
                    <HeaderMenuApp headerProps={props.headerProps} sidebarProps={props.sidebarProps} />
                </ThemeProvider>
            </ConfigProvider>
        </Suspense>
    );
};
