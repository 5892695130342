import { forwardRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Container } from "./CommonStyles";
import { Checkbox } from "@kaltura/ds-react-components";
import styled from "@emotion/styled";
// noinspection ES6PreferShortImport
import { FormLabel } from "../form/form-label/FormLabel";

export interface CheckboxFieldProps {
    id: string;
    label: string;
    description?: string;
    value?: boolean;
    required?: boolean;
    disabled?: boolean;
}

const InlineContainer = styled(Container)(({ theme }) => ({
    flexDirection: "row",
    columnGap: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        display: "flex", // override original
    },
}));

/**
 * date field for edit entry form
 */
export const CheckboxField = forwardRef<any, CheckboxFieldProps>(
    ({ id, label, description, value, required = false, disabled = false }, ref) => {
        const { control } = useFormContext();

        return (
            <InlineContainer>
                <Controller
                    control={control}
                    name={id as "dummy"}
                    defaultValue={value}
                    rules={{ required: required }}
                    render={({ field: { onChange, onBlur, value } }) => (
                        <Checkbox
                            id={id}
                            inputRef={ref}
                            onChange={onChange}
                            onBlur={onBlur}
                            checked={value}
                            disabled={disabled}
                        />
                    )}
                />
                <FormLabel htmlFor={id} text={label} required={required} fontWeightBold={true} />
            </InlineContainer>
        );
    }
);
