/**
 * add scheduled media to different calendars.
 * see usage example in EventList.tsx
 */
import { baseUrl } from "./kms";
import { Config } from "@kaltura/mediaspace-shared-data-kms-config";

export default class AddToCalendarHelper {
    static getAddToCalendarAction = (config: Config) => {
        const url = config?.calendar?.getAppointmentUrl;
        return (entryId: string, calendarType: "google" | "outlook", categoryId?: number) => {
            if (!entryId) {
                return;
            }

            const params =
                "/entryid/" + entryId + (categoryId ? `/categoryId/${categoryId}` : "") + "/cal/" + calendarType;
            window.open(baseUrl + url + params);
        };
    };
}
