import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const PlaylistIllustration = () => (
    <StyledIllustration
        fileName={"AddNewPlaylist.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M21.833 94C21.833 89.5817 25.4147 86 29.833 86H118.5C122.918 86 126.5 89.5817 126.5 94V123.628V153.256C126.5 157.674 122.918 161.256 118.5 161.256H29.833C25.4147 161.256 21.833 157.674 21.833 153.256V94Z"
            fill="#FF9DFF"
        />
        <path d="M37.4033 148.281H110.929" stroke="#333333" stroke-width="2" stroke-linecap="round" />
        <circle cx="74.1925" cy="119.755" r="15.5702" transform="rotate(-5.3899 74.1925 119.755)" fill="#006EFA" />
        <path
            d="M79.8428 118.023C81.1761 118.792 81.1761 120.717 79.8428 121.487L72.8676 125.514C71.5342 126.284 69.8676 125.321 69.8676 123.782L69.8676 115.727C69.8676 114.188 71.5342 113.226 72.8676 113.995L79.8428 118.023Z"
            fill="white"
        />
        <rect x="103.167" y="44" width="62.5047" height="80.6667" rx="8" fill="#FFD357" />
        <path
            d="M179.167 101.67C179.167 96.394 174.89 92.1172 169.614 92.1172L126.925 92.1172C121.649 92.1172 117.373 96.394 117.373 101.67V101.67C117.373 106.946 121.649 111.222 126.925 111.222H169.614C174.89 111.222 179.167 106.946 179.167 101.67V101.67Z"
            className="Tone1"
            fill="#333333"
        />
        <path
            d="M128.448 104.844C129.71 104.843 130.97 104.627 132.151 104.193C133.679 103.632 135.121 102.666 135.875 101.241C136.631 99.8171 137.297 97.8903 135.429 97.703C133.65 97.6296 133.321 100.086 134.061 101.818C134.801 103.549 136.756 104.685 138.647 104.511C139.938 104.395 141.121 103.771 142.229 103.111C143.139 102.567 143.525 102.204 145.53 100.939C146.153 100.546 146.84 100.206 147.578 100.149C149.392 100.01 150.717 101.703 152.082 102.895C154.003 104.571 156.614 105.449 159.164 105.28C160.488 105.345 163.684 104.784 165.872 102.019"
            className="sTone8"
            stroke="white"
            stroke-width="2"
            stroke-miterlimit="10"
            stroke-linecap="round"
        />
        <ellipse cx="110.601" cy="51.3637" rx="2.47826" ry="2.4545" fill="#006EFA" />
        <ellipse cx="118.863" cy="51.3637" rx="2.47826" ry="2.4545" fill="#FF3D23" />
        <ellipse cx="127.124" cy="51.3637" rx="2.47826" ry="2.4545" fill="#333333" />
        <path d="M119.877 62.707H153.37" stroke="#333333" stroke-width="2" stroke-linecap="round" />
        <path d="M114.095 72.5254H144.283" stroke="#333333" stroke-width="2" stroke-linecap="round" />
        <path d="M130.616 82.3428H153.37" stroke="#333333" stroke-width="2" stroke-linecap="round" />
        <rect
            width="37.6772"
            height="28.4356"
            rx="14.2178"
            transform="matrix(-0.999967 0.00813108 0.008131 0.999967 88.6885 39.7656)"
            fill="#FF3D23"
        />
        <path
            d="M77.4934 71.7532L68.7598 64.1162L80.7594 64.0186L80.8099 70.2314C80.8239 71.9571 78.7925 72.8892 77.4934 71.7532Z"
            fill="#FF3D23"
        />
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M69.5976 51.7429C69.5976 51.7429 67.6594 48.8473 65.2507 49.3692C62.842 49.8911 61.5959 52.9949 63.2405 55.5064C64.7293 57.7799 68.8331 60.4638 69.9278 60.8585C70.0424 60.8998 70.1887 60.8986 70.3433 60.8482C73.5377 59.8061 75.5172 57.4416 76.7152 55.3968C76.7152 55.3968 78.6018 51.5699 75.4365 49.7266C72.2712 47.8834 69.5976 51.7429 69.5976 51.7429Z"
            fill="white"
        />
    </StyledIllustration>
);
