import { isEmpty } from "ramda";
import { QueryParams, SearchFormData } from "@kaltura/mediaspace-shared-types";
import { baseUrl } from "./kms";
import { Config } from "@kaltura/mediaspace-shared-data-kms-config";

/**
 * Helper to manage Watch List
 */
export default class AddToWatchlistHelper {
    static updatingFromBE: boolean;

    /**
     * add session to watchlist
     * @param sessionId
     */
    static addToWatchlist = (sessionId: string): void => {
        const watchlistData = JSON.parse(localStorage.getItem("watchlist") || "[]");
        watchlistData.push(sessionId);
        localStorage.setItem("watchlist", JSON.stringify(watchlistData));
    };

    /**
     * update watchlist storage with given entries
     * @param sessions
     * @param time
     */
    static updateWatchlistLocalStorage = (sessions: string[] = [], time?: number): void => {
        localStorage.setItem("watchlist", JSON.stringify(sessions));
        localStorage.setItem("watchlistUpdateTime", JSON.stringify(time !== undefined ? time : Date.now()));
    };

    /**
     * remove session from watchlist
     * @param sessionId
     */
    static removeFromWatchlist = (sessionId: string): void => {
        const watchlistData = JSON.parse(localStorage.getItem("watchlist") || "[]");
        const filteredWatchlist = watchlistData.filter((id: string) => id !== sessionId);
        localStorage.setItem("watchlist", JSON.stringify(filteredWatchlist));
    };

    static getInWatchlist = (id: string): boolean => {
        const storageData = AddToWatchlistHelper.getWatchlistList();
        return !isEmpty(storageData) && storageData.includes(id);
    };

    /**
     * Update my-agenda sessions in local-storage.
     * If local storage is up to date (meaning was updated in the last localStorageExpiry) - do nothing.
     * If local storage is not up to date - update localstorage from BE.
     */
    static updateWatchlistFromBE = (
        getFromKms: (
            query: QueryParams,
            callback: (data: any) => void,
            action: string,
            spin?: boolean,
            abortable?: boolean,
            searchFormData?: SearchFormData
        ) => void,
        context: Config,
        callback?: (sessions: string[]) => void
    ): void => {
        if (!context.application.isLoggedIn) {
            AddToWatchlistHelper.updateWatchlistLocalStorage([], 0);
            return;
        }
        const updateTime = parseInt(localStorage.getItem("watchlistUpdateTime") || "", 10);

        if (updateTime && Date.now() - updateTime <= (context.watchlist?.localStorageExpiry ?? 0) * 1000 * 60) {
            callback && callback(AddToWatchlistHelper.getWatchlistList());
            return;
        }

        if (AddToWatchlistHelper.updatingFromBE) {
            const intervalId = setInterval(
                () => {
                    if (!AddToWatchlistHelper.updatingFromBE) {
                        callback && callback(AddToWatchlistHelper.getWatchlistList());
                        clearInterval(intervalId);
                    }
                },
                100
            );
            return;
        }

        // update from BE
        AddToWatchlistHelper.updateWatchlistLocalStorage([]);
        AddToWatchlistHelper.updatingFromBE = true; // make sure BE call execute only 1 time
        getFromKms(
            {},
            (result: string[]) => {
                AddToWatchlistHelper.updateWatchlistLocalStorage(result || []);
                AddToWatchlistHelper.updatingFromBE = false;
                callback && callback(AddToWatchlistHelper.getWatchlistList());
            },
            `${baseUrl}/watchlist/index/list-watchlist`,
            false
        );
    };

    /**
     * Get Watchlist list from local storage - convert it from object to array
     */
    static getWatchlistList(): string[] {
        return JSON.parse(localStorage.getItem("watchlist") || "[]");
    }
}
