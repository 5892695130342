import styled from "@emotion/styled";
import { ChangeEvent, forwardRef, useCallback, useMemo } from "react";
import SingleText from "./SingleText";
import { Button } from "@kaltura/ds-react-components";
import { Add24Icon } from "@kaltura/ds-react-icons";
import { translate } from "@kaltura/mediaspace-shared-utils";

export interface MultiTextFieldProps {
    id?: string;
    ariaLabelledby?: string;
    value: string[];
    onChange: (value: string[]) => void;
    onBlur?: () => void;
    error?: boolean;
}

const StyledMultiTextField = styled.div`
    color: pink;
`;

const MultiTextField = forwardRef<HTMLDivElement, MultiTextFieldProps>(
    ({ id, ariaLabelledby, value, onChange, onBlur, error }: MultiTextFieldProps, ref) => {
        const handleRemove = useCallback(
            (id: number) => {
                const updated = value.concat();
                updated.splice(id, 1);
                onChange(updated);
            },
            [value, onChange]
        );

        /**
         * allow getting the index of the changed input
         * @param ind index of current input
         * @return function that takes a change-event and triggers a unified change handler
         */
        const bindInputChanged = useCallback(
            (ind: number) => {
                return (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
                    const updated = value.concat();
                    updated[ind] = event.target.value;
                    onBlur?.();
                    onChange(updated);
                };
            },
            [onChange, value]
        );

        const addItem = useCallback(() => {
            const updated = value.concat();
            updated.push("");
            onBlur?.();
            onChange(updated);
        }, [value, onChange]);

        const items = useMemo(() => {
            return value.map((val, ind) => (
                <SingleText
                    key={`${id}_${ind}`}
                    id={ind}
                    htmlId={`${id}_${ind}`}
                    error={error}
                    value={val}
                    ariaLabelledby={ariaLabelledby}
                    onChange={bindInputChanged(ind)}
                    onRemove={handleRemove}
                />
            ));
        }, [value, id, ariaLabelledby, error, bindInputChanged, handleRemove]);

        return (
            <StyledMultiTextField ref={ref}>
                {items}
                <Button variant={"borderless"} color={"secondary"} startIcon={<Add24Icon />} onClick={addItem}>
                    {translate("Add")}
                </Button>
            </StyledMultiTextField>
        );
    }
);

export default MultiTextField;
