import React from "react";
import { getDataFromKms, sendDataToKms } from "@kaltura/mediaspace-shared-utils";
import deepmerge from "deepmerge";
import { QueryParams, SearchFormData } from "@kaltura/mediaspace-shared-types";
import { updateHistory } from "@kaltura/mediaspace-shared-utils";

interface Props {
    kmsAction?: string;
    spin?: boolean;
    data?: any;
    forwardedRef?: any;
}
interface State {
    data?: any;
}

export interface WrappedProps {
    data?: any;
    context?: any;
    searchFormData?: SearchFormData;
    replaceFromKms: (
        query: QueryParams,
        action: string,
        spin?: boolean,
        abortable?: boolean,
        searchFormData?: SearchFormData
    ) => void;
    updateFromKms: (
        query: QueryParams,
        action: string,
        spin?: boolean,
        abortable?: boolean,
        searchFormData?: SearchFormData
    ) => void;
    getFromKms: (
        query: QueryParams,
        callback: (data: any) => void,
        action: string,
        spin?: boolean,
        abortable?: boolean,
        searchFormData?: SearchFormData
    ) => void;
    sendToKms: (query: QueryParams, action: string, spin?: boolean, abortable?: boolean) => void;
}

const kmsConnector = <P extends WrappedProps>(WrappedComponent: React.ComponentType<P>) => {
    class KmsConnect extends React.Component<P & Props, State> {
        static defaultProps = {
            kmsAction: "",
            spin: true,
            data: null,
        };

        constructor(props: P & Props) {
            super(props);
            // bind callbacks
            this.replaceFromKms = this.replaceFromKms.bind(this);
            this.replaceCallback = this.replaceCallback.bind(this);
            this.updateFromKms = this.updateFromKms.bind(this);
            this.updateCallback = this.updateCallback.bind(this);
            this.getFromKms = this.getFromKms.bind(this);
            this.handleGetDataFromKms = this.handleGetDataFromKms.bind(this);
            this.sendToKms = this.sendToKms.bind(this);
            this.getDataFromKms = this.getDataFromKms.bind(this);
            this.handlePopState = this.handlePopState.bind(this);
            // set initial state
            this.state = {
                data: props.data,
            };
        }

        /**
         * a reference to the latest request to be able to cancel it
         * @private
         */
        private request?: AbortController;

        // send the query to kms and replace the state
        replaceFromKms(
            query: QueryParams,
            kmsAction: string,
            spin?: boolean,
            abortable = true,
            searchFormData?: SearchFormData
        ) {
            // set query params
            const doSpin = spin !== undefined ? spin : this.props.spin;
            const _searchFormData = searchFormData ? searchFormData : this.props.searchFormData;
            // send the data to kms
            this.getDataFromKms(kmsAction, query, this.replaceCallback, doSpin, abortable, _searchFormData);
        }

        // kms data callback
        replaceCallback(data: any) {
            // replace the state
            this.setState((prevState, props) => ({
                data: data,
            }));
        }

        // send the query to kms and update/append objects within the state
        updateFromKms(query: QueryParams, kmsAction: string, spin?: boolean, abortable = true) {
            // set query params
            const doSpin = spin != null ? spin : this.props.spin;
            const { searchFormData } = this.props;
            // send the data to kms
            this.getDataFromKms(kmsAction, query, this.updateCallback, doSpin, abortable, searchFormData);
        }

        // kms data callback
        updateCallback(data: any) {
            // update/append to the state - create a new merged state
            this.setState((prevState, props) => ({
                data: deepmerge(prevState.data, data),
            }));
        }

        /**
         * get data from kms and call the provided callback
         * @param query data to send to the server
         * @param callback  success callback to invoke when done
         * @param kmsAction    server action url (i.e. "mymodule/myctrl/myaction")
         * @param spin  show spinner (not relevant for DS, kept for bw compat)
         * @param abortable true if this call can be aborted
         * @param searchFormData    for updating browser history
         */
        getFromKms(
            query: QueryParams,
            callback: (data: any) => void,
            kmsAction: string,
            spin?: boolean,
            abortable = true,
            searchFormData?: SearchFormData
        ) {
            // set query params
            const doSpin = spin != null ? spin : this.props.spin;
            const _searchFormData = searchFormData ? searchFormData : this.props.searchFormData;

            // send the data to kms - callback(data) will be called
            this.getDataFromKms(
                kmsAction,
                query,
                this.handleGetDataFromKms(callback),
                doSpin,
                abortable,
                _searchFormData
            );
        }

        getDataFromKms(
            kmsAction: string,
            query: any,
            callback: (data: any) => void,
            spin = false,
            abortable = true,
            searchFormData?: SearchFormData
        ) {
            if (this.request && abortable && !this.request.signal.aborted) {
                this.request.abort();
            }
            const request = getDataFromKms(kmsAction, query, callback, spin);
            updateHistory(kmsAction, query, searchFormData);
            if (!abortable) {
                return;
            }
            this.request = request;
        }

        handleGetDataFromKms = (callback: any) => (data: any) => {
            const res = callback(data);
            if (res) {
                this.setState(res);
            }
        };

        // send data to kms, and don't listen to the response
        sendToKms(query: QueryParams, kmsAction: string, spin?: boolean, abortable = true) {
            // set query params
            const doSpin = spin !== undefined ? spin : !!this.props.spin;
            if (abortable && this.request && !this.request.signal.aborted) {
                this.request.abort();
            }
            // send the data to kms
            updateHistory(kmsAction as string, query, this.props.searchFormData);
            const request = sendDataToKms(kmsAction, query, doSpin);
            if (abortable) {
                this.request = request;
            }
        }
        handlePopState = () => {
            // cause page refresh when clicking back or forward
            // with the new query params updated in the URL.
            (window as any).location.href = window.location.href;
        };
        componentDidMount(): void {
            window.onpopstate = this.handlePopState;
        }
        componentWillUnmount(): void {
            window.onpopstate = () => void 0;
        }

        displayName = `KmsConnect(${getDisplayName(WrappedComponent)})`;

        render() {
            // Filter out extra props that are specific to this HOC and shouldn't be
            // passed through
            // use this.props as any, until TypeScript fix is available:
            // https://github.com/Microsoft/TypeScript/issues/10727
            const { kmsAction, data, spin, ...passThroughProps } = this.props as any;
            // render the wrapped component
            return (
                <WrappedComponent
                    data={this.state.data}
                    replaceFromKms={this.replaceFromKms}
                    updateFromKms={this.updateFromKms}
                    getFromKms={this.getFromKms}
                    sendToKms={this.sendToKms}
                    {...passThroughProps}
                />
            );
        }
    }

    // use forward ref to pass down refs
    return React.forwardRef((props: any, ref) => {
        return <KmsConnect {...props} forwardedRef={ref} />;
    });
};

function getDisplayName(WrappedComponent: any) {
    return WrappedComponent.displayName || WrappedComponent.name || "Component";
}

// export the HOC
export default kmsConnector;
