import { ListTitleProps } from "./ListTitle";

/**
 * Calculates props for the Title component, based on the container component's props.
 * Also returns a "show" flag, telling whether the Title component should be shown, based on the props.
 *
 * @param props
 */
export function useTitle(props: ListTitleProps) {
    const { translatedTitle = "", translatedLinkString = "", titleLink = "" } = props;
    return {
        ...props,
        show: translatedTitle || (translatedLinkString && titleLink),
    };
}
