import React, { PropsWithChildren, PureComponent } from "react";
import merge from "lodash/merge";
import raf from "raf";

import { isIE } from "@kaltura/mediaspace-shared-utils";
import styled from "@emotion/styled";
import MediaPlayerV3 from "../playerV3/MediaPlayerV3";

interface Props {
    className?: string;
    playerClassName?: string;
    active: boolean;
    media: { entryId: string; ks?: string };
    playerConfig: unknown;
    onReady?: (player: unknown) => void;
}

interface State {
    // eslint-disable-next-line
    player: any;
    playerConfiguration: Record<string, unknown>;
    error: boolean;
    canPlay: boolean;
    mediaLoaded: boolean;
}

const sharedStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
} as const;

const Thumbnail = styled("div")<{ hidden?: boolean }>(({ theme, hidden }) => ({
    ...sharedStyle,
    transition: theme.transitions.create(
        "opacity",
        {
            duration: 500,
            easing: theme.transitions.easing.easeOut,
        }
    ),
    willChange: "opacity",
    ...(
        hidden && {
            opacity: 0,
        }
    ),
}));
const Wrapper = styled("div")(sharedStyle);

const Player = styled(MediaPlayerV3)({
    position: "relative",
    zIndex: 0,
    width: "100%",
    height: "100%",
    transform: "translateZ(0)",
    "& .kaltura-player": {
        ...sharedStyle,
    },
    "& .playkit-player-gui, & .playkit-loading-backdrop, & .playkit-pre-playback-play-overlay": {
        display: "none",
    },
    "& .playkit-engine-html5": {
        objectFit: "cover",
        /* IE10+ object-fit: cover alternative */
        "@media all and (-ms-high-contrast: none), (-ms-high-contrast: active)": {
            top: "50%",
            width: "100%",
            height: "auto",
            transform: "translateY(-50%)",
        },
        /* Edge 12+ object-fit: cover alternative */
        "@supports (-ms-ime-align: auto)": {
            top: "50%",
            width: "100%",
            height: "auto",
            transform: "translateY(-50%)",
        },
    },
    "& .playkit-poster": {
        backgroundSize: "cover",
    },
});

/*
 * V7 player without any plugins and controls that is dedicated to show a muted video preview.
 */
export class PreviewPlayer extends PureComponent<PropsWithChildren<Props>, State> {
    state: State = {
        player: null,
        playerConfiguration: this.getPlayerConfig(),
        error: false,
        canPlay: false,
        mediaLoaded: false,
    };

    handleMediaLoaded = () => {
        this.setState({ mediaLoaded: true });
    };

    handlePlayerError = (error: { severity: number }) => {
        // check if error is critical
        if (error && error.severity > 1) {
            this.setState({ error: true, canPlay: false });
        }
    };

    handlePlayerPlaying = () => {
        this.setState({ canPlay: true });
    };

    handlePlayerEnded = () => {
        this.setState({ canPlay: false });
    };

    // eslint-disable-next-line
    handlePlayerReady = (player: any) => {
        this.setState({ player });

        player.addEventListener("playing", this.handlePlayerPlaying);
        player.addEventListener("ended", this.handlePlayerEnded);

        if (this.props.onReady) {
            this.props.onReady(player);
        }
    };

    componentDidUpdate(
        { active: prevActive, media: { entryId: prevEntryId } }: Props,
        { mediaLoaded: prevMediaLoaded }: State
    ) {
        const {
            active,
            media: { entryId },
        } = this.props;
        const { error, mediaLoaded } = this.state;
        if (!error && mediaLoaded && (prevMediaLoaded !== mediaLoaded || prevActive !== active)) {
            this.updatePlayerState();
        }
        if (entryId !== prevEntryId) {
            const playerConfiguration = this.getPlayerConfig();
            this.setState({
                mediaLoaded: false,
                error: false,
                playerConfiguration: playerConfiguration,
            });
        }
    }

    render() {
        const { children, media, className = "", playerClassName = "" } = this.props;
        const { error, canPlay, playerConfiguration } = this.state;
        const thumbnail = children ? <Thumbnail hidden={canPlay}>{children}</Thumbnail> : null;

        const player = !error ? (
            <Player
                className={`kms-ds-previewplayer ${playerClassName}`}
                config={playerConfiguration}
                media={media}
                onError={this.handlePlayerError}
                onMediaLoaded={this.handleMediaLoaded}
                onReady={this.handlePlayerReady}
            />
        ) : null;

        return (
            <Wrapper className={className}>
                {player}
                {thumbnail}
            </Wrapper>
        );
    }

    private getPlayerConfig() {
        const { playerConfig } = this.props;

        // fix issue with aws relative-url-streams and IE11
        const ieConfig = isIE ? { sources: { options: { forceRedirectExternalStreams: true } } } : {};

        const tempPlayer = {};
        merge(
            tempPlayer,
            ieConfig,
            {
                playback: {
                    autoplay: false,
                    muted: true,
                },
                disableUserCache: true,
            }
        );

        const temp = {};
        merge(
            temp,
            playerConfig,
            {
                player: tempPlayer,
            }
        );
        return temp;
    }

    private updatePlayerState() {
        const { active } = this.props;
        const { player } = this.state;

        // schedule player actions in a requestAnimationFrame to prevent carousel lags
        raf(() => {
            if (active) {
                player.seekToLiveEdge();
                player.play();
            }
            else {
                player.pause();
            }
        });
    }
}

export default PreviewPlayer;
