import styled from "@emotion/styled";
import { Loader } from "@kaltura/ds-react-components";

const StyledLoader = styled(Loader)({
    top: "-9px",
    right: "26px",
});
export const TextFieldLoading = () => {
    return (
        <div>
            <StyledLoader />
        </div>
    );
};
