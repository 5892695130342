import styled from "@emotion/styled";
import { alpha, Box } from "@kaltura/mediaspace-shared-styled";
import { UploadStatus } from "@kaltura/mediaspace-shared-upload";

export const StyledThumbContainer = styled(
    Box,
    {
        shouldForwardProp: (prop) => prop !== "status",
    }
)<{ status: UploadStatus }>(({ theme, status }) => ({
    flex: "0 0 auto",
    width: theme.typography.pxToRem(110),
    height: theme.typography.pxToRem(62),
    borderRadius: theme.kaltura.shape.roundness1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#FFFFFF",
    backgroundPosition: "center",
    backgroundSize: "cover",
    overflow: "hidden",
    ...(
        status === UploadStatus.IN_QUEUE && {
            backgroundColor: theme.kaltura.palette.secondary.main,
        }
    ),
    ...(
        status === UploadStatus.UPLOADING && {
            backgroundColor: alpha(theme.kaltura.palette.primary.main, 0.7),
            justifyContent: "flex-start",
        }
    ),
    ...(
        status === UploadStatus.PROCESSING && {
            backgroundColor: theme.kaltura.palette.primary.main,
        }
    ),
    ...(
        status === UploadStatus.READY && {
            backgroundColor: theme.kaltura.palette.tone3,
        }
    ),
    ...(
        status === UploadStatus.UPLOAD_FAILED && {
            backgroundColor: theme.kaltura.palette.danger.main,
        }
    ),
    ...(
        status === UploadStatus.CANCELED && {
            backgroundColor: theme.kaltura.palette.tone3,
        }
    ),
}));

export const StyledProgressBar = styled(Box)(({ theme }) => ({
    height: "100%",
    borderRadius: `${theme.kaltura.shape.roundness1}px 0 0 ${theme.kaltura.shape.roundness1}px`,
    backgroundColor: theme.kaltura.palette.primary.main,
}));
