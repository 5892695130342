import { MenuItem } from "@kaltura/ds-react-components";
import { SharedMenuItemProps } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";

type AnalyticsProps = {
    pageName: string;
    buttonType: number;
};

export interface LinkMenuItemProps extends SharedMenuItemProps {
    label: string;
    href: string;
    target?: string;
    analytics?: AnalyticsProps;
}

/**
 * An item for ActionsMenu that contains a basic link.
 */
export const LinkMenuItem = ({ label, href, target, autoFocus, analytics }: LinkMenuItemProps) => {
    const sendButtonAnalytics = useButtonAnalytics();

    const onMenuButtonClick = () => {
        if (analytics?.pageName && analytics?.buttonType) {
            sendButtonAnalytics(analytics.pageName, analytics.buttonType);
        }
    };

    return (
        <MenuItem
            component={"a"}
            href={href}
            target={target || "_self"}
            autoFocus={autoFocus}
            onClick={onMenuButtonClick}
        >
            {label}
        </MenuItem>
    );
};
