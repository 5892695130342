import styled from "@emotion/styled";
import { cloneElement, HTMLAttributes, PropsWithChildren, ReactElement, useEffect, useRef, useState } from "react";

export interface ItemContainerProps extends PropsWithChildren<HTMLAttributes<HTMLDivElement>> {
    gap: number;
    children: ReactElement<any>;
}

const StyledContainer = styled("div")<{ gap: number }>(
    ({ theme, gap }) => ({
        /* override carousel style to match design */
        position: "relative",
        paddingRight: theme.spacing(gap),
    }),
    { label: "ItemContainer" }
);

export function ItemContainer({ children, ...rest }: ItemContainerProps) {
    const myRef = useRef<HTMLDivElement>(null);
    const [parentAriaHidden, setParentAriaHidden] = useState(true);

    const clonedChild = cloneElement(children, { tabIndex: parentAriaHidden ? -1 : 0 });

    useEffect(() => {
        let observer: MutationObserver;
        const mutationCallback = (mutationsList: MutationRecord[]) => {
            for (const mutation of mutationsList) {
                if (mutation.type !== "attributes" || mutation.attributeName !== "aria-hidden") {
                    // not what we're after
                    continue;
                }
                setParentAriaHidden((mutation.target as Element).getAttribute("aria-hidden") === "true");
            }
        };

        if (myRef.current) {
            observer = new MutationObserver(mutationCallback);
            const parent = myRef.current.parentElement?.parentElement;
            if (parent) {
                setParentAriaHidden(parent.getAttribute("aria-hidden") === "true");
                observer.observe(parent, { attributes: true });
            }
        }

        return () => {
            // cleanup
            observer.disconnect();
        };
    }, [myRef]);

    return (
        <StyledContainer {...rest} ref={myRef}>
            {clonedChild}
        </StyledContainer>
    );
}

export default ItemContainer;
