import { useCurrentTimeForAnimation } from "@kaltura/mediaspace-shared-hooks";
import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";

/*
 * Vertical progress bar that shows progress between 2 points in time (`start` and `end`).
 */

export interface VerticalTimeBarProps {
    className?: string;
    start: number;
    end: number;
}

export const VerticalTimeBar = ({ className, start, end }: VerticalTimeBarProps) => {
    const now = useCurrentTimeForAnimation(undefined, 1000) / 1000;
    const timeSinceStart = now - start;
    const duration = end - start;
    const progress = timeSinceStart <= 0 ? 0 : timeSinceStart >= duration ? 1 : timeSinceStart / duration;

    return (
        <StyledContainer className={className}>
            <StyledProgress style={{ height: progress * 100 + "%" }} />
        </StyledContainer>
    );
};

const StyledContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    width: 2,
    borderRadius: theme.kaltura.shape.roundness2,
    overflow: "hidden",
    backgroundColor: theme.kaltura.palette.tone5,
}));

const StyledProgress = styled(Box)(({ theme }) => ({
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    transition: theme.transitions.create("height"),
    borderRadius: theme.kaltura.shape.roundness2,
    backgroundColor: theme.kaltura.palette.primary.main,
}));
