function TwitterSocialLink() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M16.4284 5H18.7899L13.6308 10.9303L19.7 19H14.9479L11.2258 14.1057L6.96691 19H4.60403L10.1222 12.6569L4.29993 5H9.17274L12.5372 9.47354L16.4284 5ZM15.5996 17.5785H16.9081L8.46173 6.34687H7.05757L15.5996 17.5785Z"
                fill="white"
            />
        </svg>
    );
}

export default TwitterSocialLink;
