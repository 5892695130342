// eslint-disable-next-line no-restricted-imports
import HtmlReactParserLib from "html-react-parser";

/**
 * The "html-react-parser" library crashes when getting undefined or null as an argument instead of string.
 * The function below is a patch to make sure that it doesn't happen accidentally.
 */
export const HtmlReactParser = (html: string) => HtmlReactParserLib(html || "");

export const HtmlReactParserOmitParagraphs = (html: string) => {
    if (!html) {
        return HtmlReactParser("");
    }
    // Replace opening <p> tags with an empty string
    let result = html.replace(/<p>/g, "");
    result = result.replace(/<\/p>/g, "<br><br>");
    return HtmlReactParser(result);
};
