import { KalturaESearchResult } from "kaltura-typescript-client/api/types/KalturaESearchResult";
import { KalturaESearchCuePointItemData } from "kaltura-typescript-client/api/types/KalturaESearchCuePointItemData";
import { KalturaESearchMetadataItemData } from "kaltura-typescript-client/api/types/KalturaESearchMetadataItemData";
import { KalturaESearchCaptionItemData } from "kaltura-typescript-client/api/types/KalturaESearchCaptionItemData";
import { KalturaESearchItemDataResult } from "kaltura-typescript-client/api/types/KalturaESearchItemDataResult";
import { KalturaESearchItemData } from "kaltura-typescript-client/api/types/KalturaESearchItemData";
import curry from "ramda/src/curry";
import { SearchResultData, SearchResultDataValue, SearchResultType } from "@kaltura/mediaspace-shared-types";
import { KalturaESearchEntryResult } from "kaltura-typescript-client/api/types/KalturaESearchEntryResult";

export const entrySearchResultService = {
    /**
     * returns a function that given data, creates a matching KalturaClient object
     * @param itemType
     * @return (data: any) => KalturaESearchItemData
     */
    _itemTypeToObject: curry((itemType: string, data: any) => {
        switch (itemType) {
            case "cue_points":
                return new KalturaESearchCuePointItemData(data);
            case "caption":
                return new KalturaESearchCaptionItemData(data);
            case "metadata":
                return new KalturaESearchMetadataItemData(data);
            default:
                return new KalturaESearchItemData(data);
        }
    }),

    /**
     * convert the inner items to real client objects (rather than generic data objects)
     * @param itemsData
     */
    _mapItemDataTypes(itemsData: KalturaESearchItemDataResult[]) {
        return itemsData.map((itemData) => {
            return new KalturaESearchItemDataResult({
                ...itemData,
                items: itemData.items
                    ? itemData.items.map(entrySearchResultService._itemTypeToObject(itemData.itemsType))
                    : [],
            });
        });
    },

    /**
     * get hits in a specific cue-point
     * @param cpHighlight
     */
    _getGroupItems(cpHighlight: KalturaESearchCuePointItemData) {
        const items: SearchResultDataValue[] = [];
        if (cpHighlight.highlight) {
            for (let j = 0; j < cpHighlight.highlight.length; j++) {
                const currentHL = cpHighlight.highlight[j];
                for (let k = 0; k < currentHL.hits.length; k++) {
                    const hit = currentHL.hits[k];
                    const o: SearchResultDataValue = {
                        text: hit.value,
                        startTime: parseFloat(cpHighlight.startTime),
                        endTime: parseFloat(cpHighlight.endTime),
                    };
                    if (currentHL.fieldName.startsWith("cue_points.cue_point_answers")) {
                        o.text = "A: " + o.text;
                    }
                    else if (currentHL.fieldName.startsWith("cue_points.cue_point_question")) {
                        o.text = "Q: " + o.text;
                    }
                    items.push(o);
                }
            }
        }
        return items;
    },

    /**
     * extract relevant items from given item data
     * @param cpItemData
     * @return {label: string, items: SearchResultDataValue[]} | null
     */
    _getParsedCuepointsItems: function (cpItemData: KalturaESearchCuePointItemData) {
        const items = this._getGroupItems(cpItemData);
        if (cpItemData.cuePointType === "thumbCuePoint.Thumb") {
            // slides and chapters
            if (cpItemData.subType) {
                if (cpItemData.subType === "1") {
                    // found slide
                    return { label: "slides", items: items };
                }
                else if (cpItemData.subType === "2") {
                    // chapters
                    return { label: "chapters", items: items };
                }
            }
        }
        else if (cpItemData.cuePointType === "quiz.QUIZ_QUESTION") {
            // quiz
            return { label: "quiz", items: items };
        }
        else if (cpItemData.cuePointType === "codeCuePoint.Code") {
            // polls
            return { label: "polls", items: items };
        }
        else if (cpItemData.cuePointType === "annotation.Annotation") {
            if (!cpItemData.tags) {
                return null;
            }
            // check for Comments
            if (
                cpItemData.tags.filter(
                    (tag) => tag.value === "KMS_public_comment" || tag.value.startsWith("KMS_comment_context_")
                ).length
            ) {
                // found a comment
                return { label: "comments", items: items };
            }
            // don't show qna.
            // if (cpItemData.tags.filter((tag) => tag.value === "qna").length) {
            //     // found QNA
            //     return {label: "qna", items: items};
            // }
        }
        else {
            console.log("unknown item data: ");
            console.log(cpItemData);
        }
        return null;
    },

    /**
     * This function groups items for the summary component
     */
    groupItems(entry: KalturaESearchResult) {
        const highlights = entry.highlight;

        // convert items data to client objects
        const itemsData = entrySearchResultService._mapItemDataTypes(entry.itemsData);
        const totalsObj: Record<SearchResultType, number> = {} as Record<SearchResultType, number>;
        const dataObj: Record<SearchResultType, SearchResultDataValue[]> = {} as Record<
            SearchResultType,
            SearchResultDataValue[]
        >;
        if (highlights) {
            for (const highlight of highlights) {
                // group tags:
                if (
                    highlight.fieldName
                    && highlight.fieldName.startsWith("tags") // tags.raw / tags.ngrams
                ) {
                    if (!dataObj.tags) {
                        dataObj["tags"] = [];
                    }
                    for (let i = 0; i < highlight.hits.length; i++) {
                        dataObj["tags"].push({ text: highlight.hits[i].value });
                    }
                    totalsObj["tags"] = (totalsObj["tags"] ?? 0) + highlight.hits.length;
                }
            }
        }

        if (itemsData) {
            for (const itemData of itemsData) {
                const itemsType = itemData.itemsType as SearchResultType | "cue_points";
                if (itemsType === "cue_points") {
                    // found cue-point. need to iterate its highlight and within each item iterate its hits
                    for (let i = 0; i < itemData.items.length; i++) {
                        const ret = this._getParsedCuepointsItems(itemData.items[i] as KalturaESearchCuePointItemData);
                        if (!ret) {
                            continue;
                        }

                        const label = ret.label as SearchResultType;
                        if (!dataObj[label]) {
                            dataObj[label] = [];
                        }
                        dataObj[label] = dataObj[label].concat(ret.items);
                        if (!totalsObj[label]) {
                            // only set this once, but we need the (kms-)name of the subtype
                            totalsObj[label] = itemData.totalCount;
                        }
                    }
                }
                else if (itemsType === "metadata") {
                    const items = (itemData.items as KalturaESearchMetadataItemData[])
                        .filter((item) => item.highlight && item.highlight.length && item.valueText)
                        .map((item) => {
                            return { text: item.valueText };
                        });

                    if (items.length) {
                        dataObj[itemsType] = items;
                    }
                    totalsObj[itemsType] = itemData.totalCount;
                }
                else {
                    // captions
                    const items = (itemData.items as KalturaESearchCaptionItemData[])
                        .filter((item) => item.highlight && item.line)
                        .map((item) => {
                            return {
                                text: item.line,
                                startTime: item.startsAt,
                            };
                        });

                    if (items.length) {
                        dataObj[itemsType] = items;
                    }
                    totalsObj[itemsType] = itemData.totalCount;
                }
            }
        }

        const returnObjArray: SearchResultData[] = [];

        Object
            .keys(dataObj)
            .forEach(function (keyStr) {
                const key = keyStr as SearchResultType;
                returnObjArray.push({
                    key: key,
                    value: dataObj[key],
                    totalCount: totalsObj[key],
                });
            });

        return returnObjArray;
    },

    isFullResult(searchResult: KalturaESearchEntryResult) {
        const grouped = this.groupItems(searchResult);
        for (const searchResultData of grouped) {
            if (searchResultData.value.length < searchResultData.totalCount) {
                return false;
            }
        }
        return true;
    },
};
