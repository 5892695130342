import styled from "@emotion/styled";
import { KmsTypePublishExternalCategories } from "@kaltura/mediaspace-shared-types";
import { Alert, Link } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { ExternalAppPublishedCategories } from "../../../../../../media-categories/external-published-categories/ExternalPublishedCategories";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";

export interface ExternalTabProps {
    externalCategories: KmsTypePublishExternalCategories[];
    crossApplicationUnpublishing: boolean;
    onUnpublishExternal: () => void;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
}));

const StyledButton = styled(Link)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    cursor: "pointer",
}));

/**
 * Publish Modal External Apps Tab
 */
export function ExternalTab(props: ExternalTabProps) {
    const { externalCategories = [], crossApplicationUnpublishing = false, onUnpublishExternal } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const handleClick = () => {
        sendButtonAnalytics(
            `Publish - Set publishing settings modal box - Unpublish from external apps`,
            ButtonClickAnalyticsType.DELETE
        );

        onUnpublishExternal();
    };

    return (
        <>
            {crossApplicationUnpublishing && (
                <StyledAlert severity="info">
                    {translate("You can unpublish from all applications.")}
                    <StyledButton onClick={handleClick}>{translate("Unpublish now")}</StyledButton>
                </StyledAlert>
            )}

            {!!externalCategories.length && (
                <StyledExternalCategoriesContainer>
                    {externalCategories.map((externalCategory) => (
                        <ExternalAppPublishedCategories
                            key={externalCategory.applicationName}
                            externalCategories={externalCategory}
                        />
                    ))}
                </StyledExternalCategoriesContainer>
            )}
        </>
    );
}

const StyledExternalCategoriesContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
}));
