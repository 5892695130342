import { AiQuizPreview } from "./AiQuizPreview";
import { AiQuizGenerateError } from "./AiQuizGenerateError";

export type GenerateAiQuizResponse = {
    success: boolean;
    entryId: string;
    ks: string;
    questionsDownloadUrl: string;
};

export interface GenerateAiQuizProps {
    playerConfig: any;
    generateQuiz: () => GenerateAiQuizResponse;
}

/**
 * Generate AI quiz main feature container
 */
export const GenerateAiQuiz = ({ playerConfig, generateQuiz }: GenerateAiQuizProps) => {
    const { success, ...quiz } = generateQuiz();

    return (
        <>
            {success && <AiQuizPreview playerConfig={playerConfig} {...quiz} />}
            {!success && <AiQuizGenerateError />}
        </>
    );
};
