import { Button } from "@kaltura/ds-react-components";
import { Download24Icon } from "@kaltura/ds-react-icons";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";

export interface SingleDownloadProps {
    title: string;
    downloadUrl: string;
    analytics?: {
        action: string;
    };
}

/**
 * Single Download Component
 */
export const SingleDownload = ({ title, downloadUrl, analytics }: SingleDownloadProps) => {
    const sendButtonAnalytics = useButtonAnalytics();

    return (
        <Button
            id={"kms-ds-single-download-button"}
            variant={"borderless"}
            startIcon={<Download24Icon />}
            color={"secondary"}
            href={downloadUrl}
            onClick={() => {
                analytics && sendButtonAnalytics(analytics.action, ButtonClickAnalyticsType.DOWNLOAD);
            }}
        >
            {title}
        </Button>
    );
};

export default SingleDownload;
