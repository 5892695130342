import { BulkActionProps } from "./../BulkActionProps";
import {
    ChangeOwnerContainer,
    ChangeOwnerContainerProps,
} from "@kaltura/mediaspace-shared-media-actions/items/collaboration/change-owner/ChangeOwnerContainer";

export interface BulkOwnershipProps extends BulkActionProps, ChangeOwnerContainerProps {}

/**
 * wrapper for showing the ownership-setter component as my-media bulk action
 */
export function BulkOwnership({ reloadMedia, id, ...rest }: BulkOwnershipProps) {
    return <ChangeOwnerContainer {...rest} onOwnerSet={reloadMedia} analyticsEventNameBase={" bulk actions"} />;
}
