import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { Theme } from "@emotion/react";

export const playerContainerDimensions = (theme: Theme) => ({
    borderRadius: theme.kaltura.shape.roundness2,
    minWidth: 640,
    width: "100%",
    aspectRatio: "16 / 9",
    marginBottom: theme.spacing(8),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginBottom: theme.spacing(5),
        minWidth: 456,
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginBottom: theme.spacing(5),
        minWidth: 343,
    },
});

export const StyledTitle = styled(Typography)(({ theme }) => ({
    marginBottom: theme.spacing(7),
    opacity: 0.8,
    fontSize: theme.typography.pxToRem(16),
}));

export const CenteredContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
});

export const CenteredContentContainer = styled(CenteredContainer)({
    flexGrow: 1,
});

export const Wrapper = styled(CenteredContainer)({
    height: "100%",
});

export const VerticallyCenteredContainer = styled(CenteredContainer)({
    flexGrow: 1,
    justifyContent: "center",
});

export const StyledQuizActions = styled(CenteredContainer)(({ theme }) => ({
    marginTop: theme.spacing(1),
}));
