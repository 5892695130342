import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { Children, ReactElement, useEffect, useState } from "react";
import { Typography } from "@kaltura/ds-react-components";

export interface TextSliderProps {
    children: ReactElement[];
    transitionDuration?: number; // Duration of the text transition animation in milliseconds
}

const textIn = keyframes`
    0% {
        transform: translateY(100%);
    }
    100% {
        transform: translateY(0%);
    }
`;

const textOut = keyframes`
    0% {
        transform: translateY(0%);
    }
    100% {
        transform: translateY(-100%);
    }
`;

const TextContainer = styled("div")({
    overflow: "hidden",
});

const StyledTypography = styled(Typography)<{ animateIn: boolean; animateOut: boolean }>(
    ({ animateIn, animateOut }) => ({
        display: animateIn ? "block" : "none",
        animation: `${animateIn ? textIn : animateOut ? textOut : "none"}  0.5s ease`,
    })
);

/**
 * TextSlider component: This component is designed to cycle through a list of child elements,
 * displaying one child at a time with a sliding animation effect. The displayed child slides
 * in from the bottom and slides out to the top, creating a continuous sliding effect.
 *
 * Props:
 * - children: An array of React elements to be displayed one at a time.
 * - transitionDuration: Optional. The duration (in milliseconds) for which each child element
 *   is displayed before transitioning to the next one. Default is 3000 milliseconds (3 seconds).
 */
export const TextSlider: React.FC<TextSliderProps> = ({ children, transitionDuration = 3000 }) => {
    const [index, setIndex] = useState(0);
    const childrenCount = Children.count(children);

    useEffect(() => {
        const timer = setInterval(
            () => {
                setIndex((prevIndex) => (prevIndex + 1) % childrenCount);
            },
            transitionDuration
        );

        return () => {
            clearInterval(timer);
        };
    }, [childrenCount, transitionDuration]);

    return (
        <TextContainer className={"chromatic-ignore"}>
            {Children.map(
                children,
                (child, i) => {
                    const { children: elements, ...props } = child.props;
                    return (
                        <StyledTypography
                            key={i}
                            animateIn={i === index}
                            animateOut={i === (index - 1 + childrenCount) % childrenCount}
                            {...props}
                        >
                            {elements}
                        </StyledTypography>
                    );
                }
            )}
        </TextContainer>
    );
};
