import styled from "@emotion/styled";
import { SearchInput } from "@kaltura/mediaspace-shared-ui";
import debounce from "lodash/debounce";
import { useMemo } from "react";
import { useEventHandler } from "@kaltura/mediaspace-shared-hooks";

interface CategorySearchInputProps {
    placeholder: string;
    ariaLabel: string;
    onSearch: (keyword: string) => void;
    onResetSearch: () => void;
}

const StyledSearch = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
    paddingBottom: theme.spacing(2),
    backgroundColor: theme.kaltura.palette.surfaces.background,
    position: "sticky",
    top: 0,
    zIndex: 1000,
}));

/**
 * category (channel/gallery) modal search input + debounce
 */
export function CategorySearchInput({ placeholder, ariaLabel, onSearch, onResetSearch }: CategorySearchInputProps) {
    const onSearchMemoized = useEventHandler(onSearch);
    const handleSearch = useMemo(() => {
        return debounce(
            (keyword: string) => {
                onSearchMemoized(keyword);
            },
            500
        );
    }, [onSearchMemoized]);

    const handleReset = () => {
        handleSearch.cancel();
        onResetSearch();
    };

    return (
        <StyledSearch>
            <SearchInput
                color={"primary"}
                placeholder={placeholder}
                ariaLabel={ariaLabel}
                showIcon={true}
                onInputChange={(event) => {
                    handleSearch(event.target.value);
                }}
                onReset={handleReset}
                onKeyDown={(event) => {
                    // when input is rendered from an open mui menu
                    // and there's a menu item that starts with the entered character, the menu item will be focused,
                    // so the input will lose focus and the character won't be added to the input's value
                    // in order to prevent this behaviour stopPropagation() should be used
                    event.stopPropagation();
                }}
            />
        </StyledSearch>
    );
}
