import { CSSProperties } from "react";
import { CssNumber } from "./CssNumber";

export const fullWidthFlexItemStyles = {
    flex: 1,
    minWidth: 0,
} as const;

/**
 * Limits the number of lines in the element to not more than linesLimit,
 * and displays an ellipsis sign if the content exceeds this number of lines.
 *
 * If fixedHeight is true, then make the element height exactly linesLimit lines.
 *
 * Note: typographyStyles could be retrieves from theme.typography[variantName].
 */
export function multiLineEllipsisStyles(
    linesLimit: number,
    typographyProps: CSSProperties,
    fixedHeight = false
): CSSProperties {
    const { fontSize, lineHeight } = typographyProps;

    // noinspection JSDeprecatedSymbols (WebkitBoxOrient)
    return {
        display: "-webkit-box",
        overflow: "hidden",
        textOverflow: "ellipsis",
        wordBreak: "break-word",
        // Note: converting to string is a must, otherwise the engine adds "px" to it
        WebkitLineClamp: linesLimit.toString(),
        WebkitBoxOrient: "vertical",
        [fixedHeight ? "height" : "maxHeight"]:
            fontSize !== undefined && lineHeight !== undefined
                ? CssNumber.parseLineHeight(lineHeight, fontSize).scale(linesLimit).toString()
                : undefined,
    };
}
