/* avoid TS errors when accessing window.KApps */
declare const window: any;

// Player API that behaves the same for V2 and V7
export const kmsPlayerHelper = {
    _v2Player: undefined,
    _getV2Player: function () {
        return this._v2Player || window.kdp;
    },
    _getV7Player: function () {
        return window.kalturaPlayer;
    },
    setPersistentSessionId: function (value: string) {
        if (this._getV2Player()) {
            this._getV2Player().setKDPAttribute("kAnalony", "persistentSessionId", value);
        }
        else if (this._getV7Player()) {
            this._getV7Player().configure({ plugins: { kava: { persistentSessionId: value } } });
        }
    },
};

// kalturaPlayer => v7 player object
// KalturaPlayer => v7 player lib
export const KalturaPlayer = window.KalturaPlayer || null;
