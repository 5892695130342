import { useContext } from "react";
import { HeaderMenuScrollContext } from "./HeaderMenuScrollContext";
import { CSSObject } from "@emotion/styled";

/**
 * custom hook to provide menu items with text shadow css according to the HeaderMenuScrollContext
 */
export const useHeaderTextStyle = (): CSSObject => {
    const { headerColor, scrollRatio } = useContext(HeaderMenuScrollContext);

    return {
        textShadow: headerColor === "transparent" && scrollRatio === 0 ? "0px 0px 2px #000" : "none",
        transition: "text-shadow 0.5s ease",
    };
};
