import styled from "@emotion/styled";
import { LabelledValue } from "@kaltura/mediaspace-shared-types";
import { ChangeEvent, forwardRef, useCallback, useMemo } from "react";
import { Checkbox } from "@kaltura/ds-react-components";
// noinspection ES6PreferShortImport
import { FormLabel } from "../form-label/FormLabel";

export interface MultiSelectProps {
    id?: string;
    value: LabelledValue[];
    onChange: (value: LabelledValue[]) => void;
    onBlur: () => void;
    error?: boolean;
    options: LabelledValue[];
}

const StyledElementWrap = styled.div(({ theme }) => ({
    display: "flex",
    columnGap: theme.spacing(1),
}));

const StyledMultiSelect = styled.div(({ theme }) => ({
    padding: theme.spacing(1, 0),
}));

const MultiSelect = forwardRef<HTMLDivElement, MultiSelectProps>(
    ({ id, value, onChange, onBlur, error, options }: MultiSelectProps, ref) => {
        /**
         * allow getting the changed option
         * @param option the option tied to the current checkbox
         * @return function that takes a change-event and triggers a unified change handler
         */
        const bindInputChanged = useCallback(
            (option: LabelledValue) => {
                return (event: ChangeEvent<HTMLInputElement>) => {
                    if (event.target.checked) {
                        const updated = value.concat();
                        updated.push(option);
                        onChange(updated);
                    }
                    else {
                        const updated = value.filter((opt) => opt.value !== option.value);
                        onChange(updated);
                    }
                };
            },
            [onChange, value]
        );

        const optionElements = useMemo(() => {
            return options.map((option) => (
                <StyledElementWrap key={`${id}_${option.value}`}>
                    <Checkbox
                        title={option.label}
                        id={`${id}_${option.value}`}
                        onChange={bindInputChanged(option)}
                        onBlur={onBlur}
                        checked={value.findIndex((opt) => opt.value === option.value) !== -1}
                    />
                    <FormLabel text={option.label} htmlFor={`${id}_${option.value}`} />
                </StyledElementWrap>
            ));
        }, [options, value]);
        return <StyledMultiSelect ref={ref}>{optionElements}</StyledMultiSelect>;
    }
);

export default MultiSelect;
