import StyledIllustration from "../styled-illustration/StyledIllustration";

export const ColoredErrorIllustration = () => {
    return (
        <StyledIllustration
            fileName={"ColoredError.svg"}
            xmlns="http://www.w3.org/2000/svg"
            width="300"
            height="300"
            fill="none"
            viewBox="0 0 300 300"
        >
            <rect x="205" y="187" width="45" height="53" rx="8" fill="#006EFA" />
            <rect x="54" y="192" width="54" height="48" rx="8" fill="#FFD357" />
            <path d="M72 206H93" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M63 216H87" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M75 226H93" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <rect x="125" y="208" width="63" height="32" rx="8" fill="#FF3D23" />
            <path
                d="M192.524 91.7761C194.574 89.6748 198.113 90.4236 199.136 93.1749L210.014 122.424C211.11 125.372 208.531 128.374 205.451 127.734L172.686 120.923C169.593 120.28 168.431 116.475 170.637 114.214L192.524 91.7761Z"
                fill="#FFD357"
            />
            <rect x="124" y="134" width="22" height="21" rx="4" fill="#FFD357" />
            <rect
                x="181.633"
                y="153.699"
                width="44"
                height="43.8572"
                rx="8"
                transform="rotate(36 181.633 153.699)"
                fill="white"
            />
            <path d="M173.418 185.773L200.73 181.991" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <path d="M189.495 197.455L184.652 170.311" stroke="black" strokeWidth="2" strokeLinecap="round" />
            <rect
                x="181.5"
                y="140.5"
                width="40"
                height="40"
                rx="20"
                transform="rotate(-28.5 181.5 140.5)"
                fill="#006EFA"
            />
            <circle cx="121.21" cy="182.209" r="28" transform="rotate(-15 121.21 182.209)" fill="#006EFA" />
            <path
                d="M131.918 177.548C133.405 177.946 133.903 179.805 132.814 180.894L119.894 193.814C118.805 194.903 116.946 194.405 116.548 192.917L111.819 175.268C111.42 173.781 112.781 172.42 114.268 172.819L131.918 177.548Z"
                fill="white"
            />
            <rect x="130" y="100" width="72" height="24" rx="8" transform="rotate(27 130 100)" fill="#FF3D23" />
            <path d="M24 241H276" stroke="white" strokeWidth="2" strokeLinecap="round" />
        </StyledIllustration>
    );
};
