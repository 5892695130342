import styled from "@emotion/styled";
import Filter from "../filters/Filter";
import { FilterItem } from "../types";

export interface FiltersProps {
    filters: FilterItem[];
    onFilterUpdate: (value: string) => void;
    isModal: boolean;
}

const StyledFiltersSlider = styled(
    "div",
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "isModal";
        },
    }
)<{ isModal?: boolean }>(({ theme, isModal }) => ({
    display: isModal ? "block" : "flex",
    paddingTop: theme.spacing(3),
}));

/**
 * copmonent for rendering filters groups slider
 */
const Filters = ({ filters, onFilterUpdate, isModal = false }: FiltersProps) => {
    const baseClassName = "kms-ds-filters";
    return (
        <StyledFiltersSlider isModal={isModal} className={`${baseClassName}-container`}>
            {filters.map((filterData, index) => {
                return (
                    <Filter
                        key={index}
                        label={filterData.title}
                        options={filterData.items}
                        onFilterUpdate={onFilterUpdate}
                        isModal={isModal}
                        className={`${baseClassName}-filter`}
                    />
                );
            })}
        </StyledFiltersSlider>
    );
};

export default Filters;
