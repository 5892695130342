import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";

export const StyledThumbContainer = styled(Box)(({ theme }) => ({
    width: theme.typography.pxToRem(110),
    height: theme.typography.pxToRem(62),
    borderRadius: theme.kaltura.shape.roundness1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "#ffffff",
    backgroundColor: theme.kaltura.palette.tone2,
}));
