import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { LinearProgress, linearProgressClasses } from "@mui/material";

export const LabeledProgressBarWrapper = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    marginBottom: theme.spacing(3),
}));

export const ProgressBarWrapper = styled(Box)(({ theme }) => ({
    width: "100%",
    marginRight: theme.spacing(1),
}));

export const StyledLinearProgress = styled(LinearProgress)<{ height: number }>(({ theme, height }) => ({
    height: theme.typography.pxToRem(height),
    borderRadius: theme.kaltura.shape.roundness3,
    [`&.${linearProgressClasses.colorPrimary}`]: {
        backgroundColor: theme.kaltura.palette.tone7,
    },
    [`& .${linearProgressClasses.bar}`]: {
        borderRadius: 5,
        backgroundColor: theme.kaltura.palette.primary.main,
    },
}));
