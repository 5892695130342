import { useState } from "react";
import { styled } from "@mui/material";
import { Box, Skeleton } from "@kaltura/mediaspace-shared-styled";
import { BackgroundThumbnail } from "@kaltura/mediaspace-shared-ui";
import { KmsTypePresenter } from "@kaltura/mediaspace-shared-types";
import { Initials } from "@kaltura/ds-react-components";
import { useElementSizeObserver } from "@kaltura/mediaspace-shared-hooks";

const StyledInitialsContainer = styled(Box)({
    position: "absolute",
    top: 0,
    width: "100%",
});

interface UserThumbnailProps extends KmsTypePresenter {
    onThumbnailResize?: (height: number) => void;
}

export const UserThumbnail = ({ image, name, onThumbnailResize }: UserThumbnailProps) => {
    const [imageError, setImageError] = useState(false);

    const containerSizeTracker = useElementSizeObserver();

    onThumbnailResize?.(containerSizeTracker?.height ?? 0);

    const onImageError = () => {
        setImageError(true);
    };

    //TODO - remove once PS changes
    const baseClassName = "kms-ds-user-thumbnail";
    return (
        <StyledThumbnailContainer
            ref={containerSizeTracker.elementRef}
            className={`${baseClassName} kms-ds-group-page-group-user-thumbnail`}
        >
            {image && <img style={{ display: "none" }} src={image} onError={onImageError} />}
            {image && !imageError && (
                <BackgroundThumbnail
                    thumbnailUrl={image}
                    className={`${baseClassName}-background kms-ds-group-page-group-user-thumbnail-background`}
                />
            )}
            {(!image || imageError) && (
                <StyledInitialsContainer>
                    <Initials
                        className={`${baseClassName}-initials kms-ds-group-page-group-user-thumbnail-initials`}
                        size={"large"}
                        fullName={name}
                        aria-hidden="true"
                    />
                </StyledInitialsContainer>
            )}
        </StyledThumbnailContainer>
    );
};

export const UserThumbnailSkeleton = () => {
    return (
        <StyledThumbnailContainer>
            <StyledSkeleton variant={"rectangular"} />
        </StyledThumbnailContainer>
    );
};

const StyledThumbnailContainer = styled(Box)(({ theme }) => ({
    position: "relative",
    paddingTop: "100%",
    borderRadius: theme.kaltura.shape.roundness2,
    overflow: "hidden",
}));

const StyledSkeleton = styled(Skeleton)({
    position: "absolute",
    inset: 0,
    height: "100%",
});
