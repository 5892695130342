import React, { forwardRef, useEffect, KeyboardEvent } from "react";
import { SidebarMenuItem } from "@kaltura/mediaspace-shared-types";
import { MoreHorizontal24Icon } from "@kaltura/ds-react-icons";
import { HorizontalMenuItem } from "./HorizontalMenuItem";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { useElementSizeObserver, useEventHandler } from "@kaltura/mediaspace-shared-hooks";
import { useArrowNavigation, MenuItemRef } from "./useArrowNavigation";

export interface HorizontalMenuProps {
    className?: string;
    maxItems?: number;
    pages: SidebarMenuItem[];
    showMore?: boolean;
    buttonVariant?: "pill" | "borderless";
    buttonColor?: "translucent" | "primary";
    openOnHover?: boolean;
    isTab?: boolean;
    label?: string;
}

/**
 * Header Menu Horizontal Menu
 */
export function HorizontalMenu(props: HorizontalMenuProps) {
    const {
        className,
        maxItems = 5,
        pages,
        showMore = false,
        buttonVariant,
        buttonColor,
        openOnHover,
        isTab,
        label,
    } = props;

    const mainItems = pages.slice(0, maxItems);
    const extraItems = pages.slice(maxItems);

    // use arrow for navigation inside the menubar
    const { addElementRef, handleKeyDown, handleItemClick, handleFocus, handleBlur, infocus } = useArrowNavigation();

    return (
        <div
            className={className}
            role={"menubar"}
            aria-orientation="horizontal"
            aria-label={label}
            tabIndex={infocus ? -1 : 0}
            onFocus={handleFocus}
            onBlur={handleBlur}
        >
            {mainItems.map((item: SidebarMenuItem, index: number) => (
                <HorizontalMenuItem
                    {...item}
                    uri={item.uri === "/#" ? undefined : item.uri}
                    key={index}
                    buttonVariant={buttonVariant}
                    buttonColor={buttonColor}
                    openOnHover={openOnHover}
                    isTab={isTab}
                    buttonAnalyticsData={item.buttonAnalyticsData}
                    buttonRefProp={(el) => addElementRef(el, index)}
                    onKeyDown={(event) => handleKeyDown(index, event)}
                    onClick={() => handleItemClick(index)}
                />
            ))}
            {extraItems.length > 0 && showMore && <MoreItem id={"more"} key={"more"} pages={extraItems} />}
        </div>
    );
}

export default HorizontalMenu;

interface MoreItemProps {
    id: string;
    pages: SidebarMenuItem[];
    hidden?: boolean;
    onKeyDown?: (event: KeyboardEvent<HTMLElement>) => void;
    buttonRefProp?: React.ForwardedRef<MenuItemRef>;
}
const MoreItem = forwardRef<HTMLDivElement, MoreItemProps>(
    ({ id, pages, hidden = false, onKeyDown, buttonRefProp }, ref) => (
        <HorizontalMenuItem
            ref={ref}
            id={id}
            label={translate("Show More")}
            icon={<MoreHorizontal24Icon />}
            pages={pages}
            hidden={hidden}
            onKeyDown={onKeyDown}
            buttonRefProp={buttonRefProp}
        />
    )
);

export interface AutoHorizontalMenuProps extends Omit<HorizontalMenuProps, "maxItems" | "showMore"> {
    // This callback will be called when the visibility of the horizontal menu changes
    // (the menu could be hidden because there is not enough space for it)
    onVisibilityChange?: (isVisible: boolean) => void;
}

/**
 * Horizontal menu that takes the full size of the container
 * and automatically renders the necessary number of items depending on available space.
 *
 * If there's not enough space to render even 2 items, then the component notifies the parent about
 * by using the onVisibilityChange prop, and the parent component is responsible for rendering the vertical menu instead
 * (see HeaderMenu implementation)
 */
export const AutoHorizontalMenu = (props: AutoHorizontalMenuProps) => {
    const { className, pages, buttonVariant, buttonColor, openOnHover, isTab, label } = props;

    // use arrow for navigation inside the menubar
    const { addElementRef, handleKeyDown, handleItemClick, handleFocus, handleBlur, infocus } = useArrowNavigation();

    const containerSizeTracker = useElementSizeObserver();
    // The number of pages in the props doesn't change over time, so it's safe to call the hook in a loop like that
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const itemSizeTrackers = pages.map(() => useElementSizeObserver());
    const moreItemSizeTracker = useElementSizeObserver();

    // Subtract a safety margin of 10px from the container's width to allow possible measurement errors
    const containerWidth = containerSizeTracker.width !== undefined ? containerSizeTracker.width - 10 : undefined;
    const moreItemWidth = moreItemSizeTracker.width;
    let isAllMeasured = containerWidth !== undefined && moreItemWidth !== undefined;

    // keep track of total width of first N elements
    let lastTotalWidth = 0;
    // how many items are fitting the container width?
    let fittingElementsCount = 0;
    // how many items are fitting the container width if we add the "more" item to them?
    let fittingElementsCountWithMore = 0;
    if (isAllMeasured) {
        for (let index = 0; index < itemSizeTrackers.length; index++) {
            const { width } = itemSizeTrackers[index];

            if (width === undefined) {
                isAllMeasured = false;
                break;
            }

            lastTotalWidth += width;

            if (lastTotalWidth < containerWidth!) {
                fittingElementsCount = index + 1;
            }
            if (lastTotalWidth + moreItemWidth! < containerWidth!) {
                fittingElementsCountWithMore = index + 1;
            }
        }
    }

    // Show the "more" item if all items don't fit
    const showMore = fittingElementsCount < pages.length;
    if (showMore) {
        // Take the width of the "more" item into account
        fittingElementsCount = fittingElementsCountWithMore;
    }

    const isVisible = !showMore || fittingElementsCount >= 2;
    const onVisibilityChange = useEventHandler(props.onVisibilityChange);

    useEffect(() => {
        onVisibilityChange(isVisible);
    }, [isVisible, onVisibilityChange]);

    if (!pages.length) {
        return null;
    }

    return (
        <div ref={containerSizeTracker.elementRef} className={className} style={{ position: "relative" }}>
            {/* render elements as hidden to measure the size */}
            <div
                style={{
                    position: "absolute",
                    width: 0,
                    height: 0,
                    overflow: "hidden",
                }}
                aria-hidden={true}
                role={"none"}
            >
                {pages.map((item: SidebarMenuItem, index: number) => (
                    <HorizontalMenuItem
                        ref={itemSizeTrackers[index].elementRef}
                        {...item}
                        id={item.id + "-size-tracker"}
                        uri={item.uri === "/#" ? undefined : item.uri}
                        key={index}
                        buttonVariant={buttonVariant}
                        buttonColor={buttonColor}
                        openOnHover={openOnHover}
                        isTab={isTab}
                        hidden={true}
                    />
                ))}
                <MoreItem
                    ref={moreItemSizeTracker.elementRef}
                    id={"more-size-tracker"}
                    key={"more"}
                    pages={pages}
                    hidden={true}
                />
            </div>

            {/* render the elements for the UI */}
            {isAllMeasured && isVisible && (
                <div
                    style={{
                        position: "absolute",
                        inset: 0,
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                    }}
                    role={"menubar"}
                    aria-orientation="horizontal"
                    aria-label={label}
                    data-testid={"horizontal-header-menu"}
                    tabIndex={infocus ? -1 : 0}
                    onFocus={handleFocus}
                    onBlur={handleBlur}
                >
                    {pages
                        .slice(0, fittingElementsCount)
                        .map((item: SidebarMenuItem, index: number) => (
                            <HorizontalMenuItem
                                {...item}
                                uri={item.uri === "/#" ? undefined : item.uri}
                                key={index}
                                buttonVariant={buttonVariant}
                                buttonColor={buttonColor}
                                openOnHover={openOnHover}
                                isTab={isTab}
                                buttonRefProp={(el) => addElementRef(el, index)}
                                onKeyDown={(event) => handleKeyDown(index, event)}
                                onClick={() => handleItemClick(index)}
                            />
                        ))}

                    {showMore && (
                        <MoreItem
                            id={"more"}
                            key={"more"}
                            pages={pages.slice(fittingElementsCount)}
                            buttonRefProp={(el) => addElementRef(el, fittingElementsCount)}
                            onKeyDown={(event) => handleKeyDown(fittingElementsCount, event)}
                        />
                    )}
                </div>
            )}
        </div>
    );
};
