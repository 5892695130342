import { KmsTypeFilterItemBase } from "./KmsTypeFilterItemBase";

/**
 * ts implementation of Kms_Type_Esearch_Filter_Items_DropDown
 */
export interface KmsTypeFilterItemDropdown extends KmsTypeFilterItemBase {
    /**
     * ignored. kept here to match php class.
     */
    selected?: boolean;

    /**
     * boolean - is this filter item a drop down item
     */
    dropDown: true;
}

// noinspection PointlessBooleanExpressionJS
export const isKmsTypeFilterItemDropdown = (value: KmsTypeFilterItemBase<any>): value is KmsTypeFilterItemDropdown =>
    (value as KmsTypeFilterItemDropdown).dropDown === true;
