import { useEffect, useState } from "react";

/**
 * Custom hook to throttle a value
 * (update the value only once after consecutive changes, with a certain threshold interval).
 *
 * See usage examples in the related story and in the RegistrationEmbedForm component.
 *
 * @param {unknown} value - The value to throttle.
 * @param {number} delay - Time interval in milliseconds to wait before changing the result of the hook.
 */
export const useThrottledValue = <T>(value: T, delay: number): T => {
    const [result, setResult] = useState(value);

    // Schedule the result update in {delay} milliseconds after the source value gets updated
    useEffect(() => {
        const timeout = setTimeout(() => setResult(value), delay);
        // Cancel the result update when a new source value change arrives,
        // so that the result will not be updated until {delay} milliseconds passes since the last change.
        return () => {
            clearTimeout(timeout);
        };
    }, [value, delay]);

    return result;
};
