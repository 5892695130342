import { useCallback } from "react";
import { Config, useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { sendButtonClickAnalytics } from "@kaltura/mediaspace-shared-utils";

/**
 * Custom hook to send button analytics
 */
export const useButtonAnalytics = () => {
    const config: Config = useConfig();

    /**
     * a React wrapper for sending button-click analytics events to KMS
     */
    return useCallback(
        (actionName: string, buttonType: number, entryId?: string) => {
            const analyticsContext = config.analytics?.analyticsContext;
            const prefixedActionName = `${analyticsContext ? analyticsContext + " " : ""}${actionName}`;
            sendButtonClickAnalytics(
                prefixedActionName,
                config.analytics?.pageType,
                buttonType,
                entryId ?? config.analytics?.entryId
            );
        },
        [config]
    );
};
