import styled from "@emotion/styled";
import Accordion from "@mui/material/Accordion";
import AccordionSummary, { accordionSummaryClasses } from "@mui/material/AccordionSummary";

export const StyledFileRow = styled(
    "div",
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return "isOpen" !== propName;
        },
    }
)<{ isOpen: boolean }>(({ theme, isOpen }) => ({
    display: "flex",
    padding: theme.spacing(2, 3),
    columnGap: theme.spacing(2),
    alignItems: "center",
    width: "100%",
    borderBottom: isOpen ? "none" : "1px solid",
    borderColor: theme.kaltura.palette.tone4,
}));

export const StyledAccordion = styled(Accordion)(({ theme }) => ({
    [`&.${accordionSummaryClasses.root}`]: {
        marginTop: theme.spacing(2),
    },
    backgroundColor: "transparent",
    boxShadow: "none",
    "&:before": {
        display: "none",
    },
}));

export const StyledAccordionSummary = styled(AccordionSummary)({
    border: "none",
    padding: 0,
    minHeight: 0,
    height: "unset",
    display: "block",
    [`& .${accordionSummaryClasses.content}`]: {
        marginTop: 0,
        marginBottom: 0,
    },
    [`&:hover:not(${accordionSummaryClasses.disabled})`]: {
        cursor: "default !important",
    },
});
