import styled from "@emotion/styled";
import { Button } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { ChevronDown16Icon, ChevronUp16Icon } from "@kaltura/ds-react-icons";
import React, { ReactNode } from "react";
import { FilterItem } from "../types";

export interface FiltersBarProps {
    isFilterOpen: boolean;
    handleFilterButtonClick: (event: React.MouseEvent<HTMLButtonElement>) => void;
    searchComponent?: ReactNode;
    sortComponent?: ReactNode;
    filters: FilterItem[];
}

const StyledBarWrapper = styled("div")(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        flexDirection: "column",
    },
}));

const StyledSearchWrapper = styled("div")(({ theme }) => ({
    marginRight: theme.spacing(1),
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginRight: 0,
        marginBottom: theme.spacing(2),
    },
}));

const StyledButtonsWrapper = styled("div")({
    display: "flex",
    justifyContent: "space-between",
    flexGrow: 1,
});

const StyledButton = styled(Button)(({ theme }) => ({
    "&.active": {
        backgroundColor: theme.kaltura.palette.tone6,
        border: "none",
    },
}));

/**
 * copmonent for rendering filters bar (without the sliding part)
 */
export const FiltersBar = ({
    isFilterOpen = false,
    handleFilterButtonClick,
    searchComponent,
    sortComponent,
    filters = [],
}: FiltersBarProps) => {
    return (
        <StyledBarWrapper>
            {searchComponent && <StyledSearchWrapper>{searchComponent}</StyledSearchWrapper>}
            <StyledButtonsWrapper>
                <div className="kms-ds-filters-bar-filter">
                    {!!filters.length && (
                        <StyledButton
                            className={isFilterOpen ? "active" : ""}
                            variant="select"
                            endIcon={isFilterOpen ? <ChevronUp16Icon /> : <ChevronDown16Icon />}
                            onClick={handleFilterButtonClick}
                        >
                            <span>{translate("Filters")}</span>
                        </StyledButton>
                    )}
                </div>
                {sortComponent && <div className="kms-ds-filters-bar-filter">{sortComponent}</div>}
            </StyledButtonsWrapper>
        </StyledBarWrapper>
    );
};
