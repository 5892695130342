import * as React from "react";
import { ConfigContext, defaultContext } from "./ConfigContext";

export const useConfig = () => {
    const config = React.useContext(ConfigContext) || defaultContext;

    if (process.env.NODE_ENV !== "production") {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        React.useDebugValue(config);
    }

    return config;
};

export default useConfig;
