import { Config, useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { getEntryUrl, getPlaylistUrl } from "@kaltura/mediaspace-shared-utils";
import { KalturaEntryType } from "kaltura-typescript-client/api/types/KalturaEntryType";
import { PlaylistThumbLinkType } from "@kaltura/mediaspace-shared-types";

/**
 * custom hook to get config from context and call getEntryUrl.
 */

interface BasicEntry {
    id: string;
    name: string;
    type?: KalturaEntryType;
}

export const useEntryUrl = <T extends BasicEntry>({
    entry,
    categoryId,
    entryUrl,
    playlistId,
}: {
    entry: T;
    categoryId?: number;
    entryUrl?: string;
    playlistId?: string;
}) => {
    const config: Config = useConfig();
    const enableEntryTitles = config?.application?.enableEntryTitles;
    const entryName = enableEntryTitles ? entry.name : undefined;
    if (entryUrl) {
        return entryUrl;
    }
    if (playlistId && entry.type !== KalturaEntryType.document) {
        const playlistThumbLink = config?.application?.playlistThumbLink;
        if (playlistThumbLink === PlaylistThumbLinkType.playlistPage) {
            return getPlaylistUrl(playlistId, entry.id, categoryId);
        }
    }
    return getEntryUrl(entry.id, categoryId, entryName);
};
