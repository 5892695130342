import { translate } from "@kaltura/mediaspace-shared-utils";
import { Bulletlist24Icon } from "@kaltura/ds-react-icons";
import { SidebarContents, SidebarContentsProps } from "./SidebarContents";
import { ConfigSidebarButtonDescriptor } from "@kaltura/mediaspace-shared-data-kms-config";
import { SidebarMenuButtonWithAnalytics, SidebarMenuButtonWithAnalyticsProps } from "@kaltura/mediaspace-shared-layout";

/*
 * Agenda button descriptor for DS sidebar
 */
export const SidebarButton: ConfigSidebarButtonDescriptor<SidebarMenuButtonWithAnalyticsProps, SidebarContentsProps> = {
    id: "channeltv",
    buttonComponent: SidebarMenuButtonWithAnalytics,
    buttonProps: { title: translate("Agenda"), icon: <Bulletlist24Icon />, analyticsText: "TV guide" },
    contentsComponent: SidebarContents,
};
