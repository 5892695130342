/**
 *  ts representation of Kms_Enum_MediaCollaborationType
 */
export enum MediaCollaborationType {
    OWN = 1,
    CO_EDIT = 2,
    CO_PUBLISH = 3,
    CO_VIEW = 4,
    GROUP_OWN = 5,
    GROUP_CO_EDIT = 6,
    GROUP_CO_PUBLISH = 7,
    GROUP_CO_VIEW = 8,
}
