import { useState } from "react";

const calculateItemsToShow = <T>(allItems: T[], itemsPerPage: number, currentPage: number) => {
    const startPage = itemsPerPage * (currentPage - 1);
    return allItems.slice(startPage, startPage + itemsPerPage);
};

/**
 * custom hook to handle pagination
 */
export const usePagination = <T>(allItems: T[], itemsPerPage: number): [T[], number, (page: number) => void] => {
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [itemsToShow, setItemsToShow] = useState<T[]>(calculateItemsToShow(allItems, itemsPerPage, currentPage));

    const handlePagination = (page: number) => {
        setCurrentPage(page);
        setItemsToShow(calculateItemsToShow(allItems, itemsPerPage, page));
    };

    return [itemsToShow, currentPage, handlePagination];
};
