import styled from "@emotion/styled";
import { Chip } from "@mui/material";
import clsx from "clsx";

type BadgeSize = "small" | "medium";
type BadgeMode = "dark" | "light";

export interface BadgeProps {
    label: string;
    size: BadgeSize;
    mode: BadgeMode;
}

const StyledBadge = styled(Chip)<{ size: BadgeSize; mode: BadgeMode }>(({ theme, size, mode }) => ({
    fontSize: 11,
    fontWeight: theme.kaltura.typography.fontWeightBold,
    borderColor: theme.kaltura.palette.tone2,
    ...(
        mode === "dark" && {
            borderColor: "#FFF",
        }
    ),
    borderRadius: theme.kaltura.shape.roundness1,
    backgroundColor: "transparent",
    padding: "0 2px",
    height: "unset",
    ...(
        size === "medium" && {
            fontSize: 14,
        }
    ),
    "& .kms-ds-badge-label": {
        color: theme.kaltura.palette.tone2,
        ...(
            mode === "dark" && {
                color: "#FFF",
            }
        ),
        padding: 0,
    },
}));

export function Badge({ label, size = "small", mode = "dark" }: BadgeProps) {
    return (
        <StyledBadge
            label={label}
            size={size}
            mode={mode}
            variant={"outlined"}
            classes={{
                label: "kms-ds-badge-label",
                root: clsx("kms-ds-badge-root", `kms-ds-badge-${mode}`, `kms-ds-badge-${size}`),
            }}
        />
    );
}

export default Badge;
