import styled from "@emotion/styled";
import { MouseEvent, useRef } from "react";
import Counter from "../counter/Counter";
import { KmsTypePresenter } from "@kaltura/mediaspace-shared-types";
import EventPresenterLarge from "../event-presenter-large/EventPresenterLarge";
import { useTheme } from "@kaltura/mediaspace-shared-styled";
import { useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import EventPresenter from "../event-presenter/EventPresenter";

export interface EventPresenterLargeListProps {
    /**
     * full presenters list
     */
    presenters: KmsTypePresenter[];

    /**
     * callback for the show-more button
     */
    onShowMore?: () => void;

    /**
     * classname for the root element
     */
    className?: string;

    /**
     * show presenter modal on click
     */
    showPresenterModal?: boolean;
}

const StyledEventPresentersList = styled(
    "div",
    {
        shouldForwardProp(propName: PropertyKey) {
            return propName !== "itemsCount" && propName !== "hasMore";
        },
    }
)<{ itemsCount: number; hasMore: boolean }>(({ theme, itemsCount, hasMore }) => ({
    display: "flex",
    gap: theme.spacing(1),
    flexDirection: "column",
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        display: "grid",
        gap: theme.spacing(2),
        gridTemplateColumns: `repeat(${itemsCount}, minmax(0, 1fr)) ${hasMore ? "auto" : ""}`,
    },
}));

const StyledCounter = styled(Counter)(({ theme }) => ({
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        "&.kms-ds-event-presenters-more": {
            minWidth: 64,
            minHeight: 64,
        },
    },
}));

const getPresentersElements = (presenters: KmsTypePresenter[], isTinyScreen: boolean, showPresenterModal: boolean) => {
    const Component = isTinyScreen ? EventPresenter : EventPresenterLarge;
    return presenters.map((presenter: KmsTypePresenter) => {
        return <Component key={"presenter" + presenter.id} data={presenter} showPresenterModal={showPresenterModal} />;
    });
};

export function EventPresenterLargeList({
    presenters,
    onShowMore,
    className,
    showPresenterModal = false,
}: EventPresenterLargeListProps) {
    const handleClick = (ev: MouseEvent<HTMLDivElement>) => {
        if (onShowMore) {
            onShowMore();
        }
    };
    const counterRef = useRef(null);
    const theme = useTheme();

    const show3Presenters = useMediaQuery(theme.breakpoints.up("lg"));
    const showLargePresenters = useMediaQuery(theme.breakpoints.up("sm"));

    const visiblePresentersCount = show3Presenters ? 3 : 2;
    const visiblePresenters = presenters.slice(0, visiblePresentersCount);
    const presentersElements = getPresentersElements(visiblePresenters, !showLargePresenters, showPresenterModal);

    const additionalPresenters = presenters.slice(visiblePresentersCount);
    const hasMore = additionalPresenters.length > 0;

    return (
        <StyledEventPresentersList className={className} itemsCount={presentersElements.length} hasMore={hasMore}>
            {presentersElements}
            {hasMore && (
                <StyledCounter
                    ref={counterRef}
                    data={additionalPresenters.length}
                    onClick={handleClick}
                    className={"kms-ds-event-presenters-more"}
                    variant={"normal"}
                />
            )}
        </StyledEventPresentersList>
    );
}

export default EventPresenterLargeList;
