import { generateUtilityClass, generateUtilityClasses } from "@kaltura/mediaspace-shared-styled";

export interface SimpleThumbnailClasses {
    /**  styles applied to the root element */
    root: string;
    /**  styles applied to the image element */
    image: string;
    /**  styles applied to the duration/live indicator element */
    indicator: string;
    /**  styles applied to the root element when isLive==true */
    live: string;
}

export function getSimpleThumbnailClass(slot: string) {
    return generateUtilityClass("kms-ds-media-thumbnail", slot);
}

export type SimpleThumbnailClassKey = keyof SimpleThumbnailClasses;

export const simpleThumbnailClasses = generateUtilityClasses(
    "kms-ds-media-thumbnail",
    ["root", "image", "indicator", "live"]
);
export default simpleThumbnailClasses;
