function TiktokSocialLink() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.4911 7.17164C17.3772 7.11277 17.2663 7.04824 17.1588 6.97828C16.8464 6.77172 16.5599 6.52833 16.3056 6.25336C15.6693 5.52527 15.4316 4.78664 15.3441 4.26949H15.3476C15.2745 3.84023 15.3047 3.5625 15.3093 3.5625H12.411V14.7696C12.411 14.9201 12.411 15.0688 12.4047 15.2157C12.4047 15.234 12.4029 15.2509 12.4018 15.2706C12.4018 15.2787 12.4018 15.2871 12.4001 15.2955C12.4001 15.2977 12.4001 15.2998 12.4001 15.3019C12.3695 15.704 12.2406 16.0924 12.0247 16.433C11.8088 16.7736 11.5125 17.0559 11.1619 17.2552C10.7965 17.4631 10.3831 17.5721 9.96271 17.5716C8.61236 17.5716 7.51794 16.4705 7.51794 15.1106C7.51794 13.7508 8.61236 12.6497 9.96271 12.6497C10.2183 12.6495 10.4724 12.6897 10.7154 12.7689L10.7189 9.81785C9.98112 9.72255 9.23157 9.78118 8.51756 9.99006C7.80356 10.1989 7.14059 10.5535 6.57048 11.0314C6.07093 11.4655 5.65096 11.9834 5.32946 12.5618C5.20712 12.7727 4.74552 13.6204 4.68962 14.996C4.65446 15.7768 4.88896 16.5858 5.00075 16.9201V16.9271C5.07107 17.124 5.34353 17.7959 5.78755 18.3622C6.14559 18.8165 6.56861 19.2156 7.04298 19.5466V19.5396L7.05001 19.5466C8.4531 20.5001 10.0088 20.4375 10.0088 20.4375C10.2781 20.4266 11.1802 20.4375 12.2046 19.952C13.3409 19.4137 13.9877 18.6118 13.9877 18.6118C14.401 18.1327 14.7296 17.5866 14.9595 16.9971C15.2217 16.3077 15.3093 15.4808 15.3093 15.1504V9.20473C15.3444 9.22582 15.8127 9.53555 15.8127 9.53555C15.8127 9.53555 16.4874 9.96797 17.5399 10.2496C18.2951 10.45 19.3125 10.4921 19.3125 10.4921V7.61496C18.956 7.65363 18.2322 7.54113 17.4911 7.17164Z"
                fill="white"
            />
        </svg>
    );
}

export default TiktokSocialLink;
