import { addBaseUrl, postKmsData } from "@kaltura/mediaspace-shared-utils";
import { SetCollaboration, SetCollaborationProps } from "./SetCollaboration";

export interface SetCollaborationContainerProps extends Omit<SetCollaborationProps, "setCollaborators"> {
    actionUrl: string;
    /**
     * list of entry ids to act on
     * (set by host)
     */
    entryIds: string[];

    /**
     * close menu on menu item click
     */
    onCloseMenu: () => void;
}

export const SetCollaborationContainer = ({ entryIds, actionUrl, ...rest }: SetCollaborationContainerProps) => {
    const url = addBaseUrl(actionUrl);
    return (
        <SetCollaboration
            setCollaborators={(action, permissions, userIds) => {
                return postKmsData(
                    `${url}/work/${action}`,
                    {
                        entryIds: entryIds,
                        permissions: permissions,
                        userIds: userIds,
                    }
                );
            }}
            {...rest}
        />
    );
};
