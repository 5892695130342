import MuiClickAwayListener, {
    ClickAwayListenerProps as MuiClickAwayListenerProps,
} from "@mui/material/ClickAwayListener";

/* eslint-disable-next-line */
export interface ClickAwareListenerProps extends MuiClickAwayListenerProps {}

export function ClickAwayListener({ children, ...props }: ClickAwareListenerProps) {
    return <MuiClickAwayListener {...props}>{children}</MuiClickAwayListener>;
}

export default ClickAwayListener;
