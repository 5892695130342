import { styled } from "@mui/material";

/**
 * A link element without the standard styles of the <a> tag.
 *
 * Use it to wrap a block element (like) with a link
 * without making the child elements blue and underlined.
 */
export const BoxLink = styled("a")({
    color: "inherit",
    textDecoration: "none",
});
