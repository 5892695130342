export enum EntryType {
    "video" = "video",
    "audio" = "audio",
    "image" = "image",
    "quiz" = "quiz",
    "kwebcast" = "kwebcast",
    "live" = "live",
    "rapt" = "rapt",
    "playlist" = "playlist",
    "channelplaylists" = "channelplaylists",
    /*|"webcast"|"kpw2html"|"youtube"*/
}
