import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";

export const StyledRowActions = styled(Box)(({ theme }) => ({
    flex: "0 0 auto",
    display: "flex",
    alignItems: "center",
    columnGap: theme.spacing(2),
}));

export const TooltipPopperProps = {
    modifiers: [
        {
            name: "offset",
            options: {
                offset: [0, -8],
            },
        },
    ],
};
