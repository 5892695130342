import { createContext, PropsWithChildren, useContext, useMemo, useState } from "react";

export interface EmbeddedCncWidgetContextData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cncWidget?: any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    setCncWidget: (cncWidget?: any) => void;
    showReactions: boolean;
    setShowReactions: (showReactions: boolean) => void;
}

/**
 * Context for sharing the C&C widget object between components that are spread out across the layout regions.
 * The provider for this context should be rendered in the top level of the application (see DsLayout).
 */
const EmbeddedCncWidgetContext = createContext<EmbeddedCncWidgetContextData>({
    setCncWidget: () => {},
    showReactions: false,
    setShowReactions: () => {},
});

export const EmbeddedCncWidgetContextProvider = ({ children }: PropsWithChildren) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const [cncWidget, setCncWidget] = useState<any>();
    const [showReactions, setShowReactions] = useState(false);
    const contextData = useMemo<EmbeddedCncWidgetContextData>(
        () => ({ cncWidget, setCncWidget, showReactions, setShowReactions }),
        [cncWidget, setCncWidget, showReactions, setShowReactions]
    );
    return <EmbeddedCncWidgetContext.Provider value={contextData}>{children}</EmbeddedCncWidgetContext.Provider>;
};

export const useEmbeddedCncWidgetContext = () => useContext(EmbeddedCncWidgetContext);
