import { useCallback, useEffect, useRef, useState } from "react";
import { safeSetTimeout } from "@kaltura/mediaspace-shared-utils";

/**
 * Execute eventHandler() at specific point of time.
 * The callback won't be executed if the first time of calling the hook is already after the given point of time.
 *
 * Return true if the time already passed.
 *
 * @param timestamp - the time of event, unix timestamp (pass undefined to disable the hook)
 * @param eventHandler
 */
export const useScheduledEvent = (timestamp: number | undefined, eventHandler?: () => void) => {
    const getRemainingTime = useCallback(() => (timestamp ?? 0) * 1000 - Date.now(), [timestamp]);
    const [isDone, setIsDone] = useState(() => getRemainingTime() < 0);

    const eventHandlerRef = useRef(eventHandler);
    eventHandlerRef.current = eventHandler;

    useEffect(() => {
        const remainingTime = getRemainingTime();

        if (remainingTime < 0) {
            setIsDone(true);
            return;
        }

        setIsDone(false);
        const timeout = safeSetTimeout(
            () => {
                setIsDone(true);
                eventHandlerRef.current?.();
            },
            remainingTime
        );

        return () => {
            clearTimeout(timeout);
        };
    }, [getRemainingTime, eventHandlerRef]);

    return isDone;
};
