import styled from "@emotion/styled";
import { KmsTypePublishExternalCategories } from "@kaltura/mediaspace-shared-types";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { Typography } from "@kaltura/ds-react-components";
import { CategoryType, enrichCategoriesData, EnrichmentType } from "../../utils";
import { CategoriesList } from "../../media-categories/categories-list/CategoriesList";

interface ExternalAppPublishedCategoriesProps {
    externalCategories: KmsTypePublishExternalCategories;
}

const StyledAppTitle = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone2,
    display: "block",
    marginTop: theme.spacing(1),
}));

const StyledExternalCategoriesContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    rowGap: theme.spacing(1),
}));

/**
 * Publish menu - External published categories for one external app
 */
export function ExternalAppPublishedCategories({ externalCategories }: ExternalAppPublishedCategoriesProps) {
    // get the external categories
    const { categories = {}, applicationName = "" } = externalCategories;
    const { published = [], pending = [], rejected = [] } = categories;

    const publishedExternalCategories = enrichCategoriesData(
        published,
        CategoryType.externalCategory,
        EnrichmentType.label
    );
    const pendingExternalCategories = enrichCategoriesData(
        pending,
        CategoryType.externalCategory,
        EnrichmentType.label
    );
    const rejectedExternalCategories = enrichCategoriesData(
        rejected,
        CategoryType.externalCategory,
        EnrichmentType.label
    );

    const hasExternalCategories =
        publishedExternalCategories.length + pendingExternalCategories.length + rejectedExternalCategories.length > 0;

    return (
        hasExternalCategories && (
            <>
                <StyledAppTitle variant="body1Highlight">
                    {translate("Published in %1:", [applicationName])}
                </StyledAppTitle>

                <CategoriesList categories={publishedExternalCategories} />

                <CategoriesList
                    categories={pendingExternalCategories}
                    title={{ label: translate("Pending in:"), color: "warning.main" }}
                />

                <CategoriesList
                    categories={rejectedExternalCategories}
                    title={{ label: translate("Rejected in:"), color: "error.main" }}
                />
            </>
        )
    );
}

export interface ExternalPublishedCategoriesProps {
    externalCategories: KmsTypePublishExternalCategories[];
}

/**
 * Publish menu - External published categories for several external apps
 */
export function ExternalPublishedCategories({ externalCategories = [] }: ExternalPublishedCategoriesProps) {
    return (
        <StyledExternalCategoriesContainer>
            {externalCategories.map((externalAppCategories) => (
                <ExternalAppPublishedCategories
                    key={externalAppCategories.applicationName}
                    externalCategories={externalAppCategories}
                />
            ))}
        </StyledExternalCategoriesContainer>
    );
}
