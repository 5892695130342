import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { Skeleton } from "@kaltura/mediaspace-shared-styled";

const StyledMediaCategoriesSkeleton = styled("div")(({ theme }) => ({
    maxWidth: 320,
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    borderRadius: theme.kaltura.shape.roundness1,
}));

/**
 * publish modal MediaCategories skeleton
 */
export function MediaCategoriesSkeleton() {
    const theme = useTheme();

    return (
        <>
            <StyledMediaCategoriesSkeleton>
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"40%"} />
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"25%"} />
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"25%"} />
            </StyledMediaCategoriesSkeleton>
        </>
    );
}
