import styled from "@emotion/styled";
import { useState } from "react";
import { Alert, Link } from "@kaltura/ds-react-components";
import { KmsTypePublishCategories } from "@kaltura/mediaspace-shared-types";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";

export interface PendingAlertProps {
    entryId: string;
    galleries?: KmsTypePublishCategories;
    channels?: KmsTypePublishCategories;
    onPendingClick: () => void;
}

const StyledAlert = styled(Alert)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const StyledButton = styled(Link)(({ theme }) => ({
    marginLeft: theme.spacing(0.5),
    cursor: "pointer",
}));

/**
 * pending/rejected alert
 */
export function PendingAlert(props: PendingAlertProps) {
    const { entryId, galleries, channels, onPendingClick } = props;
    const categories = galleries || channels;
    const { pending = [], rejected = [] } = categories!;
    const pendingRejectedCount = pending.length + rejected.length;

    const [showAlert, setShowAlert] = useState(true);

    const sendButtonAnalytics = useButtonAnalytics();

    const handleCloseAlert = () => {
        galleries
            && sendButtonAnalytics(
                `Publish - Set publishing settings modal box - Close exception list for gallery`,
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        channels
            && sendButtonAnalytics(
                `Publish - Set publishing settings modal box - Close exception list for channel`,
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        setShowAlert(false);
    };

    const handlePendingClick = () => {
        galleries
            && sendButtonAnalytics(
                `Publish - Set publishing settings modal box - See exception list for gallery`,
                ButtonClickAnalyticsType.LINK,
                entryId
            );
        channels
            && sendButtonAnalytics(
                `Publish - Set publishing settings modal box - See exception list for channel`,
                ButtonClickAnalyticsType.LINK,
                entryId
            );
        onPendingClick();
    };

    return (
        showAlert
        && pendingRejectedCount > 0 && (
            <StyledAlert severity="warning" onClose={handleCloseAlert}>
                {galleries && translate("Media is pending/rejected in %1 galleries.", [pendingRejectedCount])}
                {channels && translate("Media is pending/rejected in %1 channels.", [pendingRejectedCount])}
                <StyledButton size={"small"} onClick={handlePendingClick}>
                    {translate("See list")}
                </StyledButton>
            </StyledAlert>
        )
    );
}
