import { StyledLinearProgress, LabeledProgressBarWrapper, ProgressBarWrapper } from "./StyleComponents";
import { Typography } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";

export interface LabeledProgressBarProps {
    /**
     * height in px for the progress bar
     */
    height: number;
    /**
     * the current progress value in percents
     */
    value?: number;
    /**
     * label to display next to the progress bar
     */
    label?: string;
}

/**
 * LabeledProgressBar component
 * Renders a progress bar based on provided value prop in percents
 */
export const LabeledProgressBar = ({ height, value, label }: LabeledProgressBarProps) => {
    return (
        <LabeledProgressBarWrapper>
            <ProgressBarWrapper aria-live="polite">
                <StyledLinearProgress
                    variant="determinate"
                    height={height}
                    value={value}
                    className={"chromatic-ignore kms-ds-progress-bar"}
                    aria-label={translate("current progress: %1%", [parseInt(String(value))])}
                    aria-valuenow={parseInt(String(value))}
                    role="progressbar"
                />
            </ProgressBarWrapper>
            {label && (
                <Typography variant={"body2Highlight"} className={"kms-ds-progress-bar-text"}>
                    {label}
                </Typography>
            )}
        </LabeledProgressBarWrapper>
    );
};

export default LabeledProgressBar;
