import styled from "@emotion/styled";

type BadgeIconProps = {
    iconUrl?: string;
    showOpacity?: boolean;
    isSmall?: boolean;
    onAction?: () => void;
    showPointer?: boolean;
    offset?: boolean;
    showBorder?: boolean;
};

const StyledImageBadgeIcon = styled(
    "img",
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return (
                propName !== "showOpacity"
                && propName !== "isSmall"
                && propName !== "showPointer"
                && propName !== "offset"
                && propName !== "showBorder"
            );
        },
    }
)<{
    showOpacity?: boolean;
    isSmall?: boolean;
    showPointer?: boolean;
    offset?: boolean;
    showBorder?: boolean;
}>(({ theme, showOpacity, isSmall, showPointer, offset, showBorder }) => ({
    width: 100,
    height: 100,
    borderRadius: "50%",
    opacity: showOpacity ? 0.5 : 1,
    boxSizing: "border-box", // missing in non-ds layouts
    ...(
        showBorder && {
            border: `2px solid ${theme.kaltura.palette.surfaces.background}`,
        }
    ),
    ...(
        isSmall && {
            width: 60,
            maxWidth: 60, // override bootstrap.min.js
            height: 60,
        }
    ),
    ...(
        showPointer && {
            cursor: "pointer",
        }
    ),
    ...(
        offset && {
            marginLeft: theme.spacing(-1),
        }
    ),
}));

const BadgeIcon = (props: BadgeIconProps) => {
    const { iconUrl, showOpacity, isSmall, onAction, showPointer = false, offset = false, showBorder = false } = props;

    return (
        <StyledImageBadgeIcon
            className="kms-ds-personal-badges-earned-badge-icon"
            src={iconUrl}
            showOpacity={showOpacity}
            isSmall={isSmall}
            onClick={onAction}
            showPointer={showPointer}
            offset={offset}
            showBorder={showBorder}
            aria-hidden={true}
        />
    );
};

export default BadgeIcon;
