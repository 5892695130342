import { useEffect, useState } from "react";
import EmptyPlayerV3, { EmptyPlayerV3Props } from "./EmptyPlayerV3";
import { useEventHandler } from "@kaltura/mediaspace-shared-hooks";

export interface MediaPlayerV3Props extends EmptyPlayerV3Props {
    /**
     * media the player should load.
     * either entryId or playlistId should be present.
     */
    media: { entryId?: string; playlistId?: string; ks?: string };
    onMediaLoaded?: (player: any) => void;
    options?: any;
}

/**
 *  Player V7 component
 *  Note: This component assumes a V7 library is loaded on page.
 */
export function MediaPlayerV3({ media, onMediaLoaded, onReady, options, ...rest }: MediaPlayerV3Props) {
    const [player, setPlayer] = useState<any>();
    const onMediaLoadedMemoized = useEventHandler(onMediaLoaded);

    useEffect(() => {
        /*
         * The "player" state is being initialized in the "ready" event of the child component.
         * The "media.entryId" property may be changed by the parent component - it's a sign that the media
         * should be switched.
         * The "player.loadMedia()" method should be called when the player is ready and each time the entry ID changes.
         */
        if (!player) {
            return;
        }
        if (media.entryId) {
            player.loadMedia(media).then(() => {
                if (onMediaLoadedMemoized) {
                    onMediaLoadedMemoized(player);
                }
            });
        }
        else if (media.playlistId) {
            player.loadPlaylist(media, { options }).then(() => {
                if (onMediaLoadedMemoized) {
                    onMediaLoadedMemoized(player);
                }
            });
        }
    }, [media, media.entryId, onMediaLoadedMemoized, options, player]);

    const handleReady = (player: any, playerId: string) => {
        document.dispatchEvent(new CustomEvent("kms_playerReady", { detail: { id: playerId } }));

        if (onReady) {
            onReady(player, playerId);
        }
        // setting the player will trigger loadMedia
        setPlayer(player);
    };
    return <EmptyPlayerV3 {...rest} onReady={handleReady} />;
}

export default MediaPlayerV3;
