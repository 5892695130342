function YoutubeSocialLink() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.5879 7.19067C21.3575 6.33405 20.6819 5.65879 19.8252 5.42813C18.2602 5 11.9998 5 11.9998 5C11.9998 5 5.73963 5 4.17456 5.41189C3.33435 5.64231 2.64234 6.33417 2.41187 7.19067C2 8.75525 2 12 2 12C2 12 2 15.2611 2.41187 16.8093C2.64258 17.6658 3.31787 18.3411 4.17469 18.5718C5.75611 19 12 19 12 19C12 19 18.2602 19 19.8252 18.5881C20.682 18.3576 21.3575 17.6823 21.5882 16.8258C21.9999 15.2611 21.9999 12.0165 21.9999 12.0165C21.9999 12.0165 22.0164 8.75525 21.5879 7.19067V7.19067ZM10.0066 14.9976V9.00239L15.2124 12L10.0066 14.9976Z"
                fill="white"
            />
        </svg>
    );
}

export default YoutubeSocialLink;
