import { useTheme } from "@kaltura/mediaspace-shared-styled";
import { useMediaQuery } from "@kaltura/mediaspace-shared-utils";
import { Button, ButtonProps, IconButton, Tooltip } from "@kaltura/ds-react-components";
import { MouseEventHandler, ReactElement } from "react";
import styled from "@emotion/styled";

export interface ChangingButtonProps {
    label: string;
    icon: ReactElement;
    onClick?: MouseEventHandler<HTMLElement>;
    href?: string;
    target?: string;
    disabled?: boolean;
    selected?: boolean;
    ariaLabel?: string;
    color?: ButtonProps["color"];
    variant?: ButtonProps["variant"];
    loading?: boolean;
}

// this is an ugly hack to allow using the buttons' "target" prop
export const StyledButton = styled(Button<"a" | "button">)(({ theme }) => ({
    [`&.upload-changing-button-selected`]: {
        backgroundColor: theme.kaltura.palette.tone6,
    },
}));

export const StyledIconButton = styled(IconButton<"a" | "button">)(({ theme }) => ({
    [`&.upload-changing-button-selected`]: {
        backgroundColor: theme.kaltura.palette.tone6,
    },
}));

/**
 * a wrapper for a Button that switches to an IconButton on small screens
 */
export function ChangingButton(props: ChangingButtonProps) {
    const theme = useTheme();
    const isXsScreen = useMediaQuery(theme.breakpoints.down(theme.breakpoints.values.sm));

    const ButtonComponent = isXsScreen ? StyledIconButton : StyledButton;
    const descriptiveName = props.ariaLabel ?? props.label;
    const buttonResult = (
        <ButtonComponent
            variant={props.variant ?? "borderless"}
            color={props.color ?? "secondary"}
            startIcon={!isXsScreen && props.icon}
            onClick={props.onClick}
            component={props.href ? "a" : "button"}
            href={props.href}
            aria-label={descriptiveName}
            target={props.target}
            disabled={props.disabled}
            classes={props.selected ? { root: "upload-changing-button-selected" } : {}}
            loading={props.loading}
        >
            {!isXsScreen && props.label}
            {isXsScreen && props.icon}
        </ButtonComponent>
    );

    if (isXsScreen) {
        return <Tooltip title={descriptiveName}>{buttonResult}</Tooltip>;
    }
    else {
        return buttonResult;
    }
}

export default ChangingButton;
