import styled from "@emotion/styled";
import {
    KmsTypePublishCategories,
    KmsTypePublishCategory,
    KmsTypePublishUserCategories,
} from "@kaltura/mediaspace-shared-types";
import { translate } from "@kaltura/mediaspace-shared-utils";
import FormGroup from "@mui/material/FormGroup";
import FormLabel from "@mui/material/FormLabel";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { CategoryType, channelLabel } from "../../../../../../utils";
import { CategoryCheckbox } from "./../category-checkbox/CategoryCheckbox";

export interface ChannelsTabFormProps {
    channels: KmsTypePublishCategories;
    userCategories: KmsTypePublishUserCategories;
    channelResults?: KmsTypePublishCategory[];
    onCategoryChange: (categoryId: number, checked: boolean) => void;
}

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    display: "block",
    marginTop: 0,
    marginBottom: theme.spacing(1),
    textTransform: "uppercase",
    color: theme.kaltura.palette.tone2,
    fontSize: 12,
    letterSpacing: 1,
    fontWeight: theme.kaltura.typography.fontWeightBold,
}));

const StyledFormGroup = styled(FormGroup)(({ theme }) => ({
    marginBottom: theme.spacing(2),
}));

/**
 * publish modal channels tab Form (checkboxes)
 */
export function ChannelsTabForm(props: ChannelsTabFormProps) {
    const { channels, userCategories, onCategoryChange, channelResults = [] } = props;
    const { myChannels = [], moreChannels = [] } = userCategories;
    const { published = [], pending = [] } = channels;

    const sendButtonAnalytics = useButtonAnalytics();

    const isChecked = (channelId: number) => {
        return !!(
            published.find((channel) => channel.id === channelId) || pending.find((channel) => channel.id === channelId)
        );
    };

    const handleCategoryChange = (id: number, checked: boolean) => {
        checked
            && sendButtonAnalytics(
                "Publish - Set publish settings modal box - Channel checked",
                ButtonClickAnalyticsType.TOGGLE
            );
        !checked
            && sendButtonAnalytics(
                "Publish - Set publish settings modal box - Channel unchecked",
                ButtonClickAnalyticsType.TOGGLE
            );

        onCategoryChange(id, checked);
    };

    return (
        <>
            {myChannels.length > 0 && (
                <>
                    <StyledFormLabel>{translate("channels i manage")}</StyledFormLabel>
                    <StyledFormGroup className="kms-ds-publish-my-channels">
                        {myChannels.map((channel) => (
                            <CategoryCheckbox
                                key={channel.id}
                                category={channel}
                                categoryType={CategoryType.channel}
                                tooltipText={channelLabel(channel)}
                                disabled={false}
                                isChecked={isChecked}
                                onCategoryChange={handleCategoryChange}
                            />
                        ))}
                    </StyledFormGroup>
                </>
            )}

            {moreChannels.length > 0 && (
                <>
                    <StyledFormLabel>{translate("more channels")}</StyledFormLabel>
                    <StyledFormGroup className="kms-ds-publish-more-channels">
                        {moreChannels.map((channel) => (
                            <CategoryCheckbox
                                key={channel.id}
                                category={channel}
                                categoryType={CategoryType.channel}
                                tooltipText={channelLabel(channel)}
                                disabled={false}
                                isChecked={isChecked}
                                onCategoryChange={handleCategoryChange}
                            />
                        ))}
                    </StyledFormGroup>
                </>
            )}

            {channelResults.length > 0 && (
                <>
                    <StyledFormGroup className="kms-ds-publish-search-channels">
                        {channelResults.map((channel) => (
                            <CategoryCheckbox
                                key={channel.id}
                                category={channel}
                                categoryType={CategoryType.channel}
                                tooltipText={channelLabel(channel)}
                                disabled={false}
                                isChecked={isChecked}
                                onCategoryChange={handleCategoryChange}
                            />
                        ))}
                    </StyledFormGroup>
                </>
            )}
        </>
    );
}
