import { ReactNode, useRef } from "react";
import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { Button } from "@kaltura/ds-react-components";
import { ChevronLeft24Icon, ChevronRight24Icon } from "@kaltura/ds-react-icons";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { translate } from "@kaltura/mediaspace-shared-utils";

export type CarouselProps = {
    children: ReactNode;
    showArrows: boolean;
};

const StyledContainer = styled(Box)(({ theme }) => ({
    marginTop: theme.spacing(3),
    position: "relative",
    display: "flex",
    alignItems: "center",
    "&:hover > .arrow": {
        opacity: 1,
    },
}));

const StyledCarouselContainer = styled(Box)({
    overflow: "hidden",
    position: "relative",
    height: 60,
    flexGrow: 1,
});

const StyledContent = styled(Box)(({ theme }) => ({
    display: "flex",
    height: "inherit",
    gap: theme.spacing(2),
    position: "absolute",
    transition: "left .5s",
}));

const StyledArrow = styled(
    Button,
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "side";
        },
    }
)<{ side: string }>(({ theme, side }) => ({
    color: theme.kaltura.palette.tone3,
    position: "absolute",
    opacity: 0,
    backgroundColor: "transparent",
    ...(
        side === "left" && {
            left: -35,
        }
    ),
    ...(
        side === "right" && {
            right: -35,
        }
    ),
    "&:hover": {
        backgroundColor: "transparent",
        opacity: 1,
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        opacity: 1,
    },
}));

/**
 * carousel component
 * contains carousel items ui, arrows ui and arrows logic
 */
const Carousel = (props: CarouselProps) => {
    const { children, showArrows } = props;

    const itemsListRef = useRef<HTMLElement>();
    const sendButtonAnalytics = useButtonAnalytics();

    // spacing between carousel items, used as an offset on carousel scroll
    const offset = 16;

    // scroll carousel items according to the arrow direction which was clicked.
    // right arrow moves inner items list row position to the left, while checking the remaining items to show.
    // if no remaining items to show, no scroll is being done.
    // left arrow does the same for the opposite direction.
    const scroll = (direction: "right" | "left") => {
        const ref = itemsListRef.current;
        if (!ref) {
            return;
        }
        const parentRef = ref.parentElement;

        let gap;
        if (direction === "right") {
            gap = ref.offsetLeft + ref.offsetWidth - (parentRef as HTMLElement).offsetWidth;
            if (gap > 0) {
                let left = parseInt(ref.style.left);
                left = isNaN(left) ? 0 : left;
                // without this initial left value, the transition effect won't work
                (ref as HTMLElement).style.left = `${left}px`;
                ref.style.left =
                    gap >= (parentRef as HTMLElement).offsetWidth
                        ? `${left - (parentRef as HTMLElement).offsetWidth - offset}px`
                        : `${left - gap}px`;
            }
        }
        else {
            gap = (parentRef as HTMLElement).offsetLeft - ref.offsetLeft;
            if (gap > 0) {
                ref.style.left =
                    gap >= (parentRef as HTMLElement).offsetWidth
                        ? `${parseInt(ref.style.left) + (parentRef as HTMLElement).offsetWidth + offset}px`
                        : `${parseInt(ref.style.left) + gap}px`;
            }
        }

        sendButtonAnalytics("Badge carousel - Header menu - Arrows", ButtonClickAnalyticsType.BROWSE);
    };

    return (
        <StyledContainer>
            {showArrows && (
                <StyledArrow
                    variant={"borderless"}
                    onClick={() => scroll("left")}
                    disabled={false}
                    side={"left"}
                    className={"kms-ds-header-user-earned-badges-carousel-arrow arrow"}
                    aria-label={translate("previous slide arrow")}
                >
                    <ChevronLeft24Icon />
                </StyledArrow>
            )}
            <StyledCarouselContainer>
                <StyledContent ref={itemsListRef}>{children}</StyledContent>
            </StyledCarouselContainer>
            {showArrows && (
                <StyledArrow
                    variant={"borderless"}
                    onClick={() => scroll("right")}
                    disabled={false}
                    side={"right"}
                    className={"kms-ds-header-user-earned-badges-carousel-arrow arrow"}
                    aria-label={translate("next slide arrow")}
                >
                    <ChevronRight24Icon />
                </StyledArrow>
            )}
        </StyledContainer>
    );
};

export default Carousel;
