/**
 * custom hook to run a custom script.
 */

import { useEffect } from "react";

export function useScript(scriptTxt?: string) {
    useEffect(() => {
        if (!scriptTxt) {
            return;
        }
        const script = document.createElement("script");
        script.innerText = scriptTxt;
        script.async = true;
        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, [scriptTxt]);
}
