import styled from "@emotion/styled";
import { Info24Icon } from "@kaltura/ds-react-icons";
import { Tooltip } from "@kaltura/ds-react-components";
import { baseFormLabelClasses } from "./../../base-form-label/BaseFormLabel";
import { FormLabel, FormLabelProps } from "./../form-label/FormLabel";

export interface FormLabelWithDescriptionProps extends FormLabelProps {
    description?: string;
}

const StyledFormLabelWithDescription = styled("div")(({ theme }) => ({
    display: "flex",
    columnGap: theme.spacing(1),
    alignItems: "center",
}));
const StyledSpan = styled("span")(({ theme }) => ({
    height: 24,
}));

const StyledFormLabel = styled(FormLabel)(({ theme }) => ({
    [`& .${baseFormLabelClasses.label}`]: {
        paddingBottom: 0,
    },
    [`&.${baseFormLabelClasses.root}`]: {
        marginBottom: 0,
    },
}));

export function FormLabelWithDescription({ description, className, ...rest }: FormLabelWithDescriptionProps) {
    return (
        <StyledFormLabelWithDescription className={className}>
            <StyledFormLabel {...rest} />
            {description && (
                <Tooltip title={description}>
                    <StyledSpan tabIndex={0} role={"img"}>
                        <Info24Icon />
                    </StyledSpan>
                </Tooltip>
            )}
        </StyledFormLabelWithDescription>
    );
}

export default FormLabelWithDescription;
