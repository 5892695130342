import React from "react";
import { getDsLayoutComponentRenderer } from "@kaltura/mediaspace-shared-layout";

export * as Actions from "@mediaspace/features/media-page/title-area/actions";

const MediaPage = React.lazy(() => import("@mediaspace/features/media-page"));

export const VodHero = React.lazy(() => import("@mediaspace/features/media-page/hero/vod-hero/VodHero"));

export const ScheduledMediaHero = React.lazy(
    () => import("@mediaspace/features/media-page/hero/scheduled-media-hero/ScheduledMediaHero")
);

export const MeetingRoomHero = React.lazy(
    () => import("@mediaspace/features/media-page/hero/meeting-room-hero/MeetingRoomHero")
);

export const KwebcastHero = React.lazy(() => import("@mediaspace/features/media-page/hero/kwebcast-hero/KwebcastHero"));

export const TvHero = React.lazy(() => import("@mediaspace/features/media-page/hero/tv-hero/TvHero"));

export const LobbyOnlyHero = React.lazy(
    () => import("@mediaspace/features/media-page/hero/lobby-only-hero/LobbyOnlyHero")
);

export const VirtualEventHero = React.lazy(
    () => import("@mediaspace/features/media-page/hero/virtual-event-hero/VirtualEventHero")
);

export const DraftHero = React.lazy(() => import("@mediaspace/features/media-page/hero/draft-hero/DraftHero"));

export const RaptHero = React.lazy(() => import("@mediaspace/features/media-page/hero/rapt-hero/RaptHero"));

export const DocumentViewerHero = React.lazy(
    () => import("@mediaspace/features/media-page/hero/document-viewer-hero/DocumentViewerHero")
);

export const PlaylistHero = React.lazy(() => import("@mediaspace/features/media-page/hero/playlist-hero/PlaylistHero"));

export const BackButton = React.lazy(
    () => import("@mediaspace/features/media-page/hero/hosted/back-button/BackButton")
);

export const Presenters = React.lazy(
    () => import("@mediaspace/features/media-page/hero/lobby/lobby-data/presenters/Presenters")
);

export const MetadataPresenters = React.lazy(
    () => import("@mediaspace/features/media-page/media-metadata/metadata-presenters/MetadataPresenters")
);

export const Attachments = React.lazy(
    () => import("@mediaspace/features/media-page/media-module-data/attachments/MediaAttachments")
);

export const RelatedMedia = React.lazy(
    () => import("@mediaspace/features/media-page/sidebar/related-media/RelatedMedia")
);

export const SurveyCta = React.lazy(() => import("@mediaspace/features/media-page/survey-cta/SurveyCta"));

export const ReplaceMediaAction = React.lazy(
    () => import("@mediaspace/features/media-page/hero/converting/actions/replace-media/ReplaceMediaAction")
);

export const MediaCategory = React.lazy(
    () => import("@mediaspace/features/media-page/media-metadata/category/MediaCategory")
);

export const CustomMetadataLine = React.lazy(
    () => import("@mediaspace/features/media-page/media-metadata/custom-metadata/CustomMetadataLine")
);

export const MediaMetadata = React.lazy(
    () => import("@mediaspace/features/media-page/media-metadata/custom-metadata/MediaMetadata")
);

export const SchedulingError = React.lazy(
    () => import("@mediaspace/features/media-page/scheduling-error/SchedulingError")
);

export const PublishingSchedule = React.lazy(
    () => import("@mediaspace/features/media-page/media-metadata/publishing-schedule/PublishingSchedule")
);

export const PlayerNotSupported = React.lazy(
    () => import("@mediaspace/features/media-page/title-area/alerts/player-not-supported/PlayerNotSupported")
);

export const ContentPolicy = React.lazy(
    () => import("@mediaspace/features/media-page/media-metadata/content-policy/ContentPolicy")
);

export const Share = React.lazy(() => import("@mediaspace/features/media-page/share/Share"));

export const AdImages = React.lazy(
    () => import("@mediaspace/features/media-page/media-module-data/ad-images/AdImages")
);

export const ChannelTvNotifications = React.lazy(() => import("@kaltura/mediaspace-shared-channel-tv"));

// render the scheduling error page
export const renderSchedulingError = getDsLayoutComponentRenderer(SchedulingError);

export const render = getDsLayoutComponentRenderer(MediaPage);
