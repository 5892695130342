import { useEffect, useState } from "react";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { Label, StyledBox, StyledProgressIcon, StyledSeparator, Spinner } from "./StyleComponents";
import { UploadStatus } from "@kaltura/mediaspace-shared-upload";
import { MediaUploadFileInfo } from "@kaltura/mediaspace-shared-upload";

const SEPARATOR_SYMBOL = "\u2022";

type StatusData = {
    label: string;
    count: number;
    isError?: boolean;
};

const Status = ({ files }: { files: MediaUploadFileInfo[] }) => {
    const [statuses, setStatuses] = useState<StatusData[]>([]);
    const [showProgressIcon, setShowProgressIcon] = useState(false);

    useEffect(() => {
        let inQueue = 0;
        let uploading = 0;
        let processing = 0;
        let ready = 0;
        let canceled = 0;
        let error = 0;

        files.forEach((file) => {
            switch (file.fileMetaData.status) {
                case UploadStatus.IN_QUEUE: {
                    inQueue++;
                    break;
                }
                case UploadStatus.UPLOADING: {
                    uploading++;
                    break;
                }
                case UploadStatus.PROCESSING: {
                    processing++;
                    break;
                }
                case UploadStatus.READY: {
                    ready++;
                    break;
                }
                case UploadStatus.CANCELED: {
                    canceled++;
                    break;
                }
                case UploadStatus.ENTRY_FAILED:
                case UploadStatus.UPLOAD_FAILED: {
                    error++;
                    break;
                }
            }
        });

        setStatuses(
            [
                { label: "%1 In Queue", count: inQueue },
                { label: "%1 Uploading", count: uploading },
                { label: "%1 Processing", count: processing },
                { label: "%1 Ready", count: ready },
                { label: "%1 Canceled", count: canceled },
                { label: "%1 Failed", count: error, isError: true },
            ].filter((status) => status.count > 0)
        );

        setShowProgressIcon(!!uploading || !!processing);
    }, [files]);

    return !statuses.length ? null : (
        <StyledBox>
            {showProgressIcon && <StyledProgressIcon>{Spinner}</StyledProgressIcon>}
            {statuses.map((status, index) => {
                return (
                    <Box key={status.label}>
                        {!!index && <StyledSeparator>{SEPARATOR_SYMBOL}</StyledSeparator>}
                        <Label variant={"body2Highlight"} error={status.isError}>
                            {translate(status.label, [status.count])}
                        </Label>
                    </Box>
                );
            })}
        </StyledBox>
    );
};

export default Status;
