import styled from "@emotion/styled";
import { Modal } from "@kaltura/ds-react-components";

export const StyledContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
}));

export const StyledModal = styled(Modal)(({ theme }) => ({
    "& .kms-ds-set-collaborators-modal": {
        [theme.breakpoints.up("sm")]: {
            width: 584,
            height: 335,
        },
    },
}));
