import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";

export const Statistics = styled(Box)({
    display: "flex",
    alignItems: "center",
    flexWrap: "wrap",
});

export const Separator = styled("div")(({ theme }) => ({
    borderRadius: "50%",
    backgroundColor: theme.kaltura.palette.tone1,
    width: 5,
    height: 5,
    margin: theme.spacing(0, 1),
}));
