import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import MoreBadges from "./MoreBadges";
import BadgeContainer from "./BadgeContainer";
import { BadgeProps } from "../../common/types";

export type BadgesProps = {
    items: BadgeProps[];
};

const StyledBadgesContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

export const Badges = (props: BadgesProps) => {
    const { items } = props;
    const itemsToShow = items.slice(0, 5);
    const extraItems = items.slice(6);

    return (
        <StyledBadgesContainer>
            {itemsToShow.length > 0 && <BadgeContainer badge={itemsToShow[0]} showBorder={true} />}
            {itemsToShow
                .slice(1)
                .map((item) => (
                    <BadgeContainer badge={item} offset={true} showBorder={true} />
                ))}
            {extraItems.length > 0 && <MoreBadges count={extraItems.length} />}
        </StyledBadgesContainer>
    );
};
