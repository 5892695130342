import EarnedBadges from "../earned-badges/EearnedBadges";
import BadgesToEarn from "../badges-to-earn/BadgesToEarn";
import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { BadgesTabProps } from "../../common/types";

const StyledMenuContent = styled(Box)(({ theme }) => ({
    padding: theme.spacing(2, 4),
}));

const Separator = styled(Box)(({ theme }) => ({
    height: theme.spacing(3),
}));

/**
 * site Header user-badges menu component
 */
const MenuContent = (props: BadgesTabProps) => {
    const { earnedBadges, badgesToEarn } = props;

    return (
        <StyledMenuContent className={"kms-ds-header-menu-badges-menu-content"}>
            {earnedBadges.length > 0 && <EarnedBadges badges={earnedBadges} />}
            {earnedBadges.length > 0 && badgesToEarn.length > 0 && <Separator />}
            {badgesToEarn.length > 0 && <BadgesToEarn badges={badgesToEarn} />}
        </StyledMenuContent>
    );
};

export default MenuContent;
