import { StyledIllustration } from "@kaltura/mediaspace-shared-ui";

export const CaptureIllustration = () => (
    <StyledIllustration
        fileName={"AddNewCapture.svg"}
        width="201"
        height="200"
        viewBox="0 0 201 200"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M46.3879 77.5459C46.3879 73.1276 49.9696 69.5459 54.3879 69.5459H143.497C147.916 69.5459 151.497 73.1276 151.497 77.5459V137.393C151.497 138.822 151.689 140.244 152.066 141.622L156.341 157.219C157.736 162.31 153.905 167.334 148.625 167.334H51.1767C46.2292 167.334 42.4678 162.888 43.2872 158.009L46.1669 140.862C46.3139 139.986 46.3879 139.1 46.3879 138.212V77.5459Z"
            fill="#006EFA"
        />
        <rect x="52.3877" y="75.334" width="93.3333" height="60" rx="4" className="Tone1" fill="#333333" />
        <path d="M98.0542 146H139.054" stroke="white" stroke-width="2" stroke-linecap="round" />
        <path d="M58.7212 155.334H142.388" stroke="white" stroke-width="2" stroke-linecap="round" />
        <rect
            x="76.5596"
            y="52.6592"
            width="93.3333"
            height="67.3724"
            rx="8"
            transform="rotate(4.7848 76.5596 52.6592)"
            fill="#FFD357"
        />
        <path d="M100.166 122.355L114.905 63.9428L135.5 65.6666L129.399 124.746L100.166 122.355Z" fill="#FF9DFF" />
        <path
            d="M140.009 32.2889C143.083 27.5287 148.767 32.0186 146.373 36.1667C143.98 40.3147 142.828 42.5599 139.254 49.2852C146.643 56.928 141.064 65.4343 134.597 68.9653C125.451 73.9581 112.007 71.0884 110.822 57.2748C110.545 54.0422 110.152 47.659 113.069 46.4244C115.496 45.397 117.054 46.758 117.443 47.8679C117.054 46.758 117.611 44.2115 119.99 43.6586C122.369 43.1057 123.446 44.5479 124.133 46.1199C123.838 41.6392 122.899 36.8777 122.735 30.843C122.597 25.7458 129.07 24.6187 129.823 30.0983C130.287 33.4821 131.263 41.9263 132.104 46.7872C134.132 43.6244 137.801 35.7074 140.009 32.2889Z"
            fill="#FF9DFF"
        />
        <path
            d="M129.016 62.6959C125.717 61.9438 119.442 59.5464 120.734 55.9736C121.643 53.4596 128.239 55.0929 134.237 57.274"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            d="M125.081 50.7744C125.081 50.7744 126.189 52.3725 126.131 54.2073"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            d="M119.655 51.6586C122.094 58.3635 119.338 60.1482 117.236 60.5756"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            d="M161.252 77.1914L161.809 70.548C161.993 68.3466 160.358 66.4126 158.156 66.2283L151.513 65.6722"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            d="M147.231 119.493L153.874 120.049C156.076 120.234 158.01 118.598 158.194 116.397L158.75 109.753"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            d="M79.0288 103.08L78.4727 109.723C78.2884 111.925 79.9237 113.859 82.1251 114.043L88.7686 114.599"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <path
            d="M93.0508 60.7783L86.4074 60.2222C84.2059 60.038 82.2719 61.6732 82.0877 63.8746L81.5316 70.5181"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
        />
        <rect
            x="57.0264"
            y="82.8242"
            width="38.6667"
            height="16.6667"
            rx="8.33333"
            transform="rotate(-8 57.0264 82.8242)"
            fill="#FF3D23"
        />
        <ellipse
            cx="66.8151"
            cy="90.2003"
            rx="3.33333"
            ry="3.33333"
            transform="rotate(-8 66.8151 90.2003)"
            fill="white"
        />
        <path
            d="M82.8968 87.5705C82.6496 85.8111 83.8322 84.2621 85.5658 84.0184C86.0893 83.9449 86.6407 84.0074 87.1501 84.2071L87.3455 85.5975C86.8424 85.3181 86.3439 85.1956 85.786 85.274C84.739 85.4212 84.0811 86.345 84.2271 87.3835C84.3706 88.4048 85.2245 89.125 86.3059 88.973C86.8551 88.8958 87.3018 88.6492 87.7268 88.2482L87.921 89.6299C87.4385 89.9953 86.9049 90.1841 86.4243 90.2516C84.6048 90.5073 83.1477 89.3556 82.8968 87.5705Z"
            fill="white"
        />
        <path
            d="M79.1016 91.1668L78.2573 85.1592L81.7932 84.6622L81.9573 85.8294L79.7087 86.1455L79.88 87.3641L81.9312 87.0759L82.0904 88.2087L80.0392 88.497L80.225 89.8187L82.4907 89.5003L82.6547 90.6675L79.1016 91.1668Z"
            fill="white"
        />
        <path
            d="M74.7328 89.54L75.0415 91.7371L73.7628 91.9168L72.9185 85.9092L74.9181 85.6281C76.2141 85.446 77.2129 86.0145 77.3854 87.2418C77.506 88.1 77.0755 88.7732 76.3932 89.1491L78.3801 91.2679L76.861 91.4814L75.0761 89.4918L74.7328 89.54ZM74.3601 86.8881L74.5832 88.4758L75.3127 88.3733C75.8276 88.3009 76.163 87.9475 76.0931 87.4497C76.0219 86.9433 75.6045 86.7132 75.0896 86.7856L74.3601 86.8881Z"
            fill="white"
        />
        <rect
            x="15.4834"
            y="52.5049"
            width="21.3333"
            height="21.3333"
            rx="10.6667"
            transform="rotate(-15 15.4834 52.5049)"
            fill="#FF9DFF"
        />
        <path
            d="M25.0456 62.5866L28.2035 64.4098L32.5662 55.2327"
            stroke="#333333"
            stroke-width="2"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
        <circle cx="60.5076" cy="40.0866" r="12.5685" transform="rotate(-20.3899 60.5076 40.0866)" fill="#006EFA" />
        <path
            d="M64.8223 37.5849C65.9376 37.8838 66.3112 39.278 65.4947 40.0945L60.5168 45.0725C59.7003 45.8889 58.3061 45.5154 58.0072 44.4L56.1851 37.6C55.8863 36.4846 56.9069 35.464 58.0223 35.7629L64.8223 37.5849Z"
            fill="white"
        />
    </StyledIllustration>
);
