import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";

export const StyledBulkActionsRow = styled("div")(({ theme }) => ({
    display: "flex",
    padding: theme.spacing(2, 3),
    columnGap: theme.spacing(2),
    alignItems: "center",
    width: "100%",
    borderBottom: "1px solid",
    borderColor: theme.kaltura.palette.tone4,
}));

export const StyledLabel = styled.div`
    flex: 1 1 auto;
    display: flex;
    min-width: 0;
`;

export const StyledText = styled(Typography)({
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
});
