function LinkedinSocialLink() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M18 19H15.1268V14.43C15.1268 13.2338 14.6774 12.4172 13.6891 12.4172C12.9331 12.4172 12.5128 12.9682 12.3171 13.4993C12.2437 13.69 12.2552 13.9555 12.2552 14.221V19H9.40875C9.40875 19 9.44544 10.9047 9.40875 10.1688H12.2552V11.5548C12.4233 10.9488 13.3329 10.0839 14.7844 10.0839C16.5852 10.0839 18 11.3542 18 14.0895V19ZM6.53022 9.06416H6.51188C5.59466 9.06416 5 8.38906 5 7.53333C5 6.66095 5.61224 6 6.5478 6C7.4826 6 8.05738 6.65928 8.07573 7.53083C8.07573 8.38657 7.4826 9.06416 6.53022 9.06416ZM5.3279 19H7.86171V10.1688H5.3279V19Z"
                fill="white"
            />
        </svg>
    );
}

export default LinkedinSocialLink;
