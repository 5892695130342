const loadPlayerLib = (scriptUrl: string, onSuccess: () => void) => {
    // remove traces of possible previous lib
    (window as any).KalturaPlayer = undefined;
    let script = document.getElementById("playerLibLoader");
    script?.parentNode?.removeChild(script);

    // load new lib
    script = document.createElement("script");
    script.setAttribute("id", "playerLibLoader");
    script.setAttribute("src", scriptUrl);
    document.head.appendChild(script);

    // check if ready
    const intervalID = setInterval(
        () => {
            if ((window as any).KalturaPlayer) {
                clearInterval(intervalID);
                onSuccess();
            }
        },
        50
    );
};

export default loadPlayerLib;
