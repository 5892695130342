import StyledIllustration from "./StyledIllustration";

export const PasswordIllustration = (
    <StyledIllustration
        fileName={"Password.svg"}
        width="300"
        height="300"
        viewBox="0 0 300 300"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <rect
            x="74.4703"
            y="59.5825"
            width="72"
            height="145"
            rx="36"
            transform="rotate(-8 74.4703 59.5825)"
            className="sTone1"
            strokeWidth="2"
        />
        <rect
            x="70.1792"
            y="169.314"
            width="110"
            height="86"
            rx="8"
            transform="rotate(-8 70.1792 169.314)"
            className="Tone1"
        />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M135.045 209.939C137.554 208.294 139.029 205.298 138.584 202.13C137.969 197.755 133.924 194.707 129.548 195.322C125.173 195.937 122.125 199.982 122.74 204.357C123.185 207.525 125.429 209.998 128.294 210.887L126.498 222.904C126.202 224.887 127.897 226.597 129.883 226.318L137.771 225.209C139.756 224.93 140.914 222.819 140.083 220.994L135.045 209.939Z"
            className="Tone8"
        />
        <rect x="72.406" y="185.158" width="110" height="2" transform="rotate(-8 72.406 185.158)" className="Tone8" />
        <rect x="127.501" y="85.3799" width="96" height="20" rx="10" className="Tone8 sTone1" strokeWidth="2" />
        <circle cx="138.501" cy="95.3799" r="3" className="Tone1" />
        <circle cx="150.501" cy="95.3799" r="3" className="Tone1" />
        <circle cx="162.501" cy="95.3799" r="3" className="Tone1" />
        <circle cx="174.501" cy="95.3799" r="3" className="Tone1" />
        <circle cx="186.501" cy="95.3799" r="3" className="Tone1" />
        <rect x="127.501" y="117.38" width="68" height="20" rx="10" className="Tone8 sTone1" strokeWidth="2" />
        <rect x="135.501" y="123.38" width="2" height="8" rx="1" className="Tone1" />
        <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M197.501 174.88C197.501 161.901 208.022 151.38 221.001 151.38C233.98 151.38 244.501 161.901 244.501 174.88C244.501 187.859 233.98 198.38 221.001 198.38C208.022 198.38 197.501 187.859 197.501 174.88Z"
            className="Primary"
        />
        <path
            d="M210.557 176.021L218.752 184.019L234.057 167.047"
            className="sTone8"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
        />
    </StyledIllustration>
);
