import styled from "@emotion/styled";
import { useTheme } from "@mui/material";
import { Skeleton } from "@kaltura/mediaspace-shared-styled";

const StyledSkeletonRow = styled("div")(({ theme }) => ({
    marginTop: theme.spacing(2),
}));

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    display: "inline-block",
    borderRadius: theme.kaltura.shape.roundness1,
    marginRight: theme.spacing(2),
}));

/**
 * publish modal category search skeleton
 */
export function CategorySearchSkeleton() {
    const theme = useTheme();

    return (
        <>
            <StyledSkeletonRow>
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"50%"} />
            </StyledSkeletonRow>
            <StyledSkeletonRow>
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"50%"} />
            </StyledSkeletonRow>

            <StyledSkeletonRow>
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"25%"} />
            </StyledSkeletonRow>

            <StyledSkeletonRow>
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={theme.spacing(2)} />
                <StyledSkeleton variant="rectangular" height={theme.spacing(2)} width={"35%"} />
            </StyledSkeletonRow>
        </>
    );
}
