import styled from "@emotion/styled";

/**
 * styled component that wraps SVGs with theme values.
 * `theme` is passed from the emotion mechanism.
 * `fileName` is only there for dev to have some tracking of the origin file, it is not used.
 */
export const StyledIllustration = styled(
    "svg",
    {
        shouldForwardProp: (prop) => prop !== "fileName",
    }
)<{ fileName: string }>(({ theme }) => ({
    // simple class names = control fill
    ".Tone1 path, path.Tone1, ellipse.Tone1, rect.Tone1, circle.Tone1": {
        fill: theme.kaltura.palette.tone1,
    },
    ".Tone2 path, path.Tone2, ellipse.Tone2, rect.Tone2, circle.Tone2": {
        fill: theme.kaltura.palette.tone2,
    },
    ".Tone3 path, path.Tone3, ellipse.Tone3, rect.Tone3, circle.Tone3": {
        fill: theme.kaltura.palette.tone3,
    },
    ".Tone4 path, path.Tone4, ellipse.Tone4, rect.Tone4, circle.Tone4": {
        fill: theme.kaltura.palette.tone4,
    },
    ".Tone5 path, path.Tone5, ellipse.Tone5, rect.Tone5, circle.Tone5": {
        fill: theme.kaltura.palette.tone5,
    },
    ".Tone6 path, path.Tone6, ellipse.Tone6, rect.Tone6, circle.Tone6": {
        fill: theme.kaltura.palette.tone6,
    },
    ".Tone7 path, path.Tone7, ellipse.Tone7, rect.Tone7, circle.Tone7": {
        fill: theme.kaltura.palette.tone7,
    },
    ".Tone8 path, path.Tone8, ellipse.Tone8, rect.Tone8, circle.Tone8": {
        fill: theme.kaltura.palette.tone8,
    },
    ".Primary path, path.Primary, ellipse.Primary, rect.Primary, circle.Primary": {
        fill: theme.kaltura.palette.primary.main,
    },
    ".Danger path, path.Danger, ellipse.Danger, rect.Danger": {
        fill: theme.kaltura.palette.danger.main,
    },
    ".Warning path, path.Warning, ellipse.Warning, rect.Warning": {
        fill: theme.kaltura.palette.warning.main,
    },
    ".Success path": {
        fill: theme.kaltura.palette.success.main,
    },
    ".PrimaryTextContrast": {
        fill: theme.kaltura.palette.primary.contrastText,
    },

    // "s" class names - control stroke
    ".sTone1 path, path.sTone1, ellipse.sTone1, rect.sTone1, circle.sTone1": {
        stroke: theme.kaltura.palette.tone1,
    },
    ".sTone2 path, path.sTone2, ellipse.sTone2, rect.sTone2, circle.sTone2": {
        stroke: theme.kaltura.palette.tone2,
    },
    ".sTone3 path, path.sTone3, ellipse.sTone3, rect.sTone3, circle.sTone3": {
        stroke: theme.kaltura.palette.tone3,
    },
    ".sTone4 path, path.sTone4, ellipse.sTone4, rect.sTone4, circle.sTone4": {
        stroke: theme.kaltura.palette.tone4,
    },
    ".sTone5 path, path.sTone5, ellipse.sTone5, rect.sTone5, circle.sTone5": {
        stroke: theme.kaltura.palette.tone5,
    },
    ".sTone6 path, path.sTone6, ellipse.sTone6, rect.sTone6, circle.sTone6": {
        stroke: theme.kaltura.palette.tone6,
    },
    ".sTone7 path, path.sTone7, ellipse.sTone7, rect.sTone7, circle.sTone7": {
        stroke: theme.kaltura.palette.tone7,
    },
    ".sTone8 path, path.sTone8, ellipse.sTone8, rect.sTone8, circle.sTone8": {
        stroke: theme.kaltura.palette.tone8,
    },
    ".sPrimary path, path.sPrimary, ellipse.sPrimary, rect.sPrimary, circle.sPrimary": {
        stroke: theme.kaltura.palette.primary.main,
    },
    ".sDanger path, path.sDanger, ellipse.sDanger, rect.sDanger": {
        stroke: theme.kaltura.palette.danger.main,
    },
    ".sWarning path, path.sWarning, ellipse.sWarning, rect.sWarning": {
        stroke: theme.kaltura.palette.warning.main,
    },
    ".sSuccess path": {
        stroke: theme.kaltura.palette.success.main,
    },
    ".sPrimaryTextContrast": {
        stroke: theme.kaltura.palette.primary.contrastText,
    },
}));

export default StyledIllustration;
