/**
 *
 */
export default class ServerTimeHelper {
    /**
     * get server time in ms (server returns seconds, we convert to ms)
     * @param serviceUrl
     */
    static getServerTime(serviceUrl?: string): Promise<number> {
        if (!serviceUrl) {
            return Promise.reject("invalid URL");
        }
        const myRequest = new Request(serviceUrl + "/api_v3/service/system/action/ping");
        // ping API server and return date header value (ping rather than getTime because result is cached on API)
        return fetch(myRequest).then(function (response: Response) {
            if (!response.ok) {
                return Promise.reject(response);
            }
            const dateHeader = response.headers.get("date");
            if (!dateHeader) {
                return Promise.reject(response);
            }
            return Promise.resolve(new Date(dateHeader).getTime());
        });
    }

    private static timeDiff = 0;

    /**
     * sets the time used to calculate diff between server and local time
     * @param serverTime
     */
    static setTimeForDiff(serverTime: number) {
        ServerTimeHelper.timeDiff = Date.now() - serverTime;
    }

    /**
     * gets the time adjusted per initial server time
     */
    static getUpdatedTime(): number {
        return Date.now() - ServerTimeHelper.timeDiff;
    }
}
