function FacebookSocialLink() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8 9.63017H9.5003V8.22342C9.5003 7.60278 9.51651 6.64628 9.98306 6.05362C10.4752 5.42639 11.1499 5 12.3116 5C14.2034 5 15 5.26011 15 5.26011L14.6256 7.4044C14.6256 7.4044 14.0004 7.23072 13.417 7.23072C12.8336 7.23072 12.3116 7.43238 12.3116 7.99459V9.63017H14.7032L14.536 11.7242H12.3116V19H9.5003V11.7242H8V9.63017Z"
                fill="white"
            />
        </svg>
    );
}

export default FacebookSocialLink;
