import styled from "@emotion/styled";
import { MenuItem, menuItemClasses } from "@kaltura/ds-react-components";
import { baseUrl } from "@kaltura/mediaspace-shared-utils";
import { KafActionProps } from "../KafAction";

const StyledMenuItem = styled(MenuItem)(({ theme }) => ({
    [`& .${menuItemClasses.title}`]: {
        display: "flex",
        gap: theme.spacing(0.5),
        alignItems: "center",
    },
}));

/**
 * KafMenuLinkAction component - renders a dropdown menu item
 */
const KafMenuLinkAction = (props: KafActionProps) => {
    const { label, href, icon } = props;
    const { component: IconComponent } = icon;

    const onMenuItemClick = () => {
        window.location.href = `${baseUrl}/${href}`;
    };

    return (
        <StyledMenuItem onClick={onMenuItemClick}>
            <IconComponent />
            {label}
        </StyledMenuItem>
    );
};

export default KafMenuLinkAction;
