import { forwardRef } from "react";
import { Drawer as MUIDrawer, DrawerProps as MUIDrawerProps, useMediaQuery } from "@mui/material";
import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { Button } from "@kaltura/ds-react-components";
import { Close16Icon } from "@kaltura/ds-react-icons";
import { alpha } from "@kaltura/mediaspace-shared-styled";
import { translate } from "@kaltura/mediaspace-shared-utils";

export interface DrawerProps extends MUIDrawerProps {
    open: boolean;
    onClose: () => void;
    maxWidth: number;
    backgroundVariant?: "regular" | "translucent";
    contentContainerVariant?: "wide" | "narrow";
}

export const drawerClassName = "kms-ds-drawer-root";

const StyledContainer = styled(
    "div",
    {
        shouldForwardProp(propName: PropertyKey) {
            return propName !== "maxWidth" && propName !== "variant";
        },
    }
)<{ maxWidth: number; variant: "wide" | "narrow" }>(({ theme, maxWidth, variant }) => {
    const isWide = variant === "wide";
    return {
        position: "relative",
        width: "100vw",
        height: "100vh",
        overflowY: "auto",
        boxSizing: "border-box",
        padding: `${isWide ? theme.spacing(4) : theme.spacing(3)} ${theme.spacing(2)}`,
        [theme.breakpoints.up("sm")]: {
            maxWidth,
            width: `calc(100vw - ${theme.spacing(8)})`,
            padding: `${isWide ? theme.spacing(4) : theme.spacing(3)} ${theme.spacing(5)}`,
        },
    };
});

const StyledCloseButton = styled(Button)(({ theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    backgroundColor: theme.kaltura.palette.translucent.dark,
    color: theme.kaltura.palette.translucent.light,
    [theme.breakpoints.up("sm")]: {
        top: theme.spacing(2),
        right: `calc(100% + ${theme.spacing(2)})`,
    },
    zIndex: 1,
}));

const StyledTranslucentCloseButton = styled(Button)(({ theme }) => ({
    position: "absolute",
    top: theme.spacing(2),
    right: `calc(100% - ${theme.spacing(6)})`,
    zIndex: 1,
}));

const StyledDrawer = styled(MUIDrawer)(({ theme }) => ({
    zIndex: theme.zIndex.modal,
}));

/**.
 * a MUI Drawer component with a close button
 */
export const Drawer = forwardRef<HTMLDivElement, DrawerProps>((props: DrawerProps, ref) => {
    const {
        open,
        onClose,
        maxWidth,
        children,
        PaperProps,
        slotProps,
        backgroundVariant = "regular",
        contentContainerVariant = "wide",
    } = props;
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
    const isTranslucent = backgroundVariant === "translucent";
    const backgroundVariantStyles = {
        regular: {
            background: theme.kaltura.palette.surfaces.background,
        },
        translucent: {
            background: alpha(theme.kaltura.palette.translucent.commonBlack, theme.kaltura.palette.backdrop.opacity),
            backdropFilter: "blur(48px)",
            color: theme.palette.common.white,
        },
    };

    const closeButton = !isTranslucent ? (
        <StyledCloseButton variant={"borderless"} onClick={onClose} aria-label={translate("close")}>
            <Close16Icon />
        </StyledCloseButton>
    ) : (
        <StyledTranslucentCloseButton
            variant={"borderless"}
            onClick={onClose}
            color={"translucent"}
            aria-label={translate("close")}
        >
            <Close16Icon />
        </StyledTranslucentCloseButton>
    );

    return (
        <StyledDrawer
            ref={ref}
            {...props}
            className={drawerClassName}
            open={open}
            onClose={onClose}
            anchor={"right"}
            PaperProps={{
                ...PaperProps,
                sx: {
                    overflow: "visible",
                    ...backgroundVariantStyles[backgroundVariant],
                    ...(typeof PaperProps?.sx === "function" ? PaperProps?.sx(theme) : PaperProps?.sx),
                },
            }}
            slotProps={{
                ...slotProps,
                backdrop: {
                    ...slotProps?.backdrop,
                    sx: [
                        isTranslucent && {
                            background: "rgba(0, 0, 0, 0)",
                        },
                        (slotProps?.backdrop as any)?.sx,
                    ],
                },
            }}
        >
            {/* On desktop(only when not using translucent background), the close button should be outside the internal modal component*/}
            {!isMobile && !isTranslucent && closeButton}

            <StyledContainer maxWidth={maxWidth} variant={contentContainerVariant}>
                {/* On mobile or when has translucent background, the close button should be under the vertical scroller of the modal content */}
                {(isMobile || isTranslucent) && closeButton}

                {children}
            </StyledContainer>
        </StyledDrawer>
    );
});
