import styled from "@emotion/styled";
import { Divider as MuiDivider, DividerProps as MuiDividerProps } from "@mui/material";

/* eslint-disable-next-line */
export interface DividerProps extends MuiDividerProps {}

const StyledDivider = styled(MuiDivider)(({ theme }) => ({}));

/**
 * a KUIK wrapper for Mui's Divider component
 */
export function Divider(props: DividerProps) {
    return <StyledDivider {...props} />;
}

export default Divider;
