import { Button } from "@kaltura/ds-react-components";
import { KafActionProps } from "../KafAction";

/**
 * KafButtonLinkAction component - renders a link that looks like a button
 */
const KafButtonLinkAction = (props: KafActionProps) => {
    const { label, href, icon } = props;
    const { component: IconComponent } = icon;

    return (
        <Button variant={"borderless"} color={"secondary"} component={"a"} href={href} startIcon={<IconComponent />}>
            {label}
        </Button>
    );
};

export default KafButtonLinkAction;
