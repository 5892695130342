import { CategoryBubble, CategoryBubbleType } from "./CategoryBubble";
import TruncateMarkup from "react-truncate-markup";
import { useContext, useEffect, useRef } from "react";
// noinspection ES6PreferShortImport
import { CategoryLinkAnalyticsContext } from "../CategoryLinkAnalyticsContext";
import styled from "@emotion/styled";
import { IconButton } from "@kaltura/ds-react-components";

/**
 * CategoryBubbles component displays a list of category bubbles
 * with optional truncation and deletion functionality.
 */
export interface CategoryBubblesProps {
    /**
     * list of categories to show
     */
    categories: CategoryBubbleType[];
    isTruncated: boolean;
    onDelete?: (categoryId: number) => void;
    onExpand?: () => void;
    onBubblesLineCountChange?: (isSingleLine: boolean) => void;
}

export const CategoryBubbles = ({
    categories,
    isTruncated,
    onDelete,
    onExpand,
    onBubblesLineCountChange,
}: CategoryBubblesProps) => {
    const visibleCategories = useRef(0);

    const { sendCategoryLinkAnalytics, sendCategoryBubbleAnalytics } = useContext(CategoryLinkAnalyticsContext);
    const handleDelete = (category: CategoryBubbleType) => {
        sendCategoryBubbleAnalytics();
        onDelete?.(category.id);
    };

    const handleExpand = () => {
        onExpand?.();
    };

    /*
    use truncate lib's ellipsis function to check how many categories
    fit in the first line with the open/close arrow.
    when opened, add a new div with the additional categories below
    the first line - not in the same container - this way the
    flex layout will spread them properly.
     */
    const tagsLeftEllipsis = (node: any) => {
        const displayedCategories = node.props.children;
        visibleCategories.current = displayedCategories ? displayedCategories.length : 0;

        // calling setState here causes endless render loop, so using ref instead
        return visibleCategories.current > 0 && isTruncated ? (
            <ExpandButton variant={"borderless"} onClick={handleExpand}>
                {`+${categories.length - visibleCategories.current}`}
            </ExpandButton>
        ) : (
            <span />
        );
    };

    useEffect(() => {
        if (!onBubblesLineCountChange) return;
        if (categories.length < visibleCategories.current) {
            onBubblesLineCountChange(true);
        }
        else {
            onBubblesLineCountChange(false);
        }
    }, [categories, onBubblesLineCountChange]);

    const bubbles = categories.map((category, ind) => {
        const content = (
            <CategoryBubble
                key={category.id}
                category={category}
                onClick={category.url ? sendCategoryLinkAnalytics : undefined}
                onDelete={onDelete ? () => handleDelete(category) : undefined}
            />
        );

        return isTruncated ? <TruncateMarkup.Atom key={`cat_${ind}`}>{content}</TruncateMarkup.Atom> : content;
    });

    return (
        <BubblesContainer>
            {isTruncated ? (
                <BubblesLine>
                    <TruncateMarkup lines={1} ellipsis={tagsLeftEllipsis}>
                        <span>{bubbles}</span>
                    </TruncateMarkup>
                </BubblesLine>
            ) : (
                bubbles
            )}
        </BubblesContainer>
    );
};

const BubblesContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    rowGap: theme.spacing(1),
}));

const BubblesLine = styled("div")(({ theme }) => ({
    display: "inline-flex",
    gap: theme.spacing(1),
    wordBreak: "initial",
}));

const ExpandButton = styled(IconButton)(({ theme }) => ({
    height: theme.spacing(3),
    width: theme.spacing(3),
    color: "inherit",
    backgroundColor: theme.kaltura.palette.tone7,
    position: "relative",
    top: 1,
}));
