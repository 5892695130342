import styled from "@emotion/styled";
import Box from "@mui/material/Box";

// This component intended usage is to wrap top level components so they will have:
// some margin between them and the footer - in KMS
// maxWidth 1400px - in KAF
export const FeatureContainer = styled(
    Box,
    {
        shouldForwardProp(propName) {
            return propName !== "isKaf";
        },
    }
)<{ isKaf?: boolean }>(({ theme, isKaf }) => ({
    ...(
        !isKaf && {
            marginBottom: theme.spacing(7.5),
            [theme.breakpoints.down(theme.breakpoints.values.md)]: {
                marginBottom: theme.spacing(5),
            },
        }
    ),
    ...(
        isKaf && {
            maxWidth: 1400,
            width: "100%",
            margin: "0 auto",
        }
    ),
}));
