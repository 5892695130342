function TwitchSocialLink() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M5.67166 4.6266L4 6.25329V12.0716V17.8901H5.98788H7.97576V19.445C7.97576 20.3003 8.00902 21 8.04955 21C8.09018 21 8.84247 20.3003 9.72121 19.445L11.319 17.8901H12.6298H13.9406L16.9703 14.9445L20 11.9989V7.49943V3H13.6717H7.34342L5.67166 4.6266ZM18.6424 7.82862V11.3379L17.3104 12.6349L15.9784 13.9319H14.6694H13.3604L12.1711 15.085L10.9818 16.238V15.085V13.9319H9.47879H7.97576V9.12565V4.31937H13.3091H18.6424V7.82862ZM11.6606 8.51742V10.4536L12.3152 10.4258L12.9697 10.3979L12.996 8.48953L13.0222 6.58115H12.3414H11.6606V8.51742ZM15.2707 8.48953L15.297 10.3979L15.9515 10.4258L16.6061 10.4536V8.51742V6.58115H15.9252H15.2445L15.2707 8.48953Z"
                fill="white"
            />
        </svg>
    );
}

export default TwitchSocialLink;
