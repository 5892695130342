import { useDateFormat } from "@kaltura/mediaspace-shared-hooks";
import { ChevronRight16Icon } from "@kaltura/ds-react-icons";
import { KmsTypeMediaSchedulingData } from "@kaltura/mediaspace-shared-types";
import { Separator, Statistics } from "./StyleComponents";

/**
 * media title scheduling component
 */
export const MediaSchedule = (props: { schedulingData: KmsTypeMediaSchedulingData }) => {
    const { schedulingData } = props;
    const { from: startTime, to: endTime } = schedulingData;
    const { startDay, endDay, formattedScheduledStartTime, formattedScheduledEndTime } = useDateFormat(
        startTime,
        endTime
    );
    const baseClassName = "kms-ds-media-page-title-schedule";

    return (
        <Statistics className={baseClassName}>
            {startDay === endDay && (
                <>
                    {startDay}
                    <Separator className={`${baseClassName}-separator`} />
                    {formattedScheduledStartTime}&nbsp;-&nbsp;
                    {formattedScheduledEndTime}
                </>
            )}
            {startDay !== endDay && (
                <>
                    {`${startDay}, ${formattedScheduledStartTime}`}
                    &nbsp;
                    <ChevronRight16Icon />
                    &nbsp;
                    {`${endDay}, ${formattedScheduledEndTime}`}
                </>
            )}
        </Statistics>
    );
};
