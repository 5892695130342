import UploadService from "./lib/upload/UploadService";
import {
    FileMetaData,
    UploadFileInfo,
    UploadProps,
    StatusesMapper,
    UploadStatus,
    UploadServiceHandler,
    numberOfMaxChunksForSingleFile,
} from "./lib/upload/UploadServiceProps";
import {
    ShowOn,
    EntryMetadataStructure,
    MediaUploadFileInfo,
    EditFormElement,
    UploadMediaContainerProps,
    UploadMediaProps,
    DeleteMediaElement,
    ModalData,
} from "./lib/upload/UploadMediaProps";

export { StatusesMapper, UploadStatus, numberOfMaxChunksForSingleFile };
export type { FileMetaData, UploadFileInfo, UploadProps, UploadServiceHandler };
export default UploadService;

export type {
    ShowOn,
    EntryMetadataStructure,
    MediaUploadFileInfo,
    EditFormElement,
    UploadMediaContainerProps,
    UploadMediaProps,
    DeleteMediaElement,
    ModalData,
};
