import styled, { CSSObject } from "@emotion/styled";
import React, { useContext } from "react";
import { Locale } from "@kaltura/mediaspace-shared-types";
import { kmsCookie } from "@kaltura/mediaspace-shared-utils";
import { Button, buttonClasses, Menu, MenuItem, Tooltip } from "@kaltura/ds-react-components";
import { ChevronDown16Icon, Check16Icon } from "@kaltura/ds-react-icons";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { useHeaderTextStyle } from "../useHeaderTextStyle";

export interface LocaleMenuProps {
    locales: Locale[];
    onLocaleChange?: (locale: Locale) => void;
    currentLocale: Locale;
    disableScrollLock?: boolean;
}

const LocaleButton = styled(
    Button,
    {
        shouldForwardProp(propName: string) {
            return propName !== "headerTextStyle";
        },
    }
)<{ headerTextStyle: CSSObject }>(({ theme, headerTextStyle }) => ({
    backgroundColor: "initial",
    [`&:hover`]: {
        backgroundColor: "initial",
    },
    [`& .${buttonClasses.endIcon}`]: {
        margin: 0,
    },
    ...headerTextStyle,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        paddingLeft: theme.spacing(0.5), // - only on small screen
    },
}));

/**
 * Component to show locale selection
 */
export function LocaleMenu({ locales, onLocaleChange, currentLocale, disableScrollLock = false }: LocaleMenuProps) {
    const headerTextStyle = useHeaderTextStyle();

    const loadNewLocale = (locale: Locale) => {
        if (onLocaleChange) {
            onLocaleChange(locale);
            return;
        }
        createLocaleCookie(locale.id);
        // update url's lang param value according to selected language
        const currentUrl = window.location.href;
        const updatedUrl = currentUrl.replace(/(lang=)[^&]+/, "$1" + locale.id);
        // redirect to updated url
        window.location.href = updatedUrl;
    };

    const createLocaleCookie = (localeId: string) => {
        const date = new Date();
        // set cookie for 10 years
        date.setTime(date.getTime() + 10 * 365 * 24 * 60 * 60 * 1000);

        const options = {
            expires: date.toUTCString(),
            path: "/",
        };

        kmsCookie("kms-locale", localeId, options);
    };

    const sendButtonAnalytics = useButtonAnalytics();

    const handleLocaleSelected = (localeId: string) => {
        const locale = locales.find((value) => value.id === localeId);
        // we are bound to find the value, because we got it from that list to start with.
        // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
        loadNewLocale(locale!);
        sendButtonAnalytics(`Language Selected - ${locale?.code}`, ButtonClickAnalyticsType.CHOOSE);
    };

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);

    const handleButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        sendButtonAnalytics("Language - Menu", ButtonClickAnalyticsType.MENU);
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div className={"kms-ds-locale-menu"}>
            <Tooltip title={translate("Select Language")} placement={"bottom-start"}>
                <LocaleButton
                    color={"translucent"}
                    id="locale-button"
                    aria-controls={open ? "locale-menu" : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? "true" : undefined}
                    aria-label={translate("Select Language")}
                    onClick={handleButtonClick}
                    endIcon={<ChevronDown16Icon />}
                    headerTextStyle={headerTextStyle}
                >
                    {currentLocale.code.toUpperCase()}
                </LocaleButton>
            </Tooltip>
            <Menu
                id="locale-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleMenuClose}
                PaperProps={{
                    style: {
                        width: "200px",
                    },
                }}
                disableScrollLock={disableScrollLock}
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "right",
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                MenuListProps={{
                    "aria-labelledby": "locale-button",
                }}
            >
                {locales.map((locale) => {
                    return (
                        <MenuItem
                            key={locale.id}
                            value={locale.id}
                            onClick={() => handleLocaleSelected(locale.id)}
                            selected={currentLocale.id === locale.id}
                            aria-current={currentLocale.id === locale.id}
                            icon={currentLocale.id === locale.id && <Check16Icon />}
                        >
                            {locale.code.toUpperCase()} &nbsp;
                            {locale.clientName}
                        </MenuItem>
                    );
                })}
            </Menu>
        </div>
    );
}
