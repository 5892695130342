import { useEffect } from "react";
import { ServerTimeHelper } from "@kaltura/mediaspace-shared-utils";

/**
 * get the kaltura server time, and set it on the ServerTimeHelper.
 */
export function useKalturaServerTime(serviceUrl?: string) {
    useEffect(() => {
        // no service url => use local time
        if (!serviceUrl) {
            ServerTimeHelper.setTimeForDiff(Date.now());
            return;
        }

        // get server time
        ServerTimeHelper
            .getServerTime(serviceUrl)
            .then((value) => {
                ServerTimeHelper.setTimeForDiff(value);
            })
            .catch((err) => {
                console.info(">> failed getting system time, using local", err);
                ServerTimeHelper.setTimeForDiff(Date.now());
            });
    }, [serviceUrl]);

    return ServerTimeHelper.getUpdatedTime();
}
