/**
 *  stub functions that are defined in global.js or kms5main.js
 *  be aware that they might function differently in the unit tests and
 *  in the browser
 */
import { QueryParams } from "@kaltura/mediaspace-shared-types";
import { getKMSHeader, getMockData } from "./fetchData";
import { translate, translatePlural } from "./translate";

const gwindow: any = window as any;

const GLOBAL_KMS: any = gwindow.GLOBAL_KMS || {};
const KMS_GLOBAL: any = gwindow.KMS_GLOBAL || {};

/**
 * (private) generic error handler to handle general errors,
 * passes the error to a given error handler after processing
 * @param errorCallback
 */
const _transportError = (errorCallback?: (errstr: string) => void) => (data: Response) => {
    let title, errorDesc;

    if (data) {
        if (data.statusText === "abort") {
            return;
        }
        if (data.statusText === "timeout") {
            title = "Request timeout";
            errorDesc = "Request took too long to complete";
        }
        else if (data.status === 0 && data.statusText === "error") {
            // do nothing
        }
        else if (data.status === 405) {
            // Method Not Allowed, redirect to login
            document.location.href = baseUrl + "/user/login";
        }
        else {
            title = data.status !== 200 ? "Error : " + data.status : false;
            //TODO errorDesc = data.responseText;
        }
        if (!errorDesc) {
            errorDesc = "Looks like your session has expired - Please refresh the page";
        }

        if (title) {
            //TODO show error to the user
            // openAjaxErrorDialog(errorDesc, title);
        }
    }
    if (errorCallback) {
        errorCallback(title + ", " + errorDesc);
    }
};

/**
 * (private) helper for building request data
 * @param sp
 * @param o
 * @param prefix
 */
const _appendUrlSearchParams = (sp: URLSearchParams, o: any, prefix?: string) => {
    for (const key in o) {
        let effectiveKey = prefix ? `${prefix}[${key}]` : key;
        if (o[key] === null || o[key] === undefined) continue;
        if (Array.isArray(o[key])) {
            effectiveKey = `${effectiveKey}[]`;
            for (let i = 0; i < o[key].length; i++) {
                if (typeof o[key][i] === "object") {
                    sp = _appendUrlSearchParams(sp, o[key][i], effectiveKey);
                }
                else {
                    sp.append(effectiveKey, o[key][i]);
                }
            }
        }
        else if (typeof o[key] === "object") {
            sp = _appendUrlSearchParams(sp, o[key], effectiveKey);
        }
        else {
            sp.append(effectiveKey, o[key]);
        }
    }
    return sp;
};

/**
 * allow saving unique-ids of all calls between start recording action and end recording action.
 * see https://github.com/kaltura/mediaspace/pull/7287
 * @param response
 */
const recordResponse = (response: Response) => {
    try {
        // check if error recording is on
        let sessionData = localStorage.getItem("kmsSessionData");
        if (sessionData !== null) {
            const uniqueId = response.headers.get("kms-unique-id");
            const xme = response.headers.get("x-me");
            const urlWithoutDomain = window.location.href.replace(window.location.origin, "");
            sessionData += uniqueId + ";ajax;" + xme + ";" + urlWithoutDomain + ",";
            localStorage.setItem("kmsSessionData", sessionData);
        }
    }
    catch (e) {
        // safety to make sure recording code won't affect user experience.
    }
};

/**
 * get data from kms
 * @param url   kms action url
 * @param data  query data
 * @param successCallback   callback to execute when call succeeds. takes received data as parameter.
 * @param spin  unused in DS pages.
 * @param errorCallback callback to execute when call fails.
 */
const getDataFromKms = (
    url: string,
    data: QueryParams,
    successCallback: (data: any) => void,
    spin: boolean,
    errorCallback?: (errstr: string) => void
) => {
    // support for storybook mocks
    const mockData = getMockData(url, data);
    if (mockData) {
        console.log("returning mock data for " + url);
        successCallback(mockData);
        return;
    }

    // add the script param
    data["format"] = "script";

    const myData = _appendUrlSearchParams(new URLSearchParams(), data);
    const ctrl = new AbortController();
    const signal = ctrl.signal;

    const options = getKMSHeader();

    // Use the fetch API to send the POST request
    const promise = fetch(
        url,
        {
            method: "post",
            body: myData,
            signal: signal,
            ...options,
        }
    )
        .then(function (response) {
            // write uniquid to local storage if needed
            recordResponse(response);
            // report error
            if (!response.ok) {
                _transportError(errorCallback)(response);
            }
            return response.json(); // Parse the JSON response
        })
        .then(function (responseData) {
            // Call the success callback function with the parsed JSON data
            successCallback(responseData);
        })
        .catch(function (error) {
            // Call the error callback function if there is an error
            if (errorCallback) {
                errorCallback(error.message);
            }
        });
    return ctrl;
};

// send data to kms

function asyncCallback(data: any) {
    try {
        if (typeof data?.script === "string") {
            // eslint-disable-next-line no-eval
            eval(data.script);
        }
    }
    catch (e) {
        console.log(e);
    }
}

/**
 * send data to kms, ignoring the response.
 * @param url   kms action url
 * @param data  query data
 * @param spin  not relevant for DS, kept for BW compat.
 * @param errorCallback callback to trigger if the call fails
 */
function sendDataToKms(url: string, data: QueryParams, spin: boolean, errorCallback?: (errstr: string) => void) {
    // add default format
    data["format"] = data["format"] || "ajax";

    const myData = _appendUrlSearchParams(new URLSearchParams(), data);
    const ctrl = new AbortController();
    const signal = ctrl.signal;

    // Use the fetch API to send the POST request
    fetch(
        url,
        {
            method: "post",
            body: myData,
            signal: signal,
        }
    )
        .then(function (response) {
            if (!response.ok) {
                _transportError(errorCallback)(response);
            }
            return response.json(); // Parse the JSON response
        })
        .then(function (responseData) {
            // Call the success callback function with the parsed JSON data
            asyncCallback(responseData);
        })
        .catch(function (error) {
            // Call the error callback function if there is an error
            if (errorCallback) {
                errorCallback(error.message);
            }
        });
    return ctrl;
}

/**
 * send button click analytics events.
 * KMS catches these events and sends analytics beacons to kava
 */
const sendButtonClickAnalytics = (name: string, value?: string, type?: number, entryId?: string) => {
    document.dispatchEvent(
        new CustomEvent(
            "kmsButtonClickAnalytics",
            {
                detail: {
                    name: name,
                    value: value,
                    type: type,
                    entryId: entryId,
                },
            }
        )
    );
};

// kms base url
const baseUrl = gwindow.baseUrl || "";

const cdnUrl = gwindow.cdnUrl || "";

const serverUrl = KMS_GLOBAL.serverUrl || "";

const moduleAssetUrlTemplate = KMS_GLOBAL.moduleAssetUrlTemplate || baseUrl + "/{MODULE}/{FILE_NAME}";
const coreAssetUrlTemplate = KMS_GLOBAL.coreAssetUrlTemplate || baseUrl + "{FILE_NAME}";

/**
 * do what KMS server does for entry names
 * @see Kms_View_Helper_EntryLink::sanitizeParamForUrl
 */
const sanitizeParamForUrl = (str: string) => {
    str = str.replace(/\//g, "_"); // remove "/"
    str = str.replace(/\n/g, ""); // remove new lines
    str = str.replace(/\r/g, ""); // remove new lines
    str = str.replace(/\b/g, ""); // remove backspace
    str = str.replace(/\t/g, ""); // remove tab
    str = str.replace(/%/g, ""); // remove "%"
    str = str.replace(/#/g, ""); // remove "#"
    str = str.replace(/\?/g, ""); // remove "?"
    str = str.replace(/\\/g, ""); // remove "\"
    str = str.replace(/&/g, ""); // remove "&"
    str = str.replace(/'/g, ""); // remove "'"
    return str;
};

const getEntryUrl = (entryId: string, categoryId?: number, entryName?: string, playlistId?: string) => {
    let entryUrl = entryName
        ? `${baseUrl}/media/${encodeURIComponent(sanitizeParamForUrl(entryName))}/${entryId}`
        : `${baseUrl}/media/t/${entryId}`;
    if (categoryId) {
        entryUrl += `/${categoryId}`;
    }
    if (playlistId) {
        entryUrl += `/playlistid/${playlistId}`;
    }

    return entryUrl;
};

const getPlaylistUrl = (playlistId: string, entryId = "", categoryId?: number) => {
    return categoryId
        ? `${baseUrl}/playlist/dedicated/${categoryId}/${playlistId}/${entryId}`
        : `${baseUrl}/playlist/dedicated/${playlistId}/${entryId}`;
};

const getTagSearchUrl = (tag: string) => {
    return `${baseUrl}/tag?tagid=${encodeURIComponent(tag)}`;
};

const getTagGlobalSearchUrl = (tag: string) => {
    return `${baseUrl}/search?keyword=${encodeURIComponent(tag)}&fields=tags`;
};

const getModuleAssetUrl = (moduleName: string, fileName: string) => {
    return moduleAssetUrlTemplate.replace("{MODULE}", moduleName).replace("{FILE_NAME}", fileName);
};

const formatEntryThumbnailUrl = (thumbnailUrl: string, width: string | number, height: string | number) => {
    return thumbnailUrl ? thumbnailUrl + "/width/" + width + "/height/" + height + "/type/3" : thumbnailUrl;
};

/**
 * add baseUrl to urls in an intelligent way:
 * 1. if external link, don't add
 * 2. if internal link doesn't have initial "/", add that as well (mimic the behaviour of `Zend_View_Helper_BaseUrl`)
 * expected use is on objects of type `Kms_Link_Abstract->getHref()`
 * @param href
 */
const addBaseUrl = (href: string) => {
    if (href.indexOf("http") !== 0) {
        href = href.replace(new RegExp("^/"), "");
        return baseUrl + "/" + href;
    }
    return href;
};

export {
    translate,
    GLOBAL_KMS,
    KMS_GLOBAL,
    getDataFromKms,
    sendDataToKms,
    baseUrl,
    cdnUrl,
    serverUrl,
    translatePlural,
    getEntryUrl,
    getPlaylistUrl,
    getTagSearchUrl,
    getTagGlobalSearchUrl,
    formatEntryThumbnailUrl,
    addBaseUrl,
    getModuleAssetUrl,
    sendButtonClickAnalytics,
};
