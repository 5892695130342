import { useState } from "react";
import BadgesModal, { BadgesModalProps } from "./BadgesModal";

type ModalHookProps = Omit<BadgesModalProps, "showModal" | "onClose">;

export const useBadgesModal = (props: ModalHookProps) => {
    const { ...modalProps } = props;

    const [showModal, setShowModal] = useState<boolean>(false);

    const toggleModal = () => {
        setShowModal((previous) => !previous);
    };

    const modal = <BadgesModal showModal={showModal} onClose={toggleModal} {...modalProps} />;

    return [toggleModal, modal] as const;
};
