import React, { useState } from "react";
import {
    KmsTypePublishEntryCategories,
    KmsTypePublishUserCategories,
    PublishStatus,
} from "@kaltura/mediaspace-shared-types";
import { Button } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
// noinspection ES6PreferShortImport
import { CategoryLinkAnalyticsContext } from "../../../media-categories/CategoryLinkAnalyticsContext";
import { ModalProps, StyledModalActions, StyledModalContent, StyledModalDescription } from "../StyledModal";
import { PublishLocations } from "./publish-locations/PublishLocations";
import { LocationsCount } from "./locations-count/LocationsCount";
import difference from "lodash/difference";
import intersection from "lodash/intersection";

export interface PublishedModalProps extends ModalProps {
    crossApplicationUnpublishing: boolean;
    entryCategories: KmsTypePublishEntryCategories;
    userCategories: KmsTypePublishUserCategories;
    onUnpublishExternal: () => void;
}

/**
 * Publish menu (set as-)Published selection modal
 */
export function PublishedModal(props: PublishedModalProps) {
    const {
        onClose,
        onSubmit,
        entryIds,
        publishStatus,
        crossApplicationUnpublishing,
        entryCategories: entryCategoriesProp,
        userCategories: userCategoriesProp,
        onUnpublishExternal,
    } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const [processing, setProcessing] = useState<boolean>(false);
    const [dirty, setDirty] = useState<boolean>(false);
    const [locationsCount, setLocationsCount] = useState(0);
    const [entryCategories, setEntryCategories] = useState(entryCategoriesProp);
    const [userCategories] = useState(userCategoriesProp);

    const isPrivate = publishStatus === PublishStatus.PRIVATE;
    const isUnlisted = publishStatus === PublishStatus.UNLISTED;
    const isPublished = publishStatus === PublishStatus.PUBLISHED;

    const entryId = entryIds.length === 1 ? entryIds[0] : undefined;

    const { channels, galleries } = entryCategoriesProp;
    const publishedChannelsProp = channels?.published?.map((channel) => channel.id) ?? [];
    const pendingChannesProp = channels?.pending?.map((channel) => channel.id) ?? [];
    const rejectedChannesProp = channels?.rejected?.map((channel) => channel.id) ?? [];
    const publishedGalleriesProp = galleries?.published?.map((gallery) => gallery.id) ?? [];
    const pendingGalleriesProp = galleries?.pending?.map((gallery) => gallery.id) ?? [];
    const rejectedGalleriesProp = galleries?.rejected?.map((gallery) => gallery.id) ?? [];

    const handleCancel = () => {
        isPrivate
            && sendButtonAnalytics(
                "Publish - Set private to published modal box - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        isUnlisted
            && sendButtonAnalytics(
                "Publish - Set unlisted to published modal box - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );
        isPublished
            && sendButtonAnalytics(
                "Publish - Update published modal box - cancel",
                ButtonClickAnalyticsType.CLOSE,
                entryId
            );

        onClose?.();
    };

    const handleSubmit = () => {
        isPrivate
            && sendButtonAnalytics(
                "Publish - Set private to published modal box - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );
        isUnlisted
            && sendButtonAnalytics(
                "Publish - Set unlisted to published modal box - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );
        isPublished
            && sendButtonAnalytics(
                "Publish - Update published modal box - save",
                ButtonClickAnalyticsType.SAVE,
                entryId
            );

        setProcessing(true);

        const { channels, galleries } = entryCategories;

        const publishedChannels = channels?.published?.map((channel) => channel.id) ?? [];
        const pendingChannels = channels?.pending?.map((channel) => channel.id) ?? [];
        const rejectedChannels = channels?.rejected?.map((channel) => channel.id) ?? [];

        const publishedGalleries = galleries?.published?.map((gallery) => gallery.id) ?? [];
        const pendingGalleries = galleries?.pending?.map((gallery) => gallery.id) ?? [];
        const rejectedGalleries = galleries?.rejected?.map((gallery) => gallery.id) ?? [];

        // compose channels to publish to - channels that are not already published/pending
        const selectChannels = [
            ...difference(difference(publishedChannels, publishedChannelsProp), pendingChannesProp),
        ];

        // compose galleries to publish to - galleries that are not already published/pending
        const selectGalleries = [
            ...difference(difference(publishedGalleries, publishedGalleriesProp), pendingGalleriesProp),
        ];

        // compose channels to unpublish from
        const removeChannels = [
            // unchecked published channels
            ...difference(publishedChannelsProp, publishedChannels),
            // unchecked/deleted pending channels (that were not re-checked)
            ...difference(difference(pendingChannesProp, pendingChannels), publishedChannels),
            // deleted rejected channels
            ...difference(rejectedChannesProp, rejectedChannels),
            // unchecked rejected channels (that were not re-checked)
            ...intersection(rejectedChannesProp, publishedChannels),
        ];

        // compose galleries to unpublish from
        const removeGalleries = [
            // unchecked published galleries
            ...difference(publishedGalleriesProp, publishedGalleries),
            // unchecked/deleted pending galleries (that were not re-checked)
            ...difference(difference(pendingGalleriesProp, pendingGalleries), publishedGalleries),
            // deleted rejected galleries
            ...difference(rejectedGalleriesProp, rejectedGalleries),
            // unchecked rejected galleries (that were not re-checked)
            ...intersection(rejectedGalleriesProp, publishedGalleries),
        ];

        onSubmit?.({
            entryIds: entryIds,
            state: PublishStatus.PUBLISHED,
            selectChannels: selectChannels,
            selectCategories: selectGalleries,
            removeChannels: removeChannels,
            removeCategories: removeGalleries,
        });
    };

    const handleCategoryChange = (categoryId: number, checked: boolean) => {
        setDirty(true);

        if (checked) {
            const { myChannels = [], moreChannels = [], galleries = [] } = userCategories;

            // find the relevant catgeory in the user categories
            const channel =
                myChannels.find((channel) => channel.id === categoryId)
                || moreChannels.find((channel) => channel.id === categoryId);
            const gallery = galleries.find((gallery) => gallery.id === categoryId);

            // add to the published entry categories
            (channel || gallery)
                && setEntryCategories((entryCategories): KmsTypePublishEntryCategories => {
                    const { channels = {}, galleries = {} } = entryCategories;
                    const { published: publishedChannels = [] } = channels;
                    const { published: publishedGalleries = [] } = galleries;

                    return channel
                        ? {
                              ...entryCategories,
                              channels: { ...channels, published: [...publishedChannels, channel] },
                          }
                        : {
                              ...entryCategories,
                              /*
                               * Either channel or gallery is always defined.
                               * We're in the case when channel is empty, so we know that gallery is defined.
                               */
                              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                              galleries: { ...galleries, published: [...publishedGalleries, gallery!] },
                          };
                });
        }
        else {
            // remove from entry categories
            setEntryCategories((entryCategories: KmsTypePublishEntryCategories) => {
                const { channels = {}, galleries = {} } = entryCategories;

                return {
                    ...entryCategories,
                    channels: {
                        ...channels,
                        // eslint-disable-next-line eqeqeq
                        published: channels.published?.filter((channel) => channel.id != categoryId),
                        // eslint-disable-next-line eqeqeq
                        pending: channels.pending?.filter((channel) => channel.id != categoryId),
                        // eslint-disable-next-line eqeqeq
                        rejected: channels.rejected?.filter((channel) => channel.id != categoryId),
                    },
                    galleries: {
                        ...galleries,
                        // eslint-disable-next-line eqeqeq
                        published: galleries.published?.filter((gallery) => gallery.id != categoryId),
                        // eslint-disable-next-line eqeqeq
                        pending: galleries.pending?.filter((gallery) => gallery.id != categoryId),
                        // eslint-disable-next-line eqeqeq
                        rejected: galleries.rejected?.filter((gallery) => gallery.id != categoryId),
                    },
                };
            });
        }
    };

    const handleLocationsCount = (count: number) => {
        setLocationsCount(count);
    };

    const handleCategoryBubbleAnalytics = () => {
        sendButtonAnalytics(
            "Publish - Set publish settings modal box - Category list -  Remove category",
            ButtonClickAnalyticsType.DELETE,
            entryId
        );
    };

    return (
        <>
            <StyledModalContent>
                <StyledModalDescription>
                    {translate("Anyone with entitlements to the gallery/channel can view.")}
                </StyledModalDescription>
                <CategoryLinkAnalyticsContext.Provider
                    value={{
                        sendCategoryLinkAnalytics: () => {},
                        sendCategoryBubbleAnalytics: handleCategoryBubbleAnalytics,
                    }}
                >
                    {entryId && (
                        <PublishLocations
                            entryId={entryId}
                            entryCategories={entryCategories}
                            userCategories={userCategories}
                            crossApplicationUnpublishing={crossApplicationUnpublishing}
                            onUnpublishExternal={onUnpublishExternal}
                            onLocationsCount={handleLocationsCount}
                            onCategoryChange={handleCategoryChange}
                        />
                    )}
                </CategoryLinkAnalyticsContext.Provider>
            </StyledModalContent>

            <StyledModalActions>
                <LocationsCount count={locationsCount} />
                <Button variant={"borderless"} color={"secondary"} onClick={handleCancel} disabled={processing}>
                    {translate("Cancel")}
                </Button>
                <Button onClick={handleSubmit} loading={processing} disabled={!dirty || processing} size={"medium"}>
                    {translate("Save changes")}
                </Button>
            </StyledModalActions>
        </>
    );
}
export default PublishedModal;
