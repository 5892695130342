import React, { ChangeEventHandler, FocusEventHandler } from "react";
import { FieldError } from "react-hook-form";
import { PasswordValidation } from "./PasswordValidation";
import { uniqueId } from "lodash";
import { ErrorText, StyledInput } from "../FormElementStyles";

export interface PasswordFieldProps {
    name: string;
    id?: string;
    readOnly?: boolean;
    required?: boolean;
    error?: FieldError;
    showErrors?: boolean;
    errorMessage?: string;
    autoComplete?: string;
    onChange?: ChangeEventHandler<HTMLInputElement>;
    onBlur?: FocusEventHandler<HTMLInputElement>;
}

/**
 * password input field
 */
export const PasswordField = React.forwardRef((props: PasswordFieldProps, ref) => {
    const {
        name,
        id = uniqueId("input_"),
        readOnly = false,
        required = false,
        error,
        showErrors = false,
        errorMessage,
        autoComplete,
        onChange,
        onBlur,
    } = props;

    const hasError = !!error || !!errorMessage;

    return (
        <>
            <StyledInput
                id={id}
                error={hasError}
                inputRef={ref}
                type={"password"}
                name={name}
                aria-invalid={hasError ? "true" : "false"}
                readOnly={readOnly}
                required={required}
                autoComplete={autoComplete}
                onChange={onChange}
                onBlur={onBlur}
                className="kms-ds-password-field"
            />
            <div aria-live="polite">
                {showErrors && !errorMessage && <PasswordValidation errors={error} />}
                {showErrors && errorMessage && (
                    <ErrorText variant={"body2"} className="kms-ds-form-field-error kms-ds-password-field-error">
                        {errorMessage}
                    </ErrorText>
                )}
            </div>
        </>
    );
});
