import { KalturaEntryStatus } from "kaltura-typescript-client/api/types/KalturaEntryStatus";
import { KalturaUploadToken } from "kaltura-typescript-client/api/types/KalturaUploadToken";
import { CancelableAction } from "kaltura-typescript-client";
import { PublishStatus } from "@kaltura/mediaspace-shared-types";

export enum UploadStatus {
    PRE_QUEUE = "prequeue",
    IN_QUEUE = "In Queue",
    UPLOADING = "Uploading",
    PROCESSING = "Processing",
    READY = "Ready",
    ENTRY_FAILED = "Error Processing",
    UPLOAD_FAILED = "Error Uploading",
    CANCEL_FAILED = "Error Canceling Upload",
    CANCELED = "Canceled",
}

/**
 * matches values of `KalturaEntryStatus` enum to keys of `UploadStatus` enum
 */
export const StatusesMapper: Record<KalturaEntryStatus, Partial<keyof typeof UploadStatus>> = {
    6: "ENTRY_FAILED",
    3: "CANCELED",
    "-1": "ENTRY_FAILED",
    "-2": "ENTRY_FAILED",
    0: "UPLOADING",
    "virusScan.Infected": "ENTRY_FAILED",
    5: "READY",
    7: "ENTRY_FAILED",
    4: "PROCESSING",
    1: "PROCESSING",
    2: "READY",
    "virusScan.ScanFailure": "ENTRY_FAILED",
};

export interface FileMetaData {
    tokenId: string;
    progress?: number;
    status: UploadStatus;
    publishStatus: PublishStatus;
}

export interface UploadFileInfo {
    file: File;
    fileMetaData: FileMetaData;
}

export interface UploadProps {
    chunkSize: number;
    ks: string;
    serviceUrl: string;
    maxConcurrentUploadConnections?: number;
    fileInfo?: UploadFileInfo;
    onUploadProgress?: (tokenId: string, rate: number) => void;
    onUploadEnded?: (tokenId: string) => void;
    onBeforeUploadStarted?: (fileInfo: UploadFileInfo) => void;
    onUploadsCanceled?: (tokenIds: string[]) => void;
    onUploadError?: (tokenId: string, errorText?: string) => void;
    onCancelError?: (tokenIds: string[], errorText?: string) => void;
}

export interface UploadServiceHandler {
    getAllUserRelatedTokens: () => Promise<KalturaUploadToken[]>;
    updateChunkSize: (fileSize: number) => void;
    createTokenId: (fileName: string) => Promise<string | undefined>;
    cancelUploads: (tokenIds: string[]) => void;
}

export const numberOfMaxChunksForSingleFile = 10000;

export type UploadRequest = {
    requestObject: CancelableAction<KalturaUploadToken>;
    relatedUploadToken: string;
};
