import ReactDOM from "react-dom";
import { Component, ComponentType, ReactElement, ReactNode } from "react";
import isEmpty from "lodash/isEmpty";
import clsx from "clsx";
import TruncateMarkup from "react-truncate-markup";
import { translate } from "@kaltura/mediaspace-shared-utils";
import styled from "@emotion/styled";
import { Link } from "@kaltura/ds-react-components";

export interface ShowMoreLessWrapperProps {
    children?: ReactNode;
}

export interface TruncateProps {
    className?: string;
    children: ReactNode;
    showMore?: boolean;
    showMoreInNewLine?: boolean;
    lines?: number;
    // forcibly tell the truncate manager what's the line height
    // (if omitted, truncate manager will auto-calculate it)
    lineHeight?: number | string;
    showCount?: boolean;
    tokenize?: "characters" | "words";
    role?: string; // accessibility HTML role
    showMoreTranslatedText?: string;
    showLessTranslatedText?: string;

    // Passing this value is recommended so that in cases where there is some string manipulations (i.e. change <P>
    // elements to <BR>, when showing the full text the user will get the original one without those changes
    originalText?: ReactNode;
    /*
     * Custom components for "show more" / "show less" buttons.
     * Use them to add extra content to the line that contains the button.
     * They receive the current button as children.
     * Example:
     * ```
     * const Wrapper = ({children}: ShowMoreLessWrapperProps) => <div style={{display: "flex"}}>
     *     <div>{children}</div>
     *     <div>Some extra content</div>
     * </div>;
     *
     * <Truncate showMoreWrapper={Wrapper}>
     *     Truncated text
     * </Truncate>
     * ```
     */
    showMoreWrapper?: ComponentType<ShowMoreLessWrapperProps>;
    showLessWrapper?: ComponentType<ShowMoreLessWrapperProps>;
    toggleMoreClassName?: string;
    onToggle?: (showMore: boolean) => any;
    onTruncate?: (wasTruncated: boolean) => any;
    preventStateUpdate?: boolean;
    /**
     * should the show more / show less button color be translucent
     */
    translucent?: boolean;
}

interface InnerProps extends TruncateProps {
    hasError: boolean;
    lines: number; // make it required
}

interface State {
    shouldTruncate: boolean;
    additionalLines: number;
    hasError: boolean;
    suspended: boolean;
}

const StyledLessWrapper = styled("div")<{ showMoreInNewLine?: boolean }>(({ theme, showMoreInNewLine }) => ({
    ...(
        showMoreInNewLine && {
            marginTop: theme.spacing(1),
        }
    ),
    width: "100%",
}));

const StyledShowMore = styled("span")<{ showMoreInNewLine?: boolean }>(({ theme, showMoreInNewLine }) => ({
    ...(
        showMoreInNewLine && {
            display: "inline-block",
            width: "100%",
            position: "relative",
            top: theme.spacing(1),
        }
    ),
}));

/**
 *  Truncate Manager with custom error boundaries to prevent errors.
 *  This is the top level component that you want to be using.
 *
 *  The error boundaries are :
 *    1. increase the number of lines rendered by 2. (see TruncateManagerInner)
 *    2. render the original content. (see TruncateErrorBoundary)
 *    3. turn off truncation. (see this component)
 *  we use 3 seperate error boundaries, since each one can only be caught once.
 *
 *  Handling Errors in css:
 *  in case truncation failed, css classes are added to the wrapper element.
 *   * if lines are added, `xtra-lines` class is added.
 *   * if render still failed, `truncation-error` class is added.
 *
 *  Usage Example:
 *  the additional error classes can be used to make sure the text that
 *  failed truncation will not exceed its allocated space, by adding styles
 *  that only apply when truncation failed. see below:
 *  <pre>
 *  const Description = styled(Truncate)(({ theme, lines = 0 }) => ({
 *      lineHeight: 1.5,
 *     ["&.truncation-error"] :{
 *         maxHeight: `${lines*1.5}em`,
 *         overflow: "hidden",
 *     }
 * }));
 * </pre>
 */
export class Truncate extends Component<TruncateProps, { hasError: boolean }> {
    constructor(props: TruncateProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }

    render() {
        const { lines = 3, showMoreInNewLine, children, ...passThroughProps } = this.props;
        const { hasError } = this.state;

        // adjust lines to render with show more in new line - it will be included in the calculations
        const actualLines = showMoreInNewLine ? lines + 1 : lines;

        if (hasError) {
            return null;
        }

        return (
            <TruncateErrorBoundary {...passThroughProps} lines={actualLines} showMoreInNewLine={showMoreInNewLine}>
                {children}
            </TruncateErrorBoundary>
        );
    }
}

/**
 *  custom error boundary to render the original content.
 */
class TruncateErrorBoundary extends Component<TruncateProps, { hasError: boolean }> {
    constructor(props: InnerProps) {
        super(props);
        this.state = { hasError: false };
    }

    static getDerivedStateFromError() {
        // Update state so the next render will show the fallback UI (untruncated content).
        return { hasError: true };
    }

    render() {
        const { hasError } = this.state;

        return (
            <TruncateManagerInner {...this.props} hasError={hasError}>
                {this.props.children}
            </TruncateManagerInner>
        );
    }
}

/**
 *  Truncate Manager inner Component.
 *  Wraps the TruncateMarkup with a management layer, and an error boundary that increase
 *  the number of lines rendered by 2.
 */
class TruncateManagerInner extends Component<InnerProps, State> {
    static defaultProps = {
        lines: 3,
        showCount: false,
        showMore: true,
        tokenize: "characters",
    };
    private observer: IntersectionObserver | null = null;

    constructor(props: InnerProps) {
        super(props);

        this.state = {
            shouldTruncate: true,
            additionalLines: 0,
            hasError: props.hasError,
            suspended: true,
        };

        this.toggleTruncate = this.toggleTruncate.bind(this);
        this.moreEllipsis = this.moreEllipsis.bind(this);
    }

    componentDidMount() {
        const targetNode = ReactDOM.findDOMNode(this);

        // look for truncated content flicking into visibility
        // (may be rendered hidden when using suspense)
        this.observer = new IntersectionObserver((entries) => {
            // If intersectionRatio is 0, the target is out of view
            // and we do not need to do anything.
            if (entries[0].intersectionRatio <= 0) return;

            this.setState({ suspended: false });
        });

        targetNode && this.observer.observe(targetNode as Element);
    }

    componentWillUnmount() {
        this.observer?.disconnect();
    }

    // catch render errors - and increase the lines count to counter them
    componentDidCatch() {
        const { hasError } = this.props;

        this.setState((prevState) => ({
            additionalLines: prevState.additionalLines + 2,
            hasError: prevState.additionalLines > 2 || hasError,
        }));
    }

    // toggle the truncate state
    toggleTruncate() {
        // override the default toggle event - if there is onToggle prop
        if (this.props.onToggle) {
            this.props.onToggle(!this.state.shouldTruncate);
        }
        if (this.props.preventStateUpdate) {
            return;
        }
        this.setState((prevState) => ({
            shouldTruncate: !prevState.shouldTruncate,
        }));
    }

    // element-count ellipsis
    ellipsisCount(rootEl: ReactElement) {
        const originalWordCount = (this.props.children as any).length;
        const currentWordCount = rootEl.props.children.length;
        const moreText = `+ ${originalWordCount - currentWordCount} ${translate("more")}`;
        return (
            <StyledShowMore tabIndex={0} onClick={this.toggleTruncate} onKeyDown={this.toggleTruncate}>
                {moreText}
            </StyledShowMore>
        );
    }

    // 'more' ellipsis
    ellipsisMore() {
        const {
            showMoreTranslatedText = translate("Show More"),
            showMoreWrapper: CustomShowMoreWrapper = PassThrough,
            toggleMoreClassName = "",
            showMoreInNewLine = false,
            translucent = false,
        } = this.props;

        return (
            <StyledShowMore showMoreInNewLine={showMoreInNewLine}>
                <CustomShowMoreWrapper>
                    {!showMoreInNewLine && <span>&hellip;&nbsp;</span>}
                    <Link
                        component={"button"}
                        className={toggleMoreClassName}
                        onClick={this.toggleTruncate}
                        translucent={translucent}
                    >
                        {showMoreTranslatedText}
                    </Link>
                </CustomShowMoreWrapper>
            </StyledShowMore>
        );
    }

    // choose the more elements ellipsis
    moreEllipsis(rootEl: ReactNode) {
        const { showCount, showMore } = this.props;
        if (!showMore && !showCount) {
            return <span>&hellip;&nbsp;</span>;
        }
        return showCount ? this.ellipsisCount(rootEl as ReactElement) : this.ellipsisMore();
    }

    render() {
        const {
            className = "",
            lines,
            lineHeight,
            children,
            tokenize = "characters",
            role,
            showLessTranslatedText = translate("Show Less"),
            showLessWrapper: CustomShowLessWrapper = PassThrough,
            toggleMoreClassName = "",
            hasError: boundaryError,
            onTruncate,
            showMoreInNewLine = false,
            originalText,
            translucent = false,
        } = this.props;
        const { shouldTruncate, additionalLines, hasError } = this.state;
        const linesToRender = lines + additionalLines;

        if (!children || isEmpty(children)) {
            return null;
        }

        if (shouldTruncate && !hasError) {
            return (
                <TruncateMarkup
                    lines={linesToRender}
                    lineHeight={lineHeight}
                    ellipsis={this.moreEllipsis}
                    tokenize={tokenize}
                    onTruncate={onTruncate}
                >
                    <div
                        className={clsx(className, "chromatic-ignore", boundaryError && "xtra-lines")}
                        {...(role ? { role: role } : {})}
                    >
                        {children}
                    </div>
                </TruncateMarkup>
            );
        }
        else {
            return (
                <div
                    className={clsx(
                        className,
                        "chromatic-ignore",
                        hasError && "truncation-error",
                        boundaryError && "xtra-lines"
                    )}
                >
                    {originalText ?? children}
                    {!boundaryError && (
                        <StyledLessWrapper showMoreInNewLine={showMoreInNewLine}>
                            <CustomShowLessWrapper>
                                <Link
                                    component={"button"}
                                    className={toggleMoreClassName}
                                    onClick={this.toggleTruncate}
                                    translucent={translucent}
                                >
                                    {showLessTranslatedText}
                                </Link>
                            </CustomShowLessWrapper>
                        </StyledLessWrapper>
                    )}
                </div>
            );
        }
    }
}

// eslint-disable-next-line react/jsx-no-useless-fragment
const PassThrough = ({ children }: ShowMoreLessWrapperProps) => <>{children}</>;

export default Truncate;
