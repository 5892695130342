import { JoinNextSessionNotification } from "./types";
import React, { useEffect, useState } from "react";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { useButtonAnalytics, useCurrentTimeForAnimation } from "@kaltura/mediaspace-shared-hooks";
import {
    StyledNotificationButtons,
    StyledNotificationWithThumbnailContainer,
    StyledNotificationTitlePadded,
    Thumbnail,
} from "./NotificationContent";
import { TruncateWithTooltip } from "@kaltura/mediaspace-shared-ui";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { Button } from "@kaltura/ds-react-components";

export interface SingleSessionNotificationProps {
    id: number;
    notification: JoinNextSessionNotification;
}

export const SingleSessionNotification = ({ notification, id }: SingleSessionNotificationProps) => {
    const { timeTillStart, session, onStay, onWatch } = notification;
    const [remainingTime, setRemainingTime] = useState(timeTillStart);
    const [stayClicked, setStayClicked] = useState(false);
    const sendButtonAnalytics = useButtonAnalytics();

    useCurrentTimeForAnimation(
        (_) => {
            // if user clicked stay or remaining time reached 0, stop state update and prevent useEffect from being called
            if (stayClicked || remainingTime === 0) {
                return;
            }
            setRemainingTime((currentCountdown) => currentCountdown - 1);
        },
        1000
    );

    useEffect(() => {
        if (stayClicked) {
            return;
        }
        if (remainingTime === 0) {
            onWatch(session, id); //redirect to media entry
        }
    }, [remainingTime, stayClicked]);

    const stayClick = () => {
        setStayClicked(true);
        sendButtonAnalytics(
            "TV guide notification - Join next session - Stay",
            ButtonClickAnalyticsType.CLOSE,
            notification.analyticsEntryId
        );
        onStay(id);
    };

    const watchClick = () => {
        sendButtonAnalytics(
            "TV guide notification - Join next session - Go to session",
            ButtonClickAnalyticsType.LINK,
            notification.analyticsEntryId
        );
        onWatch(session, id);
    };

    return (
        <>
            <StyledNotificationTitlePadded>
                {translate("Next session is starting in %1...", [remainingTime])}
            </StyledNotificationTitlePadded>
            <StyledNotificationWithThumbnailContainer>
                <Thumbnail src={session.thumbnailUrl} />
                <TruncateWithTooltip lines={2} text={session.name}>
                    <span>{session.name}</span>
                </TruncateWithTooltip>
            </StyledNotificationWithThumbnailContainer>
            <StyledNotificationButtons>
                <Button variant={"pill"} color={"secondary"} size={"large"} fullWidth={true} onClick={stayClick}>
                    {translate("Stay here")}
                </Button>
                <Button color={"primary"} size={"large"} fullWidth={true} onClick={watchClick}>
                    {translate("Come Watch")}
                </Button>
            </StyledNotificationButtons>
        </>
    );
};
