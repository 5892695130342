import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { translate } from "@kaltura/mediaspace-shared-utils";

export interface RecycleBinConfirmationMessageProps {
    entryIds: string[];
    retentionPeriod: number;
}

const StyledMessageText = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.tone1,
    textAlign: "center",
}));

export function RecycleBinConfirmationMessage({ entryIds, retentionPeriod }: RecycleBinConfirmationMessageProps) {
    let text = translate(
        "Are you sure you want to delete this media (ID: %1) and move it to the recycle bin? "
            + "This media will be automatically deleted from the recycle bin within %2 days.",
        [entryIds[0], retentionPeriod]
    );
    if (entryIds.length > 1) {
        text = translate(
            "Are you sure you want to delete these %1 media (IDs: %2) and move them to the recycle bin? "
                + "These media will be automatically deleted from the recycle bin within %3 days.",
            [entryIds.length, entryIds.join(", "), retentionPeriod]
        );
    }

    return <StyledMessageText variant={"body1"}>{text}</StyledMessageText>;
}
