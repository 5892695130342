import styled from "@emotion/styled";
import { Skeleton } from "@kaltura/mediaspace-shared-styled";

const StyledSkeleton = styled(Skeleton)(({ theme }) => ({
    borderRadius: theme.kaltura.shape.roundness1,
}));

const StyledListItemContainer = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(2),
    marginTop: theme.spacing(2),
}));

const PlaylistsFormSkeleton = () => {
    return (
        <div>
            <StyledSkeleton variant="rectangular" height={32} />
            <StyledListItemContainer>
                <StyledSkeleton variant="rectangular" width={16} height={16} />
                <StyledSkeleton variant="rectangular" width={300} height={16} />
            </StyledListItemContainer>
            <StyledListItemContainer>
                <StyledSkeleton variant="rectangular" width={16} height={16} />
                <StyledSkeleton variant="rectangular" width={300} height={16} />
            </StyledListItemContainer>
            <StyledListItemContainer>
                <StyledSkeleton variant="rectangular" width={16} height={16} />
                <StyledSkeleton variant="rectangular" width={200} height={16} />
            </StyledListItemContainer>
            <StyledListItemContainer>
                <StyledSkeleton variant="rectangular" width={16} height={16} />
                <StyledSkeleton variant="rectangular" width={248} height={16} />
            </StyledListItemContainer>
        </div>
    );
};

export default PlaylistsFormSkeleton;
