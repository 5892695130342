import styled from "@emotion/styled";
import { alpha, systemWidth } from "@kaltura/mediaspace-shared-styled";
import { Button, Link, Typography } from "@kaltura/ds-react-components";
import footerClasses from "./footerClasses";

export const StyledFooterWrapper = styled("footer")({
    position: "sticky",
    top: "100vh",
});

export const StyledFooterContainer = styled(
    "div",
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return !["url", "color"].includes(propName.toString());
        },
    }
)<{ color: string; url?: string }>(({ color, url }) => ({
    width: "100%",
    backgroundColor: color,
    backgroundImage: url?.length ? `url(${url})` : "",
    backgroundRepeat: url?.length ? "no-repeat" : "",
    backgroundPosition: url?.length ? "right bottom" : "",
}));

export const StyledFooter = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    paddingTop: theme.spacing(7),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        paddingTop: theme.spacing(4),
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        paddingTop: theme.spacing(2),
    },

    "&": systemWidth({ theme }),
}));

export const StyledBottomContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    marginBottom: theme.spacing(7),
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        marginBottom: theme.spacing(4),
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginBottom: theme.spacing(2),
    },

    [` .${footerClasses.logo}`]: {
        // The following flex props are required to support text-only logo truncation:
        flexGrow: 0,
        flexShrink: 1,
        flexBasis: "auto",

        // Support text truncation in children - see https://css-tricks.com/flexbox-truncated-text/
        minWidth: 0,
    },
}));

export const StyledBottomContent = styled(
    "div",
    {
        shouldForwardProp(propName: PropertyKey): boolean {
            return propName !== "headerLogoExist";
        },
    }
)<{ headerLogoExist?: boolean }>(({ theme, headerLogoExist }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        flexDirection: "column-reverse",
        justifyContent: "initial",
    },
    marginTop: headerLogoExist ? theme.spacing(4) : theme.spacing(0),
}));

export const StyledUpperContainer = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        flexDirection: "column",
        justifyContent: "initial",
    },
    marginBottom: theme.spacing(7),
}));

export const StyledPromotionHeader = styled("div")(({ theme }) => ({
    display: "flex",
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        maxWidth: "100%",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        maxWidth: theme.typography.pxToRem(496),
    },
}));

export const StyledPromotionContent = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    [theme.breakpoints.up(theme.breakpoints.values.sm)]: {
        maxWidth: "100%",
    },
    [theme.breakpoints.up(theme.breakpoints.values.md)]: {
        maxWidth: theme.typography.pxToRem(496),
    },
}));

export const StyledPromotionButton = styled(Button)(({ theme }) => ({
    marginTop: theme.typography.pxToRem(16),
}));

export const StyledTypography = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    marginTop: "unset",
    marginBottom: 0,
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginBottom: theme.spacing(2),
    },
    "& span:last-child": {
        display: "none",
    },
}));

export const StyledFooterContent = styled("div")({
    display: "flex",
    flexDirection: "column",
});

export const StyledLinksAndTextContainer = styled("div")({
    display: "flex",
    flexDirection: "column",
});

export const StyledLinks = styled("div")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    alignItems: "center",
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        marginTop: 24,
    },
}));

export const StyledSeparator = styled("span")(({ theme }) => ({
    color: theme.palette.common.white,
    marginRight: theme.spacing(1),
}));

export const StyledBottomText = styled(Typography)(({ theme }) => ({
    color: theme.palette.common.white,
    fontSize: theme.typography.pxToRem(12),
}));

export const StyledLinkWrapper = styled("span")(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

export const StyledLinkContainer = styled("div")({
    display: "inline-block",
});

export const StyledLink = styled(Link)(({ theme, component }) => ({
    cursor: "pointer",
    fontSize: theme.typography.pxToRem(12),
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "inline-block",
    position: "relative",
    top: component === "button" ? 0 : 4,
    maxWidth: 437,
    [theme.breakpoints.down(theme.breakpoints.values.xl)]: {
        maxWidth: 377,
    },
    [theme.breakpoints.down(theme.breakpoints.values.lg)]: {
        maxWidth: 257,
    },
    [theme.breakpoints.down(theme.breakpoints.values.md)]: {
        maxWidth: 132,
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        maxWidth: 160,
    },
}));

export const StyledSocialLinksList = styled("ul")(({ theme }) => ({
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(2),
    [` .${footerClasses.socialIcon}`]: {
        backgroundColor: alpha(theme.kaltura.palette.translucent.light, 0.2),
        borderRadius: "20px",
        width: theme.typography.pxToRem(40),
        height: theme.typography.pxToRem(40),
        [`&:hover, &:active, &:focus, &:focus-visible`]: {
            backgroundColor: alpha(theme.kaltura.palette.translucent.light, 0.4),
            borderRadius: "20px",
        },
    },
    [theme.breakpoints.down(theme.breakpoints.values.sm)]: {
        gap: theme.spacing(1),
    },
    listStyle: "none",
    padding: 0,
    margin: 0,
}));
