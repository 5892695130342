import type { Theme } from "@mui/material";

export const systemWidthSize = { md: 960, lg: 1200, xl: 1320 };

interface Props {
    theme: Theme;
}

export const systemWidth = (props: Props) => {
    const { theme } = props;
    return {
        // don't use breakpoints.between(), it doesn't work with asset consolidation
        // down("sm")
        width: "auto",
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up("sm")]: {
            marginLeft: theme.spacing(3),
            marginRight: theme.spacing(3),
        },
        [theme.breakpoints.up("md")]: {
            width: systemWidthSize.md,
            marginLeft: "auto",
            marginRight: "auto",
        },
        [theme.breakpoints.up("lg")]: {
            width: systemWidthSize.lg,
            marginLeft: "auto",
            marginRight: "auto",
        },
        [theme.breakpoints.up("xl")]: {
            width: systemWidthSize.xl,
            marginLeft: "auto",
            marginRight: "auto",
        },
    };
};
