import { KmsTypeComponent } from "@kaltura/mediaspace-shared-types";
import styled from "@emotion/styled";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { ErrorBoundary } from "@kaltura/mediaspace-shared-ui";
import ActionsMenu, { ActionsMenuProps } from "@kaltura/mediaspace-shared-media-actions";

export interface ActionButtonsProps {
    buttons: KmsTypeComponent[];
    menu: ActionsMenuProps;
}

/**
 * Media action buttons below the player (or other media hero component), like "likes" and "comments".
 */
export const ActionButtons = ({ buttons, menu }: ActionButtonsProps) => {
    const className = "kms-ds-media-page-title-actions";

    return (
        <StyledContainer className={className}>
            {buttons.map(({ name, component: Component, props }) => (
                <ErrorBoundary key={name} fallback={<></>}>
                    <span className={className + "-button"}>
                        <Component {...props} className={props.className + " kms-ds-media-page-title-button"} />
                    </span>
                </ErrorBoundary>
            ))}

            <span className={className + "-menu-button"}>
                <ActionsMenu {...menu} />
            </span>
        </StyledContainer>
    );
};

const StyledContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    flexDirection: "row",
    gap: theme.spacing(1),
}));
