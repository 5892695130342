import { translateTime } from "@kaltura/mediaspace-shared-utils";
import { WebcastEntry } from "@kaltura/mediaspace-shared-types";
import { ScheduledSession } from "./EventList";

/**
 * converts a list of WebcastEntry objects to a list of ScheduledSession objects
 * (so they can be used with EventList component)
 *
 * @param sessions the list of entries to convert
 * @param labelDateFormat date format to use as label
 * @return ScheduledSession[]
 */
export function convertToScheduledSessions(sessions: WebcastEntry[], labelDateFormat: string): ScheduledSession[] {
    return sessions
        .filter((session) => !!session.schedulingData)
        .map((session) => ({
            ...session,
            schedulingData: {
                ...session.schedulingData,
                label: translateTime(session.schedulingData.start.timestamp, labelDateFormat),
            },
        }));
}
