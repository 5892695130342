import { KalturaEntryType } from "kaltura-typescript-client/api/types/KalturaEntryType";
import { KalturaMediaType } from "kaltura-typescript-client/api/types/KalturaMediaType";
import { EventPlatformEntry } from "@kaltura/mediaspace-shared-types";

const now = Date.now() / 1000;

const eventEntries: EventPlatformEntry[] = [
    {
        type: KalturaEntryType.liveStream,
        mediaType: KalturaMediaType.video,
        id: "1_8ywm3ja2",
        name: "single spinner please please please please",
        description:
            "This is the bright candlelit room where the life-timers are stored &ndash; shelf upon shelf of them, squat hourglasses, one for every living person, pouring their fine sand from the future into the past. The accumulated hiss of the falling grains makes the room roar like the sea.\n"
            + "This is the owner of the room, stalking through it with a preoccupied air. His name is Death.\n"
            + "But not any Death. This is the Death whose particular sphere of operations is, well, not a sphere at all, but the Discworld, which is flat and rides on the back of four giant elephants who stand on the shell of the enormous star turtle Great A'Tuin, and which is bounded by a waterfall that cascades endlessly into space.\n",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_9bk0hb1n/version/100011",
        createdAt: 1596357243,
        lastBroadcast: 1596360716,
        duration: 2822,
        presenters: [
            {
                id: "",
                name: "Michal Tsur",
                link: "http://google.com",
                title: "Co-founder & Vice President, Kaltura",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "",
                name: "Renan Gutman",
                link: "http://google.com",
                title: "EVP Product, KalturaEVP Product, KalturaEVP Product, KalturaEVP Product, KalturaEVP Product, Kaltura",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "",
                name: "Presenter &amp; Name No Link & Bio",
                link: "",
                title: "vice president elect of content &amp; joy",
                bio: "",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
        schedulingData: {
            start: {
                timestamp: 1596362400,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: 1596550500,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        mediaType: KalturaMediaType.video,
        id: "1_b7a36y0k",
        name: "webcast with qna",
        description:
            "webcast with qnawebcast with qna webcast with qna webcast with qna webcast with qna webcast with qna webcast with qna",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: 1593065700,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: 1593069300,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        mediaType: KalturaMediaType.video,
        id: "superLongSingleStringName",
        name: "LoremipsumdolorsitametconsecteturadipiscingelitSeddoeiusmodtemporincididuntutlaboreetdoloremagnaaliquaUtveniamquisnostrudexercitationullamcolaborisnisiutaliquipexeacommodoconsequatDuisautereiruredolorinreprehenderitinvoluptatevelitessecillumdoloreeufugiatnullapariaturExcepteursintoccaecatcupidatatnonproident",
        description:
            "LoremipsumdolorsitametconsecteturadipiscingelitSeddoeiusmodtemporincididuntutlaboreetdoloremagnaaliquaUtveniamquisnostrudexercitationullamcolaborisnisiutaliquipexeacommodoconsequatDuisautereiruredolorinreprehenderitinvoluptatevelitessecillumdoloreeufugiatnullapariaturExcepteursintoccaecatcupidatatnonproidentLoremipsumdolorsitametconsecteturadipiscingelitSeddoeiusmodtemporincididuntutlaboreetdoloremagnaaliquaUtveniamquisnostrudexercitationullamcolaborisnisiutaliquipexeacommodoconsequatDuisautereiruredolorinreprehenderitinvoluptatevelitessecillumdoloreeufugiatnullapariaturExcepteursintoccaecatcupidatatnonproident",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_re1zkox5/version/100001",
        createdAt: 1593065885,
        lastBroadcast: 1593068227,
        duration: 240,
        schedulingData: {
            start: {
                timestamp: 1593065700,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
            end: {
                timestamp: 1593069300,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 10800,
            },
        },
        presenters: [
            {
                id: "",
                name: "Michal Tsur",
                link: "http://google.com",
                title: "Co-founder & Vice President, Kaltura",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "",
                name: "Renan Gutman",
                link: "http://google.com",
                title: "EVP Product, KalturaEVP Product, KalturaEVP Product, KalturaEVP Product, KalturaEVP Product, Kaltura",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "",
                name: "Presenter &amp; Name",
                link: "http://google.com",
                title: "vice president elect of content &amp; joy",
                bio:
                    "Lorem &amp; ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat.Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt "
                    + "ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco "
                    + "laboris nisi ut aliquip ex ea commodo consequat",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
            {
                id: "",
                name: "Presenter &amp; Name No Link & Bio",
                link: "",
                title: "vice president elect of content &amp; joy",
                bio: "",
                image: "http://cfvod.kaltura.com/p/931422/sp/93142200/thumbnail/entry_id/0_c0otxdbj/version/100002/width/70/height/70/type/3",
            },
        ],
    },
    {
        type: KalturaEntryType.liveStream,
        mediaType: KalturaMediaType.video,
        id: "1_x8y389uh",
        name: "Upcoming session - with join buffer",
        description:
            "This card's CTA should update from 'Add to Calendar' to 'Join now' after 10 seconds, when the buffer (15 minutes before event start time) is reached. See SessionCardJoinNowIndication config in Widgets module for more info. Applies to the EventListItem component.",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/0/thumbnail/entry_id/1_8quzvpgb/version/100001",
        createdAt: 1545038801,
        lastBroadcast: 1545038807,
        duration: 1200,
        presenters: [],
        schedulingData: {
            start: {
                timestamp: now + 15 * 60 + 10,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: now + 2 * 1000,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.liveStream,
        mediaType: KalturaMediaType.video,
        id: "0_zx2hc2jo",
        name: "af & sdfsdaf",
        description: "",
        thumbnailUrl: "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/0_zx2hc2jo/version/100002",
        createdAt: 1480492931,
        lastBroadcast: 1480494142,
        duration: 782,
        presenters: [
            {
                id: "58aafb13d92f9",
                name: "my name",
                title: "my title",
                bio: "",
                link: "",
                image: "https://www.kaltura.com/api_v3/service/thumbasset/action/serve?format=2&clientTag=KMS+5.100.56%2C+build+0%3B5f54d98d490ea%3BIL-ATARSH-RND&apiVersion=16.9.0&thumbAssetId=1_59n29nn5&thumbParams%5BobjectType%5D=KalturaThumbParams&thumbParams%5BcropType%5D=3&thumbParams%5Bwidth%5D=42&thumbParams%5Bheight%5D=42&partnerId=346151&kalsig=f08c21018b0a3066e745fd36caf6b219&ks=NWU5ODlkODUwMzg5MTA0MmFmNTRlZjMyOGJjYjIyYjgwMWQ5YTVjN3wzNDYxNTE7MzQ2MTUxOzM2MTE4NjI0MzI7MjsxNzExMjgwMDMwLjI4OTk7YXRhci5zaGFkbWlAa2FsdHVyYS5jb207KixkaXNhYmxlZW50aXRsZW1lbnQ7MzQ2MTUxOw==",
            },
        ],
        schedulingData: {
            start: {
                timestamp: 1480493700,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
            end: {
                timestamp: 1480497300,
                timeZoneName: "Asia/Jerusalem",
                timeZoneOffset: 7200,
            },
        },
    },
    {
        type: KalturaEntryType.mediaClip,
        mediaType: KalturaMediaType.video,
        id: "0_xfithd78",
        name: "Annoto - Unleashing In-Video Collaboration and Insights",
        description:
            "He was appointed Senior Vice Minister for Internal Affairs and Communications in November 2005 under Prime Minister Junichiro Koizumi. He was promoted to Minister of Internal Affairs and Communications and Minister for Privatization of the Postal Services in the first Shinzo Abe cabinet in September 2006, and added the portfolio of Minister of State for Decentralization Reform in December 2006",
        thumbnailUrl:
            "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/1_cblinyoz/width/360/height/200/type/3",
        createdAt: 1480492931,
        duration: 52318,
    },
    {
        type: KalturaEntryType.mediaClip,
        mediaType: KalturaMediaType.video,
        id: "0_efl6w16e",
        name: "How to post as Anonymous | Annoto",
        description:
            "Suga gained domestic and international fame when he announced the name of the new imperial era, Reiwa, on 1 April 2019.[19] While he had previously been a low-profile member of the government, this honor gave him an instant surge in name recognition and led more LDP lawmakers to view him as a viable candidate for party leadership.[4][13] He was sent to Washington in May 2019 for a meeting with U.S. Vice President Mike Pence and other senior officials",
        thumbnailUrl:
            "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/1_cblinyoz/width/360/height/200/type/3",
        createdAt: 1480492931,
        duration: 782,
    },
    {
        type: KalturaEntryType.mediaClip,
        mediaType: KalturaMediaType.video,
        id: "0_h5d2y76t",
        name: "Adding a video as a comment | Widget | Annoto",
        description:
            "As Chief Cabinet Secretary, Suga served as an aide and advisor to Abe, and took an active managerial role in the government. He had a key role in the government's initiatives to attract tourists and foreign",
        thumbnailUrl:
            "https://cfvod.kaltura.com/p/346151/sp/34615100/thumbnail/entry_id/1_cblinyoz/width/360/height/200/type/3",
        createdAt: 1480492931,
        duration: 7823,
    },
];
export default eventEntries;
