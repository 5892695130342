import { AutoCompleteProps, Checkbox, Initials, Typography } from "@kaltura/ds-react-components";
import { HTMLAttributes, useEffect, useMemo, useState } from "react";
import { AutocompleteRenderGetTagProps } from "@mui/material";
import { Close16Icon } from "@kaltura/ds-react-icons";
import throttle from "lodash/throttle";
import { StyledAutocomplete, StyledAvatarImage, StyledChip, StyledInitialsWrap, StyledLi } from "./styled-components";
import { Collaborator } from "./collaborator";

/**
 * component props
 */
export interface UserSelectionProps
    extends Omit<AutoCompleteProps, "freeSolo" | "renderOption" | "renderTags" | "options"> {
    url: string;
}

/**
 * custom options renderer
 * @param props
 * @param option
 * @param selected
 */
const renderOption = (
    props: HTMLAttributes<HTMLLIElement>,
    option: Collaborator,
    { selected }: { selected: boolean }
) => {
    return (
        <StyledLi {...props} key={option.id}>
            <Checkbox checked={selected} />
            {option.thumbnailUrl && <StyledAvatarImage src={option.thumbnailUrl} />}
            {!option.thumbnailUrl && (
                <StyledInitialsWrap>
                    <Initials firstName={option.firstName} lastName={option.lastName} size={"small"} />
                </StyledInitialsWrap>
            )}
            <Typography variant={"body2"}>{option.screenName}</Typography>
        </StyledLi>
    );
};

/**
 * custom selected options renderer
 * @param tagValue
 * @param getTagProps
 */
const renderTags = (tagValue: Collaborator[], getTagProps: AutocompleteRenderGetTagProps) => {
    return tagValue.map((option: Collaborator, index: number) => (
        <StyledChip
            avatar={option.thumbnailUrl ? <StyledAvatarImage src={option.thumbnailUrl} /> : undefined}
            deleteIcon={<Close16Icon />}
            label={option.screenName}
            {...getTagProps({ index })}
        />
    ));
};

/**
 * user search component
 */
export function UserSelection({ url, ...props }: UserSelectionProps) {
    const [value, setValue] = useState<Collaborator[]>([]);
    const [inputValue, setInputValue] = useState("");
    const [options, setOptions] = useState<Collaborator[]>([]);
    const [loading, setLoading] = useState(false);

    const fetchData = useMemo(
        () =>
            throttle(
                (request: { input: string }, callback: (results?: Collaborator[]) => void) => {
                    fetch(url + request.input)
                        .then((response) => response.json())
                        .then((json) => {
                            callback(json);
                        });
                },
                500
            ),
        [url]
    );

    useEffect(() => {
        let active = true;

        if (inputValue === "") {
            setOptions(value ? value : []);
            return undefined;
        }
        setLoading(true);

        fetchData(
            { input: inputValue },
            (results?: Collaborator[]) => {
                if (active) {
                    let newOptions: Collaborator[] = [];
                    if (value) {
                        newOptions = value;
                    }
                    if (results) {
                        newOptions = [...newOptions, ...results];
                    }
                    setOptions(newOptions);
                    setLoading(false);
                }
            }
        );

        return () => {
            active = false;
        };
    }, [value, inputValue, fetchData]);

    return (
        <StyledAutocomplete
            {...props}
            loading={loading}
            getOptionLabel={(option: Collaborator) => option.screenName}
            options={options}
            renderOption={renderOption}
            renderTags={renderTags}
            isOptionEqualToValue={(option: Collaborator, value: Collaborator) => {
                return option.id === value.id;
            }}
            fullWidth={true}
            onKeyDown={(event) => {
                // when autoComplete input is rendered from an open mui menu
                // and there's a menu item that starts with the entered character, the menu item will be focused,
                // so the autocomplete input will lose focus and the character won't be added to the input's value
                // in order to prevent this behaviour stopPropagation() should be used
                event.stopPropagation();
            }}
            onInputChange={(event, newInputValue, reason) => {
                setInputValue(newInputValue);
                props.onInputChange?.(event, newInputValue, reason);
            }}
        />
    );
}
