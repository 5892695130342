import { KmsTypePublishCategory } from "@kaltura/mediaspace-shared-types";
import { addBaseUrl, encodeEntryNameForURI, translate } from "@kaltura/mediaspace-shared-utils";

export enum EnrichmentType {
    label = "label",
    url = "url",
    all = "all",
}

export enum CategoryType {
    channel = "channel",
    gallery = "gallery",
    externalCategory = "externalCategory",
}

export enum SaveType {
    save = "save",
    crossApplicationUnpublish = "crossApplicationUnpublish",
}

export const galleryLabel = (gallery: KmsTypePublishCategory) =>
    translate("%1 (Gallery ID: %2)", [gallery.name, gallery.id]);
export const channelLabel = (channel: KmsTypePublishCategory) =>
    translate("%1 (Channel ID: %2)", [channel.name, channel.id]);

// Generates a URL for the given category based on its type
const getCategoryUrl = (category: KmsTypePublishCategory, categoryType: CategoryType) => {
    switch (categoryType) {
        case CategoryType.channel:
            return addBaseUrl(`/channel/${encodeEntryNameForURI(category.name)}/${category.id}`);
        case CategoryType.gallery:
            return addBaseUrl(`/category/${encodeEntryNameForURI(category.name)}/${category.id}`);
        case CategoryType.externalCategory:
            // external category will never have url
            return undefined;
        default:
            return undefined;
    }
};

// Generates a label for the given category based on its type
const getCategoryLabel = (category: KmsTypePublishCategory, categoryType: CategoryType) => {
    switch (categoryType) {
        case CategoryType.channel:
            return channelLabel(category);
        case CategoryType.gallery:
            return galleryLabel(category);
        case CategoryType.externalCategory:
            return translate("Category ID: %1", [category.id]);
        default:
            return undefined;
    }
};

// Enriches the given categories with labels and/or URLs based on the enrichment type
export const enrichCategoriesData = (
    categories: KmsTypePublishCategory[],
    categoryType: CategoryType,
    enrichmentType: EnrichmentType
) => {
    const shouldEnrichWithLabel = enrichmentType === EnrichmentType.label || enrichmentType === EnrichmentType.all;
    const shouldEnrichWithUrl = enrichmentType === EnrichmentType.url || enrichmentType === EnrichmentType.all;
    return categories.map((category) => ({
        ...category,
        url: shouldEnrichWithUrl ? getCategoryUrl(category, categoryType) : undefined,
        label: shouldEnrichWithLabel ? getCategoryLabel(category, categoryType) : undefined,
    }));
};

// Enriches the given channels and galleries with labels and/or URLs and combines them into a single array
export const enrichAndCombineCategories = (
    channels: KmsTypePublishCategory[],
    galleries: KmsTypePublishCategory[],
    enrichmentType: EnrichmentType
) => {
    // enrich channel with label, url or both
    const channelLocations = enrichCategoriesData(channels, CategoryType.channel, enrichmentType);
    // enrich gallery with label, url or both
    const galleryLocations = enrichCategoriesData(galleries, CategoryType.gallery, enrichmentType);
    // concat the channels and galleries
    return [...galleryLocations, ...channelLocations];
};
