// React hooks for the methods that are available in ./formatters.ts

import { translateTime } from "./formatters";
import { useMemo } from "react";
import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone"; // dependent on utc plugin

dayjs.extend(utc);
dayjs.extend(timezone);

/**
 * Memoized guessed timezone.
 */
export const useGuessedTimeZone = () => useMemo(() => dayjs.tz.guess(), []);

/**
 * Memoized version of translateTime() - formats date/time, using current language settings and given format+timezone.
 * Tip: use useGuessedTimeZone() to auto-detect the user's timezone.
 *
 * @param timestamp     in seconds
 * @param dateFormat    required format
 * @param timeZone      timezone to use when formatting
 */
export const useTranslateTime = (timestamp: number, dateFormat: string, timeZone?: string) =>
    useMemo(() => (timestamp ? translateTime(timestamp, dateFormat, timeZone) : ""), [timestamp, dateFormat, timeZone]);
