import isEmpty from "lodash/isEmpty";
import { RegisterOptions } from "react-hook-form";

/*
 * Checks if the value from user input is empty.
 * String value is considered empty when it's a zero-length string ("") or a white-space only string (e.g. "    ").
 * Other value types are validated according to lodash's rules: https://lodash.com/docs#isEmpty
 */
export const isEmptyUserInput = (value: any) => (typeof value === "string" ? value.trim() === "" : isEmpty(value));

/*
 * The following regex and function are checking a string (that is coming from user input) to be a valid email address.
 * The angle bracket notation ("Display Name <email.address@mail.server>") is not accepted here.
 */
export const emailAddressRegex =
    /^[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?$/i;
export const isValidEmailAddress = (value: any) => typeof value === "string" && emailAddressRegex.test(value.trim());

/*
 * convert possible "true" | "false" strings to boolean
 */
export const booleanProp = (value: string | boolean | undefined) => String(value).toLowerCase() === "true";

// Normalize the validate callback type to be an object (and not just a plain function)
const normalizeValidateOptions = (validate: RegisterOptions["validate"]) =>
    typeof validate === "function"
        ? {
              default: validate,
          }
        : validate;

/**
 * A helper that extends useForm() native validation for required fields
 * to report whitespace-only field values (e.g. "   ") as empty.
 *
 * Usage: define the "required" validation rule as usual, then pass the validation rules to this function, e.g.
 * register(noWhitespacesValidation({required: true, maxLength: 32}));
 *
 * When the core "required" rule is off, this helper will do nothing,
 * so feel free to use it when the rule is dynamic.
 */
export const noWhitespacesValidation = (rules: RegisterOptions) => {
    if (rules.required) {
        rules = {
            ...rules,
            validate: {
                ...normalizeValidateOptions(rules.validate),
                // Add a validation function that trims the string before checking for emptiness.
                // It's ok to ignore non-string values, the core validation will take care of them.
                required: (value: any) => typeof value !== "string" || value.trim() !== "",
            },
        };
    }

    return rules;
};

/**
 * when field value is an array, make sure it is not empty if field is required.
 * @param rules
 */
export const noEmptyArrayValidation = (rules: RegisterOptions) => {
    if (rules.required) {
        rules = {
            ...rules,
            validate: {
                ...normalizeValidateOptions(rules.validate),
                // Add a validation function that trims the string before checking for emptiness.
                // It's ok to ignore non-string values, the core validation will take care of them.
                required: (value: any) => Array.isArray(value) && value.length > 0,
            },
        };
    }

    return rules;
};
