import { UploadStatus, MediaUploadFileInfo } from "@kaltura/mediaspace-shared-upload";
import { StyledProgressBar, StyledThumbContainer } from "./StyleComponents";
import { getModuleAssetUrl, translate } from "@kaltura/mediaspace-shared-utils";
import { Alert24Icon, NoAccess24Icon } from "@kaltura/ds-react-icons";
import { Loader } from "@kaltura/ds-react-components";
import { KalturaMediaType } from "kaltura-typescript-client/api/types/KalturaMediaType";

export interface ThumbnailProps {
    file: MediaUploadFileInfo;
}
export function Thumbnail({ file }: ThumbnailProps) {
    switch (file.fileMetaData.status) {
        case UploadStatus.PRE_QUEUE:
        case UploadStatus.IN_QUEUE:
            return (
                <StyledThumbContainer status={UploadStatus.IN_QUEUE} className={"kms-ds-upload-media-file-row-thumb"}>
                    <img
                        alt={translate("in queue")}
                        src={getModuleAssetUrl("uploadmedia", "InQueue.svg")}
                        aria-hidden={true}
                    />
                </StyledThumbContainer>
            );

        case UploadStatus.UPLOADING:
            return (
                <StyledThumbContainer status={UploadStatus.UPLOADING} className={"kms-ds-upload-media-file-row-thumb"}>
                    <StyledProgressBar style={{ width: `${file.fileMetaData.progress || 0}%` }} />
                </StyledThumbContainer>
            );

        case UploadStatus.PROCESSING:
            return (
                <StyledThumbContainer
                    status={UploadStatus.PROCESSING}
                    className={"kms-ds-upload-media-file-row-thumb"}
                    style={{ backgroundImage: file.entry?.thumbnailUrl && `url(${file.entry.thumbnailUrl})` }}
                >
                    <div style={{ width: 21, height: 21 }}>
                        <Loader variant={"white"} />
                    </div>
                </StyledThumbContainer>
            );

        case UploadStatus.READY:
            if (file.entry?.mediaType === KalturaMediaType.audio) {
                return (
                    <StyledThumbContainer status={UploadStatus.READY} className={"kms-ds-upload-media-file-row-thumb"}>
                        <img
                            alt={translate("ready")}
                            src={getModuleAssetUrl("uploadmedia", "SoundWave.svg")}
                            aria-hidden={true}
                        />
                    </StyledThumbContainer>
                );
            }
            return (
                <StyledThumbContainer
                    status={UploadStatus.READY}
                    className={"kms-ds-upload-media-file-row-thumb"}
                    style={{ backgroundImage: file.entry?.thumbnailUrl && `url(${file.entry.thumbnailUrl})` }}
                />
            );

        case UploadStatus.CANCELED:
            return (
                <StyledThumbContainer status={UploadStatus.CANCELED} className={"kms-ds-upload-media-file-row-thumb"}>
                    <NoAccess24Icon />
                </StyledThumbContainer>
            );

        case UploadStatus.ENTRY_FAILED:
        case UploadStatus.UPLOAD_FAILED:
            return (
                <StyledThumbContainer
                    status={UploadStatus.UPLOAD_FAILED}
                    className={"kms-ds-upload-media-file-row-thumb"}
                >
                    {" "}
                    {/* doesn't matter which failed status is passed here as long as it matches the one in the styled */}
                    <Alert24Icon />
                </StyledThumbContainer>
            );
    }
    return null;
}

export default Thumbnail;
