import { Component } from "react";
import EmptyPlayerV3, { EmptyPlayerV3Props } from "./EmptyPlayerV3";

export interface LiveMediaPlayerV3Props extends EmptyPlayerV3Props {
    config: any;
    className?: string;
}

interface State {
    player: any;
}

/**
 *  live media player
 */
export class LiveMediaPlayerV3 extends Component<LiveMediaPlayerV3Props, State> {
    componentDidUpdate(prevProps: Readonly<LiveMediaPlayerV3Props>, prevState: Readonly<State>, snapshot?: any) {
        /*
         * The "player" state is being initialized in the "ready" event of the child component.
         * The "config.sources.id" property may be changed by the parent component - it's a sign that
         * the media should be switched.
         * The "player.setMedia()" method should be called when the player is ready and each time the entry ID changes.
         */
        if (
            prevState?.player !== this.state.player
            || prevProps?.config?.sources?.id !== this.props.config.sources.id
        ) {
            const { config } = this.props;
            const { player } = this.state;

            player.setMedia(config);
        }
    }

    handleReady = (player: any, playerId: string) => {
        const { onReady } = this.props;

        if (onReady) {
            onReady(player, playerId);
        }

        this.setState({ player });
    };

    render() {
        const { config } = this.props;
        const initialConfig = {
            ...config,
            sources: {},
        };
        return <EmptyPlayerV3 {...this.props} config={initialConfig} onReady={this.handleReady} />;
    }
}
