import styled from "@emotion/styled";
import { Popper as MuiPopper, PopperProps as MuiPopperProps } from "@mui/material";
import Fade from "@mui/material/Fade";
import React, { PropsWithChildren } from "react";
import { ClickAwayListener } from "../click-away-listener/ClickAwayListener";
import { composeClasses } from "@kaltura/mediaspace-shared-styled";
import { getPopperClass, PopperClasses, popperClasses } from "./popperClasses";
import clsx from "clsx";

export interface PopperProps extends Omit<MuiPopperProps, "children"> {
    /**
     * Whether to show arrow or not
     */
    arrow?: boolean;
    classes?: Partial<PopperClasses>;
    /**
     * Fired when Popper wants to close
     */
    onClose?: () => void;
    /**
     * whether to flip the popper content when scrolling
     * and content gets out of the view port
     */
    flip?: boolean;
}

const useUtilityClasses = (props: Partial<PopperProps>) => {
    const { classes } = props;
    const slots = {
        root: ["root"],
        arrow: ["arrow"],
    };
    return composeClasses(slots, getPopperClass, classes);
};

const Arrow = styled("div")(({ theme }) => ({
    position: "absolute",
    width: "3em",
    height: "3em",
    fontSize: theme.typography.pxToRem(7),
    "&::before": {
        content: '""',
        margin: "auto",
        display: "block",
        width: 0,
        height: 0,
        borderStyle: "solid",
    },
    [`[x-placement*="bottom"] &`]: {
        top: 0,
        left: 0,
        marginTop: "-1em",
        width: "3em",
        height: "1em",
        "&::before": {
            borderWidth: "0 1em 1em 1em",
            borderColor: `transparent transparent ${theme.kaltura.palette.tone1} transparent`,
        },
    },
    [`[x-placement*="top"] &`]: {
        bottom: 0,
        left: 0,
        marginBottom: "-1em",
        width: "3em",
        height: "1em",
        "&::before": {
            borderWidth: "1em 1em 0 1em",
            borderColor: `${theme.kaltura.palette.tone1} transparent transparent transparent`,
        },
    },
    [`[x-placement*="right"] &`]: {
        left: 0,
        marginLeft: "-1em",
        height: "3em",
        width: "1em",
        "&::before": {
            borderWidth: "1em 1em 1em 0",
            borderColor: `transparent ${theme.kaltura.palette.tone1} transparent transparent`,
        },
    },
    [`[x-placement*="left"] &`]: {
        right: 0,
        marginRight: "-1em",
        height: "3em",
        width: "1em",
        "&::before": {
            borderWidth: "1em 0 1em 1em",
            borderColor: `transparent transparent transparent ${theme.kaltura.palette.tone1}`,
        },
    },
}));

const StyledPopper = styled(MuiPopper)(({ theme }) => ({
    borderRadius: theme.kaltura.shape.roundness1,
    border: `solid 1px ${theme.kaltura.palette.tone5}`,
    boxShadow: theme.kaltura.elevations.medium,
    zIndex: 1000,
    overflow: "hidden",
    [`.${popperClasses.root}`]: {
        padding: theme.spacing(2),
        backgroundColor: theme.kaltura.palette.tone8,
    },
}));

export function Popper({
    arrow = false,
    children,
    transition = true,
    placement = "top",
    onClose,
    className,
    flip = true,
    open,
    ...rest
}: PropsWithChildren<PopperProps>) {
    const [arrowRef, setArrowRef] = React.useState<HTMLDivElement | null>(null);
    const classes = useUtilityClasses(rest);

    return (
        <StyledPopper
            {...rest}
            open={open}
            placement={placement}
            transition={true}
            modifiers={[
                {
                    name: "flip",
                    options: {
                        enabled: true,
                        flipVariationsByContent: true,
                        behavior: [placement, "right", "left", "top"],
                    },
                },
                {
                    name: "preventOverflow",
                    options: {
                        enabled: true,
                        boundariesElement: "scrollParent",
                    },
                },
                {
                    name: "offset",
                    options: {
                        enabled: true,
                        offset: [0, 8],
                    },
                },
                {
                    name: "arrow",
                    options: {
                        enabled: arrow,
                        element: arrowRef,
                    },
                },
            ]}
        >
            {({ TransitionProps }) => (
                <Fade {...TransitionProps} timeout={transition ? 200 : 0}>
                    <div className={clsx(classes.root, className)}>
                        {arrow && <Arrow ref={setArrowRef} className={classes.arrow} />}
                        {onClose ? (
                            <ClickAwayListener onClickAway={onClose}>
                                <div>{children}</div>
                            </ClickAwayListener>
                        ) : (
                            children
                        )}
                    </div>
                </Fade>
            )}
        </StyledPopper>
    );
}

export default Popper;
