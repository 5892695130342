import React from "react";
import { getDsLayoutComponentRenderer } from "@kaltura/mediaspace-shared-layout";

const ChannelModeration = React.lazy(() => import("@mediaspace/features/channel-moderation"));

const ChannelNotificationChecker = React.lazy(
    () => import("@mediaspace/features/channel-moderation/channel-notification-checker/channel-notification-checker")
);

const ApproveAction = React.lazy(
    () => import("@mediaspace/features/channel-moderation/card-actions/approve/approve-container")
);

const RejectAction = React.lazy(
    () => import("@mediaspace/features/channel-moderation/card-actions/reject/reject-container")
);

const render = getDsLayoutComponentRenderer(ChannelModeration);

export { ChannelNotificationChecker, ApproveAction, RejectAction, render };
