import styled from "@emotion/styled";
import { Button } from "@kaltura/ds-react-components";

export const StyledPublishStatus = styled("div")(({ theme }) => ({
    display: "flex",
    gap: theme.spacing(0.5),
    alignItems: "center",
}));

export const StyledButton = styled(Button)(({ theme }) => ({
    padding: "0 0 0 4px",
    height: "auto",
}));

export const StyledContentContainer = styled("div")({
    width: 320,
    maxHeight: 152,
});
