import { isUndefined } from "./helpers";
import { extractDefaultFilterValues, removeDefaultFilterValues } from "./FilterValuesHelper";
import { buildQuerystring } from "kaltura-typescript-client/adapters/utils";
import { baseUrl } from "./kms";
import { QueryParams, SearchFormData } from "@kaltura/mediaspace-shared-types";

const searchActionToViewMap = {
    [baseUrl + "/esearch/search-my-media"]: baseUrl + "/my-media",
    [baseUrl + "/my-media"]: baseUrl + "/my-media",
    [baseUrl + "/user-media"]: baseUrl + "/user-media",
    [baseUrl + "/esearch/search-entries"]: baseUrl + "/esearch/search",
    [baseUrl + "/esearch/search-my-playlists"]: baseUrl + "/my-playlists",
    [baseUrl + "/my-channels"]: baseUrl + "/my-channels",
    [baseUrl + "/channels"]: baseUrl + "/channels",
    [baseUrl + "/esearch/search-channel"]: window.location.pathname,
};

/**
 * Update history with the new action that was performed, usually a search
 * or changes in the filters.
 * @param action
 * @param query
 * @param searchFormData
 */
export const updateHistory = (action: string, query: QueryParams, searchFormData?: SearchFormData) => {
    if (!searchFormData) {
        return;
    }
    const mappedAction = searchActionToViewMap[action];
    if (isUndefined(mappedAction)) {
        console.log(`couldn't find mapping for ${action}`);
        return;
    }
    query = {
        ...query,
        keyword: query.keyword || "",
    };
    const minimizedQuery = removeDefaultFilterValues(
        query,
        extractDefaultFilterValues(searchFormData.filters),
        ["format", "page"]
    );

    const queryString = buildQuerystring(minimizedQuery);
    // // submit the Search to kms, update the history and replace the entries
    window.history.pushState(null, "", `${mappedAction}?${queryString}`);
};

/* check if the user uses IE browser */
export const isIE =
    window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;
