import { ComponentType, lazy, Suspense } from "react";
import { createRoot, Root } from "react-dom/client";
import { ThemeProvider } from "@kaltura/mediaspace-shared-contexts";
import {
    AutoComplete as AutoCompleteComponent,
    Initials as InitialsComponent,
    InitialsProps,
    Loader as LoaderComponent,
} from "@kaltura/ds-react-components";

export { loadPlayerLib } from "@kaltura/mediaspace-shared-utils";

/*
 * This package ports DS components to be used by the legacy code (as a preparation to React migration).
 *
 * Since DS and legacy code are going to use different versions of node, React and other libraries,
 * DS components cannot be imported in a usual way and used directly in the legacy code.
 * That's why the legacy code initializes a new React root element on its side
 * and uses the functions below to render the DS components in it.
 *
 * See components/DsPort/DsComponents for usage.
 */

const KafAddNewMenuComponent = lazy(() => import("@kaltura/mediaspace-shared-kaf-add-new-menu"));

const getComponentRenderer =
    <T extends object>(Component: ComponentType<T>) =>
    (rootElement: HTMLDivElement) => {
        const WrappedComponent = (props: T) => (
            <Suspense fallback={<div />}>
                <ThemeProvider scoped={true}>
                    <Component {...props} />
                </ThemeProvider>
            </Suspense>
        );

        let root: Root | undefined;

        return {
            render: (props: T) => {
                root ??= createRoot(rootElement);
                root.render(<WrappedComponent {...props} />);
            },
            dispose: () => {
                if (root) {
                    root.unmount();
                    root = undefined;
                }
            },
        };
    };

export const KafAddNewMenu = getComponentRenderer(KafAddNewMenuComponent);

export const AutoComplete = getComponentRenderer(AutoCompleteComponent);
export const Initials = getComponentRenderer<InitialsProps>(InitialsComponent);
export const Loader = getComponentRenderer(LoaderComponent);
