import React from "react";
import { FieldError } from "react-hook-form";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { BaseInput } from "@kaltura/ds-react-components";
import { uniqueId } from "lodash";
import { FormFieldContainer, ValidationErrorText } from "../common-styles/CommonStyles";
import { FormLabel } from "../form-label/FormLabel";

export interface TextFieldProps {
    className?: string;
    title: string;
    name: string;
    id?: string;
    readOnly?: boolean;
    placeholder?: string;
    defaultValue?: string;
    error?: FieldError;
    errorMessage?: string;
    required?: boolean;
    type?: string;
    onChange?: React.ChangeEventHandler<HTMLInputElement>;
    onBlur?: React.FocusEventHandler<HTMLInputElement>;
    fullWidth?: boolean;
    isMultiline?: boolean;
    rows?: number;
}

/**
 * text input field for common forms
 */
export const TextField = React.forwardRef<any, TextFieldProps>((props: TextFieldProps, ref) => {
    const {
        title,
        name,
        id = uniqueId("input_"),
        readOnly = false,
        placeholder,
        defaultValue,
        error,
        errorMessage,
        required = false,
        onChange,
        onBlur,
        type = "text",
        fullWidth = true,
        isMultiline = false,
        rows = isMultiline ? 2 : undefined,
    } = props;

    return (
        <FormFieldContainer className={"kms-ds-text-field"}>
            <FormLabel
                htmlFor={id}
                className={"kms-ds-text-field-label"}
                required={required}
                text={title}
                fontWeightBold={true}
            />
            <BaseInput
                id={id}
                fullWidth={fullWidth}
                error={!!error}
                inputRef={ref}
                type={type}
                name={name}
                aria-invalid={error ? "true" : "false"}
                readOnly={readOnly}
                placeholder={placeholder}
                defaultValue={defaultValue}
                autoComplete={type === "email" ? "email" : undefined}
                onChange={onChange}
                onBlur={onBlur}
                required={required}
                className={"kms-ds-text-input-field"}
                multiline={isMultiline}
                rows={rows}
            />
            <div aria-live="polite">
                {error && errorMessage && (
                    <ValidationErrorText variant={"body2"} className={"kms-ds-text-field-error"}>
                        {errorMessage}
                    </ValidationErrorText>
                )}
                {error && !errorMessage && (
                    <ValidationErrorText variant={"body2"} className={"kms-ds-text-field-error"}>
                        {translate("%1 is required", [title])}
                    </ValidationErrorText>
                )}
            </div>
        </FormFieldContainer>
    );
});
