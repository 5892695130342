import { ReactNode, useState } from "react";
import { Tooltip, tooltipClasses, TooltipProps } from "@kaltura/ds-react-components";
import TruncateMarkup from "react-truncate-markup";
import styled from "@emotion/styled";

export interface TruncateWithTooltipProps {
    text: ReactNode;
    className?: string;
    tooltipPlacement?: TooltipProps["placement"];
    tokenize?: string;
    children: ReactNode;
    lines?: number;
}

const StyledTooltip = styled(Tooltip)(({ theme }) => ({
    [`&.${tooltipClasses.tooltip}`]: {
        fontWeight: theme.kaltura.typography.fontWeightBold,
    },
    [`& .${tooltipClasses.arrow}::before`]: {
        borderTopLeftRadius: theme.kaltura.shape.roundness1,
    },
}));

/**
 * This component shows truncated or not truncated text.
 * If the text is truncated the full text is shown inside a tooltip.
 * children should be wrapped by a single DOM element
 * when children is a focusable element, make sure to attach an aria-label to it
 * so that screen readers will read the full text when truncated
 *
 * a11y update:
 * describeChild=true is always passed to the Tooltip component
 * mui automatically adds aria-label to the element it wraps
 * in TruncateWithTooltip's case, it is always a span
 * which throws an a11y violation about adding aria-label to a span that has no defined role.
 * with describeChild=true, a title is added to the wrapped span instead of aria-label
 *
 */
export const TruncateWithTooltip = ({
    text,
    className,
    tooltipPlacement = "bottom",
    tokenize,
    children,
    lines = 1,
}: TruncateWithTooltipProps) => {
    const [showTooltip, setShowTooltip] = useState<boolean>(false);

    return (
        <StyledTooltip
            className={className}
            title={showTooltip ? text : ""}
            placement={tooltipPlacement}
            describeChild={true}
        >
            <span className={"chromatic-ignore"}>
                <TruncateMarkup
                    lines={lines}
                    tokenize={tokenize}
                    onTruncate={(wasTruncated) => setShowTooltip(wasTruncated)}
                >
                    {children}
                </TruncateMarkup>
            </span>
        </StyledTooltip>
    );
};
