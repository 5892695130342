import StyledIllustration from "./StyledIllustration";

export const MailIllustration = (
    <StyledIllustration
        fileName={"Mail.svg"}
        xmlns="http://www.w3.org/2000/svg"
        width="300"
        height="300"
        fill="none"
        viewBox="0 0 300 300"
    >
        <g>
            <path
                fill="#006EFA"
                fillRule="evenodd"
                d="M181.358 63.362c-9.204-1.579-17.945 4.603-19.524 13.807l-6.117 35.661c-1.579 9.204 4.603 17.946 13.807 19.525l63.258 10.851c9.204 1.579 17.945-4.603 19.524-13.807l6.117-35.661c1.579-9.204-4.602-17.946-13.807-19.525l-63.258-10.85Zm65.602 27.905a.5.5 0 0 0-.42-.908l-40.362 18.691a3.728 3.728 0 0 1-4.315-.865l-27.679-30.22a.5.5 0 0 0-.738.675l27.68 30.22a4.726 4.726 0 0 0 5.472 1.097l40.362-18.69Z"
                className="Primary"
                clipRule="evenodd"
            />
            <g className="Tone1">
                <path
                    fill="#333"
                    fillRule="evenodd"
                    d="M100.947 59.932a8 8 0 0 1 6.493-9.265l27.577-4.85a8 8 0 0 1 9.265 6.493l2.771 15.758a8 8 0 0 1-6.493 9.265l-27.577 4.85a8 8 0 0 1-9.265-6.493l-2.771-15.758Zm37.777-6.938a.5.5 0 0 1 .004.707l-13.545 13.684a2.5 2.5 0 0 1-2.972.437l-15.594-8.49a.5.5 0 0 1 .478-.879l15.594 8.49a1.5 1.5 0 0 0 1.784-.261l13.544-13.684a.5.5 0 0 1 .707-.004Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#333"
                    d="M126 180.5c-7.049 0-13.942.445-19.615 2.951-5.713 2.523-10.152 7.12-12.313 15.31-.86 3.258-1.602 6.659-2.35 10.102l-.078.365c-.722 3.328-1.452 6.693-2.302 10.024-1.761 6.906-4.028 13.628-7.763 19.479C74.167 250.338 61.957 252.5 50.5 252.5a.5.5 0 0 0 0 1c11.543 0 24.222-2.172 31.921-14.231 3.82-5.983 6.119-12.825 7.89-19.77.854-3.349 1.588-6.73 2.309-10.054l.08-.37c.748-3.446 1.486-6.825 2.339-10.059 2.086-7.906 6.324-12.254 11.75-14.65 5.465-2.415 12.171-2.866 19.211-2.866a.5.5 0 0 0 0-1Z"
                />
                <path
                    fill="#333"
                    fillRule="evenodd"
                    d="M130.705 107.633c-3.767-1.216-8.346-.91-12.067-.194-5.4 1.04-9.575 4.814-12.726 9.415-3.153 4.604-5.322 10.093-6.696 14.685-.48 1.608-.928 3.206-1.372 4.793v.002c-1.243 4.438-2.462 8.793-4.331 13.047-2.454 5.585-5.75 10.819-10.088 15.052-4.656 4.545-10.67 7.106-17.097 8.574-1.737.397-3.5.713-5.274.968a42.64 42.64 0 0 0-.57-2.533c-1.572-6.099-4.547-11.709-9.707-15.202-4.332-2.934-9.276-4.351-14.454-2.828-4.4 1.294-7.864 4.63-8.69 9.369-.492 2.823-.022 5.115 1.123 6.947 1.138 1.822 2.914 3.141 4.956 4.084 4.07 1.882 9.325 2.329 13.188 2.215 4.247-.125 8.803-.336 13.343-.932.856 4.971.908 10.123.534 14.643-.519 6.272-1.099 12.55-2.004 18.765a.5.5 0 0 0 .99.144c.91-6.247 1.491-12.55 2.01-18.827.378-4.568.33-9.796-.539-14.861a66.085 66.085 0 0 0 5.316-.977c6.534-1.493 12.74-4.116 17.574-8.833 4.45-4.343 7.812-9.693 10.305-15.366 1.9-4.324 3.146-8.778 4.394-13.237v-.002c.44-1.573.882-3.149 1.351-4.718 1.358-4.538 3.49-9.92 6.563-14.407 3.076-4.491 7.053-8.028 12.09-8.998 3.659-.705 8.041-.975 11.571.164a.5.5 0 0 0 .307-.952Zm-71.189 64.059c.206.797.387 1.605.546 2.418-4.466.583-8.965.793-13.191.918-3.799.112-8.873-.336-12.74-2.123-1.928-.891-3.523-2.1-4.527-3.707-.998-1.597-1.441-3.633-.986-6.246.752-4.312 3.9-7.379 7.987-8.581 4.811-1.415 9.448-.123 13.612 2.697 4.886 3.308 7.762 8.661 9.3 14.624Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#333"
                    d="M55.2 114.154a.5.5 0 1 0-.4.916c5.61 2.459 12.517.801 16.176-4.179 1.63-2.217 2.974-4.636 4.231-7.079.453-.879.892-1.758 1.33-2.632v-.003c.784-1.568 1.56-3.12 2.391-4.633 2.578-4.697 5.593-8.846 10.596-11.276a.5.5 0 1 0-.437-.9c-5.284 2.567-8.425 6.937-11.035 11.695-.839 1.527-1.632 3.113-2.422 4.693v.002c-.436.87-.87 1.739-1.312 2.596-1.25 2.429-2.566 4.792-4.148 6.945-3.368 4.583-9.765 6.136-14.97 3.855ZM190.935 165.192a.5.5 0 0 1-.084.702l-20.654 16.274a3.188 3.188 0 0 1-3.753.141l-20.087-13.519a.5.5 0 0 1 .558-.83l20.088 13.519a2.187 2.187 0 0 0 2.575-.096l20.654-16.275a.502.502 0 0 1 .703.084Z"
                />
                <path
                    fill="#333"
                    fillRule="evenodd"
                    d="M148.694 157.718c-6.2.405-10.897 5.76-10.491 11.96l1.404 21.454c.406 6.2 5.761 10.897 11.961 10.492l38.418-2.515c6.2-.406 10.897-5.761 10.491-11.96l-1.404-21.455c-.406-6.2-5.761-10.897-11.961-10.491l-38.418 2.515Zm-9.493 11.895c-.37-5.649 3.91-10.528 9.559-10.898l38.417-2.514c5.649-.37 10.528 3.91 10.898 9.559l1.404 21.454c.37 5.649-3.91 10.528-9.559 10.897l-38.417 2.515c-5.649.369-10.528-3.91-10.898-9.559l-1.404-21.454Z"
                    clipRule="evenodd"
                />
                <path
                    fill="#333"
                    d="M251.987 95.77c7.621 1.307 14.858-3.81 16.165-11.432 1.308-7.62-3.81-14.858-11.431-16.165-7.621-1.307-14.858 3.81-16.166 11.431-1.307 7.621 3.811 14.859 11.432 16.166Z"
                />
            </g>
        </g>
    </StyledIllustration>
);
