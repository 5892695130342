import { MultipleSessionNotification } from "./types";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { StyledNotificationButtons, StyledNotificationTitle } from "./NotificationContent";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { Button } from "@kaltura/ds-react-components";

export interface MultipleSessionNotificationProps {
    id: number;
    notification: MultipleSessionNotification;
}

/**
 * Channel Tv - notification content notifying about few sessions about to start
 */
export const MultipleSessionsNotification = ({ notification, id }: MultipleSessionNotificationProps) => {
    const { onBrowseSessions, upcomingSessions } = notification;
    const sendButtonAnalytics = useButtonAnalytics();

    const browseSessions = () => {
        sendButtonAnalytics(
            "TV guide notification - Few upcoming - Browse sessions",
            ButtonClickAnalyticsType.EXPAND,
            notification.analyticsEntryId
        );
        onBrowseSessions(id);
    };

    return (
        <>
            <StyledNotificationTitle>{translate("See what's coming up")}</StyledNotificationTitle>
            <div>{translate("There are %1 sessions coming up to choose from.", [upcomingSessions.length])}</div>
            <StyledNotificationButtons>
                <Button color={"primary"} size={"large"} fullWidth={true} onClick={browseSessions}>
                    {translate("Browse next sessions")}
                </Button>
            </StyledNotificationButtons>
        </>
    );
};
