import { ScheduledSession, EventPresentersList } from "@kaltura/mediaspace-shared-event-platform-event-list";
import { Typography } from "@kaltura/ds-react-components";
import { WithVerticalTimeBar } from "./WithVerticalTimeBar";
import { BoxLink, TruncateWithTooltip, StatusLive } from "@kaltura/mediaspace-shared-ui";
import styled from "@emotion/styled";
import { useButtonAnalytics, useCurrentTimeForAnimation, useDateFormat } from "@kaltura/mediaspace-shared-hooks";
import { getEntryUrl, translate } from "@kaltura/mediaspace-shared-utils";
import { Box } from "@kaltura/mediaspace-shared-styled";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";

/*
 * Channel TV agenda item - session card.
 */

export interface SidebarSessionItemProps {
    channelId: number;
    session: ScheduledSession;
    isActive?: boolean;
}

export const SidebarSessionItem = ({ channelId, session, isActive }: SidebarSessionItemProps) => {
    const {
        id,
        name,
        presenters = [],
        schedulingData: {
            start: { timestamp: startTime },
            end: { timestamp: endTime },
        },
    } = session;

    const sendButtonAnalytics = useButtonAnalytics();

    const { formattedScheduledStartTime, formattedScheduledEndTime } = useDateFormat(startTime * 1000, endTime * 1000);

    const now = useCurrentTimeForAnimation(undefined, 1000) / 1000;
    const isLive = now >= startTime && now < endTime;

    return (
        <WithVerticalTimeBar start={startTime} end={endTime}>
            <StyledContainer
                isActive={isActive}
                href={getEntryUrl(id, channelId, name)}
                onClick={() =>
                    sendButtonAnalytics("CnC - TV guide session card link", ButtonClickAnalyticsType.LINK, id)
                }
            >
                <StyledTimeRow>
                    {isLive && <StatusLive>{translate("Live")}</StatusLive>}

                    <StyledTime variant={"body2Highlight"}>
                        {formattedScheduledStartTime}
                        {" - "}
                        {formattedScheduledEndTime}
                    </StyledTime>
                </StyledTimeRow>

                <TruncateWithTooltip lines={2} text={name}>
                    <StyledName variant={"body1Highlight"}>{name}</StyledName>
                </TruncateWithTooltip>

                {presenters.length !== 0 && (
                    <StyledPresenters
                        presenters={presenters}
                        compact={presenters.length > 1}
                        analyticsDescription={"CnC - TV guide session card"}
                        entryId={id}
                    />
                )}
            </StyledContainer>
        </WithVerticalTimeBar>
    );
};

const StyledContainer = styled(
    BoxLink,
    {
        shouldForwardProp(propName: PropertyKey) {
            return propName !== "isActive";
        },
    }
)<{ isActive?: boolean }>(({ theme, isActive }) => ({
    display: "block",
    borderRadius: isActive ? theme.kaltura.shape.roundness2 : 0,
    padding: theme.spacing(2),
    backgroundColor: isActive ? theme.kaltura.palette.surfaces.paper : undefined,
}));

const StyledTimeRow = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    // Fixed height for the container to ensure that the height doesn't change when the "live" label appears or disappears
    height: 21,
    gap: theme.spacing(1),
}));

const StyledTime = styled(Typography)(({ theme }) => ({
    display: "block",
    color: theme.kaltura.palette.tone2,
}));

const StyledName = styled(Typography)(({ theme }) => ({
    display: "block",
    marginTop: theme.spacing(1),
}));

const StyledPresenters = styled(EventPresentersList)(({ theme }) => ({
    marginTop: theme.spacing(2),
}));
