import styled from "@emotion/styled";
import { Carousel } from "../carousel/Carousel";
import { CarouselItem } from "../carousel/CarouselItem";
import { useTitle } from "./../list-title/useTitle";
import { ListTitle, ListTitleProps } from "./../list-title/ListTitle";
import { EventPlatformEntry } from "@kaltura/mediaspace-shared-types";
import { systemWidth } from "@kaltura/mediaspace-shared-styled";
import { useTimeShiftedArray } from "@kaltura/mediaspace-shared-hooks";
import { translate } from "@kaltura/mediaspace-shared-utils";

export interface SinglePlaylistProps extends ListTitleProps {
    entries: EventPlatformEntry[];
    playlistId?: string;
    categoryId?: number;
    noLink?: boolean;
    itemsPerPage?: 2 | 3 | 4 | 5;
    timeShift?: number; // time shift in minutes
    fullScreenWidth?: boolean;
}

const StyledTitleContainer = styled(
    "div",
    {
        shouldForwardProp(propName) {
            return propName !== "fullScreenWidth";
        },
    }
)<{ fullScreenWidth?: boolean }>(({ theme, fullScreenWidth }) => ({
    "&": !fullScreenWidth && systemWidth({ theme }),
}));

/**
 * SinglePlaylist component displays vod / live entries in a carousel
 */
export function SinglePlaylist(props: SinglePlaylistProps) {
    const {
        entries,
        playlistId = "",
        categoryId,
        noLink = false,
        itemsPerPage = 4,
        timeShift = 0,
        fullScreenWidth = false,
    } = props;

    const titleProps = useTitle(props);

    const carouselEntries = useTimeShiftedArray(
        entries,
        // 1 minute = 60,000 milliseconds
        timeShift * 60000
    );

    if (!entries.length) {
        return null;
    }

    return (
        <div className="single-playlist kms-ds-homepage-component-single-playlist">
            {titleProps.show && (
                <StyledTitleContainer fullScreenWidth={fullScreenWidth} className="kms-ds-single-playlist-title">
                    <ListTitle
                        {...titleProps}
                        analyticsActionText={"Playlist - See all"}
                        linkAriaLabel={
                            titleProps.translatedTitle
                                ? translate(
                                      // e.g. "see all entries for Product Marketing playlist"
                                      "%1 for %2 playlist",
                                      [
                                          // The linkAriaLabel prop is not in use when translatedLinkString is empty,
                                          // so it's ok that the label will be badly formatted in this case
                                          titleProps.translatedLinkString ?? "",
                                          titleProps.translatedTitle,
                                      ]
                                  )
                                : undefined
                        }
                    />
                </StyledTitleContainer>
            )}
            <Carousel
                items={carouselEntries}
                itemComponent={CarouselItem}
                itemProps={{
                    playlistId,
                    categoryId,
                    noLink,
                }}
                currentCardsNumberInSlides={itemsPerPage}
                minPossibleCardsNumberInSlides={itemsPerPage}
                container={"Playlist"}
                fullScreenWidth={fullScreenWidth}
            />
        </div>
    );
}

export default SinglePlaylist;
