import styled from "@emotion/styled";
import {
    AutoComplete,
    autoCompleteClasses,
    AutoCompleteProps,
    Checkbox,
    Typography,
} from "@kaltura/ds-react-components";
import { Close16Icon } from "@kaltura/ds-react-icons";
import React, { ForwardedRef, useEffect } from "react";
import { AutocompleteRenderGetTagProps, AutocompleteRenderOptionState, Chip } from "@mui/material";
import { LabelledValue } from "@kaltura/mediaspace-shared-types";
import throttle from "lodash/throttle";

export interface LiveAutoCompleteProps extends AutoCompleteProps {
    /**
     * the url to call when fetching the options
     */
    url: string;
    /**
     * wait time between fetch options calls in milliseconds
     */
    throttleWait?: number;
    /**
     * function to determine the string value of a given option
     */
    getOptionLabel?: (option: any) => string;
    /**
     * function to determine if the option represents the given value
     */
    isOptionEqualToValue?: (option: any, value: any) => boolean;

    inputRef?: ForwardedRef<HTMLInputElement>;

    /**
     * smallest number of characters that will trigger a search
     */
    minInputLength?: number;

    /**
     * should disable fetch api calls
     */
    disableFetch?: boolean;
}

const StyledChip = styled(Chip)(({ theme }) => ({
    borderRadius: theme.kaltura.shape.roundness1,
    backgroundColor: theme.kaltura.palette.tone7,
    color: theme.kaltura.palette.tone1,
    fontSize: theme.typography.pxToRem(14),
    fontWeight: theme.kaltura.typography.fontWeightBold,
    height: theme.typography.pxToRem(24),
    display: "flex",
    alignItems: "center",
    lineHeight: theme.typography.pxToRem(16),
    "&.TagRoot": {
        maxWidth: theme.typography.pxToRem(220),
    },
    "& .TagDeleteIcon": {
        color: theme.kaltura.palette.tone1,
    },
}));

const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
    marginRight: theme.spacing(1),
}));

const StyledAutocomplete = styled(AutoComplete)({
    [`& .${autoCompleteClasses.input}`]: {
        paddingRight: "1.75rem",
    },
});

/* override kaltura-ds's AutoComplete, change the use of "title" to "label": */
const getAutocompleteOptionLabel = (option: any) => option.label || "";
const isAutocompleteOptionEqualToValue = (option: any, value: any) => option.value === value.value;

const renderAutocompleteOption = (
    props: React.HTMLAttributes<HTMLLIElement>,
    option: any,
    { selected }: AutocompleteRenderOptionState
) => (
    <li {...props}>
        <StyledCheckbox checked={selected} />
        <Typography variant={"body2Highlight"}>{option.label}</Typography>
    </li>
);

const renderAutocompleteTags = (tagValue: any[], getTagProps: AutocompleteRenderGetTagProps) =>
    tagValue.map((option, index: number) => (
        <StyledChip
            classes={{ root: "TagRoot", deleteIcon: "TagDeleteIcon" }}
            deleteIcon={<Close16Icon />}
            label={option.label}
            {...getTagProps({ index })}
        />
    ));

/**
 * Live AutoComplete Component
 * Fetch results from given URL and let the user select from them
 */
export const LiveAutoComplete = React.forwardRef<any, LiveAutoCompleteProps>(
    (
        {
            url,
            throttleWait = 500,
            getOptionLabel = getAutocompleteOptionLabel,
            isOptionEqualToValue = isAutocompleteOptionEqualToValue,
            renderOption = renderAutocompleteOption,
            renderTags = renderAutocompleteTags,
            inputRef,
            value,
            minInputLength = 1,
            freeSolo,
            multiple = true,
            disableFetch = false,
            ...props
        }: LiveAutoCompleteProps,
        ref
    ) => {
        const [currentValue, setCurrentValue] = React.useState<LabelledValue[]>(() => {
            if (value) {
                return value;
            }
            if (multiple) {
                return [];
            }
            return "";
        });
        const [inputValue, setInputValue] = React.useState("");
        const [options, setOptions] = React.useState<readonly LabelledValue[]>([]);
        const [isLoading, setIsLoading] = React.useState(false);

        const fetchData = React.useMemo(
            () =>
                throttle(
                    (request: { input: string }, callback: (results?: readonly LabelledValue[]) => void) => {
                        setIsLoading(true);
                        fetch(url + request.input)
                            .then((response) => response.json())
                            .then((json) => {
                                callback(json);
                                setIsLoading(false);
                            });
                    },
                    throttleWait
                ),
            [url, throttleWait]
        );

        useEffect(() => {
            setCurrentValue(value ?? []);
        }, [value]);

        useEffect(() => {
            if (inputValue.length < minInputLength) {
                setOptions(currentValue && Array.isArray(currentValue) ? currentValue : []);
                return;
            }
            if (!disableFetch) {
                fetchData(
                    { input: inputValue },
                    (results?: readonly LabelledValue[]) => {
                        if (results) {
                            setOptions(results);
                        }
                    }
                );
            }
        }, [fetchData, inputValue, minInputLength]);

        return (
            <StyledAutocomplete
                {...props}
                multiple={multiple}
                fullWidth={true}
                value={currentValue}
                options={options}
                inputRef={ref}
                loading={isLoading}
                onChange={(event, newValue, reason) => {
                    if (reason === "createOption") {
                        if (newValue === undefined) return;
                        if (typeof newValue === "string") {
                            // user is still typing
                            return;
                        }

                        // option added as string, convert it to LabelledValue
                        newValue = newValue.map((item: unknown) => {
                            if (typeof item === "object") {
                                return item;
                            }
                            return { label: item, value: item };
                        });
                    }
                    // general case: "selectOption" / "removeOption", and patched value from "createOption"
                    setCurrentValue(newValue);
                    props?.onChange && props.onChange(event, newValue, reason);
                }}
                getOptionLabel={getOptionLabel}
                isOptionEqualToValue={isOptionEqualToValue}
                onInputChange={(event, input) => {
                    setInputValue(input);
                }}
                renderOption={renderOption}
                renderTags={renderTags}
                freeSolo={freeSolo}
            />
        );
    }
);

export default LiveAutoComplete;
