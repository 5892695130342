import { FieldType, RegistrationFormInfo } from "@kaltura/mediaspace-shared-types";
import { uniqueId } from "lodash";

export const siteRegistrationUserData = {
    hiddenfield: "hidden value",
    checkbox4: true,
    checkbox5: true,
    dependantDefaultWithValue: "not the default value",
    dependantTagsValue: "value1",
    email: "my@kaltura.com",
};

const tags = [
    { label: "tag1", value: "value1" },
    { label: "tag2", value: "value2" },
    { label: "tag3", value: "value3" },
    { label: "tag4", value: "value4" },
    { label: "tag5", value: "value5" },
];

export const siteRegistrationFormData: Pick<RegistrationFormInfo, "sections"> = {
    sections: [
        {
            title: "first section",
            hideOnEdit: false,
            fields: [
                {
                    id: uniqueId(),
                    name: "hiddenfield",
                    fieldType: FieldType.hidden,
                    displayName: "oh oh...",
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "intro",
                    displayName:
                        'tell us about <i>you</i> <a href="https://google.com" target="_blank">Code of Conduct</a>',
                    fieldType: FieldType.readOnlyText,
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "firstName",
                    displayName: "first name",
                    mandatory: true,
                    mandatoryText: "put in first name, will ya?",
                    placeHolder: "yer first name",
                    fieldType: FieldType.text,
                    userField: true,
                    marketoRefId: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 10,
                },
                {
                    id: uniqueId(),
                    name: "select",
                    displayName: "select field",
                    mandatory: true,
                    mandatoryText: "paint you from somewhere?",
                    placeHolder: "select country",
                    createable: true,
                    fieldType: FieldType.select,
                    options: [
                        { label: "label", value: "value" },
                        { label: "label2", value: "value2" },
                    ],
                    userField: false,
                    marketoRefId: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "selectDefault",
                    displayName: "select field - with default",
                    mandatory: true,
                    mandatoryText: "paint you from somewhere?",
                    placeHolder: "should never see me!",
                    fieldType: FieldType.select,
                    options: [
                        { label: "label", value: "value" },
                        { label: "label2", value: "value2" },
                    ],
                    defaults: [
                        {
                            defaultValue: "value",
                            depends: { field: "", value: "" },
                        },
                    ],
                    userField: false,
                    marketoRefId: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "dependant field",
                    displayName: "dependant field",
                    mandatory: false,
                    mandatoryText: "put in first name, will ya?",
                    fieldType: FieldType.text,
                    userField: true,
                    depends: { field: "select", value: "value" },

                    marketoRefId: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: "registrationType",
                    displayName: "Attendance Type",
                    fieldType: FieldType.registrationType,
                    name: "whatever3",
                    mandatoryText: "mandatory!!",
                    userField: false,
                    maxLength: 10,
                },
            ],
        },
        {
            title: "second section",
            hideOnEdit: false,
            fields: [
                {
                    id: uniqueId(),
                    name: "doesnt matter",
                    displayName: "this is the checkbox section. it can has <b>markup, too</b>",
                    fieldType: FieldType.readOnlyText,
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "checkbox1",
                    displayName: "checkbox <b>field</b>",
                    fieldType: FieldType.checkbox,
                    defaults: [
                        {
                            defaultValue: "true",
                            depends: { field: "", value: "" },
                        },
                    ],
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "checkbox2",
                    displayName: "checkbox <b>field</b> - <i>required</i>",
                    fieldType: FieldType.checkbox,
                    mandatory: true,
                    mandatoryText: "check me, Simor!!!",
                    marketoRefId: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "checkbox3",
                    displayName:
                        " Yes, I'd like Kaltura to share the latest news about Kaltura services and related offerings with me by email, post or telephone. You may unsubscribe from receiving AWS news and offers at any time by following the instructions in the communications received.  AWS handles your information as described in the AWS Privacy Notice.  ",
                    fieldType: FieldType.checkbox,
                    hideOnEdit: true,
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "checkbox4",
                    displayName: "disabled on edit",
                    fieldType: FieldType.checkbox,
                    readOnlyOnEdit: true,
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
            ],
        },
        {
            title: "field value dependency section",
            hideOnEdit: false,
            fields: [
                {
                    id: uniqueId(),
                    name: "select2",
                    displayName: "select field for default values",
                    mandatory: false,
                    mandatoryText: "paint you from somewhere?",
                    placeHolder: "select something and see them jump",
                    fieldType: FieldType.select,
                    options: [
                        { label: "label", value: "value" },
                        { label: "label2", value: "value2" },
                        { label: "label3", value: "value3" },
                    ],
                    userField: false,

                    marketoRefId: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "checkbox5",
                    displayName: "i have a default AND a value",
                    fieldType: FieldType.checkbox,
                    hideOnEdit: false,
                    defaults: [
                        {
                            defaultValue: "true",
                            depends: { field: "select2", value: "value" },
                        },
                    ],
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "checkbox6",
                    displayName: "i have a default",
                    fieldType: FieldType.checkbox,
                    defaults: [
                        {
                            defaultValue: "true",
                            depends: { field: "select2", value: "value" },
                        },
                    ],
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },

                {
                    id: uniqueId(),
                    name: "dependantDefault",
                    displayName: "dependant default value",
                    mandatory: false,
                    mandatoryText: "put in first name, will ya?",
                    fieldType: FieldType.text,
                    userField: true,
                    defaults: [
                        {
                            defaultValue: "its a material world",
                            depends: { field: "select2", value: "value" },
                        },
                        {
                            defaultValue: "testing",
                            depends: { field: "select2", value: "value3" },
                        },
                    ],
                    marketoRefId: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "dependantDefault2",
                    displayName: "default + dependant default value",
                    mandatory: false,
                    mandatoryText: "put in first name, will ya?",
                    fieldType: FieldType.text,
                    userField: true,
                    defaults: [
                        {
                            defaultValue: "true love(depends on nothing)",
                        },
                        {
                            defaultValue: "testing",
                            depends: { field: "select2", value: "value3" },
                        },
                        {
                            defaultValue: "its a material world",
                            depends: { field: "select2", value: "value" },
                        },
                    ],
                    marketoRefId: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "dependantDefaultWithValue",
                    displayName: "dependant default value + user value",
                    mandatory: false,
                    mandatoryText: "put in first name, will ya?",
                    fieldType: FieldType.text,
                    userField: true,
                    defaults: [
                        {
                            defaultValue: "i am the default value",
                            depends: { field: "select2", value: "value" },
                        },
                    ],
                    marketoRefId: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "dependantSelect",
                    displayName: "select field with dependant default",
                    mandatory: false,
                    mandatoryText: "paint you from somewhere?",
                    placeHolder: "select something",
                    fieldType: FieldType.select,
                    options: [
                        { label: "label", value: "value" },
                        { label: "label2", value: "value2" },
                    ],
                    defaults: [
                        {
                            defaultValue: "value",
                            depends: { field: "select2", value: "value" },
                        },
                    ],
                    userField: false,

                    marketoRefId: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "dependantSelect2",
                    displayName: "select field with default + dependant default",
                    mandatory: false,
                    mandatoryText: "paint you from somewhere?",
                    placeHolder: "select something",
                    fieldType: FieldType.select,
                    options: [
                        { label: "label", value: "value" },
                        { label: "label2", value: "value2" },
                    ],
                    defaults: [
                        {
                            defaultValue: "value2",
                            depends: { field: "", value: "" },
                        },
                        {
                            defaultValue: "value",
                            depends: { field: "select2", value: "value" },
                        },
                    ],
                    userField: false,
                    marketoRefId: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "dependantTags",
                    displayName: "I have a dependant default value",
                    multitagInstruction: "select up to 3 tags",
                    tagsLimit: 3,
                    placeHolder: "no relevant",
                    fieldType: FieldType.multitag,
                    options: tags,
                    defaults: [
                        {
                            defaultValue: "value5",
                            depends: { field: "select2", value: "value" },
                        },
                    ],
                    marketoRefId: "",
                    mandatory: true,
                    mandatoryText: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "dependantTagsValue",
                    displayName: "I have a dependant default value + user value",
                    multitagInstruction: "select up to 3 tags",
                    tagsLimit: 3,
                    placeHolder: "no relevant",
                    fieldType: FieldType.multitag,
                    options: tags,
                    defaults: [
                        {
                            defaultValue: "value5",
                            depends: { field: "select2", value: "value" },
                        },
                    ],
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    name: "country",
                    id: uniqueId(),
                    displayName: "country",
                    mandatory: true,
                    mandatoryText: "",
                    maxLength: 200,
                    fieldType: FieldType.select,
                    options: [
                        { label: "Australia", value: "au" },
                        { label: "India", value: "in" },
                    ],
                    defaults: [
                        {
                            defaultValue: "value",
                        },
                    ],
                    userField: false,
                },
                {
                    name: "state",
                    id: uniqueId(),
                    displayName: "state",
                    mandatory: true,
                    mandatoryText: "",
                    maxLength: 200,
                    fieldType: FieldType.select,
                    userField: true,
                    depends: { field: "country", value: "au" },
                    options: [
                        { label: "AU1", value: "au1" },
                        { label: "AU2", value: "au2" },
                    ],
                    defaults: [
                        {
                            defaultValue: "au2",
                            depends: {
                                field: "country",
                                value: "au",
                            },
                        },
                    ],
                },
                {
                    name: "state",
                    id: uniqueId(),
                    displayName: "state",
                    mandatory: true,
                    mandatoryText: "",
                    maxLength: 200,
                    fieldType: FieldType.select,
                    userField: true,
                    depends: { field: "country", value: "in" },
                    options: [
                        { label: "IN1", value: "in1" },
                        { label: "IN2", value: "in2" },
                    ],
                },
                {
                    name: "hiddenByCountry",
                    displayName: "",
                    fieldType: FieldType.hidden,
                    id: "digitalTraining1",
                    mandatory: false,
                    mandatoryText: "",
                    maxLength: 200,
                    readOnlyOnEdit: false,
                    isEditableOnEPM: false,
                    hideOnEdit: false,
                    hideOnReg: false,
                    userField: false,
                    defaults: [
                        {
                            defaultValue: "builders24q1tc",
                            depends: {
                                field: "country",
                                value: "au",
                            },
                        },
                        {
                            defaultValue: "false",
                            depends: {
                                field: "country",
                                value: "in",
                            },
                        },
                    ],
                    allowOnUrl: false,
                    hideOnInvite: false,
                    readOnlyOnInvite: false,
                    hideOnSso: false,
                    readOnlyOnSso: false,
                },
                {
                    name: "hiddenWithDefault",
                    displayName: "",
                    fieldType: FieldType.hidden,
                    id: "digitalTraining2",
                    mandatory: false,
                    mandatoryText: "",
                    maxLength: 200,
                    readOnlyOnEdit: false,
                    isEditableOnEPM: false,
                    hideOnEdit: false,
                    hideOnReg: false,
                    userField: false,
                    defaults: [
                        {
                            defaultValue: "builders24q1tc",
                        },
                    ],
                    allowOnUrl: false,
                    hideOnInvite: false,
                    readOnlyOnInvite: false,
                    hideOnSso: false,
                    readOnlyOnSso: false,
                },
                {
                    name: "textByCountry",
                    displayName: "textByCountry",
                    fieldType: FieldType.text,
                    id: "digitalTraining3",
                    mandatory: false,
                    mandatoryText: "",
                    maxLength: 200,
                    readOnlyOnEdit: false,
                    isEditableOnEPM: false,
                    hideOnEdit: false,
                    hideOnReg: false,
                    userField: false,
                    defaults: [
                        {
                            defaultValue: "Austria default",
                            depends: {
                                field: "country",
                                value: "au",
                            },
                        },
                        {
                            defaultValue: "india default",
                            depends: {
                                field: "country",
                                value: "in",
                            },
                        },
                        {
                            defaultValue: "initialDefaultValue",
                        },
                    ],
                    allowOnUrl: false,
                    hideOnInvite: false,
                    readOnlyOnInvite: false,
                    hideOnSso: false,
                    readOnlyOnSso: false,
                },
                {
                    name: "textByCountryall",
                    displayName: "textByCountryAll",
                    fieldType: FieldType.text,
                    id: "digitalTraining4",
                    mandatory: false,
                    mandatoryText: "",
                    maxLength: 200,
                    readOnlyOnEdit: false,
                    isEditableOnEPM: false,
                    hideOnEdit: false,
                    hideOnReg: false,
                    userField: false,
                    defaults: [
                        {
                            defaultValue: "builders24q1tc",
                        },
                    ],
                    allowOnUrl: false,
                    hideOnInvite: false,
                    readOnlyOnInvite: false,
                    hideOnSso: false,
                    readOnlyOnSso: false,
                },
            ],
        },
        {
            title: "third section",
            hideOnEdit: false,
            fields: [
                {
                    id: uniqueId(),
                    name: "doesnt matter",
                    displayName: "some user tags, and not so user tags section.",
                    fieldType: FieldType.readOnlyText,
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "tags",
                    displayName: "Which topics are you most excited about?",
                    multitagInstruction: "select up to 3 tags",
                    tagsLimit: 3,
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "no relevant",
                    fieldType: FieldType.multitag,
                    options: tags,
                    marketoRefId: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "tags2",
                    displayName: "these will be user tags",
                    multitagInstruction: "select up to 3 tags",
                    tagsLimit: 3,
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "no relevant",
                    fieldType: FieldType.usertag,
                    options: tags,
                    marketoRefId: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "email",
                    displayName: "user id or email",
                    fieldType: FieldType.email,
                    readOnlyOnEdit: true,
                    marketoRefId: "",
                    mandatory: false,
                    mandatoryText: "",
                    placeHolder: "",
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "password",
                    displayName: "aaaargghhh",
                    fieldType: FieldType.password,
                    marketoRefId: "",
                    mandatory: true,
                    mandatoryText: "is mandatory",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
            ],
        },
        {
            title: "hidden on edit section",
            hideOnEdit: true,
            fields: [
                {
                    id: uniqueId(),
                    name: "doesnt matter",
                    displayName: "these guys are hidden on edit.",
                    fieldType: FieldType.checkbox,
                    mandatory: false,
                    marketoRefId: "",
                    mandatoryText: "",
                    placeHolder: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    userField: true,
                    allowOnUrl: true,
                    maxLength: 50,
                },
                {
                    id: uniqueId(),
                    name: "h1",
                    displayName: "you should not see me on edit",
                    mandatory: false,
                    mandatoryText: "put in first name, will ya?",
                    placeHolder: "yer first name",
                    fieldType: FieldType.text,
                    userField: true,
                    marketoRefId: "",
                    readOnlyOnEdit: false,
                    hideOnEdit: false,
                    allowOnUrl: true,
                    maxLength: 50,
                },
            ],
        },
    ],
};
