import BadgeIcon from "../../common/BadgeIcon";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { sendButtonClickAnalytics } from "@kaltura/mediaspace-shared-utils";
import { BadgeProps } from "../../common/types";
import { useBadgesModal } from "../../modal/useBadgesModal";

type BadgeContainerProps = {
    badge: BadgeProps;
    offset?: boolean;
    showBorder?: boolean;
};

/**
 * this is a wrapper component
 * its only purpose is to use the useBadgesModal custom hook,
 * which can't be used specifically for each item in the parent component
 */
const BadgeContainer = (props: BadgeContainerProps) => {
    const { badge, offset = false, showBorder = false } = props;

    const [toggleModal, modal] = useBadgesModal({
        title: badge.name,
        badgeIconUrl: badge.iconUrl,
        description: badge.description,
    });

    const handleIconClick = () => {
        sendButtonClickAnalytics("Show Badge Info - User profile name", "My Profile", ButtonClickAnalyticsType.LAUNCH);
        toggleModal();
    };

    return (
        <>
            <BadgeIcon
                iconUrl={badge.iconUrl}
                isSmall={true}
                onAction={handleIconClick}
                showPointer={true}
                offset={offset}
                showBorder={showBorder}
            />

            {modal}
        </>
    );
};

export default BadgeContainer;
