/**
 * The fixed height of the HeaderMenu component.
 * Could be safely used in position/size calculations.
 *
 * Please note that the header is absolutely positioned when "headerColor" prop equals to "transparent".
 * When DS layout is used, the color of the header could be retrieved from the config context: config.dsLayout.headerColor.
 *
 * See usage example in libs/shared/layout/src/lib/HeroLayout.tsx
 */
export const headerHeight = 56;
