import React from "react";
import styled from "@emotion/styled";
import { Typography, Link } from "@kaltura/ds-react-components";
import { ButtonClickAnalyticsType } from "@kaltura/mediaspace-shared-types";
import { useButtonAnalytics } from "@kaltura/mediaspace-shared-hooks";

export interface ListTitleProps {
    translatedTitle?: string;
    translatedLinkString?: string;
    titleLink?: string;
    linkAriaLabel?: string;
    titleClassName?: string;
    openTitleInNewTab?: boolean;
    analyticsType?: ButtonClickAnalyticsType;
    analyticsActionText?: string;
}

const TitleLine = styled("div")(({ theme }) => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "baseline",
    marginBottom: theme.spacing(2),
}));

const TitleLineTitle = styled(Typography)(({ theme }) => ({
    margin: 0,
    lineHeight: "normal",
}));

/**
 * Title used for components that show a partial list of entries, such as
 * a playlist or category content, with a configurable "show all" link
 *
 * @param props
 * @constructor
 */
export const ListTitle = (props: ListTitleProps) => {
    const {
        translatedTitle = "",
        translatedLinkString = "",
        titleLink = "",
        linkAriaLabel = "",
        openTitleInNewTab = false,
        analyticsType = ButtonClickAnalyticsType.NAVIGATE,
        analyticsActionText,
    } = props;

    const sendButtonAnalytics = useButtonAnalytics();

    const sendAnalytics = () => {
        if (analyticsActionText) {
            sendButtonAnalytics(analyticsActionText, analyticsType);
        }
    };

    return (
        <TitleLine className="kms-ds-homepage-component-title">
            {translatedTitle && (
                <TitleLineTitle variant={"h3"} component={"h2"}>
                    {translatedTitle}
                </TitleLineTitle>
            )}
            {translatedLinkString && titleLink && (
                <Link
                    href={titleLink}
                    target={openTitleInNewTab ? "_blank" : "_self"}
                    aria-label={linkAriaLabel}
                    onClick={sendAnalytics}
                    size={"small"}
                >
                    {translatedLinkString}
                </Link>
            )}
        </TitleLine>
    );
};

export default ListTitle;
