import { ComponentType } from "react";

export interface ChannelSectionProps<T> {
    /**
     * channel id
     */
    categoryId?: number;

    /**
     * active prop is not really used
     * it just to make sure that component is re-rendered
     * when section becomes active
     * to cause truncate markup to calculate
     * lines height again.
     */
    active?: boolean;
    content: T;
    additionalParams?: Record<string, unknown>;
}
export enum SectionIndex {
    SESSIONS = 1,
    MEDIA = 2,
    PLAYLISTS = 3,
    ABOUT = 4,
}

export interface ChannelSection<T = any> {
    content: T;
    name: string;
    component: ComponentType<T>;
    index: SectionIndex;
    additionalParams?: Record<string, unknown>;
}

export type BoardMessage = {
    id: number;
    message: string;
    allowDismiss: boolean;
};
