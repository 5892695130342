export interface ICssNumber {
    toString(): string;

    toCalc(): CssNumberCalc;

    scale(coeff: number): ICssNumber;

    add(cssNumber: ICssNumber): ICssNumber;
}

/**
 * Utility for performing basic math operations with CSS numbers like "16px",
 * with ability to turn them into calc expressions like "calc(100% - 16px)".
 *
 * Usage:
 * 1. Create instances of CssNumber using the constructor directly or one of the parse methods, e.g.
 *    ```
 *    const fullWidth = new CssNumber(100, "%");
 *    const margin = CssNumber.parseValue(theme.spacing(2));
 *    const lineHeight = CssNumber.parseLineHeight(theme.typography.body.lineHeight);
 *    ```
 *    The source values usually come from the theme,
 *    because there's no need of using the utility when all values and units are known in advance.
 * 2. Perform math operations on CSS values by using methods like `scale` and `add`, e.g.
 *    ```
 *    // boxHeight = margin * 2 + lineHeight
 *    const boxHeight = margin.scale(2).add(lineHeight);
 *    ```
 * 3. Transform the calculated value back to string before using it in element's styles, e.g.
 *    ```
 *    const StyledBox = styled(Box)(({theme}) => {
 *      // ...
 *      return {
 *        height: boxHeight.toString(),
 *      };
 *    });
 *    ```
 *
 * Note that the class of a CSS calculation result is not known in advance: it could be either CssNumber or CssNumberCalc.
 * Therefore, it's safer to use the generic ICssNumber interface as the type when storing or passing calculated values.
 */
export class CssNumber implements ICssNumber {
    public readonly value: number;
    public readonly unit: string;

    public constructor(value: number, unit: string) {
        this.value = value;
        this.unit = unit;
    }

    public toString() {
        return this.value + this.unit;
    }

    public toCalc() {
        return new CssNumberCalc([this]);
    }

    public scale(coeff: number) {
        return new CssNumber(this.value * coeff, this.unit);
    }

    public add(cssNumber: ICssNumber) {
        if (cssNumber instanceof CssNumber && cssNumber.unit === this.unit) {
            return new CssNumber(this.value + cssNumber.value, this.unit);
        }

        return this.toCalc().add(cssNumber);
    }

    public static parseValue(value: number | string, unit = "px"): CssNumber {
        if (typeof value === "number") {
            return new CssNumber(value, unit);
        }

        const match = value.match(/^(-?[0-9.]+)([a-z]+)$/i);
        if (!match) {
            throw new Error(`Failed to parse CSS value "${value}"`);
        }
        return new CssNumber(Number(match[1]), match[2]);
    }

    public static parseLineHeight(lineHeight: number | string, fontSize: number | string): CssNumber {
        // If line-height is just a number, it means that it's relative to the font-size
        return typeof lineHeight === "number"
            ? CssNumber.parseValue(fontSize).scale(lineHeight)
            : CssNumber.parseValue(lineHeight);
    }
}

export class CssNumberCalc implements ICssNumber {
    private readonly parts: CssNumber[];

    public constructor(parts: CssNumber[]) {
        this.parts = parts;
    }

    public toString() {
        return `calc(${this.parts.join(" + ")})`;
    }

    public toCalc() {
        return this;
    }

    public scale(coeff: number) {
        return new CssNumberCalc(this.parts.map((part) => part.scale(coeff)));
    }

    public add(cssNumber: ICssNumber) {
        const partsByUnits: Record<string, number> = {};
        for (const { value, unit } of this.parts) {
            partsByUnits[unit] = value;
        }
        for (const { value, unit } of cssNumber.toCalc().parts) {
            partsByUnits[unit] = (partsByUnits[unit] ?? 0) + value;
        }
        return new CssNumberCalc(Object.entries(partsByUnits).map(([unit, value]) => new CssNumber(value, unit)));
    }
}
