import styled from "@emotion/styled";
import { translate } from "@kaltura/mediaspace-shared-utils";
import { Typography } from "@kaltura/ds-react-components";

export const StyledLocationsCount = styled(Typography)({
    display: "inline-flex",
    flexGrow: 1,
});

/**
 * media publish locations count
 */
export function LocationsCount({ count }: { count: number }) {
    if (count === 0) {
        return null;
    }
    return (
        <StyledLocationsCount className="kms-ds-publish-location-count" variant="body2" component="span">
            {count === 1 && translate("Publish in %1 location", [count])}
            {count > 1 && translate("Publish in %1 locations", [count])}
        </StyledLocationsCount>
    );
}
