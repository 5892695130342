import styled from "@emotion/styled";
import { Typography } from "@kaltura/ds-react-components";
import { Box } from "@kaltura/mediaspace-shared-styled";

export const FormFieldContainer = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(3),
}));

export const ValidationErrorText = styled(Typography)(({ theme }) => ({
    color: theme.kaltura.palette.danger.main,
    display: "block",
    marginTop: theme.spacing(1),
}));
