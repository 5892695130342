function SlackSocialLink() {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.36156 14.1108C7.36156 15.0359 6.60586 15.7916 5.68078 15.7916C4.7557 15.7916 4 15.0359 4 14.1108C4 13.1857 4.7557 12.43 5.68078 12.43H7.36156V14.1108Z"
                fill="white"
            />
            <path
                d="M8.2085 14.1108C8.2085 13.1857 8.9642 12.43 9.88928 12.43C10.8144 12.43 11.5701 13.1857 11.5701 14.1108V18.3192C11.5701 19.2443 10.8144 20 9.88928 20C8.9642 20 8.2085 19.2443 8.2085 18.3192V14.1108Z"
                fill="white"
            />
            <path
                d="M9.88928 7.36156C8.9642 7.36156 8.2085 6.60586 8.2085 5.68078C8.2085 4.7557 8.9642 4 9.88928 4C10.8144 4 11.5701 4.7557 11.5701 5.68078V7.36156H9.88928Z"
                fill="white"
            />
            <path
                d="M9.88925 8.20844C10.8143 8.20844 11.57 8.96414 11.57 9.88922C11.57 10.8143 10.8143 11.57 9.88925 11.57H5.68078C4.7557 11.57 4 10.8143 4 9.88922C4 8.96414 4.7557 8.20844 5.68078 8.20844H9.88925Z"
                fill="white"
            />
            <path
                d="M16.6387 9.88922C16.6387 8.96414 17.3944 8.20844 18.3195 8.20844C19.2445 8.20844 20.0002 8.96414 20.0002 9.88922C20.0002 10.8143 19.2445 11.57 18.3195 11.57H16.6387V9.88922Z"
                fill="white"
            />
            <path
                d="M15.7917 9.88925C15.7917 10.8143 15.036 11.57 14.111 11.57C13.1859 11.57 12.4302 10.8143 12.4302 9.88925V5.68078C12.4302 4.7557 13.1859 4 14.111 4C15.036 4 15.7917 4.7557 15.7917 5.68078V9.88925Z"
                fill="white"
            />
            <path
                d="M14.111 16.6384C15.036 16.6384 15.7917 17.3941 15.7917 18.3192C15.7917 19.2443 15.036 20 14.111 20C13.1859 20 12.4302 19.2443 12.4302 18.3192V16.6384H14.111Z"
                fill="white"
            />
            <path
                d="M14.111 15.7916C13.1859 15.7916 12.4302 15.0359 12.4302 14.1108C12.4302 13.1857 13.1859 12.43 14.111 12.43H18.3194C19.2445 12.43 20.0002 13.1857 20.0002 14.1108C20.0002 15.0359 19.2445 15.7916 18.3194 15.7916H14.111Z"
                fill="white"
            />
        </svg>
    );
}

export default SlackSocialLink;
