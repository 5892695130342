import { ElementType } from "react";
import { KmsTypePublishCategory } from "@kaltura/mediaspace-shared-types";
import { Tag, Tooltip } from "@kaltura/ds-react-components";
import styled from "@emotion/styled";

export interface CategoryBubbleType extends KmsTypePublishCategory {
    url?: string;
    label?: string;
}

interface CategoryBubbleProps {
    category: CategoryBubbleType;
    onClick?: () => void;
    onDelete?: (category: CategoryBubbleType) => void;
}

const StyledCategoryBubble = styled(Tag)(({ theme }) => ({
    marginRight: theme.spacing(1),
    border: "none",
    backgroundColor: theme.kaltura.palette.tone7,
    width: "fit-content",
}));

/**
 * Category bubble - shows a category chip (tag)
 * can be a link to the category page - when the chip is clicked
 * or a button to remove the category - when the delete icon is clicked
 * it should not be both at the same time
 */
export function CategoryBubble({ category, onClick, onDelete }: CategoryBubbleProps) {
    // add link props if bubble is a category link
    const tagLinkProps: { component?: ElementType; href?: string; target?: string } = category.url
        ? { component: "a", href: category.url, target: "_blank" }
        : {};

    const handleDelete = () => {
        onDelete?.(category);
    };

    return (
        <Tooltip title={category.label}>
            <StyledCategoryBubble
                className="kms-ds-publish-media-category-chip"
                label={category.name}
                onClick={onClick}
                onDelete={onDelete ? handleDelete : undefined}
                {...tagLinkProps}
            />
        </Tooltip>
    );
}
