import { useCallback } from "react";
import { Config, useConfig } from "@kaltura/mediaspace-shared-data-kms-config";
import { ScanQrAnalyticsType } from "@kaltura/mediaspace-shared-types";

/**
 * Custom hook to send scan QR analytics
 */
export const useScanQrAnalytics = () => {
    const config: Config = useConfig();

    /**
     * a React wrapper for sending scan-qr analytics events to KMS
     */
    return useCallback(
        (actionName: string, scanType: ScanQrAnalyticsType, value: string) => {
            const analyticsContext = config.analytics?.analyticsContext;
            const prefixedActionName = `${analyticsContext ? analyticsContext + " " : ""}${actionName}`;
            document.dispatchEvent(
                new CustomEvent(
                    "kmsScanQrAnalytics",
                    {
                        detail: {
                            name: prefixedActionName,
                            type: scanType,
                            value,
                        },
                    }
                )
            );
        },
        [config]
    );
};
