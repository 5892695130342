import styled from "@emotion/styled";
import { BaseInput, BaseInputProps } from "@kaltura/ds-react-components";
import { ChangeEvent, ForwardedRef, forwardRef, useEffect, useMemo, useState } from "react";
import { uniqueId } from "lodash";

export interface InputWithCharCountProps extends BaseInputProps {
    /**
     * max number of chars
     */
    maxLength?: number;

    /**
     * should the counter be visible?
     * @default true
     */
    showCount?: boolean;

    inputRef?: ForwardedRef<HTMLInputElement>;
}

const StyledInputWithCharCount = styled(
    "div",
    {
        shouldForwardProp: (prop) => prop !== "fullWidth",
    }
)<{ fullWidth?: boolean }>(({ fullWidth }) => ({
    ...(
        fullWidth && {
            width: "100%",
        }
    ),
}));

const CounterWrap = styled("div")(({ theme }) => ({
    width: "100%",
    textAlign: "right",
    marginTop: theme.spacing(1),
    color: theme.kaltura.palette.tone2,
}));

export const InputWithCharCount = forwardRef<any, InputWithCharCountProps>(
    (
        {
            maxLength,
            showCount = true,
            inputRef,
            fullWidth,
            inputProps = {},
            value,
            defaultValue,
            id,
            onChange,
            ...rest
        }: InputWithCharCountProps,
        ref
    ) => {
        const [currentValue, setCurrentValue] = useState<string | unknown>();

        const realId = useMemo(() => {
            if (id) {
                return id;
            }
            return uniqueId("ccinput_");
        }, [id]);

        const getModifiedInputProps = () => ({
            ...inputProps,
            ...(maxLength ? { maxLength: maxLength } : {}),
            ...(showCount ? { [`aria-describedby`]: `${realId}_cnt` } : {}),
        });

        useEffect(() => {
            if (value !== undefined) {
                setCurrentValue(value);
            }
            else if (defaultValue !== undefined) {
                setCurrentValue(defaultValue);
            }
        }, [value, defaultValue]);

        const getCounterText = () => {
            const nChars = currentValue ? (currentValue as string).length : 0;
            if (maxLength) {
                return `${nChars}/${maxLength}`;
            }
            return nChars.toString();
        };

        const changeHandler = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
            setCurrentValue(event.target.value);
            !!onChange && onChange(event);
        };

        return (
            <StyledInputWithCharCount ref={ref} fullWidth={fullWidth}>
                <BaseInput
                    {...rest}
                    id={realId}
                    inputProps={getModifiedInputProps()}
                    fullWidth={true}
                    ref={inputRef}
                    value={currentValue ?? ""}
                    onChange={changeHandler}
                    className="kms-ds-form-item-input"
                />
                {showCount && (
                    <CounterWrap className="kms-ds-form-item-counter">
                        <span id={`${realId}_cnt`} aria-live={"polite"}>
                            {getCounterText()}
                        </span>
                    </CounterWrap>
                )}
            </StyledInputWithCharCount>
        );
    }
);

export default InputWithCharCount;
